import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
import getFloorplanImageZoomInFactor from '../../utils/navigationHelpers';
import useFullScreen from '../hooks/useFullscreen';
import useCanvasSize from '../hooks/useNavigationSize';
const GlobalContext = createContext(null);
export const GlobalsContextProvider = ({ children, engine, assetConfig, hostRootRef, appContext, analytics, }) => {
    const { isFullscreen } = useFullScreen();
    const { squareSize, navRadius, setSquareSize } = useCanvasSize();
    const floorplanImageZoomInFactor = getFloorplanImageZoomInFactor();
    return (_jsx(GlobalContext.Provider, Object.assign({ value: {
            engine,
            assetConfig,
            isFullscreen,
            squareSize,
            navRadius,
            setSquareSize,
            floorplanImageZoomInFactor,
            hostRootRef,
            appContext,
            analytics,
        } }, { children: children })));
};
export default function useGlobalContext() {
    const context = useContext(GlobalContext);
    if (!context)
        throw new Error('useGlobalContext must be used within a GlobalsContextProvider');
    return context;
}
