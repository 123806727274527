import type { SceneConfig } from '@g360/vt-types';
import { hexToRGB } from '@g360/vt-utils';
import { Mixin } from 'ts-mixer';

import Matrix from '../../common/Matrix';
import RendererDebug from '../../common/RendererDebug';
import { hslToRgb } from '../../common/Utils';
import { Utils } from '../../index';
import type { ProgramName } from '../../types/internal';
import WebGLProgram from '../mixins/Program';
import type SphereProgram from '../SphereProgram';
import fragmentShader from './debugGeometry.fs.glsl';
import vertexShader from './debugGeometry.vs.glsl';

const permanentlyDisabled = true;
/**
 * Doesn't work
 * Needs fixing
 * Turn on by enabling debug controls in ?dev panel and pressing "'" key
 *
 *  --------------- > i believe the matrices are bad, if so the cameraPosition might not be set
 */
class DebugGeometryProgram extends Mixin(WebGLProgram) {
  canvas: HTMLCanvasElement;
  gl: WebGLRenderingContext;
  sphereProgram: SphereProgram; // for debugging
  name: ProgramName;

  sceneConfig?: SceneConfig;

  pitch = 0;
  yaw = 0;
  fov = 0;
  alpha = 1.0;
  cameraPosition: number[] = [];

  orderIndex = 0;
  vertCoordLocation = 0;
  vertAdditionalDataLocation = 0;
  matrixPerspectiveLocation: WebGLUniformLocation | null = null;
  matrixRotationLocation: WebGLUniformLocation | null = null;
  matrixScaleLocation: WebGLUniformLocation | null = null;
  matrixCameraPosLocation: WebGLUniformLocation | null = null;

  positionBuffer: WebGLBuffer | null = null;
  vertDataBuffer: WebGLBuffer | null = null;

  geometry: number[] = [];
  geometryAdditionalData: number[] = [];

  t = 0;

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    sphereProgram: SphereProgram,
    name?: ProgramName
  ) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.sphereProgram = sphereProgram;
    this.name = name ?? 'DebugGeometryProgram';
  }

  init(): void {
    if (permanentlyDisabled) return;

    if (__DEV__) {
      this.initShaders(vertexShader, fragmentShader);
      if (this.program) {
        this.vertCoordLocation = this.gl.getAttribLocation(this.program, 'a_vertCoord');
        this.vertAdditionalDataLocation = this.gl.getAttribLocation(this.program, 'a_vertData');

        this.matrixPerspectiveLocation = this.gl.getUniformLocation(this.program, 'u_perspective');
        this.matrixRotationLocation = this.gl.getUniformLocation(this.program, 'u_rotate');
        this.matrixScaleLocation = this.gl.getUniformLocation(this.program, 'u_scale');
        this.matrixCameraPosLocation = this.gl.getUniformLocation(this.program, 'u_cameraPos');

        this.positionBuffer = this.gl.createBuffer();
        this.vertDataBuffer = this.gl.createBuffer();
        this.vertexAttributes = [this.vertCoordLocation, this.vertAdditionalDataLocation];
      }
    }
  }

  loadGeometry(sceneConfig?: SceneConfig): void {
    if (__DEV__) {
      if (sceneConfig) {
        this.sceneConfig = sceneConfig;
      }

      if (this.sceneConfig?.geometry) {
        this.geometry = this.sceneConfig.geometry.flat(2);
        this.createGeometryAdditionalData();
        this.prepStage();
      }
    }
  }

  pushHexColor(hex: string): void {
    if (__DEV__) {
      const color = hexToRGB(hex) as number[];
      // console.log({hex}, {color});

      this.geometryAdditionalData.push(color[0] / 255);
      this.geometryAdditionalData.push(color[1] / 255);
      this.geometryAdditionalData.push(color[2] / 255);
    }
  }

  pushPoint(point: number[]): void {
    if (__DEV__) {
      this.geometry = [...this.geometry, ...point];
    }
  }

  createGeometryAdditionalData(): void {
    if (__DEV__) {
      this.geometryAdditionalData = [];
      for (let i = 0; i < this.geometry.length / 3; i += 9) {
        // same color for entire triangle (9 vertices)
        // const rgb = hslToRgb(Math.random() * 360, 0.6, 0.5); // rainbow
        const rgb = hslToRgb(Math.random() * (230 - 190) + 190, Math.random() * (0.5 - 0.4) + 0.4, 0.6); // blu-ish
        // rgb = [i / this.geometry.length, i / this.geometry.length, i / this.geometry.length]; // grey
        //
        this.geometryAdditionalData = [
          ...this.geometryAdditionalData,
          ...rgb,
          ...rgb,
          ...rgb,
          ...rgb,
          ...rgb,
          ...rgb,
          ...rgb,
          ...rgb,
          ...rgb,
        ];
      }
    }
  }

  prepStage(): void {
    if (__DEV__) {
      this.loadShaders();

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
      this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.geometry), this.gl.STATIC_DRAW);
      this.gl.vertexAttribPointer(this.vertCoordLocation, 3, this.gl.FLOAT, false, 0, 0);

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertDataBuffer);
      this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.geometryAdditionalData), this.gl.STATIC_DRAW);
      this.gl.vertexAttribPointer(this.vertAdditionalDataLocation, 3, this.gl.FLOAT, false, 0, 0);
    }
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  destroy(): void {}

  render(): void {
    if (!__DEV__ || !__DEV_PANEL__) return;
    if (permanentlyDisabled || !this.gl || !RendererDebug.runDebugRender.geometry) return;

    this.draw();
  }

  private draw(): void {
    if (__DEV__ && __DEV_PANEL__) {
      this.loadShaders();
      this.enableVertexAttributes();

      // @ts-expect-error -- dev panel integration
      if (window?.devSettings?.debugControls) {
        // recalculate lines - no need to reload since culling is removed
        // (slow on large geometries)
        if (Math.random() > 0.85) this.loadGeometry();
      }

      this.gl.enable(this.gl.CULL_FACE);
      this.gl.cullFace(this.gl.FRONT);
      this.gl.enable(this.gl.DEPTH_TEST);
      this.gl.depthFunc(this.gl.NOTEQUAL);

      this.t += 0.001;

      const drawDistance = 40000;
      const size = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };
      const geometryPerspectiveMatrix = Matrix.transposeM4(Matrix.perspective(this.fov, size, 0.1, drawDistance));
      this.gl.uniformMatrix4fv(this.matrixPerspectiveLocation, false, new Float32Array(geometryPerspectiveMatrix));
      // console.log('DebugGeometryProgram: set up perspective matrix', geometryPerspectiveMatrix, size);

      const cameraPosMatrix = Matrix.translationM4(
        this.cameraPosition[0],
        this.cameraPosition[1],
        this.cameraPosition[2]
      );

      this.gl.uniformMatrix4fv(this.matrixCameraPosLocation, false, new Float32Array(cameraPosMatrix));

      let modelRotationMatrix = Matrix.identityM3();
      modelRotationMatrix = Matrix.rotateZ(modelRotationMatrix, this.yaw);
      modelRotationMatrix = Matrix.rotateX(modelRotationMatrix, Utils.toRad(-90) + this.pitch);
      // modelRotationMatrix = Matrix.rotateX(modelRotationMatrix, this.pitch);
      modelRotationMatrix = Matrix.m3toM4(modelRotationMatrix);
      this.gl.uniformMatrix4fv(this.matrixRotationLocation, false, new Float32Array(modelRotationMatrix));

      const scaleMatrix = Matrix.scalingM4(1, 1, -1);
      this.gl.uniformMatrix4fv(this.matrixScaleLocation, false, new Float32Array(scaleMatrix));

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
      this.gl.vertexAttribPointer(this.vertCoordLocation, 3, this.gl.FLOAT, false, 0, 0);

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertDataBuffer);
      this.gl.vertexAttribPointer(this.vertAdditionalDataLocation, 3, this.gl.FLOAT, false, 0, 0);

      this.gl.drawArrays(this.gl.LINES, 0, this.geometry.length / 3);

      this.gl.enable(this.gl.BLEND);
      this.gl.disable(this.gl.DEPTH_TEST);
      this.gl.depthFunc(this.gl.LESS); // default
      this.gl.disable(this.gl.CULL_FACE);
      this.disableVertexAttributes();
    }
  }
}

export default DebugGeometryProgram;
