import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import CloseIcon from '../../../icons/CloseIcon';
import IconButton from './IconButton';
const HotSpotImagePreviewOverlay = ({ src, onClose }) => (
// TODO: Implement mobile design
// This has not been tested on mobile, because we currently do not have production tours with images in hotspots
// Once we deploy we can create couple of these in prod and implement mobile design as well.
_jsx(Transition, Object.assign({ show: Boolean(src), className: "pointer-events-auto absolute inset-0 z-hs-image-preview flex select-none items-center justify-center bg-black bg-opacity-50 p-2", enter: "transition duration-400 ease-out", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition duration-400 ease-out", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsxs("div", Object.assign({ className: "relative flex h-full w-full items-center justify-center p-6", onClick: (evt) => {
            // Close on click outside
            if (evt.target === evt.currentTarget) {
                onClose();
            }
        }, onKeyPress: (evt) => {
            // Close on escape
            if (evt.key === 'Escape') {
                onClose();
            }
        }, role: "button", tabIndex: 0 }, { children: [_jsx("img", { src: src !== null && src !== void 0 ? src : '', alt: "hostpot-attachment", className: "max-h-full max-w-full rounded-lg object-contain" }), _jsx(IconButton, Object.assign({ className: "absolute right-2 top-2 sm:right-4 sm:top-4", onClick: onClose }, { children: _jsx(CloseIcon, {}) }))] })) })));
export default HotSpotImagePreviewOverlay;
