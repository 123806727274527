import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext } from 'react';
const ServicesContext = createContext(null);
export const ServicesContextProvider = ({ children, services }) => (_jsx(ServicesContext.Provider, Object.assign({ value: services }, { children: children })));
export default function useServicesContext() {
    const context = useContext(ServicesContext);
    if (!context)
        throw new Error('useServicesContext must be used within a ServicesContextProvider');
    return context;
}
