import { Mixin } from 'ts-mixer';

import { Utils } from '../../..';
import type Renderer from '../../../mixins/Renderer';
import WebGLProgram from '../../mixins/Program';
import type MeasureToolProgram from '..';
import fragmentShader from './zoomScope.fs.glsl';
import vertexShader from './zoomScope.vs.glsl';
import ZoomScopeEventEmitter from './ZoomScopeEventEmitter';

// Zoomed view parameters
// Scope content radius
const SCOPE_CONTENT_SCREEN_PERC = 0.42;
// Scope border width
const SCOPE_BORDER_WIDTH_PX = 5;
// Zoom factor
const ZOOM = 2;

// how far scope is offset from the cursor location
const SCOPE_RADIUS_OFFSET_PX = { x: 0, y: 220 };

class ZoomScopeProgram extends Mixin(WebGLProgram, ZoomScopeEventEmitter) {
  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  renderer: Renderer;

  yaw = 0; // unused
  pitch = 0; // unused
  fov = Utils.toRad(120); // unused

  orderIndex = 0;
  vertexBuffer: WebGLBuffer | null;
  vertexAttribute = 0;
  aspectRatioUniform: WebGLUniformLocation | null = null;
  cursorScreenCoordsUniform: WebGLUniformLocation | null = null;
  cursorIsSetUniform: WebGLUniformLocation | null = null;
  pixelSizeUniform: WebGLUniformLocation | null = null;
  zoomUniform: WebGLUniformLocation | null = null;
  scopeContentRadiusUniform: WebGLUniformLocation | null = null;
  scopeRadiusUniform: WebGLUniformLocation | null = null;
  scopeCoordsUniform: WebGLUniformLocation | null = null;
  scopeCenterCoordsUniform: WebGLUniformLocation | null = null;
  panoTexUniform: WebGLUniformLocation | null = null;
  measureTexUniform: WebGLUniformLocation | null = null;

  ready = false;

  abortController = window.AbortController ? new AbortController() : null;

  vertices = new Float32Array([-1, 1, 1, 1, 1, -1, -1, 1, 1, -1, -1, -1]);

  private mainProgram: MeasureToolProgram;

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    renderer: Renderer,
    mainProgram: MeasureToolProgram
  ) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.mainProgram = mainProgram;

    mainProgram.setZoomScopeProgram(this);

    this.vertexBuffer = this.gl.createBuffer();
  }

  init(): void {
    this.initShaders(vertexShader, fragmentShader);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');
      this.aspectRatioUniform = this.gl.getUniformLocation(this.program, 'u_aspectRatio');
      this.cursorScreenCoordsUniform = this.gl.getUniformLocation(this.program, 'u_screen_coords_cursor');
      this.cursorIsSetUniform = this.gl.getUniformLocation(this.program, 'u_bool_cursor_set');
      this.pixelSizeUniform = this.gl.getUniformLocation(this.program, 'u_pixel_size');
      this.zoomUniform = this.gl.getUniformLocation(this.program, 'u_zoom');
      this.scopeContentRadiusUniform = this.gl.getUniformLocation(this.program, 'u_scope_content_radius');
      this.scopeRadiusUniform = this.gl.getUniformLocation(this.program, 'u_scope_radius');
      this.scopeCoordsUniform = this.gl.getUniformLocation(this.program, 'u_scope_coords');
      this.scopeCenterCoordsUniform = this.gl.getUniformLocation(this.program, 'u_scope_center_coords');
      this.panoTexUniform = this.gl.getUniformLocation(this.program, 'u_tex_pano');
      this.measureTexUniform = this.gl.getUniformLocation(this.program, 'u_tex_measure');

      this.vertexAttributes = [this.vertexAttribute];
    }

    this.ready = true;
  }

  destroy(): void {
    this.abortPending();
    this.destroyEventEmitter();
    this.destroyProgram();
  }

  abortPending(): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = new AbortController();
    }
  }

  render(): void {
    if (!this.mainProgram) return;

    const { renderer } = this;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.draw(renderer.fbTexture1!, renderer.fbTexture2!);
  }

  private draw(panoTexture: WebGLTexture, measureTexture: WebGLTexture): void {
    if (!this.gl || !this.ready) return;

    this.loadShaders();
    this.enableVertexAttributes();

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
    this.gl.enableVertexAttribArray(this.vertexAttribute);
    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, panoTexture);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, measureTexture);

    this.gl.uniform1i(this.panoTexUniform, 1);
    this.gl.uniform1i(this.measureTexUniform, 2);

    const contentRadius = SCOPE_CONTENT_SCREEN_PERC * Math.min(this.gl.drawingBufferWidth, this.gl.drawingBufferHeight);

    // includes the whole scope
    const scopeRadius = this.mainProgram.isHandheld ? contentRadius + SCOPE_BORDER_WIDTH_PX : 0;
    const pixelSize = [1 / this.gl.drawingBufferWidth, 1 / this.gl.drawingBufferHeight];
    const cursorScreenCoords = this.mainProgram.cursorCoords
      ? [this.mainProgram.cursorCoords.screenX, this.mainProgram.cursorCoords.screenY]
      : [0, 0];

    // Get whether scope is over the edge of the screen
    const scopeOverLeft = cursorScreenCoords[0] - scopeRadius / this.gl.drawingBufferWidth;
    const scopeOverRight = cursorScreenCoords[0] + scopeRadius / this.gl.drawingBufferWidth;
    const scopeOverTop =
      cursorScreenCoords[1] + (scopeRadius * 2 + SCOPE_RADIUS_OFFSET_PX.y) / this.gl.drawingBufferHeight >= 1;

    let xOffset = 0;
    if (scopeOverLeft < -1) {
      xOffset = -1 - scopeOverLeft;
    } else if (scopeOverRight > 1) {
      xOffset = 1 - scopeOverRight;
    }

    const yOffset = scopeOverTop
      ? -(scopeRadius + SCOPE_RADIUS_OFFSET_PX.y) / this.gl.drawingBufferHeight
      : (scopeRadius + SCOPE_RADIUS_OFFSET_PX.y) / this.gl.drawingBufferHeight;

    const scopeCoords = [cursorScreenCoords[0] + xOffset, cursorScreenCoords[1] + yOffset];

    const distOverLeft = Math.abs(cursorScreenCoords[0] + 1) - scopeRadius / this.gl.drawingBufferWidth;
    const distOverRight = Math.abs(cursorScreenCoords[0] - 1) - scopeRadius / this.gl.drawingBufferWidth;
    const distOverTop = Math.abs(cursorScreenCoords[1] - 1) - scopeRadius / this.gl.drawingBufferHeight;
    const distOverBottom = Math.abs(cursorScreenCoords[1] + 1) - scopeRadius / this.gl.drawingBufferHeight;

    // Add offsets in case cursor is close to edge of the screen, so we dont try to render from outside the texture
    const scopeCenterCoords = [
      scopeCoords[0] + Math.min(0, distOverLeft) - Math.min(0, distOverRight),
      scopeCoords[1] + Math.min(0, distOverBottom) - Math.min(0, distOverTop),
    ];

    if (scopeRadius > 0)
      this.emit('scope_position.set', {
        x: scopeCoords[0],
        y: scopeCoords[1],
        radius: (scopeRadius / (this.gl.drawingBufferWidth * 2)) * this.canvas.clientWidth,
      });

    let cursorSet = this.mainProgram.cursorCoords !== null ? 1 : 0;
    if (!this.mainProgram.isHandheld) cursorSet = 0;

    this.gl.uniform1f(this.aspectRatioUniform, this.gl.drawingBufferWidth / this.gl.drawingBufferHeight);
    this.gl.uniform2fv(this.pixelSizeUniform, pixelSize);
    this.gl.uniform2fv(this.cursorScreenCoordsUniform, cursorScreenCoords);
    this.gl.uniform1f(this.cursorIsSetUniform, cursorSet);
    this.gl.uniform1f(this.zoomUniform, ZOOM);

    this.gl.uniform1f(this.scopeContentRadiusUniform, this.mainProgram.isHandheld ? contentRadius : 0);
    this.gl.uniform1f(this.scopeRadiusUniform, scopeRadius);
    this.gl.uniform2fv(this.scopeCoordsUniform, scopeCoords);
    this.gl.uniform2fv(this.scopeCenterCoordsUniform, scopeCenterCoords);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);

    this.gl.disable(this.gl.CULL_FACE);
    this.disableVertexAttributes();
  }
}

export default ZoomScopeProgram;
