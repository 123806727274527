import { jsx as _jsx } from "react/jsx-runtime";
import useGlobalContext from '../../../contexts/useGlobalContext';
import useFullScreen from '../../../hooks/useFullscreen';
import FullScreenIcon from '../icons/FullScreenIcon';
const FullScreenSwitch = () => {
    const { analytics } = useGlobalContext();
    const { isFullscreen, toggleFullscreen } = useFullScreen(analytics);
    return (_jsx("button", Object.assign({ type: "button", className: "btn-theme-primary h-10 w-10 p-0", onClick: toggleFullscreen, "data-testid": "fullscreen-btn" }, { children: _jsx(FullScreenIcon, { className: "h-8 w-8", isOpen: isFullscreen }) })));
};
export default FullScreenSwitch;
