import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from 'react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
var BalloonPosition;
(function (BalloonPosition) {
    BalloonPosition["Top"] = "top";
    BalloonPosition["Bottom"] = "bottom";
    BalloonPosition["Left"] = "left";
    BalloonPosition["Right"] = "right";
    BalloonPosition["ScreenCenter"] = "screen-center";
})(BalloonPosition || (BalloonPosition = {}));
const BALLOON_HOTSPOT_OFFSET = 26 + 12;
const DEFAULT_BALLOON_RENDER_CONFIG = {
    balloonClass: '',
    balloonStyle: {},
    triangleClass: '',
    triangleInnerClass: '',
    visible: false,
};
const HotSpotBalloon = ({ children, screenSpacePos, canvasRect, onClose, onClick, bgClasses = 'bg-white', className = '', lockAutoClose = false, rootRef, }) => {
    const mainRef = useRef(null);
    const balloonRef = useRef(null);
    const [balloonDimensions, setBalloonDimensions] = useState(null);
    const [balloonPosition, setBalloonPosition] = useState(null);
    // Necessary on small mobile screens so that it does not go off screen
    const maxWidthOverride = useMemo(() => {
        if (canvasRect.width < 320 + 32)
            return { maxWidth: `${canvasRect.width - 32}px` };
        return {};
    }, [canvasRect.width]);
    const maxHeightOverride = useMemo(() => {
        if (canvasRect.height < 320 + 32)
            return { maxHeight: `${canvasRect.height - 32}px` };
        return {};
    }, [canvasRect.height]);
    useOnClickOutside(rootRef, mainRef, () => {
        onClose();
    }, lockAutoClose);
    const balloonRenderConfig = useMemo(() => {
        if (!balloonDimensions || !screenSpacePos || !canvasRect)
            return DEFAULT_BALLOON_RENDER_CONFIG;
        switch (balloonPosition) {
            case BalloonPosition.Top:
                return {
                    balloonClass: '-translate-x-1/2',
                    balloonStyle: {
                        left: `${screenSpacePos.x}px`,
                        bottom: `${canvasRect.height - screenSpacePos.y + BALLOON_HOTSPOT_OFFSET}px`,
                    },
                    triangleClass: 'left-1/2 -bottom-[4px] w-[12px] translate-x-[4px]',
                    triangleInnerClass: 'origin-top-left rotate-45',
                    visible: true,
                };
            case BalloonPosition.Bottom:
                return {
                    balloonClass: '-translate-x-1/2',
                    balloonStyle: {
                        left: `${screenSpacePos.x}px`,
                        top: `${screenSpacePos.y + BALLOON_HOTSPOT_OFFSET}px`,
                    },
                    triangleClass: 'left-1/2 -top-[8px] w-[12px]',
                    triangleInnerClass: 'origin-top-left rotate-45',
                    visible: true,
                };
            case BalloonPosition.Left:
                return {
                    balloonClass: '-translate-y-1/2',
                    balloonStyle: {
                        right: `${canvasRect.width - screenSpacePos.x + BALLOON_HOTSPOT_OFFSET}px`,
                        top: `${screenSpacePos.y}px`,
                    },
                    triangleClass: 'top-1/2 -right-[10px] -translate-y-[6px] w-[10px]',
                    triangleInnerClass: 'origin-top-left rotate-45',
                    visible: true,
                };
            case BalloonPosition.Right:
                return {
                    balloonClass: '-translate-y-1/2',
                    balloonStyle: {
                        left: `${screenSpacePos.x + BALLOON_HOTSPOT_OFFSET}px`,
                        top: `${screenSpacePos.y}px`,
                    },
                    triangleClass: 'top-1/2 -left-[10px] -translate-y-[6px] w-[10px]',
                    triangleInnerClass: 'origin-top-right -rotate-45',
                    visible: true,
                };
            case BalloonPosition.ScreenCenter:
                return {
                    balloonClass: '-translate-y-1/2 -translate-x-1/2',
                    balloonStyle: {
                        left: `${canvasRect.width / 2}px`,
                        top: `${canvasRect.height / 2}px`,
                    },
                    triangleClass: '',
                    triangleInnerClass: '',
                    visible: true,
                };
            default:
                return DEFAULT_BALLOON_RENDER_CONFIG;
        }
    }, [balloonPosition, balloonDimensions, screenSpacePos, canvasRect]);
    useEffect(() => {
        let timeout = null;
        if (balloonRef.current) {
            timeout = setTimeout(() => {
                if (!balloonRef.current)
                    return;
                const { width, height } = balloonRef.current.getBoundingClientRect();
                setBalloonDimensions({ width, height });
            });
        }
        else {
            timeout = setTimeout(() => {
                var _a;
                if (!balloonRef.current)
                    return;
                const { width, height } = (_a = balloonRef.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
                setBalloonDimensions({ width, height });
            }, 100);
        }
        return () => {
            if (timeout)
                clearTimeout(timeout);
        };
    }, []);
    useEffect(() => {
        if (!balloonDimensions || !screenSpacePos || !canvasRect)
            return;
        const { width, height } = balloonDimensions;
        const { x, y } = screenSpacePos;
        const { width: canvasWidth, height: canvasHeight } = canvasRect;
        const fitsVerticallyInCenter = y + height / 2 < canvasHeight && y - height / 2 > 0;
        const fitsHorizontallyInCenter = x + width / 2 < canvasWidth && x - width / 2 > 0;
        const isRight = x + width + BALLOON_HOTSPOT_OFFSET < canvasWidth && fitsVerticallyInCenter;
        if (isRight) {
            setBalloonPosition(BalloonPosition.Right);
            return;
        }
        const isBottom = y + height + BALLOON_HOTSPOT_OFFSET < canvasHeight && fitsHorizontallyInCenter;
        if (isBottom) {
            setBalloonPosition(BalloonPosition.Bottom);
            return;
        }
        const isLeft = x - width - BALLOON_HOTSPOT_OFFSET > 0 && fitsVerticallyInCenter;
        if (isLeft) {
            setBalloonPosition(BalloonPosition.Left);
            return;
        }
        const isTop = y - height - BALLOON_HOTSPOT_OFFSET > 0 && fitsHorizontallyInCenter;
        if (isTop) {
            setBalloonPosition(BalloonPosition.Top);
            return;
        }
        setBalloonPosition(BalloonPosition.ScreenCenter);
    }, [balloonDimensions, screenSpacePos, canvasRect]);
    if (!screenSpacePos)
        return null;
    return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    _jsx("div", Object.assign({ className: `hot-spot ${className}`, ref: mainRef, onClick: onClick }, { children: _jsxs("div", Object.assign({ className: `${bgClasses} ${balloonRenderConfig.balloonClass} ${balloonRenderConfig.visible ? 'opacity-100' : 'opacity-0'} pointer-events-auto absolute z-[100] inline-block max-h-[360px] min-h-[24px] min-w-[32px] max-w-[320px] rounded-[12px] `, style: Object.assign(Object.assign(Object.assign({}, balloonRenderConfig.balloonStyle), maxWidthOverride), maxHeightOverride), ref: balloonRef }, { children: [_jsx("div", Object.assign({ className: `absolute inline-block ${balloonRenderConfig.triangleClass}` }, { children: _jsx("div", { className: `${bgClasses} h-[18px] ${balloonRenderConfig.triangleInnerClass} transform` }) })), children] })) })));
};
export default HotSpotBalloon;
