import type { Pixel, Pos, ScenePos } from '@g360/vt-types';

import type Engine from '../../../../Engine';
import { toRad } from '../../Angle';
import cameraToPixel from '../cameraToPixel/cameraToPixel';
import pixelToCamera from '../pixelToCamera/pixelToCamera';

const spaceMapper = (
  engine: Pick<Engine, 'getActiveSceneConfig' | 'getCanvasBoundingClientRect' | 'getPitch' | 'getYaw' | 'getFov'>
) => {
  const getBoundingRect = () => engine.getCanvasBoundingClientRect();

  const getYawOffset = () => {
    const scene = engine.getActiveSceneConfig();
    return toRad(scene.camera[3] || 0);
  };

  const getCameraPos = (): Required<ScenePos> => ({
    pitch: engine.getPitch(),
    yaw: engine.getYaw() + getYawOffset(),
    fov: engine.getFov(),
  });

  const cameraPointToPixel = (point: ScenePos) => cameraToPixel(point, getCameraPos(), getBoundingRect());

  const pixelPointToCamera = (point: Pos<Pixel>): ScenePos => {
    const size = getBoundingRect();
    const { pitch, yaw } = pixelToCamera(point, size, getCameraPos());
    return { pitch, yaw };
  };

  const pixelPointToCameraClamped = (point: Pos<Pixel>): ScenePos => {
    const size = getBoundingRect();
    const cameraPos = getCameraPos();
    cameraPos.yaw %= Math.PI * 2;
    if (cameraPos.yaw < 0) {
      cameraPos.yaw = Math.PI * 2 + cameraPos.yaw;
    }
    const { pitch, yaw } = pixelToCamera(point, size, cameraPos);
    return { pitch, yaw };
  };

  const pixelPointToCameraClampedEq = (
    point: Pos<Pixel>,
    forcedCameraPos: Required<ScenePos> | null = null
  ): ScenePos => {
    const size = getBoundingRect();
    const cameraPos = forcedCameraPos || getCameraPos();

    cameraPos.yaw %= Math.PI * 2;

    if (cameraPos.yaw > Math.PI) {
      cameraPos.yaw -= Math.PI * 2;
    }
    if (cameraPos.yaw < -Math.PI) {
      cameraPos.yaw += Math.PI * 2;
    }
    const { pitch, yaw } = pixelToCamera(point, size, cameraPos);
    return { pitch, yaw };
  };

  return {
    cameraPointToPixel,
    pixelPointToCamera,
    pixelPointToCameraClamped,
    pixelPointToCameraClampedEq,
    getCameraPos,
    cameraToPixel,
  };
};

export default spaceMapper;
