import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import PanoSwitcher from '../../altPano/PanoSwitcher';
import MenuCollapse from '../containers/MenuCollapse';
import FullScreenSwitch from './FullScreenSwitch';
import InfoButton from './InfoButton';
import MeasureModeButton from './MeasureModeButton';
import UtilsMenuButton from './UtilsMenuButton';
/** Menu for buttons like fullscreen, autoplay, measurement mode, etc.
 *
 * The menu button is automatically hidden and all child buttons visible if isUtilityMenuCollapsible is false.
 * This should only happen if there is enough space to show all buttons at once without colliding with other components.
 *
 * State for this component is managed by the layout service, because it can be different for each layout
 * and changed by resizing the viewport and toggling other components.
 *
 * @prop children - The buttons to show in the utility menu, passed as children because this depends on the layout used
 */
const UtilsMenu = forwardRef(({ enabledUtils, measureTooltipSide = 'bottom', showPanSwitcher }, ref) => {
    const { layoutService, tourConfigService } = useServices();
    const { isUtilityMenuOpen, isUtilityMenuCollapsible, isNavigationOpen } = useReactive(layoutService, [
        'isUtilityMenuOpen',
        'isUtilityMenuCollapsible',
        'isNavigationOpen',
    ]);
    const { features } = useReactive(tourConfigService, ['features']);
    const handleToggle = () => {
        if (isNavigationOpen) {
            layoutService.toggleNavigation(false);
        }
    };
    return (_jsxs(MenuCollapse, Object.assign({ ref: ref, button: _jsx(UtilsMenuButton, { onToggle: handleToggle }), direction: "right", isOpen: isUtilityMenuOpen, isCollapsible: isUtilityMenuCollapsible }, { children: [showPanSwitcher && _jsx(PanoSwitcher, { className: "sm:hidden" }), enabledUtils.info && features.infoSection && _jsx(InfoButton, {}), enabledUtils.measure && _jsx(MeasureModeButton, { tooltipSide: measureTooltipSide }), enabledUtils.fullscreen && features.fullscreen && _jsx(FullScreenSwitch, {})] })));
});
UtilsMenu.displayName = 'UtilsMenu';
export default UtilsMenu;
