import type { Theme } from '@g360/vt-types';
import { linearScale } from '@g360/vt-utils/';
import { Mixin } from 'ts-mixer';

import { Utils } from '../..';
import type Renderer from '../../mixins/Renderer';
import type { ProgramName } from '../../types/internal';
import WebGLProgram from '../mixins/Program';
import fragmentShader from './colorFilter.fs.glsl';
import vertexShader from './colorFilter.vs.glsl';

class ColorFilterProgram extends Mixin(WebGLProgram) {
  static readonly textureCoords = new Float32Array([0, 1, 1, 1, 1, 0, 0, 0]);
  static readonly geometry = new Float32Array([
    -1,
    1, // top left
    1,
    1, // top right
    1,
    -1, // bottom right
    -1,
    -1, // bottom left
  ]);

  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  renderer: Renderer;
  theme: Theme;
  name: ProgramName;

  yaw = 0; // unused
  pitch = 0; // unused
  fov = Utils.toRad(120); // unused

  orderIndex = 0;
  vertexBuffer: WebGLBuffer | null;
  textureBuffer: WebGLBuffer | null;
  vertexAttribute = 0;
  textureAttribute = 0;
  alphaUniform: WebGLUniformLocation | null = null;
  resolutionUniform: WebGLUniformLocation | null = null;
  kernelUniform: WebGLUniformLocation | null = null;
  texture: WebGLTexture | null = null;

  ready = false;
  alpha = 1.0;

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    renderer: Renderer,
    theme: Theme,
    name?: ProgramName
  ) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.theme = theme;
    this.name = name ?? 'ColorFilterProgram';

    this.vertexBuffer = this.gl.createBuffer();
    this.textureBuffer = this.gl.createBuffer();

    this.setTheme(this.theme);
  }

  init(): void {
    this.initShaders(vertexShader, fragmentShader);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_vertCoord');
      this.textureAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');
      this.vertexAttributes = [this.vertexAttribute, this.textureAttribute];
    }

    this.ready = true;
  }

  setTheme(theme: Theme): void {
    const color = theme === 'dark' ? [35, 31, 32] : [255, 255, 255];
    const alpha = linearScale(0.8, [0, 1], [0, 255]);
    this.texture = this.createTexture([...color, alpha], true);
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  destroy(): void {}

  render(): void {
    if (!this.gl || !this.ready) return;

    const { renderer } = this;

    if (!renderer.overlay || renderer.isInTransition) return;

    this.draw();
  }

  private draw(): void {
    this.loadShaders();
    this.enableVertexAttributes();

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, ColorFilterProgram.textureCoords, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.textureAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, ColorFilterProgram.geometry, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.texture);
    this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);

    this.disableVertexAttributes();
  }
}

export default ColorFilterProgram;
