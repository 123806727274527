/* eslint-disable import/prefer-default-export,no-restricted-syntax,no-continue,no-await-in-loop */

import type { GltfAsset } from 'gltf-loader-ts';
import { GLTF_COMPONENT_TYPE_ARRAYS, GltfLoader } from 'gltf-loader-ts';
import type { Accessor, GlTf, MeshPrimitive } from 'gltf-loader-ts/lib/gltf';

import type { FPMesh } from '../types';
import { isThereANan } from './debug';
import { createExpandCoordsForMesh, fixUpMesh, makePipesFromLines } from './fixUpMesh';

/**
 * Position data ( VEC3::FLOAT32 )
 */
const getPosData = async (primitive: MeshPrimitive, accessors: Accessor[], asset: GltfAsset): Promise<Float32Array> => {
  const index = primitive.attributes.POSITION;
  const info = accessors[index];
  const num = info.count * 3; // 3 floats per point
  const DataType = GLTF_COMPONENT_TYPE_ARRAYS[info.componentType];

  const posDataRaw: Uint8Array = await asset.accessorData(index);
  return new DataType(posDataRaw.buffer, posDataRaw.byteOffset, num);
};

/**
 * Triangle index data ( SCALAR::UINT16 )
 */
const getIndexData = async (
  primitive: MeshPrimitive,
  accessors: Accessor[],
  asset: GltfAsset
): Promise<Uint16Array | null> => {
  if (primitive.indices === undefined) return null;
  const index = primitive.indices;
  const info = accessors[index];
  if (!info) return null;
  const num = info.count;
  const DataType = GLTF_COMPONENT_TYPE_ARRAYS[info.componentType]; // `new indexDataType` to dynamically get right data type

  const indexDataRaw: Uint8Array = await asset.accessorData(index);
  return new DataType(indexDataRaw.buffer, indexDataRaw.byteOffset, num);
};

/**
 * UV map (texture coordinates) data ( VEC2::FLOAT32 )
 */
const getTexCoordData = async (
  primitive: MeshPrimitive,
  accessors: Accessor[],
  asset: GltfAsset
): Promise<Float32Array | null> => {
  const index = primitive.attributes.TEXCOORD_0;
  if (index === undefined) return null;
  const info = accessors[index];
  const num = info.count * 2; // 2 floats per point
  const DataType = GLTF_COMPONENT_TYPE_ARRAYS[info.componentType];

  const texCoordDataRaw: Uint8Array = await asset.accessorData(index);
  return new DataType(texCoordDataRaw.buffer, texCoordDataRaw.byteOffset, num);
};

export const getBoundingBoxesAndCenter = (meshes: FPMesh[]) => {
  let minX = Infinity;
  let minY = Infinity;
  let minZ = Infinity;
  let maxX = -Infinity;
  let maxY = -Infinity;
  let maxZ = -Infinity;

  meshes.forEach((mesh) => {
    for (let i = 0; i < mesh.positions.length; i += 3) {
      const x = mesh.positions[i];
      const y = mesh.positions[i + 1];
      const z = mesh.positions[i + 2];

      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      minZ = Math.min(minZ, z);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
      maxZ = Math.max(maxZ, z);
    }
  });

  const boundingBoxMin = [minX, minY, minZ];
  const boundingBoxMax = [maxX, maxY, maxZ];

  //  center of the bounding box (volume center)
  const center = [(minX + maxX) / 2, (minY + maxY) / 2, (minZ + maxZ) / 2];

  return { center, boundingBoxMin, boundingBoxMax };
};

const loadNode = async (
  asset: GltfAsset,
  nodeIndex: number,
  meshes: FPMesh[],
  nodePath: string,
  roomIds: string[],
  transformationMatrix: number[] | undefined
) => {
  const gltf: GlTf = asset.gltf;
  if (!gltf.nodes) throw new Error('FloorPlan3DProgram::loadImages::no nodes in .glb');
  if (!gltf.meshes) throw new Error('FloorPlan3DProgram::loadImages::no meshes in .glb');
  const node = gltf.nodes[nodeIndex];

  const meshIndex = node.mesh ?? -1;
  if (meshIndex === -1) return;

  const accessors = asset.gltf.accessors || [];
  const mesh = gltf.meshes[meshIndex];

  for (const primitive of mesh.primitives) {
    const isCeiling = nodePath.includes('ceilings');
    const isFloor = nodePath.includes('floors');
    const isBottomWall = nodePath.includes('/bot_walls');
    const isTopWall = nodePath.includes('/top_walls');
    const isWall = nodePath.includes('wall') || nodePath.includes('fillblock');
    const isWallCap = nodePath.includes('cap');
    const isOutline = primitive.material === 3; // material 3 is outline material
    // const isWallCutaway = false; // @todo -- find out when there are side cutaways in the model

    // await in loop is not the best,
    // better would be to await all promises and then process data,
    // but this is good enough for small .glb files
    const posData = await getPosData(primitive, accessors, asset);
    const indexData = await getIndexData(primitive, accessors, asset);
    if (indexData === null) continue;

    let texCoordData = new Float32Array(0);

    // @todo -- get rid of MAYBE, just return empty array if no data or modify FPMesh to allow null/undef
    const maybeTexCoordData = await getTexCoordData(primitive, accessors, asset);
    if (maybeTexCoordData !== null) texCoordData = maybeTexCoordData;

    const msh: FPMesh = {
      skipFlatRendering: false,
      name: node.name,
      nodePath,
      isCeiling,
      isFloor,
      isBottomWall,
      isTopWall,
      isWall,
      isWallCap,
      isOutline,
      panoIds: roomIds, // roomIds are panoIds, @todo -- maybe later get actual room IDs ?
      shape2d: [],
      center2d: [0, 0],
      unfocusedRoom: false,
      skipSolidRendering: false,
      roomIds: [],
      positions: posData,
      expandCoords: new Float32Array(0),
      triangles: indexData,
      normals: new Float32Array(0),
      texCoords: texCoordData,
      dataNum: 0,
      dataOffsetExpandCoords: 0,
      dataOffsetNormals: 0,
      dataOffsetPositions: 0,
      dataOffsetTexCoords: 0,
      debugId: nodeIndex,
    };

    fixUpMesh(msh, transformationMatrix);

    if (msh.isOutline) {
      makePipesFromLines(msh);
      createExpandCoordsForMesh(msh);
    }

    meshes.push(msh);

    // const mat = primitive.material;
    // console.log(msh.debugId, msh.name,  nodePath ,mat, msh);
    if (isThereANan(msh.positions)) console.error('there is a NaN in positions', msh.debugId, msh.name);
    if (isThereANan(msh.normals)) console.error('there is a NaN in normals', msh.debugId, msh.name);
    if (isThereANan(msh.texCoords)) console.error('there is a NaN in texCoords', msh.debugId, msh.name);
    if (isThereANan(msh.expandCoords)) console.error('there is a NaN in expandCoords', msh.debugId, msh.name);
  }
};

/**
 *
 * @param asset
 * @param nodeIndices
 * @param meshes
 * @param pathAcc -- accumulated path: parent/child/grand-child
 * @param roomIdAcc -- accumulated roomIds: all roomIds from parent nodes
 * @param transformationMatrixAcc -- accumulated scale/rotation/translation matrix -- currently not accumulated, just latest found
 */
const loadNodesRecursively = async (
  asset: GltfAsset,
  nodeIndices: number[],
  meshes: FPMesh[],
  pathAcc = '',
  roomIdAcc: string[] = [],
  transformationMatrixAcc: number[] | undefined = undefined
) => {
  const gltf: GlTf = asset.gltf;
  if (!gltf.nodes) throw new Error('FloorPlan3DProgram::loadImages::no nodes in .glb');

  for (const nodeIndex of nodeIndices) {
    const node = gltf.nodes[nodeIndex];

    // if no name is present, use parent name (it's a problem for furniture and portals) @todo -- fix or ignore
    if (node.name === undefined) {
      node.name = pathAcc.split('/').pop() ?? 'no-name';
    }

    const pathAccNext = `${pathAcc}/${node.name}`;
    const roomIdAccNext = [...roomIdAcc, ...(node?.extras?.room_ids ?? [])];
    const matrixNext = transformationMatrixAcc ?? node?.matrix; // not accumulating, just using the latest found

    await loadNode(asset, nodeIndex, meshes, pathAccNext, roomIdAccNext, matrixNext);
    if (node.children) {
      await loadNodesRecursively(asset, node.children, meshes, pathAccNext, roomIdAccNext, matrixNext);
    }
  }
};

const loadMeshes = async (asset: GltfAsset) => {
  const meshes: FPMesh[] = [];

  const gltf: GlTf = asset.gltf;
  const sceneIndex = gltf.scene ?? 0;
  if (!gltf.scenes) throw new Error('FloorPlan3DProgram::loadImages::no scenes in .glb');
  if (!gltf.nodes) throw new Error('FloorPlan3DProgram::loadImages::no nodes in .glb');
  if (!gltf.meshes) throw new Error('FloorPlan3DProgram::loadImages::no meshes in .glb');
  const scene = gltf.scenes[sceneIndex];
  const rootNodes = scene.nodes;
  if (!rootNodes) throw new Error('FloorPlan3DProgram::loadImages::no root nodes in .glb');

  await loadNodesRecursively(asset, rootNodes, meshes);

  return meshes;
};

/**
 * Loading minimal info from the .glb model, using assumptions and hardcoded values.
 * Definitely not supporting any .glb file.
 */
export const loadGlb = async (uri: string) => {
  const loader = new GltfLoader();
  const asset = await loader.load(uri);
  const gltf: GlTf = asset.gltf;
  console.log('gltf-->', gltf);

  if (!gltf || !gltf.scenes) throw new Error('FloorPlan3DProgram::loadGlb::no gltf data');

  console.log('FloorPlan3DProgram::loadGlb::all mats in .glb:', gltf.materials);

  const meshes = await loadMeshes(asset);

  return meshes;
};
