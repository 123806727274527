/**
 * @param name - name of the cookie to set
 * @param value - value of the cookie
 * @param days - number of days until the cookie expires
 * @param domain - domain of the cookie
 *
 * @example
 * setCookie('cookie_preferences', '1_123456789', 365 * 20)
 *
 * @example
 * setCookie('cookie_preferences', '1_123456789', 365 * 20, '.giraffe360.com')
 */
const setCookie = (name: string, value: string, days: number, domain = '.giraffe360.com') => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `expires=${date.toUTCString()}`;
  }

  if (typeof document === 'undefined') return;

  document.cookie = `${name}=${value || ''}; ${expires}; path=/; domain=${domain}`;
};

export default setCookie;
