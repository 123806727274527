import type { AssetConfig } from '@g360/vt-types';
import { Mixin } from 'ts-mixer';
import urlJoin from 'url-join';

import Utils from '../../common/Utils/Utils';
import type { Image } from '../../types/internal';
import WebGLProgram from '../mixins/Program';
import fragmentShader from './measureCrosshair.fs.glsl';
import vertexShader from './measureCrosshair.vs.glsl';
import type MeasureToolProgram from './MeasureToolProgram';

const XHAIR_RADIUS = 120;
/** If crosshair is too far, the user can't see it, so we artifically make it closer (mm) */
const MAX_XHAIR_DIST = 2200;
/** Draws the crosshair outer ring */
class MeasureCrosshairProgram extends Mixin(WebGLProgram) {
  // Save the crosshair image to avoid fetching it multiple times
  private static crosshairImg: Image | null = null;

  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  assetConfig: AssetConfig;

  vertexBuffer: WebGLBuffer | null;
  uvBuffer: WebGLBuffer | null = null;
  vertexAttribute = 0;
  uvAttribute = 0;

  projectionMatUniform: WebGLUniformLocation | null = null;
  localRotationMatUniform: WebGLUniformLocation | null = null;

  xhairTexUniform: WebGLUniformLocation | null = null;

  abortController = window.AbortController ? new AbortController() : null;

  private crosshairTexture: WebGLTexture | null = null;

  private vertices = new Float32Array(18);
  private readonly uvMap = new Float32Array([1, 1, 0, 1, 1, 0, 1, 0, 0, 1, 0, 0]);

  private mainProgram: MeasureToolProgram;

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    assetConfig: AssetConfig,
    mainProgram: MeasureToolProgram
  ) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.mainProgram = mainProgram;
    this.assetConfig = assetConfig;

    this.vertexBuffer = this.gl.createBuffer();
    this.uvBuffer = this.gl.createBuffer();

    this.crosshairTexture = this.createTexture();
  }

  async init(): Promise<void> {
    this.initShaders(vertexShader, fragmentShader);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_position');
      this.uvAttribute = this.gl.getAttribLocation(this.program, 'a_uv');

      this.xhairTexUniform = this.gl.getUniformLocation(this.program, 'u_xhair_tex');

      this.projectionMatUniform = this.gl.getUniformLocation(this.program, 'u_proj_mat');
      this.localRotationMatUniform = this.gl.getUniformLocation(this.program, 'u_local_rot_mat');

      this.vertexAttributes = [this.vertexAttribute, this.uvAttribute];
    }

    await this.loadCrosshairTexture();
  }

  async loadCrosshairTexture(): Promise<void> {
    if (!MeasureCrosshairProgram.crosshairImg) {
      const url = urlJoin(this.assetConfig.assetPath, 'measure/crosshair_outer.png');
      const img = await Utils.fetchImage(url, this.abortController);
      if (!img) {
        throw new Error('Failed to load crosshair texture');
      }
      MeasureCrosshairProgram.crosshairImg = img;
    }

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.crosshairTexture);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR_MIPMAP_LINEAR);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
    this.gl.texImage2D(
      this.gl.TEXTURE_2D,
      0,
      this.gl.RGBA,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      MeasureCrosshairProgram.crosshairImg
    );
    this.gl.generateMipmap(this.gl.TEXTURE_2D);
  }

  destroy(): void {
    this.gl.deleteTexture(this.crosshairTexture);
    this.abortPending();
    this.destroyProgram();
  }

  abortPending(): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = new AbortController();
    }
  }

  draw(): void {
    this.loadShaders();
    this.enableVertexAttributes();

    const cursorPosition = this.mainProgram.cursorCoords?.snapPosition ?? this.mainProgram.cursorCoords;

    if (!cursorPosition) return;

    // Don't draw outer ring if cursor above missing data point
    if (cursorPosition.distance === 0) return;

    const normalVec = this.mainProgram.getNormalMapData(cursorPosition.textureX, cursorPosition.textureY);
    if (!normalVec) return;

    const randomAxis = [-normalVec[0], normalVec[1], 2 * normalVec[2]];

    const axis1 = Utils.normalizeVec3(Utils.vec3CrossProduct(normalVec, randomAxis));
    const axis2 = Utils.normalizeVec3(Utils.vec3CrossProduct(normalVec, axis1));

    let x = cursorPosition.cartX;
    let y = cursorPosition.cartY;
    let z = cursorPosition.cartZ;

    if (cursorPosition.distance > MAX_XHAIR_DIST) {
      const cursorDirection = Utils.normalizeVec3([x, y, z]);
      x = cursorDirection[0] * MAX_XHAIR_DIST;
      y = cursorDirection[1] * MAX_XHAIR_DIST;
      z = cursorDirection[2] * MAX_XHAIR_DIST;
    }

    const v1 = [x + axis1[0] * XHAIR_RADIUS, y + axis1[1] * XHAIR_RADIUS, z + axis1[2] * XHAIR_RADIUS];
    const v2 = [x - axis1[0] * XHAIR_RADIUS, y - axis1[1] * XHAIR_RADIUS, z - axis1[2] * XHAIR_RADIUS];
    const v3 = [x + axis2[0] * XHAIR_RADIUS, y + axis2[1] * XHAIR_RADIUS, z + axis2[2] * XHAIR_RADIUS];
    const v4 = [x - axis2[0] * XHAIR_RADIUS, y - axis2[1] * XHAIR_RADIUS, z - axis2[2] * XHAIR_RADIUS];

    // Two triangles to form a quad
    this.vertices[0] = v3[0];
    this.vertices[1] = v3[1];
    this.vertices[2] = v3[2];
    ///
    this.vertices[3] = v1[0];
    this.vertices[4] = v1[1];
    this.vertices[5] = v1[2];
    ///
    this.vertices[6] = v2[0];
    this.vertices[7] = v2[1];
    this.vertices[8] = v2[2];
    ///
    this.vertices[9] = v2[0];
    this.vertices[10] = v2[1];
    this.vertices[11] = v2[2];
    ///
    this.vertices[12] = v1[0];
    this.vertices[13] = v1[1];
    this.vertices[14] = v1[2];
    ///
    this.vertices[15] = v4[0];
    this.vertices[16] = v4[1];
    this.vertices[17] = v4[2];

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.uvMap, this.gl.DYNAMIC_DRAW);

    this.gl.enableVertexAttribArray(this.uvAttribute);
    this.gl.vertexAttribPointer(this.uvAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);

    this.gl.enableVertexAttribArray(this.vertexAttribute);
    this.gl.vertexAttribPointer(this.vertexAttribute, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.uniformMatrix4fv(this.projectionMatUniform, false, this.mainProgram.projectionMatrix);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.crosshairTexture);
    this.gl.uniform1i(this.xhairTexUniform, 1);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, this.vertices.length / 3);

    this.gl.disable(this.gl.CULL_FACE);
    this.disableVertexAttributes();
  }
}

export default MeasureCrosshairProgram;
