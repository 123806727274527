import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
const UnitIcon = ({ children, active }) => {
    const twActive = active ? 'bg-modal-light text-modal-dark' : 'text-white';
    return (_jsx("div", Object.assign({ className: `text-primary-dark flex h-[2.25rem] w-[2.4rem] items-center justify-center rounded-[1.4rem] font-bold ${twActive}` }, { children: children })));
};
const MeasureModeUnitSwitch = ({ className }) => {
    const { tourConfigService } = useServices();
    const { units } = useReactive(tourConfigService, ['units']);
    const { analytics } = useGlobalContext();
    const t = useTranslation();
    const handleChange = () => {
        tourConfigService.units = units === 'metric' ? 'imperial' : 'metric';
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'MSR', 'Unit switch', { units: tourConfigService.units });
    };
    return (_jsxs("button", Object.assign({ className: `pointer-events-auto flex h-10 w-20 items-center justify-center rounded-[1.25rem] bg-theme-primary
        bg-opacity-theme-low font-primary text-sm text-modal-dark hover:bg-opacity-theme-medium ${className}`, type: "button", onClick: handleChange }, { children: [_jsx(UnitIcon, Object.assign({ active: units === 'metric' }, { children: t('units.m') })), _jsx(UnitIcon, Object.assign({ active: units !== 'metric' }, { children: t('units.ft') }))] })));
};
export default MeasureModeUnitSwitch;
