import { jsx as _jsx } from "react/jsx-runtime";
const IconButton = ({ className = '', transparent = false, hoverable = true, children, onClick, testId, }) => {
    const twOpacity = transparent ? 'bg-opacity-0' : 'bg-opacity-100';
    const twHover = hoverable ? 'hover:bg-modal-gray-hover' : '';
    return (_jsx("button", Object.assign({ className: `
        center pointer-events-auto inline-flex h-10 w-10 min-w-[2.5rem] max-w-[2.5rem] items-center
        justify-center rounded-md bg-modal-gray fill-modal-dark stroke-modal-dark
        ${twOpacity} ${twHover} ${className}`, type: "button", onClick: onClick, "data-testid": testId }, { children: children })));
};
export default IconButton;
