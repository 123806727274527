import type { WatermarkConfig } from '@g360/vt-types';
import { Mixin } from 'ts-mixer';

import Matrix from '../../common/Matrix';
import Utils from '../../common/Utils';
import type Renderer from '../../mixins/Renderer';
import type { ProgramName } from '../../types/internal';
import WebGLProgram from '../mixins/Program';
import fragmentShader from './cube.fs.glsl';
import vertexShader from './cube.vs.glsl';
import CubeEventEmitter from './WatermarkEventEmitter';
import { createWatermarkPlane, rotateWatermarkPlane } from './watermarkUtils';

class WatermarkProgram extends Mixin(WebGLProgram, CubeEventEmitter) {
  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  renderer: Renderer;
  name: ProgramName;

  orderIndex = 0;
  vertexBuffer: WebGLBuffer | null;
  textureBuffer: WebGLBuffer | null;
  vertexAttribute = 0;
  textureAttribute = 0;
  perspectiveUniform: WebGLUniformLocation | null = null;
  alphaUniform: WebGLUniformLocation | null = null;
  alphaFixUniform: WebGLUniformLocation | null = null;

  webglReady = false;
  yaw = 0;
  pitch = 0;
  fov = Utils.toRad(120);
  alpha = 1.0;
  yawOffset = 0;
  rotationMatrix: number[] = [];
  perspectiveMatrix: number[] = [];
  rotatedPerspective: number[] = [];
  textureCoords = new Float32Array([0, 0, 1, 0, 1, 1, 0, 1]);
  textureObject: WebGLTexture | null;
  watermarkReloaded = false;
  watermarkTextureReady = false;

  abortController = window.AbortController ? new AbortController() : null;

  watermarkConfig: Required<WatermarkConfig> | null = null;
  /** Watermark image aspect ratio */
  aspectRatio: number | null = null;
  /** List of vertices for each watermark location */
  watermarkNodes: Float32Array[] | null = null;

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, renderer: Renderer, name?: ProgramName) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.name = name ?? 'WatermarkProgram';

    this.vertexBuffer = this.gl.createBuffer();
    this.textureBuffer = this.gl.createBuffer();
    this.textureObject = this.createTexture();
  }

  public init(): void {
    this.initShaders(vertexShader, fragmentShader);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_vertCoord');
      this.textureAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');
      this.perspectiveUniform = this.gl.getUniformLocation(this.program, 'u_perspective');
      this.alphaUniform = this.gl.getUniformLocation(this.program, 'u_alpha');
      this.alphaFixUniform = this.gl.getUniformLocation(this.program, 'u_alpha_fix');
      this.vertexAttributes = [this.vertexAttribute, this.textureAttribute];
    }

    this.webglReady = true;
  }

  /** Remove the watermark but keep the program - abort image fetch, remove texture from gpu and clear nodes array */
  public removeWatermark(): void {
    this.abortController?.abort();
    this.watermarkNodes = null;
    this.gl.deleteTexture(this.textureObject);
    this.emit('render');
  }

  /** Remove the watermark and destroy the webgl program and event emitter */
  public destroy(): void {
    this.webglReady = false;

    this.removeWatermark();
    this.destroyEventEmitter();
    this.destroyProgram();
  }

  async loadWatermark(watermarkConfig: WatermarkConfig): Promise<boolean> {
    // If it is the same image, just update the config and nodes
    if (this.watermarkTextureReady && watermarkConfig.image === this.watermarkConfig?.image) {
      this.watermarkConfig = {
        count: 6,
        scale: 0.5,
        yPosition: 0.3,
        ...watermarkConfig,
      };

      this.createWatermarkNodes();
      return true;
    }

    this.watermarkConfig = {
      count: 6,
      scale: 0.5,
      yPosition: 0.3,
      ...watermarkConfig,
    };

    this.watermarkTextureReady = false;
    const watermarkImage = await Utils.fetchImage(watermarkConfig.image, this.abortController);

    if (watermarkImage) {
      this.textureObject = this.createTexture();
      this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureObject);
      this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, watermarkImage);

      this.aspectRatio = watermarkImage.height / watermarkImage.width;

      this.createWatermarkNodes();
      Utils.closeImage(watermarkImage);
      this.emit('error.clear', 'WATERMARK_INTERRUPTED');
      this.watermarkTextureReady = true;
      return true;
    }

    // Try to reload watermark one time, could improve the issue in case if CORS errors happen on the first request
    // Also reload only if fetch is not aborted (watermarkImage === undefined if aborted)
    if (watermarkImage === null && !this.watermarkReloaded) {
      this.watermarkReloaded = true;

      setTimeout(() => {
        this.loadWatermark(watermarkConfig);
      }, 2000);
    }

    this.emit('error.set', 'WATERMARK_INTERRUPTED');
    return false;
  }

  /** Creates new set of watermarks nodes which then are used to render the watermark texture, this also triggers
   * the global render function if emitter is properly attached
   */
  createWatermarkNodes(): void {
    if (!this.watermarkConfig || !this.aspectRatio) {
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.warn('Trying to create geometry with missing watermark config or image size');
      }

      return;
    }

    this.watermarkNodes = [];

    const watermarkPlane = createWatermarkPlane(this.watermarkConfig, this.aspectRatio);
    const { count } = this.watermarkConfig;

    for (let i = 0; i < count; i += 1) {
      const watermarkAngle = (360 / count) * i;
      const rotatedPlane = rotateWatermarkPlane(watermarkAngle, watermarkPlane);

      // Vertices for webgl are flattened and stored in a Float32Array
      this.watermarkNodes.push(new Float32Array(rotatedPlane.flat()));
    }

    this.emit('render');
  }

  drawWatermark(geometry: Float32Array): void {
    // Set texture coordinates
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.textureCoords, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.textureAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.uniform1f(this.alphaUniform, this.alpha);
    this.gl.uniform1i(this.alphaFixUniform, 1);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, geometry, this.gl.STATIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureObject);
    this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);
  }

  render(): void {
    if (!this.renderer.isSceneLayoutReady || !this.watermarkNodes || !this.webglReady) return;

    this.draw();
  }

  private draw(): void {
    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);
    this.gl.activeTexture(this.gl.TEXTURE0);

    this.loadShaders();
    this.enableVertexAttributes();

    this.perspectiveMatrix = Matrix.perspective(this.fov, { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight }, 0.1, 100.0); // prettier-ignore
    this.rotationMatrix = Matrix.identityM3();
    this.rotationMatrix = Matrix.rotateX(this.rotationMatrix, -this.pitch);
    this.rotationMatrix = Matrix.rotateY(this.rotationMatrix, this.yaw);
    this.rotationMatrix = Matrix.m3toM4(this.rotationMatrix);
    this.rotatedPerspective = Matrix.rotatePerspective(this.perspectiveMatrix, this.rotationMatrix);

    this.gl.uniformMatrix4fv(this.perspectiveUniform, false, Matrix.transposeM4(this.rotatedPerspective));

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.watermarkNodes!.forEach((nodeGeometry) => this.drawWatermark(nodeGeometry));

    this.gl.disable(this.gl.CULL_FACE);
    this.disableVertexAttributes();
  }
}

export default WatermarkProgram;
