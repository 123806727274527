/* eslint-disable import/prefer-default-export,no-restricted-syntax */
import type { Radian } from '@g360/vt-types';

import { getVec3Difference, normalizeVec3 } from '../../../common/Utils';
import { calculateDistance, calculateMidpoint, perpdendicularFoorOfTheLongestSide } from './matrix';

/**
 * This returns the position a of the camera at a given percentage of the orbit.
 * And the pitch and yaw of the camera to see the model
 *
 * @param orbitPercentage - The percentage of the orbit, time of the day
 * @param maxOrbitHeight - how high to orbit around the origin
 * @param orbitRadius - how far to orbit around the origin
 * @param orbitCenter - the center of the orbit, where to look at (actual height is the orbitHeight)
 *
 * @todo -- make this scientifically accurate ?
 */
export const getOrbitalPosition = (
  orbitPercentage: number,
  maxOrbitHeight: number,
  orbitRadius: number,
  orbitCenter: number[]
) => {
  const sunriseOffset = -0.25; //  make daytime from 0600 to 1800)
  const position = [0, 0, 0];
  const angle = -(orbitPercentage - sunriseOffset) * 2 * Math.PI;
  const deltaX = Math.sin(angle) * orbitRadius;
  const deltaY = Math.cos(angle) * orbitRadius;

  const elevationAngle = Math.sin(angle) * (Math.PI / 4);
  const orbitHeight = Math.sin(elevationAngle) * maxOrbitHeight;

  position[0] = orbitCenter[0] + deltaX;
  position[1] = orbitHeight;
  position[2] = orbitCenter[2] + deltaY;

  const height = orbitHeight - orbitCenter[1];
  const pitch = -Math.atan2(height, orbitRadius);

  return { position, pitch, yaw: angle };
};

export function calculateTriangleNormal(v1: number[], v2: number[], v3: number[]): number[] {
  const edge1 = [v2[0] - v1[0], v2[1] - v1[1], v2[2] - v1[2]];

  const edge2 = [v3[0] - v1[0], v3[1] - v1[1], v3[2] - v1[2]];

  const normal = [
    edge1[1] * edge2[2] - edge1[2] * edge2[1],
    edge1[2] * edge2[0] - edge1[0] * edge2[2],
    edge1[0] * edge2[1] - edge1[1] * edge2[0],
  ];

  const length = Math.sqrt(normal[0] * normal[0] + normal[1] * normal[1] + normal[2] * normal[2]);

  // Check if the length is too small (degenerate triangle)
  if (length < 1e-8) {
    throw new Error('Degenerate triangle');
  }

  return [normal[0] / length, normal[1] / length, normal[2] / length];
}

function normalize(vecA: number[], vecB: number[]): number[] {
  if (vecA[0] === vecB[0] && vecA[1] === vecB[1] && vecA[2] === vecB[2]) return [0, 0, 0];
  return normalizeVec3(getVec3Difference(vecA, vecB));
}

/**
 * Get direction [x,y,z] for each triangle vertex:
 *  in what direction to move it to get this triangle to make thicker lines
 *
 * A) Find midpoint of the longest side and give it to the adjacent vertices
 * B) Find projection ("perpendicular foot") on the longest side of the opposite vertex and give it to the opposite vertex
 */
export function getTriangleVertexOffsetDirections(vertices: number[][]): number[][] {
  const expandCoords = [
    [0, 0, 0],
    [0, 0, 0],
    [0, 0, 0],
  ];
  const sides = [
    {
      points: [vertices[0], vertices[1]],
      length: calculateDistance(vertices[0], vertices[1]),
    },
    {
      points: [vertices[1], vertices[2]],
      length: calculateDistance(vertices[1], vertices[2]),
    },
    {
      points: [vertices[2], vertices[0]],
      length: calculateDistance(vertices[2], vertices[0]),
    },
  ];

  const longestSide = sides.reduce((acc, side) => (side.length > acc.length ? side : acc), sides[0]);
  const longestSideLen = longestSide.length;
  const midPointLongestSide = calculateMidpoint(longestSide.points[0], longestSide.points[1]);

  for (let j = 0; j < sides.length; j += 1) {
    const side = sides[j];
    const oppositeVertexId = (j + 2) % 3; // the vertex that was not part of the side
    if (side.length === longestSideLen) {
      // longest side

      const idA = j;
      const idB = (j + 1) % 3;
      expandCoords[oppositeVertexId] = perpdendicularFoorOfTheLongestSide(
        vertices[oppositeVertexId],
        vertices[idA],
        vertices[idB]
      );
    } else {
      // short side

      // There are very long triangles, that make up long lines
      // and there are short triangles that make up curved lines
      // for long triangles, move only the opposite of longest side, ignore side vertices
      // eslint-disable-next-line no-lonely-if
      if (longestSideLen > 3) {
        expandCoords[oppositeVertexId] = vertices[oppositeVertexId]; // give same vertex as it is, to cancel the effect
      } else {
        expandCoords[oppositeVertexId] = midPointLongestSide;
      }
    }

    expandCoords[oppositeVertexId] = normalize(vertices[oppositeVertexId], expandCoords[oppositeVertexId]);
  }

  return expandCoords;
}

export function getAngleDelta(angle1: Radian, angle2: Radian) {
  let delta = angle1 - angle2;
  delta = ((delta + Math.PI) % (2 * Math.PI)) - Math.PI;
  return delta;
}

function pointsEqual(a: [number, number], b: [number, number], epsilon: number): boolean {
  return Math.abs(a[0] - b[0]) < epsilon && Math.abs(a[1] - b[1]) < epsilon;
}

function sortPointsToFormClosedShape(points: [number, number][]): [number, number][] {
  // Calculate the centroid of the shape
  const centroid = points.reduce(
    (acc, val) => [acc[0] + val[0] / points.length, acc[1] + val[1] / points.length],
    [0, 0]
  );

  // Calculate angle from centroid to each point
  const pointsWithAngle = points.map((point) => {
    const angle = Math.atan2(point[1] - centroid[1], point[0] - centroid[0]);
    return { point, angle };
  });

  // Sort points by angle
  pointsWithAngle.sort((a, b) => a.angle - b.angle);

  // Return sorted points
  return pointsWithAngle.map((p) => p.point);
}

export function getOutlineFrom3DMesh(vertices: Float32Array): [number, number][] {
  const epsilon = 1e-1; // how close points need to be in order to be considered the same

  const points: [number, number][] = [];
  for (let i = 0; i < vertices.length; i += 3) {
    const newPoint: [number, number] = [vertices[i], vertices[i + 2]];
    let isUnique = true;

    for (const point of points) {
      if (pointsEqual(point, newPoint, epsilon)) {
        isUnique = false;
        break;
      }
    }

    if (isUnique) {
      points.push(newPoint);
    }
  }

  // sometimes shapes look like Z instead of normal square
  return sortPointsToFormClosedShape(points);
}

export function calculateCenter2d(wall2dPoints: [number, number][]): [number, number] {
  return wall2dPoints.reduce(
    (acc, val) => [acc[0] + val[0] / wall2dPoints.length, acc[1] + val[1] / wall2dPoints.length],
    [0, 0]
  );
}

export function isPointInsidePolygon(point: [number, number], shape: [number, number][]) {
  const [x, y] = point;
  let inside = false;

  // eslint-disable-next-line no-plusplus -- 2 foor loops in single line
  for (let i = 0, j = shape.length - 1; i < shape.length; j = i++) {
    const [xi, yi] = shape[i];
    const [xj, yj] = shape[j];

    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

    if (intersect) inside = !inside;
  }

  return inside;
}

/**
 * Calculate the optimal distance for the camera to see the whole model with the given field of view,
 * accounting for rotation (assuming the model is rotated along its center)
 */
export function calculateCameraParameters(
  boundingBoxMin: number[],
  boundingBoxMax: number[],
  fov: number
): { optimalDistance: number; modelRadius: number } {
  const width = boundingBoxMax[0] - boundingBoxMin[0];
  const height = boundingBoxMax[1] - boundingBoxMin[1];
  const depth = boundingBoxMax[2] - boundingBoxMin[2];
  const maxDimension = Math.max(width, height, depth);
  const modelRadius = (Math.sqrt(3) * maxDimension) / 2;
  const optimalDistance = modelRadius / Math.tan(fov / 2);
  return { optimalDistance, modelRadius };
}
