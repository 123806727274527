import type { AssetConfig } from '@g360/vt-types';
import { getTextScaleForVideoEditor } from '@g360/vt-utils';
import opentype from 'opentype.js';
import { Mixin } from 'ts-mixer';
import urljoin from 'url-join';

import ProgramEventEmitter from '../../../common/ProgramEventEmitter';
import WebGLProgram from '../../mixins/Program';
import fragmentShader from './text.fs.glsl';
import vertexShader from './text.vs.glsl';
import { TextProgramEvents } from './TextProgram2DEvents';
import type { BoundingBox } from './utils';
import { getTextData } from './utils';

type TextParams2D = {
  text: string;
  font: string;
  fontSize: number;
  fontColor: [number, number, number, number];
  clipSpaceOffset: [number, number];
};

const defaultFont = 'GilroyMedium';

class TextProgram extends Mixin(WebGLProgram, ProgramEventEmitter<TextProgramEvents>) {
  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  indexData: Uint16Array = new Uint16Array(0);
  vertexData: Float32Array = new Float32Array(0);
  vertexAttribute = 0;
  vertexBuffer: WebGLBuffer | null;
  textureBuffer: WebGLBuffer | null;
  scaleUniform: WebGLUniformLocation | null = null;
  offsetUniform: WebGLUniformLocation | null = null;
  colorUniform: WebGLUniformLocation | null = null;
  boundingBox: BoundingBox | null = null;
  fontData: { [key: string]: opentype.Font } | null = null;
  textParams: TextParams2D = {
    text: '',
    font: defaultFont,
    fontSize: 72,
    fontColor: [0, 0, 0, 1],
    clipSpaceOffset: [0.0, 0.0],
  };
  fonts: { [key: string]: string } = {
    [defaultFont]: 'fonts/gilroy/Gilroy-Medium.woff2',
  };
  assetConfig: AssetConfig;

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, assetConfig: AssetConfig) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;

    this.vertexBuffer = this.gl.createBuffer();
    this.textureBuffer = this.gl.createBuffer();

    this.assetConfig = assetConfig;

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  init(): void {
    this.initShaders(vertexShader, fragmentShader);

    if (!this.program) {
      throw new Error('PROGRAM_NOT_INITIALIZED');
    }

    this.scaleUniform = this.gl.getUniformLocation(this.program, 'u_scale');
    this.offsetUniform = this.gl.getUniformLocation(this.program, 'u_offset');
    this.colorUniform = this.gl.getUniformLocation(this.program, 'u_color');
    this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_position');

    this.vertexAttributes = [this.vertexAttribute];

    this.loadFonts();
    window.addEventListener('resize', this.handleWindowResize);
  }

  update(textParams: TextParams2D): void {
    if (!this.fontData) {
      this.subscribeOnce('fonts.loaded', () => this.update(textParams));
      return;
    }

    this.textParams = textParams;

    const textScale = getTextScaleForVideoEditor(this.canvas.getBoundingClientRect());
    const { vertexData, indexData, boundingBox } = this.getTextData(textScale);

    this.vertexData = vertexData;
    this.indexData = indexData;
    this.boundingBox = boundingBox;
  }

  hide() {
    this.update({ ...this.textParams, text: '' });
  }

  draw(): void {
    if (!this.program || !this.boundingBox) {
      return;
    }

    this.loadShaders();

    this.gl.uniform2fv(this.scaleUniform, [2.0 / this.canvas.clientWidth, -2.0 / this.canvas.clientHeight]);

    const color = this.textParams.fontColor ?? [0, 0, 0, 1];

    this.gl.uniform2fv(this.offsetUniform, this.textParams.clipSpaceOffset);
    this.gl.uniform4fv(this.colorUniform, color);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertexData, this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.textureBuffer);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, this.indexData, this.gl.STATIC_DRAW);

    this.enableVertexAttributes();

    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, true, 8, 0);
    this.gl.drawElements(this.gl.TRIANGLES, this.indexData.length, this.gl.UNSIGNED_SHORT, 0);

    this.disableVertexAttributes();

    this.emit('render');
  }

  destroy(): void {
    this.destroyProgram();
  }

  getFontPath(fontName: string) {
    if (!this.fonts[fontName]) {
      throw new Error(`Font ${fontName} not found!`);
    }

    return urljoin(this.assetConfig.assetPath, this.fonts[fontName]);
  }

  handleWindowResize(): void {
    this.update(this.textParams);
  }

  protected loadFonts() {
    return Promise.all(
      Object.keys(this.fonts).map((fontName) =>
        fetch(this.getFontPath(fontName))
          .then((res) => res.arrayBuffer())
          .then((data) => {
            const font = opentype.parse(data);
            if (!font) {
              console.warn(`Font ${fontName} could not be loaded!`);
              throw new Error('FONT_LOAD_ERROR');
            }
            return { name: fontName, font };
          })
      )
    )
      .then((loadedFonts) => {
        this.fontData = loadedFonts.reduce((acc, { name, font }) => {
          // eslint-disable-next-line no-param-reassign
          acc[name] = font;
          return acc;
        }, {});
        this.emit('fonts.loaded');
      })
      .catch((error) => {
        console.warn(`Error loading fonts: ${error}`);
        throw new Error('FONT_LOAD_ERROR');
      });
  }

  protected getTextMaxWidth() {
    return this.canvas.clientWidth;
  }

  protected getTextData(fontScale: number) {
    if (!this.fontData) {
      return {
        vertexData: new Float32Array(0),
        indexData: new Uint16Array(0),
        boundingBox: null,
      };
    }

    const { vertexData, indices, boundingBox } = getTextData(
      this.textParams.text,
      this.fontData[this.textParams.font],
      this.textParams.fontSize * fontScale,
      this.getTextMaxWidth()
    );

    return {
      vertexData,
      indexData: indices,
      boundingBox,
    };
  }
}

export default TextProgram;
