/* eslint-disable no-continue */
import type { AssetConfig, HotSpot3DAsset, HotSpot3DConfig, HotSpot3DType, Theme, TourConfig } from '@g360/vt-types';
import { linearScale } from '@g360/vt-utils/';
import { Mixin } from 'ts-mixer';
import urljoin from 'url-join';

import { MAX_FOV_RAD } from '../../common/Globals';
import Matrix from '../../common/Matrix';
import { Utils } from '../../index';
import type Animator from '../../mixins/Animator';
import type Renderer from '../../mixins/Renderer';
import type { HotSpot3DSprite, HotSpotEditAction, Image, ProgramName } from '../../types/internal';
import HotSpotsEventEmitter from '../HotSpotProgram/HotSpotsEventEmitter';
import WebGLProgram from '../mixins/Program';
import type HotSpot3D from './HotSpot3D';
import fragmentShader from './hotSpot3d.fs.glsl';
import vertexShader from './hotSpot3d.vs.glsl';
import HotSpot3DLogic from './HotSpot3DLogic';

class HotSpotProgram3D extends Mixin(WebGLProgram, HotSpotsEventEmitter) {
  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  renderer: Renderer;
  theme: Theme;
  assetConfig: AssetConfig;
  name: ProgramName;

  fov = 0;
  pitch = 0;
  yaw = 0;
  alpha = 0; // only for compatibility with 2D hotspots, not used in 3D, remove later
  cameraPosition: number[] = [0, 0, 0]; // reference to singleton-like variable

  orderIndex = 0;
  tourConfig?: TourConfig;
  currentPanoKey = '';
  hotSpotScale = [30, 30, 1]; // x,y,z
  interactive = true;
  iconsAssets: { [key: string]: HotSpot3DAsset } = {};
  boundingRect: DOMRect;
  screenAspectRatio = 1;
  vertexLocation = 0;
  uvLocation = 0;
  mainTexLocation: WebGLUniformLocation | null = null;
  matrixPerspectiveLocation: WebGLUniformLocation | null = null;
  matrixRotationLocation: WebGLUniformLocation | null = null;
  matrixLocalRotationLocation: WebGLUniformLocation | null = null;
  matrixScaleLocation: WebGLUniformLocation | null = null;
  matrixCameraPosLocation: WebGLUniformLocation | null = null;
  alphaLocation: WebGLUniformLocation | null = null;
  divideByAlphaLocation: WebGLUniformLocation | null = null;
  vertexBuffer: WebGLBuffer | null = null;
  normalBuffer: WebGLBuffer | null = null;
  vertIndexBuffer: WebGLBuffer | null = null;
  uvBuffer: WebGLBuffer | null = null;
  textureCoordsBuffer: WebGLBuffer | null = null;
  actualArrowSpriteSize = 0.1; // width, in clip space coords |  will be set each time an arrow is drawn
  actualArrowSpriteSizePx = 40; // same as above, but in pixels | lags 1 frame behind actualArrowSpriteSize
  skipDrawing = false; // don't draw while loading next scene, occasionally a frame sneaks in with new hotspots in the previous pano
  unhoveredOpacity = 1;
  hotSpotScaleModifier = 1; // only for 3D hotspots ('normal' hotspots)

  /*
   *  In order to reduce jaggedness:
   *    A) 3D sprites (direct hotspot circles) must be mip-mapped.
   *    B) 2D sprites must be as small as possible, because webgl downscales very poorly (mip-mapping does nothing for these)
   *
   */
  iconNames = [
    'arrow',
    'arrowHover',
    'hs',
    'hsHover',
    'doors',
    'doorsHover',
    'stairs',
    'stairsHover',
    'stairsDown',
    'stairsDownHover',
  ];

  readonly geometry: number[] = [-1, 1, 0, -1, -1, 0, 1, -1, 0, 1, 1, 0];
  readonly geometryIndices: number[] = [0, 1, 2, 2, 3, 0];
  readonly uvMap: number[] = [0, 1, 0, 0, 1, 0, 1, 1];
  readonly logic: HotSpot3DLogic;

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    renderer: Renderer,
    theme: Theme,
    assetConfig: AssetConfig,
    name?: ProgramName
  ) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.theme = theme;
    this.assetConfig = assetConfig;
    this.name = name ?? 'HotSpotProgram3D';

    this.logic = new HotSpot3DLogic(this);
    this.boundingRect = this.canvas.getBoundingClientRect();
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.handleWindowResize();

    renderer.subscribe('scene.preload.start', this.sceneLoadStart.bind(this));
    renderer.subscribe('scene.preload.end', this.sceneLoadEnd.bind(this));

    this.initAssets();
  }

  init(tourConfig: TourConfig, cameraPos: number[], animator: Animator): void {
    this.cameraPosition = cameraPos;
    this.tourConfig = tourConfig;
    this.initShaders(vertexShader, fragmentShader);
    this.logic.init(tourConfig, animator);
    window.addEventListener('resize', this.handleWindowResize);

    if (this.program) {
      this.uvLocation = this.gl.getAttribLocation(this.program, 'a_uv');
      this.vertexLocation = this.gl.getAttribLocation(this.program, 'a_vertCoord');
      this.mainTexLocation = this.gl.getUniformLocation(this.program, 'u_sampler_main_tex');
      this.matrixPerspectiveLocation = this.gl.getUniformLocation(this.program, 'u_perspective');
      this.matrixRotationLocation = this.gl.getUniformLocation(this.program, 'u_rotate');
      this.matrixLocalRotationLocation = this.gl.getUniformLocation(this.program, 'u_localRotate');
      this.matrixScaleLocation = this.gl.getUniformLocation(this.program, 'u_scale');
      this.matrixCameraPosLocation = this.gl.getUniformLocation(this.program, 'u_cameraPos');
      this.alphaLocation = this.gl.getUniformLocation(this.program, 'u_alpha');
      this.divideByAlphaLocation = this.gl.getUniformLocation(this.program, 'u_divide_by_alpha');

      this.vertexBuffer = this.gl.createBuffer();
      this.normalBuffer = this.gl.createBuffer();
      this.vertIndexBuffer = this.gl.createBuffer();
      this.uvBuffer = this.gl.createBuffer();
      this.textureCoordsBuffer = this.gl.createBuffer();
      this.vertexAttributes = [this.uvLocation, this.vertexLocation];

      this.prepStage();
    }
  }

  initAssets(): void {
    const unhoveredHotSpotOpacityDark = 0.5;
    const unhoveredHotSpotOpacityLight = 0.4;
    this.unhoveredOpacity = this.theme === 'dark' ? unhoveredHotSpotOpacityDark : unhoveredHotSpotOpacityLight;

    Object.values(this.iconNames).forEach((icoName) => {
      this.iconsAssets[icoName] = {
        loadStage: 0,
        fullPath: urljoin(this.assetConfig.assetPath, 'hotspots/v4_themes', this.theme, `${icoName}.png`),
        image: null,
        texture: null,
        mipMapped: icoName === 'hs' || icoName === 'hsHover',
      };
    });
  }

  // on every pano change and startup
  changeScene(nextScenePanoKey: string): void {
    this.currentPanoKey = nextScenePanoKey;
    this.logic.setCurrentScene(nextScenePanoKey);
    this.logic.createHotSpots();
  }

  prepStage(): void {
    this.loadShaders(false);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.geometry), this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.uvMap), this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.vertIndexBuffer);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(this.geometryIndices), this.gl.STATIC_DRAW);
  }

  getHotSpots(): HotSpot3D[] {
    return this.logic.hotSpots;
  }

  getTextureForHotSpot(id: number): WebGLTexture | undefined {
    let asset: HotSpot3DAsset | undefined;
    const hotSpot = this.logic.hotSpots[id];

    if (hotSpot.hidden) {
      return undefined;
    }
    if (!hotSpot.hover && hotSpot.obstructed) {
      return undefined;
    }

    if (hotSpot.type === 'normal') {
      asset = hotSpot.hover ? this.iconsAssets.hsHover : this.iconsAssets.hs;
    } else if (hotSpot.type === 'doors') {
      asset = hotSpot.hover ? this.iconsAssets.doorsHover : this.iconsAssets.doors;
    } else if (hotSpot.type === 'stairsUp') {
      asset = hotSpot.hover ? this.iconsAssets.stairsHover : this.iconsAssets.stairs;
    } else if (hotSpot.type === 'stairsDown') {
      asset = hotSpot.hover ? this.iconsAssets.stairsDownHover : this.iconsAssets.stairsDown;
    }

    return asset?.texture || undefined;
  }

  drawHotSpotsIn3D(): void {
    const rescaleThreshold = 500;
    const drawDistance = 10000;
    this.hotSpotScaleModifier = linearScale(this.fov, [MAX_FOV_RAD, 0], [1, 0]) * 0.845;

    const size = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };
    const geometryPerspectiveMatrix = Matrix.transposeM4(Matrix.perspective(this.fov, size, 0.1, drawDistance));
    this.gl.uniformMatrix4fv(this.matrixPerspectiveLocation, false, new Float32Array(geometryPerspectiveMatrix));

    let modelRotationMatrix = Matrix.identityM3();
    modelRotationMatrix = Matrix.rotateZ(modelRotationMatrix, this.yaw);
    modelRotationMatrix = Matrix.rotateX(modelRotationMatrix, Utils.toRad(-90) + this.pitch);
    modelRotationMatrix = Matrix.m3toM4(modelRotationMatrix);
    this.gl.uniformMatrix4fv(this.matrixRotationLocation, false, new Float32Array(modelRotationMatrix));

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.vertIndexBuffer);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.vertexAttribPointer(this.vertexLocation, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvBuffer);
    this.gl.vertexAttribPointer(this.uvLocation, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.uniform1i(this.mainTexLocation, 1);
    this.gl.activeTexture(this.gl.TEXTURE1);

    for (let i = 0; i < this.logic.hotSpots.length; i += 1) {
      const hotSpot = this.logic.hotSpots[i];
      if (hotSpot.farAway || hotSpot.type !== 'normal') continue;

      const texture = this.getTextureForHotSpot(i);
      if (texture) {
        let proximityScale = 1;
        const alpha = hotSpot.hover ? 1 : this.unhoveredOpacity;

        // direct HS (on the floor) are shrank only if too near to camera
        if (hotSpot.distanceToCamera < rescaleThreshold) {
          // direct HS: only closer ones
          const equalSizeQuotient = Math.sqrt(hotSpot.distanceToCamera) / Math.sqrt(rescaleThreshold); // XXX cm from camera is the BEST size, scale up farther away hotpots and scale down closest ones
          proximityScale = equalSizeQuotient; // forced-equal size objects at different distances look weird, make closest a little bigger and farthest - smaller
        }
        hotSpot.proximityScale = proximityScale;

        const scaleMatrix = Matrix.scalingM4(
          this.hotSpotScale[0] * this.hotSpotScaleModifier * proximityScale,
          this.hotSpotScale[1] * this.hotSpotScaleModifier * proximityScale,
          -this.hotSpotScale[2] * proximityScale
        );
        this.gl.uniformMatrix4fv(this.matrixScaleLocation, false, new Float32Array(scaleMatrix));
        this.gl.bindTexture(this.gl.TEXTURE_2D, texture as WebGLTexture);
        if (hotSpot.type === 'normal') {
          this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR_MIPMAP_LINEAR);
        }

        const ext =
          this.gl.getExtension('EXT_texture_filter_anisotropic') ||
          this.gl.getExtension('MOZ_EXT_texture_filter_anisotropic') ||
          this.gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic');
        if (ext) {
          const max = this.gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT);
          this.gl.texParameterf(this.gl.TEXTURE_2D, ext.TEXTURE_MAX_ANISOTROPY_EXT, max);
        }

        this.gl.uniform1f(this.alphaLocation, alpha);
        this.gl.uniform1f(this.divideByAlphaLocation, 1);

        let localRotationMx = Matrix.identityM3();
        localRotationMx = Matrix.rotateX(localRotationMx, hotSpot.rotation[0]);
        localRotationMx = Matrix.rotateY(localRotationMx, hotSpot.rotation[1]);
        localRotationMx = Matrix.rotateZ(localRotationMx, hotSpot.rotation[2]);
        localRotationMx = Matrix.m3toM4(localRotationMx);
        this.gl.uniformMatrix4fv(this.matrixLocalRotationLocation, false, new Float32Array(localRotationMx));

        const cameraPosMx = Matrix.translationM4(
          this.cameraPosition[0] + hotSpot.position[0],
          this.cameraPosition[1] + hotSpot.position[1],
          this.cameraPosition[2] + hotSpot.position[2]
        );
        this.gl.uniformMatrix4fv(this.matrixCameraPosLocation, false, new Float32Array(cameraPosMx));
        this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);
        this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
      }
    }
  }

  drawArrowsAndBillboardsIn2D(): void {
    if (!this.gl) return;

    const size = {
      width: this.gl.drawingBufferWidth,
      height: this.gl.drawingBufferHeight,
    };
    const geometryPerspectiveMx = Matrix.transposeM4(Matrix.perspective(1.6, size, 0.1, 2000));
    this.gl.uniformMatrix4fv(this.matrixPerspectiveLocation, false, new Float32Array(geometryPerspectiveMx));

    const modelRotationMx = Matrix.m3toM4(Matrix.identityM3());
    this.gl.uniformMatrix4fv(this.matrixRotationLocation, false, new Float32Array(modelRotationMx));

    for (let i = 0; i < this.logic.arrows.length; i += 1) {
      this.drawSprite(this.logic.arrows[i], true);
    }

    for (let i = 0; i < this.logic.nonDirectHotSpots.length; i += 1) {
      this.drawSprite(this.logic.nonDirectHotSpots[i], false);
    }
  }

  // positions are in clipspace -1,-1 .. 1,1 => bottom left corner .. top right corner
  drawSprite(sprite: HotSpot3DSprite, isArrow = false): void {
    const visibleSize = 450 * Math.round(window.devicePixelRatio);
    const rescaleX = (visibleSize / this.gl.drawingBufferWidth) * 2; // pixels to clipspace
    const rescaleY = 1 / this.screenAspectRatio;
    const spriteSize = sprite.scale * rescaleX;

    if (isArrow) {
      this.actualArrowSpriteSize = spriteSize; // for anonymous statistics
    }

    const scaleMatrix = Matrix.scalingM4(-spriteSize, spriteSize, 1); // always square sprite
    this.gl.uniformMatrix4fv(this.matrixScaleLocation, false, new Float32Array(scaleMatrix));

    let localRotationMx = Matrix.identityM3();
    localRotationMx = Matrix.rotateZ(localRotationMx, sprite.rotation);
    localRotationMx = Matrix.m3toM4(localRotationMx);
    this.gl.uniformMatrix4fv(this.matrixLocalRotationLocation, false, new Float32Array(localRotationMx));

    const cameraPosMx = Matrix.translationM4(sprite.x, sprite.y * rescaleY, -1);
    this.gl.uniformMatrix4fv(this.matrixCameraPosLocation, false, new Float32Array(cameraPosMx));

    const divideByAlpha = this.theme === 'dark' ? 0 : 1; // don't divide the RGB by alpha value for dark theme arrows
    this.gl.uniform1f(this.divideByAlphaLocation, divideByAlpha);
    this.gl.uniform1f(this.alphaLocation, sprite.alpha);

    this.gl.bindTexture(this.gl.TEXTURE_2D, sprite.texture);

    this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);
  }

  // Gets called on every frame [if being drawn]
  maybeLoadPicture(httpFetchOnly = false): void {
    Object.entries(this.iconsAssets).forEach(([, icoAsset_]) => {
      if (icoAsset_.loadStage === 0) {
        icoAsset_.loadStage = 1;
        Utils.fetchImage(icoAsset_.fullPath, null).then((image?: Image) => {
          if (image) {
            icoAsset_.image = image;
          }
        });
      }

      if (httpFetchOnly) return; // when preloader doesn't want to load shaders and do any actual work

      // put images in video memory if they are loaded now (can't do that in fetch callback, might be loaded wrong program then)
      if (icoAsset_.loadStage === 1) {
        if (icoAsset_.image) {
          icoAsset_.loadStage = 2;
          icoAsset_.texture = this.createTexture();
          this.gl.activeTexture(this.gl.TEXTURE1);
          this.gl.bindTexture(this.gl.TEXTURE_2D, icoAsset_.texture);
          this.gl.texImage2D(
            this.gl.TEXTURE_2D,
            0,
            this.gl.RGBA,
            this.gl.RGBA,
            this.gl.UNSIGNED_BYTE,
            icoAsset_.image as ImageBitmap
          );

          if (icoAsset_.mipMapped) {
            this.gl.generateMipmap(this.gl.TEXTURE_2D);
          }
        }
        this.emit('render');
      }
    });
  }

  handleWindowResize(): void {
    this.boundingRect = this.canvas.getBoundingClientRect();
    this.screenAspectRatio = this.boundingRect.width / this.boundingRect.height;
  }

  enableHotSpots(flag = true): void {
    this.interactive = flag;
    if (flag === false) {
      this.canvas.style.cursor = 'default';
    }
  }

  setHotSpotsDisabled(flag: boolean, hotSpotType?: HotSpot3DType): void {
    // TODO: implement later, needs some refactoring
    console.log('setHotSpotDisabled not implemented', flag, hotSpotType);

    this.emit('render');
  }

  updateHotSpot(nextHotSpotConfig: HotSpot3DConfig, targetSceneKey: string, action: HotSpotEditAction): void {
    if (!this.tourConfig) return;

    this.logic.handleHotSpotAction(action, nextHotSpotConfig, targetSceneKey);

    this.emit('render');
  }

  destroy(): void {
    window.removeEventListener('resize', this.handleWindowResize);
    this.destroyEventEmitter();
    this.destroyProgram();
    this.renderer.unsubscribe('scene.preload.start', this.sceneLoadStart);
    this.renderer.unsubscribe('scene.transition.end', this.sceneLoadEnd);
  }

  sceneLoadStart(): void {
    this.skipDrawing = true;
  }

  sceneLoadEnd(): void {
    this.skipDrawing = false;
  }

  updateHotSpotTheme(theme: Theme): void {
    this.theme = theme;
    this.initAssets();
    this.logic.createHotSpots();
    this.emit('render');
  }

  render(): void {
    if (!this.gl || !this.interactive || this.skipDrawing || this.renderer.isInTransition) return;

    this.draw();
  }

  private draw(): void {
    this.logic.calculateArrows();
    this.logic.calculateHotSpotScreenPositions();

    this.loadShaders();
    this.enableVertexAttributes();
    this.maybeLoadPicture();

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.drawHotSpotsIn3D();
    this.drawArrowsAndBillboardsIn2D();

    this.disableVertexAttributes();
    this.gl.disable(this.gl.CULL_FACE);
  }
}

export default HotSpotProgram3D;
