import { linearScale } from '@g360/vt-utils/';
import { Mixin } from 'ts-mixer';

import SimpleAnimator from '../common/SimpleAnimator';
import Utils from '../common/Utils';
import EventEmitter from './EventEmitter';

type StopSpinningFn = {
  (): void;
  isFallback?: boolean;
};

const stopFallback: StopSpinningFn = () => {
  console.warn('no active spinning');
};
stopFallback.isFallback = true;

abstract class Camera extends Mixin(EventEmitter) {
  public stopSpinningYaw: StopSpinningFn = stopFallback;

  protected _pitch;
  protected _yaw;
  protected _fov;

  protected abstract render: () => undefined;

  public spinYaw(direction: 'negative' | 'positive', duration?: number) {
    if (!this.stopSpinningYaw.isFallback) {
      this.stopSpinningYaw();
    }
    const stopAnimation = this.spinControl('yaw', direction, duration);

    this.stopSpinningYaw = () => {
      stopAnimation();
      this.stopSpinningYaw = stopFallback;
    };
    return this.stopSpinningYaw;
  }

  private spinControl(control: 'pitch' | 'yaw' | 'fov', direction: 'negative' | 'positive', duration = 10000) {
    const currentValue: number = this[control] || 0;
    this.emit('scene.move.start', { pitch: this._pitch, yaw: this._yaw, fov: this._fov });

    const animation = new SimpleAnimator(duration, 'linear', (progress) => {
      this[control] = linearScale(
        progress,
        [0, 1],
        [currentValue, currentValue + Utils.toRad(direction === 'positive' ? 360 : -360)]
      );
      this.emit('scene.move.update', { pitch: this._pitch, yaw: this._yaw, fov: this._fov });
      this.render();
    });

    return () => {
      animation.stop();
      this.emit('scene.move.end');
    };
  }
}

export default Camera;
