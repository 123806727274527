import type { AssetConfig } from '@g360/vt-types';
import { Mixin } from 'ts-mixer';
import urljoin from 'url-join';

import Matrix from '../../common/Matrix';
import Utils from '../../common/Utils';
import type Renderer from '../../mixins/Renderer';
import type { Image, ProgramName } from '../../types/internal';
import type MeasureToolProgram from '../MeasureToolProgram';
import ColorCorrectionProgramPartial from '../mixins/ColorCorrectionProgramPartial';
import WebGLProgram from '../mixins/Program';
import fragmentShader from './cube.fs.glsl';
import vertexShader from './cube.vs.glsl';
import fragmentShaderColorCorrection from './cubeColorCorrection.fs.glsl';
import CubeEventEmitter from './CubeEventEmitter';
import Tile from './Tile';

class CubeProgram extends Mixin(WebGLProgram, CubeEventEmitter, ColorCorrectionProgramPartial) {
  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  renderer: Renderer;
  assetConfig: AssetConfig;
  colorCorrectionMode = false;
  name: ProgramName;

  vertexBuffer: WebGLBuffer | null;
  textureBuffer: WebGLBuffer | null;
  vertexAttribute = 0;
  textureAttribute = 0;
  perspectiveUniform: WebGLUniformLocation | null = null;
  alphaUniform: WebGLUniformLocation | null = null;
  alphaFixUniform: WebGLUniformLocation | null = null;
  samplerUniform: WebGLUniformLocation | null = null;

  ready = false;
  yaw = 0;
  pitch = 0;
  fov = Utils.toRad(120);

  orderIndex = 0;
  alpha = 1.0;
  yawOffset = 0;
  rotationMatrix: number[] = [];
  perspectiveMatrix: number[] = [];
  rotatedPerspective: number[] = [];

  tileLoadQueue: Tile[] = [];
  tiles: Tile[] = [];
  tilesPath?: string;
  optimalRenderedLevel = 3;
  maxRenderedLevel = 3;
  abortController = window.AbortController ? new AbortController() : null;
  forcePreview = false;
  numDownloading = 0;
  highResMode = false;
  isPreloadPano = false;

  measureToolProgram?: MeasureToolProgram;

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    renderer: Renderer,
    assetConfig: AssetConfig,
    colorCorrectionMode: boolean,
    name?: ProgramName
  ) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.assetConfig = assetConfig;
    this.colorCorrectionMode = colorCorrectionMode;
    this.name = name ?? 'PanoProgram';

    this.vertexBuffer = this.gl.createBuffer();
    this.textureBuffer = this.gl.createBuffer();

    // Initialize tile structure and default textures
    for (let sideIter = 0; sideIter < 6; sideIter += 1) {
      const sideKey = Tile.sideKeys[sideIter];

      this.tiles.push(
        new Tile(
          Tile.sideKeys[sideIter],
          Tile.faceGeometry[sideKey],
          `/${sideKey}/l${1}/${1}/l${1}_${sideKey}_${1}_${1}.jpg`,
          1,
          null
        )
      );
    }

    this.drawToFramebufferTexture = this.drawToFramebufferTexture.bind(this);
  }

  init(): void {
    if (this.colorCorrectionMode) {
      this.initShaders(vertexShader, fragmentShaderColorCorrection);
    } else {
      this.initShaders(vertexShader, fragmentShader);
    }

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_vertCoord');
      this.textureAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');
      this.perspectiveUniform = this.gl.getUniformLocation(this.program, 'u_perspective');
      this.alphaUniform = this.gl.getUniformLocation(this.program, 'u_alpha');
      this.alphaFixUniform = this.gl.getUniformLocation(this.program, 'u_alpha_fix');
      this.samplerUniform = this.gl.getUniformLocation(this.program, 'u_sampler');
      this.vertexAttributes = [this.vertexAttribute, this.textureAttribute];
      if (this.colorCorrectionMode) {
        this.initColorCorrection(this.program, this.gl);
      }
    }

    this.ready = true;
  }

  /** Delete all created textures, abort pending loads and clear queue */
  destroy(): void {
    this.ready = false;

    const destroyTile = (tile: Tile) => {
      // Explicitly delete texture
      this.gl.deleteTexture(tile.textureObject);

      if (tile.children) {
        tile.children.forEach(destroyTile);
      }
    };

    this.abortPending();
    this.tileLoadQueue = [];
    this.tiles.forEach(destroyTile);

    this.destroyEventEmitter();
    this.destroyProgram();
  }

  /** Abort all pending fetch requests */
  abortPending(): void {
    this.tileLoadQueue = [];

    if (this.abortController) {
      this.abortController.abort();
    }
  }

  /** Find optimal resolution level based on canvas size and scene zoom (fov) */
  setOptimalLevel(): void {
    if (this.highResMode) {
      this.optimalRenderedLevel = 4;
      return;
    }

    // TODO(uzars): need to revise this
    const maxLevel = Tile.levelSizes.length;
    const fov = (this.fov * 180) / Math.PI;
    const projectionPixelsList = Tile.levelSizes.map((size) => (size * 4 * fov) / 360);
    const viewportPixels = window.innerWidth * (1.4 + 0.4 + (window.devicePixelRatio - 1) * 0.2);

    let newLevel = maxLevel;

    for (let i = 0; i < maxLevel; i += 1) {
      if (viewportPixels < projectionPixelsList[i]) {
        newLevel = i + 1;
        break;
      }
    }

    this.optimalRenderedLevel = newLevel;
  }

  /** Load the preview.jpg image for the fastest first paint, this is needed only for the initial scene load
   *  or if we fail to load watermarks - then L1 is replaced with preview and L1+ is disabled.
   */
  async loadPreview(tilesPath: string): Promise<void> {
    this.tilesPath = tilesPath;
    const previewPath = urljoin(tilesPath, 'preview.jpg');

    await Utils.fetchSignedImage(previewPath, this.assetConfig, this.abortController).then((image?: Image) => {
      if (!image) return;

      // Same texture is used for all sides, only texture coordinates are changed
      const textureObject = this.createTexture([255, 255, 255, 0.0]);
      this.gl.bindTexture(this.gl.TEXTURE_2D, textureObject);
      this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGB, this.gl.RGB, this.gl.UNSIGNED_BYTE, image);
      Utils.closeImage(image);

      // Disable multi-res levels
      this.tiles.forEach((tile) => {
        tile.disableAll();
      });

      // prettier ignore
      const sideIndex = { f: 1, b: 3, u: 4, d: 5, l: 0, r: 2 };
      const pixelOffset = 0.0003;
      const sizeOffset = 1 / 6;

      Tile.sideKeys.forEach((sideKey) => {
        const textureCoords = new Float32Array([
          0,
          pixelOffset + sideIndex[sideKey] * sizeOffset,
          1,
          pixelOffset + sideIndex[sideKey] * sizeOffset,
          1,
          sizeOffset - pixelOffset + sideIndex[sideKey] * sizeOffset,
          0,
          sizeOffset - pixelOffset + sideIndex[sideKey] * sizeOffset,
        ]);

        const tile = new Tile(sideKey, Tile.faceGeometry[sideKey], 'preview.jpg', 1, null, null, textureCoords)
          .setTextureObject(textureObject)
          .setAsPreview();

        this.tiles.push(tile);
      });

      this.emit('render');
    });
  }

  /** Load tiles using queue */
  loadTiles(tilesPath?: string): void {
    if (this.forcePreview) {
      return;
    }

    if (tilesPath) {
      this.tilesPath = tilesPath;
    }

    this.tiles.forEach((tile) => {
      if (!tile.preview) {
        tile.disableAll(false);
      }
    });

    this.emit('render');
  }

  /** Load tiles without queue as fast as possible */
  async preload(tilesPath: string): Promise<void> {
    if (this.forcePreview) {
      await this.loadPreview(tilesPath);
      return;
    }

    const promiseList: Promise<void>[] = [];
    this.tilesPath = tilesPath;

    this.tiles.forEach((tile_) => {
      if (!tile_.preview) {
        promiseList.push(
          new Promise((resolve) => {
            tile_.loading = true;
            const tilePath = urljoin(tilesPath, tile_.path);

            Utils.fetchSignedImage(tilePath, this.assetConfig, this.abortController).then((image?: Image) => {
              if (image) {
                if (!tile_.textureObject) {
                  tile_.textureObject = this.createTexture();
                }

                this.gl.bindTexture(this.gl.TEXTURE_2D, tile_.textureObject);
                this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGB, this.gl.RGB, this.gl.UNSIGNED_BYTE, image);

                tile_.loaded = true;
                tile_.disabled = false;
              }

              resolve();
            });
          })
        );
      }
    });

    await Promise.all(promiseList);
  }

  /**
   * Sets high resolution mode to load the highes level tiles
   * @param enableHighRes - enable high resolution mode
   */
  setHighResMode(enableHighRes: boolean): void {
    this.highResMode = enableHighRes;
    this.setOptimalLevel();
    this.draw();
  }

  render(): void {
    if (!this.gl || !this.ready) return;

    const { renderer } = this;
    if (renderer.renderMode !== 'pano') return;

    // Transition rendering
    if (renderer.isInTransition) {
      const isDoublePanoBlend = !renderer.transitionConnected || renderer.watermarkInterrupted;

      if (isDoublePanoBlend) {
        if (this.isPreloadPano) this.alpha = renderer.transitionProgress;
        this.draw(true);
        if (this.isPreloadPano) renderer.render();
      }

      return;
    }

    const { cameraMoved } = renderer;

    // Normal rendering with measure tool
    if (this.measureToolProgram) {
      renderer.nextDrawMoved = cameraMoved;

      if (cameraMoved) {
        renderer.shouldDrawAfterMove = true;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.needsToDrawFb = false;
      } else if (renderer.needsToDrawFb) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.needsToDrawFb = false;
      } else if (renderer.shouldDrawAfterMove) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.shouldDrawAfterMove = false;
      }

      return;
    }

    // Normal rendering
    this.draw(cameraMoved);
  }

  draw(moved = false): void {
    if (!this.gl || !this.ready) return;

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.activeTexture(this.gl.TEXTURE1);

    this.loadQueuedTile();
    this.loadShaders();
    this.enableVertexAttributes();

    if (moved || !this.perspectiveMatrix.length) {
      this.perspectiveMatrix = Matrix.perspective(this.fov,{width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight}, 0.1, 100.0); // prettier-ignore

      this.rotationMatrix = Matrix.identityM3();
      this.rotationMatrix = Matrix.rotateX(this.rotationMatrix, -this.pitch);
      this.rotationMatrix = Matrix.rotateY(this.rotationMatrix, this.yaw + this.yawOffset);
      this.rotationMatrix = Matrix.m3toM4(this.rotationMatrix);
      this.rotatedPerspective = Matrix.rotatePerspective(this.perspectiveMatrix, this.rotationMatrix);
      this.gl.uniformMatrix4fv(this.perspectiveUniform, false, Matrix.transposeM4(this.rotatedPerspective));
    }

    this.maxRenderedLevel = this.optimalRenderedLevel;
    if (moved && this.optimalRenderedLevel > 1) {
      this.maxRenderedLevel = this.optimalRenderedLevel - 1;
    }

    this.tiles.forEach((tile) => {
      this.checkTile(tile);
    });

    this.gl.disable(this.gl.CULL_FACE);

    this.disableVertexAttributes();
  }

  private drawToFramebufferTexture(): void {
    this.draw(this.renderer.nextDrawMoved);
  }

  /** Sort tiles based on screen space coordinates and level - lowest level and tiles closest to the center
   * of the viewport are first */
  private sortQueuePriority(): void {
    this.tileLoadQueue.sort((a, b) => {
      if (a.level > b.level) return -1;
      if (a.level < b.level) return 1;

      return b.diff - a.diff;
    });
  }

  /** Load tile image and add it to the texture loading queue */
  private loadTile(tile_: Tile): void {
    if (this.tilesPath) {
      tile_.loading = true;
      tile_.resetTextureCoords();
      const tilePath = urljoin(this.tilesPath, tile_.path);

      this.numDownloading += 1;
      Utils.fetchSignedImage(tilePath, this.assetConfig, this.abortController).then((image?: Image) => {
        if (image) {
          this.numDownloading -= 1;
          this.addToTileQueue(image, tile_);
          this.emit('render');
        }
      });
    }
  }

  /** Load tile texture to the GPU and setup state for parent tile */
  private loadTileTexture(image: Image, tile_: Tile): void {
    if (!image) return;

    // TODO(uzars): improve error capture
    if (!tile_.textureObject) {
      tile_.textureObject = this.createTexture();
    }

    this.gl.bindTexture(this.gl.TEXTURE_2D, tile_.textureObject);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGB, this.gl.RGB, this.gl.UNSIGNED_BYTE, image);

    tile_.loaded = true;

    // Check if all sibling tiles are loaded
    if (tile_.parent) {
      tile_.parent.checkChildrenLoaded();
    }

    // Delete preview tile if this tile is level 1 (l1 replaces preview)
    if (tile_.level === 1 && !this.forcePreview) {
      this.tiles = this.tiles.filter((baseTile) => !(baseTile.sideKey === tile_.sideKey && baseTile.preview));
    }

    Utils.closeImage(image);
    this.emit('render');
  }

  /** Take and run texture loading task from the queue, only a single texture is loaded in an animation frame */
  private loadQueuedTile(): void {
    if (this.tileLoadQueue.length) {
      const tile = this.tileLoadQueue.pop();

      if (tile?.loadTexture) {
        tile.loadTexture();
      }
    }
  }

  /** Add callable texture loading task to the queue */
  private addToTileQueue(image: Image, tile_: Tile): void {
    tile_.loadTexture = () => {
      this.loadTileTexture(image, tile_);
    };

    tile_.setDiff(this.rotatedPerspective);

    this.tileLoadQueue.unshift(tile_);

    this.sortQueuePriority();
  }

  private checkTile(tile: Tile): void {
    if (tile.disabled || tile.level > this.maxRenderedLevel) return;
    if (tile.level < this.maxRenderedLevel && !tile.isVisible(this.rotatedPerspective)) return;
    if (tile.parent && !tile.parent.loaded) return;

    if (!tile.loading) {
      this.loadTile(tile);
    } else if (tile.loaded) {
      if (!tile.childrenLoaded || this.maxRenderedLevel === tile.level) {
        this.drawTile(tile);
      }
    } else {
      return;
    }

    if (tile.children) {
      tile.children.forEach((childTile) => {
        this.checkTile(childTile);
      });
    }
  }

  private drawTile(tile: Tile): void {
    if (this.colorCorrectionMode) {
      this.setColorCorrectionUniforms(this.gl);
    }

    // Set texture coordinates
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, tile.textureCoords, this.gl.DYNAMIC_DRAW);
    this.gl.vertexAttribPointer(this.textureAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.uniform1f(this.alphaUniform, this.alpha);
    this.gl.uniform1i(this.alphaFixUniform, 0);

    this.gl.uniform1i(this.samplerUniform, 1);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, tile.geometry, this.gl.STATIC_DRAW);
    this.gl.vertexAttribPointer(this.vertexAttribute, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.bindTexture(this.gl.TEXTURE_2D, tile.textureObject);
    this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);
  }
}

export default CubeProgram;
