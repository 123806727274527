import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServicesContext from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import Text from '../common/Text';
import TextButton from '../common/TextButton';
import HeadroomOneHalfIcon from '../icons/HeadroomOneHalfIcon';
import HeadroomOneIcon from '../icons/HeadroomOneIcon';
import HeadroomTwoIcon from '../icons/HeadroomTwoIcon';
function ReducedHeadroomSection({ standard, units }) {
    const t = useTranslation();
    const unitsPostfix = units === 'imperial' ? 'ft' : 'm';
    if (!standard || standard === 'international')
        return null;
    if (standard === 'german') {
        return (_jsxs("div", Object.assign({ className: "mb-8 flex" }, { children: [_jsxs("div", Object.assign({ className: "mr-8 flex flex-col whitespace-nowrap" }, { children: [_jsx(Text, Object.assign({ size: "medium" }, { children: t('property-info.reduced-headroom') })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: "absolute w-4 overflow-hidden" }, { children: _jsx(HeadroomOneIcon, { className: "w-[3.75rem]" }) })), _jsx(Text, Object.assign({ size: "medium", className: "ml-9" }, { children: t(`footer.below-one-meter-${unitsPostfix}`) }))] })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: "absolute w-4 overflow-hidden" }, { children: _jsx(HeadroomTwoIcon, { className: "w-[3.75rem]" }) })), _jsx(Text, Object.assign({ size: "medium", className: "ml-9" }, { children: t(`footer.below-two-meters-${unitsPostfix}`) }))] }))] })), _jsx(Text, Object.assign({ size: "medium" }, { children: t(`footer.german-standard-headroom-${unitsPostfix}`) }))] })));
    }
    if (standard === 'default') {
        return (_jsxs("div", Object.assign({ className: "mb-8 flex flex-col" }, { children: [_jsx(Text, Object.assign({ size: "medium" }, { children: t('property-info.reduced-headroom') })), _jsxs("div", Object.assign({ className: "flex items-center" }, { children: [_jsx("div", Object.assign({ className: "absolute w-4 overflow-hidden" }, { children: _jsx(HeadroomOneHalfIcon, { className: "w-[3.75rem]" }) })), _jsx(Text, Object.assign({ size: "medium", className: "ml-9" }, { children: t(`footer.below-one-half-meters-${unitsPostfix}`) }))] }))] })));
    }
    return null;
}
function FloorplanStandardInfo({ onBack }) {
    const t = useTranslation();
    const { tourConfigService } = useServicesContext();
    const { projectDataConfig, units } = useReactive(tourConfigService, ['projectDataConfig', 'units']);
    // which FP standard was used
    const standard = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.floorplanStandard;
    // did client change the measurements
    const adjusted = projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.floorplanAdjusted;
    const standardDisclaimerString = useMemo(() => {
        if (standard === 'international') {
            if (adjusted) {
                return t('footer.international-standard-adjusted');
            }
            return t('footer.international-standard');
        }
        if (standard === 'german') {
            if (adjusted) {
                return t('footer.german-standard-adjusted');
            }
            return t('footer.german-standard');
        }
        if (standard === 'default') {
            if (adjusted) {
                return t('footer.default-standard-adjusted');
            }
            return t('footer.default-standard');
        }
        console.warn('Unknown floorplan standard:', standard);
        return '';
    }, [t, adjusted, standard]);
    return (_jsxs("div", Object.assign({ className: "px-8 py-6" }, { children: [_jsx(ReducedHeadroomSection, { standard: standard, units: units }), _jsx(Text, Object.assign({ size: "medium" }, { children: t('footer.disclaimer') })), _jsx(Text, Object.assign({ size: "medium" }, { children: standardDisclaimerString })), _jsx(TextButton, Object.assign({ className: "ml-auto mt-11 h-10 rounded-lg border @xl:w-auto", variant: "primary", onClick: onBack, testId: "back-btn" }, { children: t('contact.back') }))] })));
}
export default FloorplanStandardInfo;
