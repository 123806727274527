var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState } from 'react';
const DISSAPEAR_TIMEOUT_MS = 3000;
const DST_FROM_SCREEN_EDGE_PX = 24;
const TooltipButton = (_a) => {
    var { children, tooltipText, placement, pxFromScreenEdge = DST_FROM_SCREEN_EDGE_PX, dissapearTimeoutMs = DISSAPEAR_TIMEOUT_MS, className, onClick, disabled } = _a, buttonProps = __rest(_a, ["children", "tooltipText", "placement", "pxFromScreenEdge", "dissapearTimeoutMs", "className", "onClick", "disabled"]);
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [translateX, setTranslateX] = useState(0);
    const tooltipContentRef = useRef(null);
    const timerRef = useRef(null);
    const onMouseEnter = useCallback(() => {
        setTooltipVisible(true);
    }, []);
    const onMouseLeave = useCallback(() => {
        setTooltipVisible(false);
    }, []);
    const onTouchEnd = useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        setTooltipVisible(true);
        timerRef.current = setTimeout(() => {
            setTooltipVisible(false);
        }, dissapearTimeoutMs);
    }, [dissapearTimeoutMs]);
    /** Dismiss the tooltip when user starts touching outside the tooltip content */
    const windowTouchHandler = useCallback((e) => {
        if (!tooltipVisible)
            return;
        if (tooltipContentRef.current && e.target !== tooltipContentRef.current) {
            setTooltipVisible(false);
            if (timerRef.current)
                clearTimeout(timerRef.current);
        }
    }, [tooltipVisible]);
    useEffect(() => {
        const ref = tooltipContentRef.current;
        if (!ref)
            return;
        const rect = ref.getBoundingClientRect();
        setTranslateX((oldX) => {
            const maxNewX = window.innerWidth - rect.right - pxFromScreenEdge + oldX;
            const minNewX = pxFromScreenEdge - rect.left + oldX;
            let desiredNewX = 0;
            if (rect.left <= pxFromScreenEdge) {
                // Overflows left edge
                desiredNewX = oldX - rect.left + pxFromScreenEdge;
            }
            else if (rect.right >= window.innerWidth - pxFromScreenEdge) {
                // Overflows right edge
                desiredNewX = oldX + window.innerWidth - rect.right - pxFromScreenEdge;
            }
            else {
                // Should be centered
                desiredNewX = -rect.width / 2;
            }
            return Math.max(Math.min(desiredNewX, maxNewX), minNewX);
        });
    }, [tooltipVisible, pxFromScreenEdge]);
    useEffect(() => {
        window.addEventListener('touchstart', windowTouchHandler);
        return () => {
            window.removeEventListener('touchstart', windowTouchHandler);
        };
    }, [windowTouchHandler]);
    return (_jsxs("div", Object.assign({ className: "relative" }, { children: [tooltipText && (_jsx("div", Object.assign({ ref: tooltipContentRef, style: { transform: `translateX(${translateX}px)` }, className: `pointer-events-auto absolute ${placement === 'bottom' ? 'top-[calc(100%+0.5rem)]' : 'bottom-[calc(100%+0.5rem)]'} left-1/2 w-max max-w-[min(330px,calc(100vw-3rem))] rounded-md bg-theme-primary bg-opacity-theme-text-bg px-4 py-2 text-sm text-theme-contrast transition-opacity ${tooltipVisible ? 'opacity-100' : 'opacity-0'}` }, { children: tooltipText }))), _jsx("button", Object.assign({ onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onTouchEnd: onTouchEnd, type: "button", className: className, onClick: () => {
                    if (disabled)
                        return;
                    onClick();
                } }, buttonProps, { children: children }))] })));
};
export default TooltipButton;
