import type { TourConfig } from '@g360/vt-types';

import { translateHotspotPosition } from './translateHotspotPosition';

/** Reroutes hotspots targeting subScenes to target the main scenes instead,
 * also copies the hotspots from the main scene to its subScenes
 */
export function rerouteSubSceneHotSpots(tourConfig: TourConfig): TourConfig {
  const scenes = tourConfig.scenes;
  const sceneGroups = tourConfig.sceneGroups;

  if (!sceneGroups) return tourConfig;

  // Copy the subScene hotspots into the main scene of that subScene
  Object.values(scenes).forEach((mainScene) => {
    const subScenes = mainScene.subScenes;
    if (subScenes === undefined) return;

    const mainSceneHotspots = mainScene.hotSpots;

    Object.keys(subScenes).forEach((subSceneKey) => {
      const subScene = subScenes[subSceneKey];
      // Filter out all hotspots which are already targeted from main Scene
      const filteredSubSceneHotspots = subScene.hotSpots
        .filter((hotspot) => !mainSceneHotspots.some((mainSceneHotspot) => mainSceneHotspot.target === hotspot.target))
        .map((hotSpot) => translateHotspotPosition(subScene, mainScene, hotSpot));

      mainSceneHotspots.push(...filteredSubSceneHotspots);
    });
  });

  // First we reroute all hotspots inside mainScenes that are targeting subScenes
  // to target the respective mainScenes instead
  Object.keys(scenes).forEach((mainSceneKey) => {
    const mainScene = scenes[mainSceneKey];

    // Reroute the main scene hotspots
    for (let i = 0; i < mainScene.hotSpots.length; i += 1) {
      const hotSpot = mainScene.hotSpots[i];

      for (let j = 0; j < sceneGroups.length; j += 1) {
        const sceneGroup = sceneGroups[j];

        const indexOf = sceneGroup.indexOf(hotSpot.target);

        // If it's in the group, but not targeting the main scene
        if (indexOf !== 0 && indexOf !== -1) {
          // make it target the main scene
          hotSpot.target = sceneGroup[0];
        }
      }
    }

    // TODO: remove; this is only for debug data, remove hotspots targeting the group itself
    // this shouldn't happen in real data
    mainScene.hotSpots = mainScene.hotSpots.filter((h) => h.target !== mainSceneKey);
    const removeIdx: Set<number> = new Set();

    // TODO: remove; this is only for debug data, remove multiple hotspots targeting the same scene
    // this shouldn't happen in real data
    for (let i = 0; i < mainScene.hotSpots.length; i += 1) {
      for (let j = i + 1; j < mainScene.hotSpots.length; j += 1) {
        if (mainScene.hotSpots[i].target === mainScene.hotSpots[j].target) {
          removeIdx.add(j);
        }
      }
    }

    mainScene.hotSpots = mainScene.hotSpots.filter((_, idx) => !removeIdx.has(idx));
  });

  // Copy the main scene hotspots into each subScene of that main scene
  Object.values(scenes).forEach((mainScene) => {
    const subScenes = mainScene.subScenes;
    if (subScenes === undefined) return;

    const mainSceneHotSpots = mainScene.hotSpots;

    Object.keys(subScenes).forEach((subSceneKey) => {
      const subScene = subScenes[subSceneKey];
      subScene.hotSpots = mainSceneHotSpots.map((hotSpot) => translateHotspotPosition(mainScene, subScene, hotSpot));
    });
  });

  return tourConfig;
}
