/* eslint-disable no-lonely-if,no-unused-expressions */
import type {
  DataJsonPortalType,
  DataJsonType,
  HotSpot3DConfig,
  Pano,
  Panos,
  Point3D,
  PointOfInterest,
  PointOfInterestType,
  ScenePos,
} from '@g360/vt-types';

import { toRad } from '../math';

export const offsetPoint = (point: number[], offset: number[]): number[] => [
  point[0] + offset[0],
  point[1] + offset[1],
];

export const rotatePoint = (angleInRadians: number, point: number[], origin: number[] = [0, 0]) => {
  const cosTheta = Math.cos(angleInRadians);
  const sinTheta = Math.sin(angleInRadians);

  const dx = point[0] - origin[0];
  const dy = point[1] - origin[1];

  const x = origin[0] + (dx * cosTheta - dy * sinTheta);
  const y = origin[1] + (dx * sinTheta + dy * cosTheta);

  return [x, y];
};

export const getPitchAndYawToLookAtP2FromP1 = (p1: Point3D, p2: Point3D): ScenePos => {
  const dx = p2[0] - p1[0];
  const dy = p2[1] - p1[1];
  const dz = p2[2] || 0 - p1[2] || 0;
  const yaw = Math.atan2(dy, dx) + Math.PI / 2; // half-a-radian is needed cos the floorplan axis is rotated 90deg
  const pitch = Math.atan2(dz, Math.sqrt(dx * dx + dy * dy));

  return { pitch, yaw };
};

const getMidpoint = (point1: number[], point2: number[]): number[] => {
  const [x1, y1, z1] = point1;
  const [x2, y2, z2] = point2;

  const midX = (x1 + x2) / 2;
  const midY = (y1 + y2) / 2;
  const midZ = (z1 + z2) / 2;

  return [midX, midY, midZ];
};

export const tryGetRoomInfo = (dataJson: DataJsonType, roomId: string) => {
  let room = dataJson.rooms[roomId];
  // console.log(roomId,room);

  if (room.rotation_rad && room.position_m) {
    return {
      roomRotation: room.rotation_rad,
      roomOffset: room.position_m,
    };
  }

  if (room.parent_room_id) {
    room = dataJson.rooms[room.parent_room_id];
    if (room.rotation_rad && room.position_m) {
      return {
        roomRotation: room.rotation_rad,
        roomOffset: room.position_m,
      };
    }
  }

  // console.error(`tryGetRoomInfo::no room info found for ${roomId} !`);
  return {
    roomRotation: 0,
    roomOffset: [0, 0, 0],
    failed: true,
  };
};

export const getInterestPoints = (sceneKey: string, panos: Panos, dataJson: DataJsonType): PointOfInterest[] => {
  const pano = panos[sceneKey];
  const interestPoints: PointOfInterest[] = [];

  if (pano.outside || !pano.portals) return interestPoints;

  pano.portals.forEach((portalId) => {
    const portal = dataJson.portals[portalId];
    if (portal.type !== 'door') return;
    if (portal.matched) return;
    if (!portal.entrance && !portal.open) return;

    const type: PointOfInterestType = portal.entrance ? 'entrance' : 'doors';
    const portalMidPointBot = getMidpoint(portal.bot_left_3d_m, portal.bot_right_3d_m);
    const portalMidPointTop = getMidpoint(portal.top_left_3d_m, portal.top_right_3d_m);
    const portalMidMid = getMidpoint(portalMidPointBot, portalMidPointTop);

    const cameraPosFrom = [pano.camera[0] / 100, pano.camera[1] / 100, pano.camera[2] / 100] as Point3D; // convert to meters
    const cameraPosToLocal = [portalMidMid[0], portalMidMid[1], portalMidMid[2]] as Point3D;

    const { roomRotation, roomOffset } = tryGetRoomInfo(dataJson, portal.room_id);
    const cameraPosTo = offsetPoint(rotatePoint(roomRotation, cameraPosToLocal), roomOffset) as Point3D;
    cameraPosTo[2] = cameraPosToLocal[2]; // don't localize z value

    const { pitch, yaw } = getPitchAndYawToLookAtP2FromP1(cameraPosFrom, cameraPosTo);
    interestPoints.push({ scenePos: { pitch, yaw }, type });
  });

  return interestPoints;
};

const findPortalToNextPano = (panoFrom: Pano, nextPano: Pano, dataJson: DataJsonType) => {
  const portalIdsToNext: string[] = [];
  const panoFromPortalIds = panoFrom.portals || [];
  const panoToPortalIds = nextPano.portals || [];
  // if (panoFrom.sceneKey.endsWith('10')) console.log(panoFrom.sceneKey, '->', nextPano.sceneKey, { panoFromPortalIds });

  panoFromPortalIds.forEach((portalFromId) => {
    const portalFrom = dataJson.portals[portalFromId];
    // const portalTo = dataJson.portals[portalFrom.matched_id];

    if (!portalFrom.matched) return;
    if (panoToPortalIds.includes(portalFrom.matched_id)) {
      portalIdsToNext.push(portalFromId);
    }
  });
  return portalIdsToNext;
};

/**
 * Normalize angle to [0, 2π]
 *
 * @param angle
 */
export const normalizeRadian = (angle: number) => ((angle % (2 * Math.PI)) + 2 * Math.PI) % (2 * Math.PI);

/**
 * Will get the angle to look at when going restarting path
 *
 * @param path
 * @param index
 * @param panos
 */
export const tryGetPathEndDirection = (path: string[], index: number, panos: Panos): ScenePos | undefined => {
  if (index === path.length - 1) {
    const pano = panos[path[index]];
    const view = pano.view || [0, 0]; // sometimes(?) only the starting pano has view data
    const yaw = toRad(-pano.camera[3] + view[1]);
    const pitch = toRad(view[0]);
    return { yaw, pitch };
  }
  return undefined;
};

/**
 * Center of the portal in absolute (pano) coordinates
 * @todo -- maybe -- calculate this for every portal and store it in dataJson, since it is calculated multiple times for the same portal
 *
 * @param portal
 * @param dataJson
 */
const getPortalMidPointAbsolute = (portal: DataJsonPortalType, dataJson: DataJsonType): Point3D => {
  const portalMidPointBot = getMidpoint(portal.bot_left_3d_m, portal.bot_right_3d_m);
  const portalMidPointTop = getMidpoint(portal.top_left_3d_m, portal.top_right_3d_m);
  const portalMidMid = getMidpoint(portalMidPointBot, portalMidPointTop);
  const cameraPosToLocal = [portalMidMid[0], portalMidMid[1], portalMidMid[2]] as Point3D;
  const { roomRotation, roomOffset } = tryGetRoomInfo(dataJson, portal.room_id);
  const pos = offsetPoint(rotatePoint(roomRotation, cameraPosToLocal), roomOffset) as Point3D;
  pos[2] = cameraPosToLocal[2]; // don't localize z value
  return [pos[0] * 100, pos[1] * 100, pos[2] * 100] as Point3D; // convert to cm, pano scale
};

/**
 * Will get the angle to look at when going to the next pano
 * But only for:
 *              stairs
 *              outdoor -> outdoor
 *              entrance doors
 *
 * @param panos
 * @param panoFrom
 * @param panoNext
 */
const tryGetCameraPosFromHotSpot = (panos: Panos, panoFrom: Pano, panoNext: Pano): Point3D | undefined => {
  const fromHotSpots = panos[panoFrom.sceneKey]?.hotSpots;
  if (!fromHotSpots) return undefined;

  const hotSpotToNextPano = fromHotSpots.find((hotSpot) => hotSpot.target === panoNext.sceneKey);
  if (!hotSpotToNextPano) return undefined;

  if (hotSpotToNextPano.type.includes('stairs')) {
    const hotSpot3DConfig = hotSpotToNextPano as HotSpot3DConfig;
    return hotSpot3DConfig.pos as Point3D;
  }

  if (panoFrom.outside && panoNext.outside) {
    const hotSpot3DConfig = hotSpotToNextPano as HotSpot3DConfig;
    return hotSpot3DConfig.pos as Point3D;
  }

  return undefined;
};

const tryGetCameraPosFromPortals = (dataJson: DataJsonType, panoFrom: Pano, panoNext: Pano, basic = false) => {
  let portalIdsToNext: string[] = [];

  if (!basic) {
    portalIdsToNext = findPortalToNextPano(panoFrom, panoNext, dataJson);
  }

  if (portalIdsToNext.length === 0) {
    return [panoNext.camera[0], panoNext.camera[1], panoNext.camera[2]] as Point3D;
  }

  // sort portals by distance to camera
  portalIdsToNext.sort((a, b) => {
    const portalA = dataJson.portals[a];
    const portalB = dataJson.portals[b];

    const absPosA = getPortalMidPointAbsolute(portalA, dataJson);
    const absPosB = getPortalMidPointAbsolute(portalB, dataJson);

    const distA = Math.sqrt((absPosA[0] - panoFrom.camera[0]) ** 2 + (absPosA[1] - panoFrom.camera[1]) ** 2);
    const distB = Math.sqrt((absPosB[0] - panoFrom.camera[0]) ** 2 + (absPosB[1] - panoFrom.camera[1]) ** 2);
    return distA - distB;
  });

  const bestPortalId = portalIdsToNext[0]; // best
  const bestPortal = dataJson.portals[bestPortalId];
  bestPortal.debug = '+';
  return getPortalMidPointAbsolute(bestPortal, dataJson);
};

/**
 * Where to look when exiting a pano to the next one
 *
 * @param path
 * @param index
 * @param panos
 * @param dataJson
 * @param projectStartAngle
 * @param basic -- don't turn to the best portal(doors) just straight to pano;  only for debug, remove when no debug is needed
 */
export const getPanoExitAngle = (
  path: string[],
  index: number,
  panos: Panos,
  dataJson: DataJsonType,
  projectStartAngle: number,
  basic = false
): ScenePos => {
  const panoFrom = panos[path[index]];
  const panoNext = panos[path[(index + 1) % path.length]];
  const cameraPosFrom = [panoFrom.camera[0], panoFrom.camera[1], panoFrom.camera[2]] as Point3D;
  let cameraPosTo: Point3D;

  if (basic) {
    cameraPosTo = tryGetCameraPosFromPortals(dataJson, panoFrom, panoNext, true);
  } else {
    if (index === path.length - 1) {
      return { yaw: projectStartAngle, pitch: 0 };
    }
    cameraPosTo =
      tryGetCameraPosFromHotSpot(panos, panoFrom, panoNext) || tryGetCameraPosFromPortals(dataJson, panoFrom, panoNext);
  }

  return getPitchAndYawToLookAtP2FromP1(cameraPosFrom, cameraPosTo);
};

export const angleDifference = (angle1: number, angle2: number): number => {
  const normalizeAngle = (angle: number): number => (angle + Math.PI * 2) % (Math.PI * 2);
  const diff = normalizeAngle(angle1 - angle2);
  return Math.min(diff, Math.PI * 2 - diff);
};

export const sortPointsOfInterestByAngle = (objects_: PointOfInterest[], referenceAngle: number) => {
  objects_.sort((a, b) => {
    const diffA = angleDifference(a.scenePos.yaw, referenceAngle);
    const diffB = angleDifference(b.scenePos.yaw, referenceAngle);
    return diffA - diffB;
  });
};

/**
 * Determine the longer turn direction between two angles.
 * -1 or 1
 * ccw or cw
 *
 * @param angleA
 * @param angleB
 */
export const longerTurnDirection = (angleA: number, angleB: number): number => {
  let diff = angleB - angleA;

  // Normalize the difference to the range [-π, π].
  while (diff < -Math.PI) diff += 2 * Math.PI;
  while (diff > Math.PI) diff -= 2 * Math.PI;

  // Determine the longer turn direction based on the normalized difference.
  return diff > 0 ? -1 : 1;
};

export const shorterTurnDirection = (angleA: number, angleB: number): number => {
  let diff = angleB - angleA;

  // Normalize the difference to the range [-π, π].
  while (diff < -Math.PI) diff += 2 * Math.PI;
  while (diff > Math.PI) diff -= 2 * Math.PI;

  // Determine the longer turn direction based on the normalized difference.
  return diff < 0 ? -1 : 1;
};

// in radians
// look at spot 80° to the side of largest arc
export const getLookAtAngle = (angleA: number, angleB: number, diffDegrees = 80): number => {
  const direction = longerTurnDirection(angleA, angleB);
  return direction * toRad(diffDegrees) + angleA;
};

// in radians
export const getOtherLookAtAngle = (angleA: number, angleB: number, diffDegrees = 80): number => {
  const direction = shorterTurnDirection(angleA, angleB);
  return direction * toRad(diffDegrees) + angleA;
};

/**
 * keeping the same distance (ange diff) put AngleB either on longer turning side or on the shorter
 * using linear not angular logic like engine animation does
 */
export const directionalizeAngle = (angleA: number, angleB: number, goLong: boolean): number => {
  let linearDistance = angleB - angleA;

  // If goLong is true, adjust the angle to take the longer path
  if (goLong) {
    if (linearDistance > 0) {
      // If endAngle is ahead of startAngle, go the other way around
      linearDistance -= 2 * Math.PI;
    } else {
      // If endAngle is behind startAngle, go the other way around
      linearDistance += 2 * Math.PI;
    }
  } else {
    // If goLong is false, take the shortest path
    // Normalize to [-π, π)
    if (linearDistance > Math.PI) {
      linearDistance -= 2 * Math.PI;
    } else if (linearDistance < -Math.PI) {
      linearDistance += 2 * Math.PI;
    }
  }

  return angleA + linearDistance;
};
