import { jsx as _jsx } from "react/jsx-runtime";
import { Utils } from '@g360/vt-engine';
import { Transition } from '@headlessui/react';
import { useEffect, useReducer, useRef, useState } from 'react';
import useGlobalContext from '../../../contexts/useGlobalContext';
import useServices from '../../../contexts/useServices';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useReactive from '../../../hooks/useReactive';
import useResizeObserver from '../../../hooks/useResizeObserver';
import HotSpotBalloon from './HotSpotBalloon';
import HotSpotBalloonContent from './HotSpotBalloonContent';
const HotSpot = ({ onExpandImage, onSelectHotSpot, onHotSpotEditClick, lockAutoClose, }) => {
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const { assetConfig, engine, hostRootRef, appContext, analytics } = useGlobalContext();
    const { tourConfigService } = useServices();
    const { features } = useReactive(tourConfigService, ['features']);
    const [hotSpotInfo, setHotSpotInfo] = useState();
    const [visible, setVisible] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [canvasRect, setCanvasRect] = useState(engine.getCanvasBoundingClientRect());
    const visibleRef = useRef(visible);
    const timeoutRef = useRef(null);
    const closeHotSpotBalloon = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        visibleRef.current = false;
        setVisible(false);
        engine.emitInfoHotSpotRelease();
    };
    const openHotSpotBalloon = (info, isAnimating) => {
        setTimeout(() => {
            var _a;
            analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'IHS', 'Open', {
                scene_id: engine.getActiveSceneKey(),
                info_hotspot_id: `${(_a = info.config) === null || _a === void 0 ? void 0 : _a.id}`,
            });
            visibleRef.current = true;
            setCanvasRect(engine.getCanvasBoundingClientRect());
            // Delay the balloon opening to allow the guided viewing to animate away from edges to avoid reposition flickers
            // But only if the camera is animating - engine.isGVAnimatingCamera handles the local event, and isAnimating the remote event
            // This is hacky, but to improve this we would need to refactor the event system for GV and hot-spots
            if (appContext === 'guided-viewing' && (engine.isGVAnimatingCamera || isAnimating)) {
                timeoutRef.current = setTimeout(() => {
                    setVisible(true);
                }, 1000);
            }
            else {
                setVisible(true);
            }
            setHotSpotInfo(info);
            if (onSelectHotSpot) {
                onSelectHotSpot(info.config);
            }
        }, 0);
    };
    const mainRef = useRef(null);
    useOnClickOutside(hostRootRef, mainRef, () => {
        closeHotSpotBalloon();
    });
    useEffect(() => {
        const subClick = engine.subscribe('hotspots.info.click', (info) => {
            openHotSpotBalloon(info);
        });
        const subRemote = engine.subscribe('hotspots.info.remoteUpdate', (evt) => {
            if ((evt === null || evt === void 0 ? void 0 : evt.sceneKey) && (evt === null || evt === void 0 ? void 0 : evt.clipSpacePos) && (evt === null || evt === void 0 ? void 0 : evt.config)) {
                openHotSpotBalloon({
                    ops: evt.ops,
                    clipSpacePos: evt.clipSpacePos,
                    config: evt.config,
                    sceneKey: evt.sceneKey,
                }, evt.isAnimating);
            }
            else {
                closeHotSpotBalloon();
            }
        });
        const subMoveStart = engine.subscribe('scene.move.start', () => {
            closeHotSpotBalloon();
        });
        const subMove = engine.subscribe('scene.move.update', () => {
            if (visibleRef.current) {
                forceUpdate();
            }
        });
        const subPingMove = engine.subscribe('scene.ping.update', () => {
            if (visibleRef.current) {
                forceUpdate();
            }
        });
        const subAnimMove = engine.subscribe('scene.anim.update', () => {
            if (visibleRef.current) {
                forceUpdate();
            }
        });
        const subDelete = engine.subscribe('hotspots.info.delete', () => {
            closeHotSpotBalloon();
            setHotSpotInfo(null);
            if (onSelectHotSpot) {
                onSelectHotSpot(null);
            }
        });
        return () => {
            subClick.unsubscribe();
            subMoveStart.unsubscribe();
            subMove.unsubscribe();
            subPingMove.unsubscribe();
            subAnimMove.unsubscribe();
            subDelete.unsubscribe();
            subRemote.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        let timeout;
        if (visible) {
            timeout = setTimeout(() => {
                setMounted(true);
            }, 250);
        }
        else {
            setMounted(false);
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [visible]);
    useResizeObserver(() => {
        setCanvasRect(engine.getCanvasBoundingClientRect());
    }, hostRootRef);
    // Need to use the same math as is used in the vt-editor to get the screen position of the hotspot
    const getScreenPos = (hotSpot) => {
        if (!hotSpot)
            return null;
        const [pitch, yaw] = hotSpot.pos;
        const { cameraPointToPixel } = Utils.spaceMapper(engine);
        const pos = cameraPointToPixel({ pitch: Utils.toRad(pitch), yaw: Utils.toRad(yaw) });
        if (!pos)
            return null;
        return {
            y: pos.y,
            x: pos.x,
        };
    };
    const screenSpacePos = getScreenPos(hotSpotInfo === null || hotSpotInfo === void 0 ? void 0 : hotSpotInfo.config);
    return (_jsx(Transition, Object.assign({ show: visible, className: "", enter: "transition duration-400 ease-out", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition duration-400 ease-out", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsx(HotSpotBalloon, Object.assign({ screenSpacePos: screenSpacePos !== null && screenSpacePos !== void 0 ? screenSpacePos : undefined, canvasRect: canvasRect, onClose: () => {
                closeHotSpotBalloon();
                if (onSelectHotSpot) {
                    onSelectHotSpot(null);
                }
            }, lockAutoClose: lockAutoClose || !mounted, rootRef: hostRootRef, onClick: () => {
                if (onHotSpotEditClick && hotSpotInfo) {
                    onHotSpotEditClick(hotSpotInfo);
                }
            }, className: onHotSpotEditClick && 'cursor-text' }, { children: _jsx(HotSpotBalloonContent, { hotSpotInfo: hotSpotInfo, signatureData: assetConfig.signatureData, canvasRect: canvasRect, onExpandImage: onExpandImage, areHotSpotLinksClickable: features.hotspotClickableLinks }) })) })));
};
export default HotSpot;
