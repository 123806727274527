import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useGlobalContext from '../../../contexts/useGlobalContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import InfoIcon from '../icons/InfoIcon';
const InfoButton = () => {
    const { analytics } = useGlobalContext();
    const { layoutService } = useServices();
    const { showInfoModalIndicator, showInfoModal } = useReactive(layoutService, [
        'showInfoModalIndicator',
        'showInfoModal',
    ]);
    return (_jsxs("button", Object.assign({ type: "button", className: "btn-theme-primary relative ml-3 h-10 w-10 p-0 smvh:ml-4", onClick: () => {
            layoutService.showInfoModal = !showInfoModal;
            analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'INFO', layoutService.showInfoModal ? 'Open' : 'Close');
            layoutService.dismissNotification();
        }, "data-testid": "info-btn" }, { children: [_jsx(InfoIcon, { className: "h-8 w-8" }), showInfoModalIndicator && (_jsxs("div", Object.assign({ className: "absolute -right-1 -top-1 flex items-center justify-center" }, { children: [_jsx("div", { className: "absolute h-3 w-3 rounded-full bg-theme-accent" }), _jsx("div", { className: "h-3 w-3 animate-notification-ping rounded-full border border-theme-accent bg-theme-accent" })] })))] })));
};
export default InfoButton;
