/* eslint-disable no-restricted-syntax,no-continue */
import type { Centimeter, TourConfig } from '@g360/vt-types';

import type { FPMesh } from './types';
import { calculateCenter2d, getOutlineFrom3DMesh, isPointInsidePolygon } from './utils/geometry';

type RoomInfo = {
  center: number[];
  numberOfPanos: number;
  wallAngles: Record<number, number>; // in which direction the wall is facing inside the room for each wall | [wallId] => angle
};

/**
 * Looks after navigation in single model, rooms,walls, centering on rooms, etc.
 * Lowering walls and centering on rooms
 * @todo -- maybe improve 3D to 2D conversion for floors?
 * @todo -- def improve the wall lowering algorithm (raycast from wall fragment to floor to check if it's covering the floor
 */
class ModelNavigation {
  private tourConfig: TourConfig;
  private pano2Room: Record<string, string> = {}; // pano ID => room name
  private roomInfo: Record<string, RoomInfo> = {}; // room name => room info
  private modelCenter: number[] = [0, 0, 0];
  /** if null - then centering on model center, if string - then on that room */
  private centeringOnRoom: string | null = null;
  private orbitCenter: number[] = [0, 0, 0];
  private meshes: FPMesh[] | null = null;

  private lastLowWallsCalculationParams: {
    yaw: number;
    topDownView: boolean;
  } | null = null;

  constructor(tourConfig: TourConfig) {
    this.tourConfig = tourConfig;
    this.calculatePano2Room();
  }

  init(meshes: FPMesh[]) {
    this.meshes = meshes;

    this.calculateRoomsForMeshes();
    this.calculate2DDataForMeshes();
    this.calculateTransparency();
    this.calculateHiddenMeshes();
  }

  getCenteringOnRoom(): string | null {
    return this.centeringOnRoom;
  }

  /**
   *
   * @param mesh
   * @param explicitPosition -- if omitted, the room center is used
   */
  centerOnRoomByMesh(mesh: FPMesh | null, explicitPosition: number[] | undefined = undefined) {
    const newRoom = mesh?.roomIds[0] ?? null;
    const isSameRoom = newRoom === this.centeringOnRoom;

    this.centeringOnRoom = isSameRoom ? null : newRoom;
    this.orbitCenter = this.centeringOnRoom ? this.roomInfo[this.centeringOnRoom]?.center : this.modelCenter;

    if (explicitPosition) {
      this.orbitCenter = explicitPosition;
    }

    this.lastLowWallsCalculationParams = null; // force recalculation of low walls
    this.calculateTransparency();
    this.calculateHiddenMeshes();
  }

  /**
   * @todo -- rem later
   */
  debugNextCentering() {
    const roomNames = Object.keys(this.roomInfo);
    if (roomNames.length === 0) {
      console.log('Navigation, no rooms to center on');
      this.orbitCenter = this.modelCenter;
      return;
    }

    if (this.centeringOnRoom === null) {
      this.centeringOnRoom = roomNames[0];
      console.log('Navigation, now centering on room', this.centeringOnRoom);
      this.orbitCenter = this.roomInfo[this.centeringOnRoom].center;
    } else {
      const roomIndex = roomNames.indexOf(this.centeringOnRoom);
      const nextRoomIndex = roomIndex + 1;
      if (nextRoomIndex >= roomNames.length) {
        this.centeringOnRoom = null;
        this.orbitCenter = this.modelCenter;
        console.log('Navigation, now centering on model center; orbit center=', this.orbitCenter);
      } else {
        this.centeringOnRoom = roomNames[nextRoomIndex];
        this.orbitCenter = this.roomInfo[this.centeringOnRoom].center;
        console.log('Navigation, now centering on room', this.centeringOnRoom, 'orbit center=', this.orbitCenter);
      }
    }

    this.lastLowWallsCalculationParams = null; // force recalculation of low walls
    this.calculateTransparency();
    this.calculateHiddenMeshes();
  }

  getCurrentOrbitCenter() {
    return this.orbitCenter;
  }

  setModelCenter(modelCenter: number[]) {
    this.modelCenter = modelCenter;
    this.orbitCenter = this.modelCenter;
    this.centeringOnRoom = null;
  }

  /**
   * Calculate which wall meshes should be drawn as low walls
   * by checking if point offset from wall fragment center is inside the room shape
   *
   * Calculates what meshes can be skipped by some programs for current centered room
   * @todo -- extract out 2 separate logics after the cache check
   */
  calculateCutaways(yaw: number, topDownView: boolean) {
    if (!this.meshes) return;

    const centeringOnRoom = this.centeringOnRoom;
    if (centeringOnRoom === null) return;

    const actualYaw = -yaw - Math.PI / 2;
    const yawRoundAmount = 0.2; // no need to calc for every tiny bit of yaw change
    const rayDistance: Centimeter = 100;

    let roundedYaw = Math.round(actualYaw / yawRoundAmount) * yawRoundAmount;
    if (topDownView) {
      roundedYaw = 999; // don't recalculate low walls in top-down view based on angle (only if the mode changes)
    }

    if (!this.shouldRecalculateLowWalls(roundedYaw, topDownView)) return;

    // top-down view, where all walls are low walls
    if (topDownView) {
      for (const mesh of this.meshes) {
        mesh.debugLine = undefined;
        mesh.skipSolidRendering = false;
        mesh.skipFlatRendering = false;

        if (!mesh.isWall) continue;

        // Don't draw top walls
        if (mesh.isTopWall) {
          mesh.skipSolidRendering = true;
        }

        // Don't draw wall caps on top walls
        if (mesh.isWallCap && mesh.isTopWall) {
          mesh.skipSolidRendering = true;
          mesh.skipFlatRendering = true;
        }
      }
      return;
    }

    // regular, camera facing cutaway
    const floorMesh = this.meshes.find((mesh) => mesh.isFloor && mesh.roomIds.includes(centeringOnRoom));
    if (!floorMesh) return;

    for (const mesh of this.meshes) {
      mesh.debugLine = undefined;
      mesh.skipSolidRendering = false;
      mesh.skipFlatRendering = false;

      if (!mesh.isWall || !mesh.roomIds.includes(centeringOnRoom)) continue;
      const wallCenter = mesh.center2d;

      const rayEnd: [number, number] = [
        wallCenter[0] + Math.cos(roundedYaw) * rayDistance,
        wallCenter[1] + Math.sin(roundedYaw) * rayDistance,
      ];

      // top & bottom walls have exact 2D footprint, so this "raycasting" is called twice on same data
      // @todo -- maybe cache or smtn ?
      const towardsCamera = isPointInsidePolygon(rayEnd, floorMesh.shape2d);

      // Don't draw top walls (if lowered)
      if (towardsCamera && mesh.isTopWall) {
        mesh.debugLine = [wallCenter[0], wallCenter[1], rayEnd[0], rayEnd[1]];
        mesh.skipSolidRendering = true;
      }

      // Don't draw wall caps on top walls (if lowered)
      if (towardsCamera && mesh.isWallCap && mesh.isTopWall) {
        mesh.skipSolidRendering = true;
        mesh.skipFlatRendering = true;
      }

      // Don't draw wall caps on bottom wall in flat renderer (if not lowered)
      if (!towardsCamera && mesh.isWallCap && mesh.isBottomWall) {
        // mesh.skipSolidRendering = false;
        mesh.skipFlatRendering = true;
      }

      // Don't draw wall caps on bottom walls (if lowered) ..
      if (towardsCamera && mesh.isWallCap && mesh.isBottomWall) {
        mesh.skipSolidRendering = true; // ..textured shader only, still draw in flat shader)
      }
    }
  }

  /**
   * What meshes should be transparent based on focused room (if there is one)
   */
  calculateTransparency() {
    if (!this.meshes) return;

    const centeringOnRoom = this.centeringOnRoom;

    for (const mesh of this.meshes) {
      if (!centeringOnRoom) {
        // not centering room, nothing is transparent
        mesh.unfocusedRoom = false;
      } else {
        // centering - meshes from non-centered rooms are transparent
        mesh.unfocusedRoom = !mesh.roomIds.includes(centeringOnRoom);
      }
    }
  }

  /**
   * Fix roomIds for each mesh based on panoIds
   * Room ids we get from tour.json and panoIds for meshes come from model
   *
   * @todo -- some panos are not found in tour.json
   *          (merged rooms?)
   *          a) ask for more data in model
   *          b) ignore them, since the mesh has the other pano id from merged room that is in our data
   */
  private calculateRoomsForMeshes() {
    const meshes = this.meshes;
    if (!meshes) throw new Error('Navigation::calculateRoomsForMeshes: no meshes');

    const roomNames = Object.keys(this.roomInfo);
    for (const mesh of meshes) {
      const panoIds = mesh.panoIds;
      const roomIds: string[] = [];
      for (const panoId of panoIds) {
        const roomName = this.pano2Room[panoId];
        const roomIndex = roomNames.indexOf(roomName);
        if (roomIndex === -1) {
          // console.warn('Navigation::fixRoomsForMeshes: room not found in roomInfo', {
          //   panoId,
          //   roomName,
          //   roomInfo: this.roomInfo,
          //   pano2Room:this.pano2Room,
          //   roomNames,
          // });
          continue;
        }
        if (!roomIds.includes(roomName)) {
          roomIds.push(roomName);
        }
      }
      mesh.roomIds = roomIds;
    }

    console.log('rooms fixed for meshes:', { meshes });
    return meshes;
  }

  private calculate2DDataForMeshes() {
    const meshes = this.meshes;
    if (!meshes) throw new Error('Navigation::calculate2DDataForMeshes: no meshes');

    for (let i = 0; i < meshes.length; i += 1) {
      const mesh = meshes[i];

      // calculate 2D shapes only for walls and floors
      if (mesh.isWall || mesh.isFloor) {
        const wallVertices = mesh.positions;
        mesh.shape2d = getOutlineFrom3DMesh(wallVertices);
        mesh.center2d = calculateCenter2d(mesh.shape2d);
        // console.log("calculateShape2DData::", mesh.positions,'=>',mesh.shape2d,mesh.center2d);
      }
    }
  }

  /**
   * Finds the room name for each panoId
   * There might be problems in HUGE properties with lots of rooms
   * @todo -- maybe we can use floor geometry as base for rooms ?
   *          not room names from tour.json  (since they might be same names for different rooms)
   *          and attach panos to room by position?
   */
  private calculatePano2Room() {
    const panoIds = Object.keys(this.tourConfig.scenes);
    for (const panoId of panoIds) {
      const scene = this.tourConfig.scenes[panoId];
      if (!scene) throw new Error('Navigation::calculatePano2Room: scene not found in tourConfig');

      const roomName = `${scene.name}-${scene.building}/${scene.floor}-${scene.area}`; // hopefully unq name, since there might be many duplicate "WC" rooms if identified by name only
      this.pano2Room[panoId] = roomName;

      const roomInfo = this.roomInfo[roomName] ?? {
        center: [0, 0, 0],
        numberOfPanos: 0,
        wallNormals: {},
        wallAngles: {},
      };
      roomInfo.numberOfPanos += 1;
      // sum up all pano positions to later find the center of the room
      roomInfo.center[0] += scene.camera[0]; // tour config uses z,y for a plane and z for height
      roomInfo.center[1] += scene.camera[2];
      roomInfo.center[2] += scene.camera[1]; // we are using x,z for a plane and y for height
      this.roomInfo[roomName] = roomInfo;
    }

    // @todo -- maybe use data json to get room positions ?
    //          not directly, to avoid unnecessary download,
    //          but this info could be embedded in .glb file

    // find room center by averaging all panos in the room
    const rooms = Object.keys(this.roomInfo);
    for (const roomName of rooms) {
      const roomInfo = this.roomInfo[roomName];
      roomInfo.center[0] /= roomInfo.numberOfPanos;
      roomInfo.center[1] /= roomInfo.numberOfPanos;
      roomInfo.center[2] /= roomInfo.numberOfPanos;
      this.roomInfo[roomName] = roomInfo;
    }

    // console.log('this.pano2Room', this.pano2Room);
  }

  /**
   * Calculates what meshes can be skipped by some programs for uncentered rooms
   * This resets everything, should be called when room centering changes
   */
  private calculateHiddenMeshes() {
    if (!this.meshes) return;

    const centeringOnRoom = this.centeringOnRoom;
    if (centeringOnRoom !== null) return; // don't do if centering on room

    for (const mesh of this.meshes) {
      mesh.skipSolidRendering = false;
      mesh.skipFlatRendering = false;

      // Don't draw wall caps on top walls
      if (mesh.isWallCap && mesh.isTopWall) {
        mesh.skipSolidRendering = true; // .. with solid renderer (flat renderer will render this mesh)
      }

      // Don't draw any wall caps on bottom wall (they are hidden inside the wall)
      if (mesh.isWallCap && mesh.isBottomWall) {
        // @todo -- we need to draw bottom wall caps if they are part of a window - visible when not lowered,
        //         but we need to skip bottom caps that are inside normal walls - those are completely invisible
        //         dunno we can distinquish them now
        // mesh.skipSolidRendering = true;
        mesh.skipFlatRendering = true;
      }
    }
  }

  private shouldRecalculateLowWalls(roundedYaw: number, topDownView: boolean): boolean {
    if (this.lastLowWallsCalculationParams === null) {
      this.lastLowWallsCalculationParams = { yaw: roundedYaw, topDownView };
      return true;
    }

    const yawChanged = this.lastLowWallsCalculationParams.yaw !== roundedYaw;
    const viewChanged = this.lastLowWallsCalculationParams.topDownView !== topDownView;
    if (yawChanged || viewChanged) {
      this.lastLowWallsCalculationParams = { yaw: roundedYaw, topDownView };
      return true;
    }

    return false;
  }
}

export default ModelNavigation;
