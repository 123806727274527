const config = {
  baseUrl:
    (process.env.NODE_ENV === "production" &&
      process.env.REACT_APP_BACKEND_ENDPOINT) ||
    "",
  dashboardUrl: process.env.REACT_APP_DASHBOARD_URL || "",
  internetSpeedImage: {
    url: "https://static.giraffe360.com/apps/guided-viewing/bandwidth-test/image.jpeg",
    size: 55366, // bytes.
  },
  baseName: process.env.REACT_APP_BASE_NAME || "",
  mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN || "",
};

export default config;
