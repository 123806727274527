import { makeTilesPath } from '../../common/Utils';
import type SphereProgram from './index';
import SphereFace from './SphereFace';

class Sphere {
  public readonly panoKey: string;
  public readonly sphereFaces: SphereFace[] = []; // 6 faces
  public readonly sphereProgram: SphereProgram;
  public readonly outside: boolean;

  public localCubemapsGenerated = false; // relevant only in equirect mode
  public positionOffsetForTransition = [0, 0, 0];

  constructor(
    sphereProgram: SphereProgram,
    panoKey: string,
    yawOffset: number,
    position: number[],
    outside: boolean,
    usingLocalCubemaps: boolean
  ) {
    this.sphereProgram = sphereProgram;
    this.panoKey = panoKey;
    this.outside = outside;
    this.createFaces(panoKey, yawOffset, position, usingLocalCubemaps);
  }

  public get allDepthmapsLoaded(): boolean {
    return this.sphereFaces.every((e) => e.depthmapsLoaded);
  }

  createFaces(panoKey: string, yawOffset: number, position: number[], usingLocalCubemaps: boolean): void {
    if (this.sphereProgram.tourConfig) {
      for (let i = 0; i < 6; i += 1) {
        const faceLetter = SphereFace.letters[i];
        const tilesPath = makeTilesPath(this.sphereProgram.tourConfig, this.sphereProgram.assetConfig.basePath, this.panoKey); // prettier-ignore
        const panoFullPath = `${tilesPath}/${faceLetter}/l1/1/l1_${faceLetter}_1_1.jpg`;

        this.sphereFaces.push(
          new SphereFace(
            i,
            this,
            panoKey,
            yawOffset,
            position,
            panoFullPath,
            usingLocalCubemaps,
            this.sphereProgram.assetConfig
          )
        );
      }
    }
  }

  destroy(mild = false): void {
    this.sphereFaces.forEach((e) => {
      e.destroy(mild);
    });
  }

  draw(): void {
    for (let i = 0; i < 6; i += 1) {
      this.sphereFaces[i].draw();
    }
  }
}

export default Sphere;
