import { isParticipantsInArray, isStringArrayEqual } from "func";
import { useAppStateContext, useHandshake, useRoomContext } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { RemoteParticipant } from "twilio-video";
import isInStringArray from "utils/isInStringArray";
import { participantIdentityToName } from "utils";
import config from "config";

const useParticipants = () => {
  const { isHost, roomName, handleEndRoom } = useAppStateContext();
  const { room, localParticipant, twilioMessageEmitter } = useRoomContext();

  const allRemoteParticipants = useRef<RemoteParticipant[]>([]);

  const [handshakeUserIdentities, setHandshakeUserIdentities] = useState<
    string[]
  >([]);
  const [needApprovalUserIdentities, setNeedApprovalUserIdentities] = useState<
    string[]
  >([]);
  const [approvedUserIdentities, setApprovedUserIdentities] = useState<
    string[]
  >([]);

  const [allowedRemoteParticipants, setAllowedRemoteParticipants] = useState<
    RemoteParticipant[]
  >([]);

  const [roomHostIdentity, setRoomHostIdentity] = useState<string>("");
  const [isAllowedInRoom, setIsAllowedInRoom] = useState<boolean>(false);

  // If host white list change, send new white list to all participants
  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (isHost) {
      interval = setInterval(() => {
        twilioMessageEmitter.emit("host.info.send", {
          roomHostIdentity,
          approvedUserIdentities,
        });
      }, 750);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isHost, twilioMessageEmitter, approvedUserIdentities, roomHostIdentity]);

  const participantConnected = useCallback((participant: RemoteParticipant) => {
    allRemoteParticipants.current = [
      ...allRemoteParticipants.current,
      participant,
    ];

    if (!isHost) return;
    setHandshakeUserIdentities((state) => {
      if (!state.includes(participant.identity)) {
        return [...state, participant.identity];
      }
      return state;
    });
  }, []);

  const participantDisconnected = useCallback(
    (participant: RemoteParticipant) => {
      if (participant.identity === roomHostIdentity) {
        setIsAllowedInRoom(false);
        setHandshakeUserIdentities([]);
        setNeedApprovalUserIdentities([]);
        setApprovedUserIdentities([]);
        setAllowedRemoteParticipants([]);
      }

      setHandshakeUserIdentities((state) =>
        state.filter((id) => id !== participant.identity)
      );

      setNeedApprovalUserIdentities((state) =>
        state.filter((id) => id !== participant.identity)
      );

      setApprovedUserIdentities((state) =>
        state.filter((id) => id !== participant.identity)
      );

      setAllowedRemoteParticipants((prevParticipants) =>
        prevParticipants.filter((p) => p !== participant)
      );
    },
    [roomHostIdentity]
  );

  const handleUserResponding = useCallback(
    (identity: string) => {
      setHandshakeUserIdentities((prev) =>
        prev.filter((id) => id !== identity)
      );
      setNeedApprovalUserIdentities((prev) => {
        return [...prev, identity];
      });
    },
    [setHandshakeUserIdentities, setNeedApprovalUserIdentities]
  );

  const handleUserNotResponding = useCallback((identity: string) => {
    twilioMessageEmitter.emit("room.close.send", identity);

    const participant = allRemoteParticipants.current.find(
      (p) => p.identity === identity
    );
    if (!participant) return;

    participantDisconnected(participant);
  }, []);

  const { performHandshake } = useHandshake(
    handleUserResponding,
    handleUserNotResponding
  );

  const approveParticipant = useCallback(
    (identity: string) => {
      setNeedApprovalUserIdentities((prev) =>
        prev.filter((id) => id !== identity)
      );
      setApprovedUserIdentities((prev) => {
        if (!isInStringArray(roomHostIdentity, prev)) {
          return [...prev, identity, roomHostIdentity];
        }
        return [...prev, identity];
      });
    },
    [roomHostIdentity]
  );

  const declineParticipant = useCallback(
    (identity: string) => {
      twilioMessageEmitter.emit("participant.decline.send", identity);
    },
    [twilioMessageEmitter]
  );

  const handleEndMeeting = useCallback(
    async (isDeleteLink: boolean) => {
      if (!roomName) return;
      twilioMessageEmitter.emit("room.close.send", null);
      const redirectLink = await handleEndRoom(isDeleteLink);
      window.location.replace(`${config.baseName}${redirectLink}`);
    },
    [handleEndRoom, roomName, twilioMessageEmitter]
  );

  useEffect(() => {
    if (!localParticipant) return;

    if (approvedUserIdentities.includes(localParticipant.identity)) {
      setIsAllowedInRoom(true);
    } else {
      setIsAllowedInRoom(false);
    }

    for (let i = 0; i < allRemoteParticipants.current.length; i++) {
      const participant = allRemoteParticipants.current[i];
      if (!participant) return;

      const found = approvedUserIdentities.find(
        (id) => id === participant.identity
      );

      if (found) {
        setAllowedRemoteParticipants((prevParticipants) => {
          if (!isParticipantsInArray(participant, prevParticipants)) {
            return [...prevParticipants, participant];
          } else {
            const newParticipants = [...prevParticipants];
            const foundIndex = newParticipants.findIndex(
              (p) => p.identity === participant.identity
            );
            newParticipants[foundIndex] = participant;

            return newParticipants;
          }
        });

        allRemoteParticipants.current = allRemoteParticipants.current.filter(
          (p) => p.identity !== participant.identity
        );
      }
    }
  }, [localParticipant, approvedUserIdentities]);

  useEffect(() => {
    if (handshakeUserIdentities.length === 0) return;

    for (let i = 0; i < handshakeUserIdentities.length; i++) {
      const needsHandshakeIdentity = handshakeUserIdentities[i];
      if (!needsHandshakeIdentity) return;

      if (
        needApprovalUserIdentities.includes(needsHandshakeIdentity) ||
        approvedUserIdentities.includes(needsHandshakeIdentity)
      )
        continue;

      performHandshake(needsHandshakeIdentity);
    }
  }, [
    handshakeUserIdentities,
    needApprovalUserIdentities,
    approvedUserIdentities,
    performHandshake,
  ]);

  useEffect(() => {
    if (isHost && localParticipant) {
      setIsAllowedInRoom(true);
      setRoomHostIdentity(localParticipant.identity);
    }
  }, [isHost, localParticipant]);

  useEffect(() => {
    if (!room) return;

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);
  }, [participantConnected, participantDisconnected, room]);

  useEffect(() => {
    const subscriptions = [
      twilioMessageEmitter.subscribe("host.info.receive", (data) => {
        setRoomHostIdentity(data.roomHostIdentity);

        if (
          !isStringArrayEqual(
            data.approvedUserIdentities,
            approvedUserIdentities
          )
        ) {
          setApprovedUserIdentities(data.approvedUserIdentities);
        }
      }),

      twilioMessageEmitter.subscribe("room.close.receive", (identity) => {
        if (identity === null || identity === localParticipant?.identity) {
          window.location.replace(`${config.baseName}/leave`);
        }
      }),

      twilioMessageEmitter.subscribe(
        "participant.decline.receive",
        (identity) => {
          if (identity === localParticipant?.identity) {
            window.location.replace(
              `${
                config.baseName
              }/${roomName}/declined/?name=${participantIdentityToName(
                localParticipant?.identity
              )}`
            );
          }
        }
      ),
    ];

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, [
    room,
    twilioMessageEmitter,
    approvedUserIdentities,
    localParticipant,
    roomName,
  ]);

  return {
    needApprovalUserIdentities,
    approvedUserIdentities,
    isAllowedInRoom,
    roomHostIdentity,
    allowedRemoteParticipants,
    approveParticipant,
    declineParticipant,
    handleEndMeeting,
  };
};

export default useParticipants;
