import { jsx as _jsx } from "react/jsx-runtime";
/** Position element at a specific layout position like top/bottom-left/right/middle */
const LayoutSlot = ({ className, position, children }) => {
    let twPosition = '';
    switch (position) {
        case 'top-left':
            twPosition = 'top-0 left-0';
            break;
        case 'top-right':
            twPosition = 'top-0 right-0';
            break;
        case 'bottom-left':
            twPosition = 'bottom-0 left-0';
            break;
        case 'bottom-right':
            twPosition = 'bottom-0 right-0';
            break;
        case 'top-middle':
            twPosition = 'top-0 left-0 right-0 flex flex-col justify-center';
            break;
        case 'bottom-middle':
            twPosition = 'bottom-0 left-0 right-0 flex flex-col justify-center';
            break;
        default:
            break;
    }
    return _jsx("div", Object.assign({ className: `absolute ${twPosition} z-layout-slot select-none ${className || ''}` }, { children: children }));
};
export default LayoutSlot;
