import type { TourConfig } from '@g360/vt-types';

import type DebugControls from '../DebugControls';
import type Renderer from '../mixins/Renderer';
import type SphereProgram from '../programs/SphereProgram';

// @note -- not multiple-engine-instance safe, fix if there is a need for 2 engines in a single app being debugged
class RendererDebug {
  public static runDebugRender = {
    sphere: false,
    geometry: false,
    depth: false,
    blend: false,
  };

  static addDebugCallbacksToDebugControls(renderer: Renderer, debugControls: DebugControls) {
    if (__DEV_PANEL__) {
      /* eslint-disable no-param-reassign */
      /* eslint-disable @typescript-eslint/no-explicit-any */

      // (<any>renderer) <-- hack to ignore member accessibility and just use private members here

      debugControls.setToggleSphereCallback(() => {
        RendererDebug.runDebugRender.sphere = !RendererDebug.runDebugRender.sphere;
      });
      debugControls.setToggleDepthCallback(() => {
        RendererDebug.runDebugRender.depth = !RendererDebug.runDebugRender.depth;
      });
      debugControls.setToggleDoubleBlendCallback(() => {
        RendererDebug.runDebugRender.blend = !RendererDebug.runDebugRender.blend;
      });
      debugControls.setToggleDebugGeometryCallback(() => {
        RendererDebug.runDebugRender.geometry = !RendererDebug.runDebugRender.geometry;
      });
      debugControls.setClearStuffCallback(() => {
        const sphereProgram = renderer.getProgram<SphereProgram>('SphereProgram');
        sphereProgram?.removeAllSpheres();
      });
      debugControls.setResetCameraCallback(() => {
        (<any>renderer).actualCameraPos[0] = -(<any>renderer).activeSceneConfig.camera[0];
        (<any>renderer).actualCameraPos[1] = -(<any>renderer).activeSceneConfig.camera[1];
        (<any>renderer).actualCameraPos[2] = -(<any>renderer).activeSceneConfig.camera[2];
      });

      // debugControls.setNumericalCallback((n) => { });

      // debugControls.setNumericalWithShiftCallback((n) => {   });

      // debugControls.setNumericalWithControlCallback((n) => {    });

      // debugControls.setNumericalWithAltCallback((n) => { });

      // "=" key
      // debugControls.setGenericMinusCallback2(() => { });

      // "/" key
      // debugControls.setGenericPlusCallback2(() => { });
    }
  }

  static getDebugParam(name: string, defaultVal = 0): number {
    if (__DEV__) {
      const hash = window.location.hash.substr(1);
      const params = hash.split('&').reduce((res, item) => {
        const parts = item.split('=');
        // eslint-disable-next-line no-param-reassign
        res[parts[0]] = parts[1];
        return res;
      }, {});
      return params[name] ?? defaultVal;
    }
    return defaultVal;
  }

  static getDebugParamOrNull(name: string, defaultVal: number | null = 0): number | null {
    if (__DEV__) {
      const hash = window.location.hash.substr(1);
      const params = hash.split('&').reduce((res, item) => {
        const parts = item.split('=');
        // eslint-disable-next-line no-param-reassign
        res[parts[0]] = parts[1];
        return res;
      }, {});
      const v = parseFloat(params[name]) ?? defaultVal;
      return Number.isNaN(v) ? defaultVal : v;
    }
    return defaultVal;
  }

  static getDebugParamFloat(name: string, defaultVal = 0): number {
    // @     ts-expect-error global variable
    // if (__DEV__) {    <-- only for DEBUG, should not go into prod with this commented out
    const hash = window.location.hash.substr(1);
    const params = hash.split('&').reduce((res, item) => {
      const parts = item.split('=');
      // eslint-disable-next-line no-param-reassign
      res[parts[0]] = parts[1];
      return res;
    }, {});
    return params[name] ? parseFloat(params[name]) : defaultVal;
    // }
    // return defaultVal;
  }

  static getDebugStringParam(name: string, defaultVal = ''): string {
    // @     ts-expect-error global variable
    // if (__DEV__) {    <-- only for DEBUG, should not go into prod with this commented out
    const hash = window.location.hash.substr(1);
    const params = hash.split('&').reduce((res, item) => {
      const parts = item.split('=');
      // eslint-disable-next-line no-param-reassign
      res[parts[0]] = parts[1];
      return res;
    }, {});

    return params[name] ?? defaultVal;
    // }
    // return defaultVal;
  }

  static insertImageInHtml(img: HTMLImageElement, i: number) {
    if (__DEV__) {
      const row = 0;
      const domId = `debug-pic-${row}-${i}`;
      let prevParent: HTMLElement | null = null;
      const prevElement = document.getElementById(domId);
      if (prevElement) {
        prevParent = prevElement.parentElement;
        prevElement.remove();
      }
      const size = 100;
      img.id = domId;
      // img.style.visibility= 'hidden'

      if (prevParent) {
        prevParent.append(img);
      } else {
        img.style.position = 'absolute';
        img.style.top = `${row * size + 12}px`;
        img.style.left = `${size * i}px`;
        img.style.width = `${size}px`;
        img.style.height = `${size}px`;
        img.style.border = '1px dashed red';
        img.style.zIndex = '100';
        document.body.style.overflow = 'hidden';
        document.body.append(img);
      }
    }
  }

  static insertTextureInHtml(
    texture: WebGLTexture,
    gl: WebGLRenderingContext,
    width: number,
    heght: number,
    i: number
  ) {
    if (__DEV__) {
      const image = RendererDebug.debugCreateImageFromTexture(texture, width, heght, gl);
      if (image) {
        RendererDebug.insertImageInHtml(image, i);
      }
    }
  }

  // // hacky image download for debug
  // const MIME_TYPE = "image/jpg";
  // const dlLink = document.createElement('a');
  // dlLink.download = "panorama_image.jpg";
  // dlLink.href = maskImage.src;
  // dlLink.dataset.downloadurl = [MIME_TYPE, dlLink.download, dlLink.href].join(':');
  // document.body.appendChild(dlLink);
  // dlLink.click();
  // document.body.removeChild(dlLink);

  static debugCreateImageFromTexture(
    texture: WebGLTexture,
    width: number,
    height: number,
    gl: WebGLRenderingContext
  ): null | HTMLImageElement {
    if (__DEV__) {
      // Create a framebuffer backed by the texture
      const framebuffer = gl.createFramebuffer();
      gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
      gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);

      // Read the contents of the framebuffer
      const data = new Uint8Array(width * height * 4);
      gl.readPixels(0, 0, width, height, gl.RGBA, gl.UNSIGNED_BYTE, data);

      gl.deleteFramebuffer(framebuffer);

      // Create a 2D canvas to store the result
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d');
      if (!context) return null;

      // Copy the pixels to a 2D canvas
      const imageData = context.createImageData(width, height);
      imageData.data.set(data);
      context.putImageData(imageData, 0, 0);

      const img = new Image();
      img.src = canvas.toDataURL();
      return img;
    }
    return null;
  }

  static tryGetHotSpotDataFromUrlParam(): Promise<TourConfig> {
    return new Promise((resolve) => {
      const hotSpotDataEncoded = RendererDebug.getDebugStringParam('hotspotDataEncoded', '');
      let hotSpotDataJsonString = '';
      if (hotSpotDataEncoded) {
        hotSpotDataJsonString = decodeURI(hotSpotDataEncoded);
        hotSpotDataJsonString = hotSpotDataJsonString.split('%3A').join(':'); // replaceAll, but for ppl from 1992
        hotSpotDataJsonString = hotSpotDataJsonString.split('%2C').join(',');
        resolve(JSON.parse(hotSpotDataJsonString));
      }
    });
  }

  static tryGetHotSpotDataFromUrlParamSync(): TourConfig {
    const hotSpotDataEncoded = RendererDebug.getDebugStringParam('hotspotDataEncoded', '');
    let hotSpotDataJsonString = '';
    if (hotSpotDataEncoded) {
      hotSpotDataJsonString = decodeURI(hotSpotDataEncoded);
      hotSpotDataJsonString = hotSpotDataJsonString.split('%3A').join(':'); // replaceAll, but for ppl from 1992
      hotSpotDataJsonString = hotSpotDataJsonString.split('%2C').join(',');
      return JSON.parse(hotSpotDataJsonString);
    }
    return {} as TourConfig;
  }
}

export default RendererDebug;
