const cache = new Map();
const noResult = { size: null, center: null };
/**
 * Calculate floor center from floor geometry  - look it up by sceneKey.
 * Use triangles to calculate floor size. The floor size in config assumes all floors are equal size.
 */
const getFloorPropertiesFromGeometry = (structure, currentSceneKey) => {
    var _a;
    const cachedValue = cache.get(currentSceneKey);
    if (cachedValue)
        return cachedValue;
    const scene = structure.allScenes.find((sc) => sc.sceneKey === currentSceneKey);
    if (!scene)
        return noResult;
    const geometry = (_a = scene.geometry) === null || _a === void 0 ? void 0 : _a.flat(1);
    if (!geometry)
        return noResult;
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;
    for (let i = 0; i < geometry.length; i += 1) {
        const triangle = geometry[i];
        for (let j = 0; j < triangle.length; j += 3) {
            const x = triangle[j];
            const y = triangle[j + 1];
            minX = Math.min(minX, x);
            minY = Math.min(minY, y);
            maxX = Math.max(maxX, x);
            maxY = Math.max(maxY, y);
        }
    }
    const result = {
        size: { width: maxX - minX, height: maxY - minY },
        center: { x: (minX + maxX) / 2, y: (minY + maxY) / 2 },
    };
    cache.set(currentSceneKey, result);
    return result;
};
export default getFloorPropertiesFromGeometry;
