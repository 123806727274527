/* eslint-disable no-restricted-syntax,no-continue,no-await-in-loop */
import type { AssetConfig, EquirectAssetPath, SceneConfig } from '@g360/vt-types';
import { toRad } from '@g360/vt-utils';
import urljoin from 'url-join';

import { transitionSettings } from '../common/Globals';
import { objectToQueryString } from '../common/Utils';
import Cube2Equirect from '../common/Utils/Cube2Equirect';
import Tile from '../programs/CubeProgram/Tile';
import type DepthProgram from '../programs/DepthProgram';
import type EquirectProgram from '../programs/EquirectProgram';
import type SphereProgram from '../programs/SphereProgram';

/**
 * A collection of functions for making depthmaps from geometry
 * and visual cubemaps from single equirectangular image
 */
abstract class SnapshotMaker {
  protected panoSnapshotProgram?: EquirectProgram;
  protected frameBuffer?: WebGLFramebuffer | null;
  protected renderBuffer?: WebGLRenderbuffer | null;

  private sphereProgram?: SphereProgram;
  private depthProgram?: DepthProgram;
  private cube2Equirect?: Cube2Equirect;
  private renderDepthSnapshotsRenderFunction?: () => void;

  protected abstract gl?: WebGLRenderingContext | null;
  protected abstract canvas?: HTMLCanvasElement;

  static createComboImage(tilePaths: string[], onCreated: (image: HTMLImageElement) => void) {
    const size = 512;
    const numInRow = 1; //  3 for lvl 2;  6 for lvl 3
    let numLoading = numInRow * numInRow; // need to wait for all images to load
    const canvas = document.createElement('canvas');

    canvas.width = size * numInRow;
    canvas.height = size * numInRow;
    const ctx = canvas.getContext('2d');

    Object.values(tilePaths).forEach((path, i) => {
      const img = new Image();

      img.crossOrigin = 'Anonymous'; // this is needed to avoid tainted canvas
      img.src = path;
      img.onload = () => {
        const x = Math.floor(i / numInRow) * size;
        const y = (i % numInRow) * size;
        numLoading -= 1;
        if (ctx) {
          ctx.drawImage(img, x, y);
        }

        if (numLoading === 0) {
          const dataurl = canvas.toDataURL('image/jpg');
          const finalImg = document.createElement('img');
          finalImg.src = dataurl;
          finalImg.width = size * numInRow; // is this needed?
          finalImg.height = size * numInRow;
          onCreated(finalImg);
          // document.write(`<img src="${dataurl}" width="${size*6}" height="${size*6}"/>`);
        }
      };
    });
  }

  /**
   * @deprecated -- use RenderTarget class for a little bit optimized performance
   */
  renderToTextureWithFunction(
    drawFunction: () => void,
    texture: WebGLTexture,
    useDepth = true,
    explicitSize?: { width: number; height: number }
  ): void {
    if (!this.gl || !this.frameBuffer || !this.renderBuffer) return;

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, this.frameBuffer);
    this.gl.bindRenderbuffer(this.gl.RENDERBUFFER, this.renderBuffer);

    const size = explicitSize || { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };
    if (useDepth) {
      this.gl.renderbufferStorage(this.gl.RENDERBUFFER, this.gl.DEPTH_STENCIL, size.width, size.height);
      this.gl.framebufferRenderbuffer(this.gl.FRAMEBUFFER, this.gl.DEPTH_STENCIL_ATTACHMENT, this.gl.RENDERBUFFER, this.renderBuffer); // prettier-ignore
    }

    this.gl.viewport(0, 0, size.width, size.height);
    this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGB, size.width, size.height, 0, this.gl.RGB, this.gl.UNSIGNED_BYTE, null); // prettier-ignore
    this.gl.framebufferTexture2D(this.gl.FRAMEBUFFER, this.gl.COLOR_ATTACHMENT0, this.gl.TEXTURE_2D, texture, 0);

    drawFunction();

    this.gl.bindFramebuffer(this.gl.FRAMEBUFFER, null);
    this.gl.bindRenderbuffer(this.gl.RENDERBUFFER, null);
    this.gl.viewport(0, 0, this.gl.drawingBufferWidth, this.gl.drawingBufferHeight);
  }

  createEquirectForPano(assetPath: string, assetConfig: AssetConfig): Promise<HTMLImageElement> {
    return new Promise((resolve) => {
      if (!this.cube2Equirect && this.gl) {
        this.cube2Equirect = new Cube2Equirect();
      }

      const level = 1; // @note -- hardcoded here and in lower function
      let numLoadingFaces = 6;
      const gridSize = 1; //  3 for lvl 2;   6 for lvl 3
      const comboImages: HTMLImageElement[] = [];

      Object.values(Tile.sideKeys).forEach((sideKey, i) => {
        const tilePaths: string[] = [];
        const signatureQuery = objectToQueryString(assetConfig.signatureData);
        for (let gridCol = 1; gridCol <= gridSize; gridCol += 1) {
          for (let gridRow = 1; gridRow <= gridSize; gridRow += 1) {
            let path = `/${sideKey}/l${level}/${gridRow}/l${level}_${sideKey}_${gridRow}_${gridCol}.jpg`;
            if (signatureQuery) path += `?${signatureQuery}`;
            tilePaths.push(urljoin(assetPath, path));
          }
        }

        SnapshotMaker.createComboImage(tilePaths, (comboImage: HTMLImageElement) => {
          numLoadingFaces -= 1;
          comboImages[i] = comboImage;
          if (numLoadingFaces === 0) {
            if (this.cube2Equirect) {
              // @note -- cube2Equirect can manage only 1 pano at a time, if asked next one too fast, it messes up previous one
              this.cube2Equirect.convert(comboImages, (equirectImage: HTMLImageElement) => {
                // RendererDebug.insertImageInHtml(equirectImage, 0); // debuggggggggggggg
                resolve(equirectImage);
              });
            }
          }
        });
      });
    });
  }

  protected initSpecialRenderer(
    gl: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    sphereProgram?: SphereProgram,
    depthProgram?: DepthProgram,
    panoSnapshotProgram?: EquirectProgram
  ): void {
    this.gl = gl;
    this.canvas = canvas;
    this.sphereProgram = sphereProgram;
    this.depthProgram = depthProgram;
    this.panoSnapshotProgram = panoSnapshotProgram;
  }

  protected renderEquirectSnapshots(program_: EquirectProgram, panoKey: string): void {
    if (!__USE_EQUIRECT__) return;

    if (!this.gl || !program_ || !this.sphereProgram || !this.canvas) {
      return;
    }

    if (!this.needToGenerateCubemap(panoKey)) return;

    const oldSizeDB = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };
    const oldSizeCanvas = { width: this.canvas.width, height: this.canvas.height };
    const oldFov = program_.fov;
    const oldPitch = program_.pitch;
    const oldYaw = program_.yaw;
    const oldYawOffset = program_.yawOffset;

    const s = transitionSettings.cubemapResolution;
    this.canvas.width = s;
    this.canvas.height = s;
    this.gl.viewport(0, 0, s, s);

    program_.fov = Math.PI / 2; // 90°
    program_.yawOffset = 0;

    for (let i = 0; i < 6; i += 1) {
      program_.yaw = 0;
      if (i < 4) program_.yaw += i * (Math.PI / 2);
      if (i === 5) program_.pitch = -(Math.PI / 2);
      if (i === 4) program_.pitch = Math.PI / 2;

      program_.draw();
      const img = document.createElement('img');
      img.src = this.canvas.toDataURL();
      this.sphereProgram.spheres[panoKey].sphereFaces[i].localCubemap = img;

      // RendererDebug.insertImagesInHtml(img,i); // adding to DOM for visual inspection
    }

    this.canvas.width = oldSizeCanvas.width;
    this.canvas.height = oldSizeCanvas.height;
    this.gl.viewport(0, 0, oldSizeDB.width, oldSizeDB.height);

    program_.fov = oldFov;
    program_.pitch = oldPitch;
    program_.yaw = oldYaw;
    program_.yawOffset = oldYawOffset;

    // // pink for debugging
    // this.gl.clearColor(1.0, 0.6, 0.95, 1.0);
    // this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    this.sphereProgram.spheres[panoKey].localCubemapsGenerated = true;
    this.render();
  }

  protected needToGenerateCubemap(panoKey: string): boolean {
    return (
      !!this.sphereProgram &&
      !this.sphereProgram.spheres[panoKey].localCubemapsGenerated &&
      !this.sphereProgram.spheres[panoKey].outside
    );
  }

  protected renderDepthSnapshots(sceneConfig: SceneConfig | undefined, safeMode = false): void {
    const panoKey = sceneConfig?.sceneKey ?? '';

    if (
      sceneConfig === undefined ||
      !this.gl ||
      !this.depthProgram ||
      !this.sphereProgram ||
      !this.canvas ||
      this.sphereProgram.spheres[panoKey].allDepthmapsLoaded ||
      this.sphereProgram.spheres[panoKey].outside
    )
      return;

    const oldSizeDB = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight }; // are these 2 ever different ?
    const oldSizeCanvas = { width: this.canvas.width, height: this.canvas.height };
    const oldPos = [...this.depthProgram.cameraPosition];
    this.depthProgram.loadGeometry(sceneConfig);

    // depthmap resolution can be a bit lower than the geometry it displaces, no need to go higher
    const resolution = transitionSettings.depthmapResolution;
    this.canvas.width = resolution;
    this.canvas.height = resolution;
    this.gl.viewport(0, 0, resolution, resolution);

    for (let i = 0; i < 6; i += 1) {
      if (!this.sphereProgram.spheres[panoKey].sphereFaces[i].depthTextureSet) {
        this.depthProgram.fov = Math.PI / 2; // 90°
        this.depthProgram.pitch = 0;
        this.depthProgram.yaw = -toRad(sceneConfig.camera[3]);
        this.depthProgram.cameraPosition[0] = sceneConfig.camera[0];
        this.depthProgram.cameraPosition[1] = sceneConfig.camera[1];

        if (i < 4) this.depthProgram.yaw += i * (Math.PI / 2);
        if (i === 5) this.depthProgram.pitch = -(Math.PI / 2);
        if (i === 4) this.depthProgram.pitch = Math.PI / 2;

        let bufferIsEmpty = false;
        this.gl.clear(this.gl.DEPTH_BUFFER_BIT); // if not cleared, the snapshots are contaminated by previous one (works just fine in normal render loop)

        if (!safeMode) {
          // rendering to buffer, guaranteed not to contaminate the visual output
          // but doesn't work too well with Chrome CPU mode
          // also this flips the image vertically, inverted UVs are used to counter this

          if (!this.renderDepthSnapshotsRenderFunction) {
            this.renderDepthSnapshotsRenderFunction = () => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              this.depthProgram!.draw(true);
              bufferIsEmpty = this.isTheBufferEmpty(); // this needs to be checked before buffer cleanup which happens when the "renderToTexture" function finishes
              // on Safari it used to sometimes fail on 1st render (retrying fixes it)
            };
          }

          this.renderToTextureWithFunction(
            this.renderDepthSnapshotsRenderFunction,
            this.sphereProgram.spheres[panoKey].sphereFaces[i].depthTexture
          );
        } else {
          this.depthProgram.draw(true);
          this.gl.bindTexture(this.gl.TEXTURE_2D, this.sphereProgram.spheres[panoKey].sphereFaces[i].depthTexture);
          this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, this.gl.canvas);
          bufferIsEmpty = this.isTheBufferEmpty();
        }

        if (!bufferIsEmpty) {
          this.sphereProgram.spheres[panoKey].sphereFaces[i].depthTextureSet = true;
        } else if (__DEV__) {
          // sometimes the render fails, not sure about the reasons now: after the refactor to render-to-texture. Safari is prone to it.
          // Might be a false positive:
          // a windowsill shot on the edge of geometry, can't do anything about it.

          // eslint-disable-next-line no-console
          console.log(`(i:${i}) Discarding DM because pixels are all the same color, Better luck next time`);
        }

        // DEBUG add to DOM
        // const tex = this.sphereProgram.spheres[panoKey].sphereFaces[i].depthTexture;
        // RendererDebug.insertTextureInHtml(tex, this.gl, resolution, resolution, i);
      }
    }

    this.canvas.width = oldSizeCanvas.width;
    this.canvas.height = oldSizeCanvas.height;
    this.gl.viewport(0, 0, oldSizeDB.width, oldSizeDB.height);
    this.depthProgram.cameraPosition[0] = oldPos[0];
    this.depthProgram.cameraPosition[1] = oldPos[1];
  }

  protected createDepthmapsForCurrentAndNextPano(
    prevSceneConfig: SceneConfig | undefined,
    nextSceneConfig: SceneConfig,
    safeMode: boolean
  ): void {
    if (!this.gl || !this.depthProgram || !this.sphereProgram || !this.canvas) return;

    // try 2 times each (will skip if already done or not needed)
    for (const scene of [nextSceneConfig, prevSceneConfig, nextSceneConfig, prevSceneConfig]) {
      this.renderDepthSnapshots(scene, safeMode);
    }
  }

  protected areDepthmapsReadyForCurrentAndNextPano(
    prevSceneConfig: SceneConfig | undefined,
    nextSceneConfig: SceneConfig
  ): boolean {
    if (this.sphereProgram === undefined) return false;

    const sceneKey1 = nextSceneConfig.sceneKey || '';
    const sceneKey2 = prevSceneConfig?.sceneKey || '';

    return (
      this.sphereProgram.spheres[sceneKey1].allDepthmapsLoaded &&
      this.sphereProgram.spheres[sceneKey2].allDepthmapsLoaded
    );
  }

  protected async createCubeMapsForCurrentAndNextPano(
    nextSceneConfig: SceneConfig,
    prevSceneConfig?: SceneConfig
  ): Promise<void> {
    if (!__USE_EQUIRECT__) return;

    for (const sceneConfig of [prevSceneConfig, nextSceneConfig]) {
      if (sceneConfig && this.needToGenerateCubemap(sceneConfig.sceneKey)) {
        if (!this.panoSnapshotProgram) continue;

        // Only load primary assets for cube map generation
        const { primaryPath, primaryOptions } = sceneConfig.assetPathConfig as EquirectAssetPath;
        await this.panoSnapshotProgram.preload(primaryPath, primaryOptions);
        this.renderEquirectSnapshots(this.panoSnapshotProgram, sceneConfig.sceneKey);
      }
    }
  }

  // take a sample of pixels, if they are the same, the render has failed
  // (currently tested only for the extra colorful DM renders)
  private isTheBufferEmpty(): boolean {
    const width = 2;
    const height = 2;
    const len = 4 * width * height;
    const pixels = new Uint8Array(len);
    this.gl!.readPixels(0, 0, width, height, this.gl!.RGBA, this.gl!.UNSIGNED_BYTE, pixels);
    // console.log("pixels:", pixels)

    for (let j = 0; j < len - 4; j += 4) {
      const a = pixels[j] + pixels[j + 1] + pixels[j + 2] + pixels[j + 3]; // sum of RGBA of a pixel
      const b = pixels[j + 4] + pixels[j + 5] + pixels[j + 6] + pixels[j + 7]; // neighboring pixel
      if (a !== b) {
        return false;
      }
    }
    return true;
  }

  protected abstract render(): void;
}

export default SnapshotMaker;
