import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef } from 'react';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import MenuCollapse from '../containers/MenuCollapse';
import BuildingSwitch from './BuildingSwitch';
import FloorSwitch from './FloorSwitch';
import OutsideSwitch from './OutsideSwitch';
import MiniMapMenuButton from './ToggleButton';
/** Menu for mini-map/scene navigation controls
 * This menu is collapsible and contains the tour scenes navigation controls
 * Toggling menu also toggles the mini-map visibility
 * If minimap is disabled and no other controls are enabled, this menu will not be rendered
 * Visibility of this menu is also handled by layout outside of this component
 *
 * @prop enabledMinMap - Whether the mini-map is enabled - there can be no enabledControls but only minimap,
 *  and this menu toggle also controls the open/close state of the mini-map
 * @prop enabledControls - Object with keys for each control type, and boolean values for whether they are enabled
 * @prop listMode - Whether the navigation is in list mode - mode mini-map, all scenes listed in the OutsideSwitch
 */
const MiniMapMenu = forwardRef(({ enabledNavigation, listMode }, ref) => {
    const { layoutService } = useServices();
    const { isNavigationOpen, isUtilityMenuOpen, isUtilityMenuCollapsible } = useReactive(layoutService, [
        'isNavigationOpen',
        'isUtilityMenuOpen',
        'isUtilityMenuCollapsible',
    ]);
    const handleToggle = () => {
        if (isUtilityMenuOpen && isUtilityMenuCollapsible) {
            layoutService.toggleUtilityMenu(false);
        }
    };
    return (_jsxs(MenuCollapse, Object.assign({ ref: ref, button: _jsx(MiniMapMenuButton, { onToggle: handleToggle }), direction: "left", isOpen: isNavigationOpen, isCollapsible: true }, { children: [enabledNavigation.buildings && _jsx(BuildingSwitch, {}), enabledNavigation.outside ? _jsx(OutsideSwitch, { listMode: listMode }) : enabledNavigation.floors && _jsx(FloorSwitch, {})] })));
});
MiniMapMenu.displayName = 'MiniMapMenu';
export default MiniMapMenu;
