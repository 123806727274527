import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from 'react';
import Modal from '../../common/Modal';
import { useCookieConsentContext } from '../../contexts/useCookieConsentContext';
import useGlobalContext from '../../contexts/useGlobalContext';
import { useTranslation } from '../../contexts/useLocalizationContext';
import useServices from '../../contexts/useServices';
import useReactive from '../../hooks/useReactive';
import useResizeObserver from '../../hooks/useResizeObserver';
import FloorplanStandardInfo from './components/FloorplanStandardInfo';
import Contact from './views/Contact';
import Information from './views/Information';
function ModalContents({ actuallyShowCTA, showFpStandardInfo, hasOverflowY, disabledDisclaimerAndUnitSwitch, onFpLearnMoreClick, onBack, }) {
    if (actuallyShowCTA) {
        return _jsx(Contact, { hasOverflowY: hasOverflowY });
    }
    if (showFpStandardInfo) {
        return _jsx(FloorplanStandardInfo, { onBack: onBack });
    }
    return (_jsx(Information, { hasOverflowY: hasOverflowY, disabledDisclaimerAndUnitSwitch: disabledDisclaimerAndUnitSwitch, onFpLearnMoreClick: onFpLearnMoreClick }));
}
function InfoModal({ disabledDisclaimerAndUnitSwitch }) {
    const scrollRef = useRef(null);
    const [hasOverflowY, setHasOverflowY] = useState(false);
    const [showFpStandardInfo, setShowFpStandardInfo] = useState(false);
    const { tourConfigService, layoutService } = useServices();
    const { projectDataConfig, features } = useReactive(tourConfigService, ['projectDataConfig', 'features']);
    const { analytics } = useGlobalContext();
    const t = useTranslation();
    const { showCookieModal } = useCookieConsentContext();
    const { showInfoModal } = useReactive(layoutService, ['showInfoModal']);
    const { showCTA } = useReactive(layoutService, ['showCTA']);
    const actuallyShowCTA = !!(showCTA && (projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.contactFormFields) && features.contactSection);
    const handleInformationClose = () => {
        layoutService.showInfoModal = false;
        setShowFpStandardInfo(false);
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'INFO', 'Close');
    };
    const handleContactBack = () => {
        layoutService.showCTA = false;
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'CTA', 'Back arrow');
    };
    const handleFpInfoBack = () => {
        setShowFpStandardInfo(false);
    };
    const handleOutsideClick = () => {
        layoutService.showInfoModal = false;
        // Modal has a fade out animation, changing the screen while fading out doesn't look nice
        setTimeout(() => {
            setShowFpStandardInfo(false);
        }, 400);
        analytics === null || analytics === void 0 ? void 0 : analytics.push('click', actuallyShowCTA ? 'CTA' : 'INFO', 'Outside');
    };
    const handleResize = () => {
        var _a, _b;
        const osInstance = (_a = scrollRef.current) === null || _a === void 0 ? void 0 : _a.osInstance();
        setHasOverflowY((_b = osInstance === null || osInstance === void 0 ? void 0 : osInstance.state().hasOverflow.y) !== null && _b !== void 0 ? _b : false);
    };
    useResizeObserver(handleResize, scrollRef, true, 5);
    useEffect(() => {
        if (!features.contactSection)
            layoutService.showCTA = false;
        if (!features.infoSection) {
            layoutService.showInfoModal = false;
            // Modal has a fade out animation, changing the screen while fading out doesn't look nice
            setTimeout(() => {
                layoutService.showCTA = false;
                setShowFpStandardInfo(false);
            }, 400);
        }
    }, [features, layoutService]);
    const modalTitle = useMemo(() => {
        if (actuallyShowCTA) {
            return t('contact.title');
        }
        if (showFpStandardInfo) {
            return t('footer.measurements');
        }
        return t('share.information');
    }, [actuallyShowCTA, showFpStandardInfo, t]);
    return (_jsx(Modal, Object.assign({ className: "z-info-modal", show: showInfoModal && !showCookieModal, title: modalTitle, showBackButton: actuallyShowCTA || showFpStandardInfo, showCloseButton: !actuallyShowCTA && !showFpStandardInfo, onBack: actuallyShowCTA ? handleContactBack : handleFpInfoBack, onClose: actuallyShowCTA ? undefined : handleInformationClose, onOutsideClick: handleOutsideClick, ref: scrollRef }, { children: _jsx(ModalContents, { hasOverflowY: hasOverflowY, disabledDisclaimerAndUnitSwitch: disabledDisclaimerAndUnitSwitch, actuallyShowCTA: actuallyShowCTA, showFpStandardInfo: showFpStandardInfo, onFpLearnMoreClick: () => setShowFpStandardInfo(true), onBack: handleFpInfoBack }) })));
}
export default InfoModal;
