/* eslint-disable no-continue */
import { invertM4, multiplyM4AndPoint } from '../../common/Matrix';
import { getVec3Difference, getVec3Dot, normalizeVec3, vec3CrossProduct } from '../../common/Utils';
import FloorPlan3DProgram from './FloorPlan3DProgram';
import type { FPMesh } from './types';

type Ray = { origin: number[]; direction: number[] };

type Intersection = {
  point: [number, number, number];
  distance: number;
};

export type MeshIntersection = {
  mesh: FPMesh;
  point: [number, number, number];
  distance: number;
};

function createRayFromMouse(
  mouseX: number,
  mouseY: number,
  canvasWidth: number,
  canvasHeight: number,
  viewMatrix: Float32Array,
  projectionMatrix: Float32Array
): Ray {
  const x = (2.0 * mouseX) / canvasWidth - 1.0;
  const y = 1.0 - (2.0 * mouseY) / canvasHeight;
  const rayClip = [x, y, -1.0, 1.0];

  // typecast ir 100% safe, matrix functions does care if its number[] or Float32Array
  const rayEye = multiplyM4AndPoint(invertM4(projectionMatrix as unknown as number[]), rayClip);
  rayEye[2] = -1.0;
  rayEye[3] = 0.0;

  const rayWorld = multiplyM4AndPoint(invertM4(viewMatrix as unknown as number[]), rayEye);

  const invViewMatrix = invertM4(viewMatrix as unknown as number[]);
  const cameraPosition = [invViewMatrix[12], invViewMatrix[13], invViewMatrix[14]];

  const direction = normalizeVec3([rayWorld[0], rayWorld[1], rayWorld[2]]);

  return { origin: cameraPosition, direction };
}

function rayTriangleIntersection(ray: Ray, v0: number[], v1: number[], v2: number[]): Intersection | null {
  const edge1 = getVec3Difference(v1, v0);
  const edge2 = getVec3Difference(v2, v0);
  const h = vec3CrossProduct(ray.direction, edge2);
  const a = getVec3Dot(edge1, h);

  if (a > -1e-6 && a < 1e-6) return null;

  const f = 1.0 / a;
  const s = getVec3Difference(ray.origin, v0);
  const u = f * getVec3Dot(s, h);

  if (u < 0.0 || u > 1.0) return null;

  const q = vec3CrossProduct(s, edge1);
  const v = f * getVec3Dot(ray.direction, q);

  if (v < 0.0 || u + v > 1.0) return null;

  const t = f * getVec3Dot(edge2, q);

  if (t > 1e-6) {
    const intersectionPoint: [number, number, number] = [
      ray.origin[0] + t * ray.direction[0],
      ray.origin[1] + t * ray.direction[1],
      ray.origin[2] + t * ray.direction[2],
    ];
    return { point: intersectionPoint, distance: t };
  }

  return null;
}

export default class MeshRaycaster {
  private meshes: FPMesh[] = [];
  private goodMeshIds: number[] = [];
  private positionData: number[] = [];
  private canvas: HTMLCanvasElement;
  private floorPlan3DProgram: FloorPlan3DProgram;

  constructor(canvas: HTMLCanvasElement, floorPlan3DProgram: FloorPlan3DProgram) {
    this.canvas = canvas;
    this.floorPlan3DProgram = floorPlan3DProgram;
  }

  loadGeometry(meshes: FPMesh[], positionData: number[]): void {
    this.meshes = meshes;
    this.positionData = positionData;

    for (let m = 0; m < meshes.length; m += 1) {
      const mesh = meshes[m];

      if (mesh.dataNum === 0) continue; // skip bad meshes
      if (mesh.isOutline || mesh.isCeiling) continue;
      if (mesh.isWallCap && mesh.isTopWall) continue; // skip upper wall caps

      this.goodMeshIds.push(m);
    }
  }

  raycastMouse(event: MouseEvent): MeshIntersection | null {
    const boundingRect = this.canvas.getBoundingClientRect();
    try {
      const ray = createRayFromMouse(
        event.clientX,
        event.clientY,
        boundingRect.width,
        boundingRect.height,
        this.floorPlan3DProgram.matrixViewFloat32Array,
        this.floorPlan3DProgram.matrixProjectionFloat32Array
      );
      return this.findClosestIntersectionWithRay(ray);
    } catch (e) {
      // one matrix function throws error if matrix is not invertible (should never happen IRL)
      return null;
    }
  }

  raycastPoint(point: number[]): MeshIntersection | null {
    try {
      const ray = {
        origin: point,
        direction: [0, -1, 0],
      };
      return this.findClosestIntersectionWithRay(ray);
    } catch (e) {
      return null;
    }
  }

  findClosestIntersectionWithRay(ray: Ray): MeshIntersection | null {
    let closestIntersection: MeshIntersection | null = null;
    const positions = this.positionData;

    for (let m = 0; m < this.goodMeshIds.length; m += 1) {
      const mesh = this.meshes[this.goodMeshIds[m]];

      const start = mesh.dataOffsetPositions / 4;
      const end = start + mesh.dataNum * 3;

      for (let i = start; i < end; i += 9) {
        const v0 = [positions[i], positions[i + 1], positions[i + 2]];
        const v1 = [positions[i + 3], positions[i + 4], positions[i + 5]];
        const v2 = [positions[i + 6], positions[i + 7], positions[i + 8]];

        const intersection = rayTriangleIntersection(ray, v0, v1, v2);
        if (intersection && (!closestIntersection || intersection.distance < closestIntersection.distance)) {
          closestIntersection = {
            mesh,
            point: intersection.point,
            distance: intersection.distance,
          };
        }
      }
    }
    return closestIntersection;
  }
}
