import type { Destroyable } from '@g360/vt-types';

class RenderTarget implements Destroyable {
  public readonly texture: WebGLTexture;

  private readonly gl: WebGLRenderingContext;
  private readonly fb: WebGLFramebuffer;
  private readonly depthBuffer: WebGLRenderbuffer | null;
  private width: number;
  private height: number;

  constructor(gl: WebGLRenderingContext, width: number, height: number, useDepth: boolean) {
    this.gl = gl;
    const fb = gl.createFramebuffer();
    if (!fb) throw new Error('RenderTarget::Failed to create framebuffer');
    this.fb = fb;
    const texture = gl.createTexture();
    if (!texture) throw new Error('RenderTarget::Failed to create texture');
    this.texture = texture;
    this.depthBuffer = useDepth ? gl.createRenderbuffer() : null;
    this.width = width;
    this.height = height;
    this.init();
  }

  init() {
    const gl = this.gl;
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.fb);

    gl.bindTexture(gl.TEXTURE_2D, this.texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, this.width, this.height, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, this.texture, 0);

    if (this.depthBuffer) {
      gl.bindRenderbuffer(gl.RENDERBUFFER, this.depthBuffer);
      gl.renderbufferStorage(gl.RENDERBUFFER, gl.DEPTH_COMPONENT16, this.width, this.height);
      gl.framebufferRenderbuffer(gl.FRAMEBUFFER, gl.DEPTH_ATTACHMENT, gl.RENDERBUFFER, this.depthBuffer);
    }

    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
  }

  resize(width: number, height: number) {
    if (this.width === width && this.height === height) return;

    this.width = width;
    this.height = height;
    this.init();
  }

  bind() {
    const gl = this.gl;
    gl.bindFramebuffer(gl.FRAMEBUFFER, this.fb);
    gl.viewport(0, 0, this.width, this.height);
  }

  unbind() {
    const gl = this.gl;
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
  }

  destroy() {
    const gl = this.gl;

    if (this.fb) {
      gl.deleteFramebuffer(this.fb);
    }

    if (this.texture) {
      gl.deleteTexture(this.texture);
    }

    if (this.depthBuffer) {
      gl.deleteRenderbuffer(this.depthBuffer);
    }
  }
}

export default RenderTarget;
