import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import useGlobalContext from '../../../contexts/useGlobalContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import BuildingIcon from '../icons/BuildingIcon';
import OutsideIcon from '../icons/OutsideIcon';
import Scrollbars from './Scrollbars';
const ButtonContent = ({ label, singleBuilding, disabledHoverShadow }) => {
    const pathClassName = disabledHoverShadow ? 'group-hover:filter-none' : '';
    const twTextHoverShadow = disabledHoverShadow ? 'group-hover:text-shadow-none' : '';
    if (label === 'outside') {
        return _jsx(OutsideIcon, { className: "h-8 w-8", pathClassName: pathClassName });
    }
    if (singleBuilding) {
        return _jsx(BuildingIcon, { className: "h-8 w-8 filter-none", pathClassName: pathClassName });
    }
    return (_jsxs("div", Object.assign({ className: `flex items-end justify-center text-shadow-dark ${twTextHoverShadow}` }, { children: [_jsx("span", Object.assign({ className: "ml-1 min-w-[0.75rem] pb-[1px] text-center" }, { children: label })), _jsx(BuildingIcon, { className: "h-8 w-8", pathClassName: pathClassName })] })));
};
const BuildingSwitch = () => {
    const { navigationService, layoutService } = useServices();
    const { changeBuilding, panoMatchBuilding, structure } = navigationService;
    const { activeScene, buildingKey, transitionInProgress } = useReactive(navigationService, [
        'activeScene',
        'buildingKey',
        'transitionInProgress',
    ]);
    const { isRemoteControlled } = useReactive(layoutService, ['isRemoteControlled']);
    const singleBuilding = structure.buildingsOrder.length === 1;
    const { analytics } = useGlobalContext();
    return (_jsxs(Listbox, Object.assign({ as: "div", disabled: isRemoteControlled || transitionInProgress, className: "relative", value: buildingKey, onChange: (nextBuildingKey) => {
            if (nextBuildingKey === 'outside') {
                analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'BMENU', 'Outdoors', {
                    scene_id: activeScene.sceneKey,
                });
            }
            else {
                analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'BMENU', 'Building', {
                    scene_id: activeScene.sceneKey,
                    building: nextBuildingKey,
                });
            }
            if (!panoMatchBuilding(nextBuildingKey)) {
                // panoMatchBuildingChange couldn't find a pano in the next building
                // then no pano switching will occur and we must explicitly switch UI to that building,
                changeBuilding(nextBuildingKey);
            }
        } }, { children: [_jsx(Listbox.Button, Object.assign({ as: "button", className: "btn-theme-primary group pointer-events-auto h-10 w-[4.5rem] min-w-0 truncate disabled:cursor-not-allowed\n          disabled:hover:bg-opacity-theme-low", "data-testid": "building-switch-btn" }, { children: _jsx(ButtonContent, { label: buildingKey, singleBuilding: singleBuilding }) })), _jsx("div", Object.assign({ className: "absolute bottom-full h-[20rem] w-full overflow-hidden" }, { children: _jsx(Transition, Object.assign({ as: Fragment }, { children: _jsx(Listbox.Options, Object.assign({ as: "div", className: "absolute bottom-0 w-full pb-2 outline-none" }, { children: _jsxs(Scrollbars, Object.assign({ className: "max-h-[15.25rem]" }, { children: [structure.buildingsOrder.map((buildingId) => (_jsx(Listbox.Option, Object.assign({ as: Fragment, value: buildingId }, { children: _jsx("button", Object.assign({ type: "button", className: "btn-theme-secondary group h-10 min-h-[2.5rem] w-full", "data-testid": "building-btn" }, { children: _jsx(ButtonContent, { label: buildingId, singleBuilding: singleBuilding, disabledHoverShadow: true }) })) }), `building-${buildingId}`))), structure.outsideScenes && (_jsx(Listbox.Option, Object.assign({ as: Fragment, value: "outside" }, { children: _jsx("button", Object.assign({ type: "button", className: "btn-theme-secondary group h-10 min-h-[2.5rem] w-full", "data-testid": "outside-btn" }, { children: _jsx(ButtonContent, { label: "outside", disabledHoverShadow: true }) })) }), "outside"))] })) })) })) }))] })));
};
export default BuildingSwitch;
