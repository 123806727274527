import type { ColorCorrectionSettings } from '@g360/vt-types';

import { identityM4, multiplyM4 } from '../../common/Matrix';

// part of a shader program
class ColorCorrectionProgramPartial {
  // @note -- not multiple-engine-instance safe, fix if there is a need for 2 engines in a single app running Panorama editors with color correction
  // Since there is no need for different values for different programs we can use static variables.
  public static colorCorrectionMatrix = identityM4();
  public static colorCorrectionOffset = [0, 0, 0, 0];
  public static colorBalanceVector = [1, 1, 1];
  public static shadows = 1; // 0..2; 1 - no change
  public static highlights = 1;

  private colorCorrectionMatrixUniform: WebGLUniformLocation | null = null;
  private colorCorrectionOffsetUniform: WebGLUniformLocation | null = null;
  private colorBalanceVectorUniform: WebGLUniformLocation | null = null;
  private shadowsUniform: WebGLUniformLocation | null = null;
  private highlightsUniform: WebGLUniformLocation | null = null;

  static setColorCorrectionSettings(values: ColorCorrectionSettings): void {
    const vSaturation = values.saturation;
    const vContrast = values.contrast;
    const vExposure = values.exposure;
    const vTemperature = values.temperature;
    const vShadows = values.shadows;
    const vHighlights = values.highlights;
    const vTint = 0;

    ColorCorrectionProgramPartial.shadows = vShadows;
    ColorCorrectionProgramPartial.highlights = vHighlights;

    const s = 1 + vSaturation;

    // https://www.w3.org/TR/WCAG20/#relativeluminancedef
    const lr = 0.2126;
    const lg = 0.7152;
    const lb = 0.0722;

    const sr = (1 - s) * lr;
    const sg = (1 - s) * lg;
    const sb = (1 - s) * lb;

    const saturationMatrix = [sr + s, sr, sr, 0, sg, sg + s, sg, 0, sb, sb, sb + s, 0, 0, 0, 0, 1];

    const c = 1 + vContrast;
    const o = 0.5 * (1 - c);
    const contrastMatrix = [c, 0, 0, 0, 0, c, 0, 0, 0, 0, c, 0, 0, 0, 0, 1];
    const contrastOffset = [o, o, o, 0];

    const e = 1 + vExposure;
    const exposureMatrix = [e, 0, 0, 0, 0, e, 0, 0, 0, 0, e, 0, 0, 0, 0, 1];

    ColorCorrectionProgramPartial.colorCorrectionMatrix = multiplyM4(
      multiplyM4(saturationMatrix, contrastMatrix),
      exposureMatrix
    );
    ColorCorrectionProgramPartial.colorCorrectionOffset = contrastOffset;

    // temp & tint

    // Range ~[-1.67;1.67] works best
    const t1 = (vTemperature * 10.0) / 6.0;
    const t2 = (vTint * 10.0) / 6.0;

    // Get the CIE xy chromaticity of the reference white point.
    // Note: 0.31271 = x value on the D65 white point
    const x = 0.31271 - t1 * (t1 < 0.0 ? 0.1 : 0.05);
    const standardIlluminantY = 2.87 * x - 3.0 * x * x - 0.27509507;
    const y = standardIlluminantY + t2 * 0.05;

    // Calculate the coefficients in the LMS space.
    const w1 = [0.949237, 1.03542, 1.08728]; // D65 white point

    // CIExyToLMS
    const Y = 1.0;
    const X = (Y * x) / y;
    const Z = (Y * (1.0 - x - y)) / y;
    const L = 0.7328 * X + 0.4296 * Y - 0.1624 * Z;
    const M = -0.7036 * X + 1.6975 * Y + 0.0061 * Z;
    const S = 0.003 * X + 0.0136 * Y + 0.9834 * Z;
    const w2 = [L, M, S];
    ColorCorrectionProgramPartial.colorBalanceVector = [w1[0] / w2[0], w1[1] / w2[1], w1[2] / w2[2]];
  }

  initColorCorrection(program: WebGLProgram, gl: WebGLRenderingContext): void {
    this.colorCorrectionMatrixUniform = gl.getUniformLocation(program, 'u_colorCorrectionMatrix');
    this.colorCorrectionOffsetUniform = gl.getUniformLocation(program, 'u_colorCorrectionOffset');
    this.colorBalanceVectorUniform = gl.getUniformLocation(program, 'u_colorBalance');
    this.shadowsUniform = gl.getUniformLocation(program, 'u_shadows');
    this.highlightsUniform = gl.getUniformLocation(program, 'u_highlights');
  }

  setColorCorrectionUniforms(gl: WebGLRenderingContext): void {
    gl.uniformMatrix4fv(this.colorCorrectionMatrixUniform, false, ColorCorrectionProgramPartial.colorCorrectionMatrix);
    gl.uniform4fv(this.colorCorrectionOffsetUniform, ColorCorrectionProgramPartial.colorCorrectionOffset);
    gl.uniform3fv(this.colorBalanceVectorUniform, ColorCorrectionProgramPartial.colorBalanceVector);
    gl.uniform1f(this.shadowsUniform, ColorCorrectionProgramPartial.shadows);
    gl.uniform1f(this.highlightsUniform, ColorCorrectionProgramPartial.highlights);
  }
}

export default ColorCorrectionProgramPartial;
