import type { SceneConfig, TourConfig } from '@g360/vt-types';

function euclideanDistanceVec3(ax: number, ay: number, az: number, bx: number, by: number, bz: number) {
  return Math.sqrt((ax - bx) ** 2 + (ay - by) ** 2 + (az - bz) ** 2);
}

/** Returns all scenes within a given distance (cm) from the queryScene */
function getScenesWithinDistance(
  queryScene: SceneConfig,
  allScenes: SceneConfig[],
  maxDistance: number
): SceneConfig[] {
  return allScenes.filter((scene) => {
    // Need scenes to be in the same building and floor in order to group them together
    if (scene.building !== queryScene.building) return false;
    if (scene.floor !== queryScene.floor) return false;

    // Group scenes that are within the given distance
    const distance = euclideanDistanceVec3(
      queryScene.camera[0],
      queryScene.camera[1],
      queryScene.camera[2],
      scene.camera[0],
      scene.camera[1],
      scene.camera[2]
    );
    return distance <= maxDistance;
  });
}

/** Given a scene and its neighbors, also adds the neighbors' neighbors to the sceneGroup */
function expandSceneGroup(
  queryScene: SceneConfig,
  allScenes: SceneConfig[],
  querySceneNeighbors: SceneConfig[],
  newSceneGroup: Set<SceneConfig>,
  maxDistance: number,
  visitedScenes: Set<SceneConfig>,
  otherSceneGroups: SceneConfig[][]
) {
  // The query scene itself is the first element of the group
  newSceneGroup.add(queryScene);

  for (let i = 0; i < querySceneNeighbors.length; i += 1) {
    const neighbor = querySceneNeighbors[i];

    // Mark scenes as visited here to avoid revisiting them in the main function loop
    if (!visitedScenes.has(neighbor)) {
      visitedScenes.add(neighbor);
      const neighborNeighbors = getScenesWithinDistance(neighbor, allScenes, maxDistance).filter(
        // Avoid adding scenes that are already in the newSceneGroup or in other scene groups
        (n) => !otherSceneGroups.some((group) => group.includes(n))
      );

      neighborNeighbors.forEach((n) => newSceneGroup.add(n));

      expandSceneGroup(
        neighbor,
        allScenes,
        neighborNeighbors,
        newSceneGroup,
        maxDistance,
        visitedScenes,
        otherSceneGroups
      );
    }
  }
}

/**
 * Given a tourConfig, groups all scenes within the given distance from each other using DBSCAN algorithm
 * @returns An array of scene groups, where each group is an array of scene keys
 */
export function mergeScenesByDistance(tourConfig: TourConfig, distanceCm = 50): string[][] {
  // Store visited scenes to avoid revisiting them in nested loops
  const visitedScenes = new Set<SceneConfig>();
  let sceneGroups: SceneConfig[][] = [];

  const allScenes = Object.entries(tourConfig.scenes)
    .map(([sceneKey, scene]) => ({ ...scene, sceneKey }))
    // Sort the scenes by scene key to have consistent main scenes
    // (consistent iteration order)
    .sort((a, b) => a.sceneKey.localeCompare(b.sceneKey));

  for (let i = 0; i < allScenes.length; i += 1) {
    const scene = allScenes[i];
    if (visitedScenes.has(scene)) {
      // eslint-disable-next-line no-continue
      continue;
    }

    // Get all scenes within a certain distance of the main scene
    const neighbors = getScenesWithinDistance(scene, allScenes, distanceCm);

    const sceneGroup = new Set<SceneConfig>();
    // Add all the neighbors and their neighbors to the new sceneGroup
    expandSceneGroup(scene, allScenes, neighbors, sceneGroup, distanceCm, visitedScenes, sceneGroups);
    sceneGroups.push([...sceneGroup]);
  }

  sceneGroups = sceneGroups.filter((group) => group.length > 1);

  // Sort the scenes in each group so the one closest to the centroid is the main scene
  sceneGroups.forEach((group) => {
    const groupCentroidSum = group.reduce(
      (acc, scene) => {
        // eslint-disable-next-line no-param-reassign
        acc[0] += scene.camera[0];
        // eslint-disable-next-line no-param-reassign
        acc[1] += scene.camera[1];
        // eslint-disable-next-line no-param-reassign
        acc[2] += scene.camera[2];
        return acc;
      },
      [0, 0, 0]
    );
    const groupCentroid = groupCentroidSum.map((sum) => sum / group.length);

    group.sort((scene1, scene2) => {
      // least distance to centroid
      const dist1 = euclideanDistanceVec3(
        scene1.camera[0],
        scene1.camera[1],
        scene1.camera[2],
        groupCentroid[0],
        groupCentroid[1],
        groupCentroid[2]
      );

      const dist2 = euclideanDistanceVec3(
        scene2.camera[0],
        scene2.camera[1],
        scene2.camera[2],
        groupCentroid[0],
        groupCentroid[1],
        groupCentroid[2]
      );

      return dist1 - dist2;
    });
  });

  return sceneGroups.map((group) => group.map((scene) => scene.sceneKey));
}
