import { Mixin } from 'ts-mixer';

import WebGLProgram from '../mixins/Program';
import fragmentShader from './measureDebug.fs.glsl';
import vertexShader from './measureDebug.vs.glsl';
import type MeasureToolProgram from './MeasureToolProgram';

class MeasureDebugProgram extends Mixin(WebGLProgram) {
  // Debug params
  // Listed as static to persist between measure mode toggles
  static overlayOpacity = 0;
  static overlayMode = 2;
  static overlayHoverRadius = 0;

  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  vertexBuffer: WebGLBuffer | null;

  vertexAttribute = 0;

  depthMapUniform: WebGLUniformLocation | null = null;
  normalMapUniform: WebGLUniformLocation | null = null;

  aspectRatioUniform: WebGLUniformLocation | null = null;
  yawUniform: WebGLUniformLocation | null = null;
  yawOffsetUniform: WebGLUniformLocation | null = null;
  pitchUniform: WebGLUniformLocation | null = null;
  fovUniform: WebGLUniformLocation | null = null;

  alphaUniform: WebGLUniformLocation | null = null;
  cartesianCursorUniform: WebGLUniformLocation | null = null;

  sphereRadiusUniform: WebGLUniformLocation | null = null;
  sphereHasRadiusUniform: WebGLUniformLocation | null = null;
  normalsFullSelectedUniform: WebGLUniformLocation | null = null;
  normalsAbsSelectedUniform: WebGLUniformLocation | null = null;

  private mainProgram: MeasureToolProgram;

  private vertices = new Float32Array([-1, 1, 1, 1, 1, -1, -1, 1, 1, -1, -1, -1]);

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, mainProgram: MeasureToolProgram) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.mainProgram = mainProgram;

    this.vertexBuffer = this.gl.createBuffer();
  }

  init(): void {
    this.initShaders(vertexShader, fragmentShader);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');

      this.depthMapUniform = this.gl.getUniformLocation(this.program, 'u_tex_depth_map');
      this.normalMapUniform = this.gl.getUniformLocation(this.program, 'u_tex_normal_map');

      this.aspectRatioUniform = this.gl.getUniformLocation(this.program, 'u_aspectRatio');
      this.yawUniform = this.gl.getUniformLocation(this.program, 'u_yaw');
      this.yawOffsetUniform = this.gl.getUniformLocation(this.program, 'u_yaw_offset');
      this.pitchUniform = this.gl.getUniformLocation(this.program, 'u_pitch');
      this.fovUniform = this.gl.getUniformLocation(this.program, 'u_fov');

      this.alphaUniform = this.gl.getUniformLocation(this.program, 'u_alpha');
      this.cartesianCursorUniform = this.gl.getUniformLocation(this.program, 'u_cart_cursor');

      this.sphereRadiusUniform = this.gl.getUniformLocation(this.program, 'u_hover_sphere_radius');
      this.sphereHasRadiusUniform = this.gl.getUniformLocation(this.program, 'u_bool_sphere_has_radius');
      this.normalsFullSelectedUniform = this.gl.getUniformLocation(this.program, 'u_bool_normals_full_selected');
      this.normalsAbsSelectedUniform = this.gl.getUniformLocation(this.program, 'u_bool_normals_abs_selected');

      this.vertexAttributes = [this.vertexAttribute];
    }
  }

  destroy(): void {
    this.destroyProgram();
  }

  draw(): void {
    if (MeasureDebugProgram.overlayOpacity === 0) return;

    this.loadShaders();
    this.enableVertexAttributes();

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
    this.gl.enableVertexAttribArray(this.vertexAttribute);
    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.mainProgram.depthMapTextureObject);
    this.gl.uniform1i(this.depthMapUniform, 1);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.mainProgram.normalMapTextureObject);
    this.gl.uniform1i(this.normalMapUniform, 2);

    this.gl.uniform1f(this.aspectRatioUniform, this.gl.drawingBufferWidth / this.gl.drawingBufferHeight);
    this.gl.uniform1f(this.yawUniform, this.mainProgram.yaw + this.mainProgram.yawOffset);
    this.gl.uniform1f(this.yawOffsetUniform, this.mainProgram.yawOffset);
    this.gl.uniform1f(this.pitchUniform, this.mainProgram.pitch);
    this.gl.uniform1f(this.fovUniform, this.mainProgram.fov);

    this.gl.uniform1f(this.alphaUniform, MeasureDebugProgram.overlayOpacity);

    const cursorCoords = this.mainProgram.cursorCoords?.snapPosition ?? this.mainProgram.cursorCoords;

    const cartCursor = cursorCoords ? [cursorCoords.cartX, cursorCoords.cartY, cursorCoords.cartZ] : [0, 0, 0];

    this.gl.uniform3fv(this.cartesianCursorUniform, [cartCursor[0], cartCursor[2], -cartCursor[1]]);
    this.gl.uniform1f(this.sphereRadiusUniform, MeasureDebugProgram.overlayHoverRadius);
    this.gl.uniform1f(this.sphereHasRadiusUniform, MeasureDebugProgram.overlayHoverRadius > 0 ? 1 : 0);
    this.gl.uniform1f(this.normalsAbsSelectedUniform, MeasureDebugProgram.overlayMode === 1 ? 1 : 0);
    this.gl.uniform1f(this.normalsFullSelectedUniform, MeasureDebugProgram.overlayMode === 2 ? 1 : 0);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);

    this.gl.disable(this.gl.CULL_FACE);
    this.disableVertexAttributes();
  }
}

export default MeasureDebugProgram;
