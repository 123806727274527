/**
 * Manages everything related to the camera for FloorPlan3DProgram.
 */
import type { Centimeter, Radian } from '@g360/vt-types';
import { lerp } from '@g360/vt-utils';

import type DebugControls from '../../DebugControls';
import { debugMoveCamera } from './utils/debug';

class Camera {
  /** cached on last matrix recalculation */
  lastFar = 0; // @todo -- rem, seems unused
  lastNear = 0;

  /** 0 - orbit camera; 1 - FPS camera (for debug only)  */
  private mode = 0;
  private center: number[] = [0, 0, 0];
  /** camera orbit radius at its max */
  private radius = 0;
  /** size of loaded model */
  private modelRadius = 0;
  private zoom = 1;
  private bonusHeight = 1;
  // private height; @todo -- height above the ground (change center[1] )
  private readonly debugControls: DebugControls | undefined;

  constructor(debugControls: DebugControls | undefined) {
    this.debugControls = debugControls;
  }

  calculate(pitch: Radian, yaw: Radian) {
    if (this.mode === 1) {
      if (!this.debugControls) throw new Error('DebugControls not set, A');

      return debugMoveCamera(
        -this.debugControls.cameraManualOffsets[2],
        -this.debugControls.cameraManualOffsets[0],
        -this.debugControls.cameraManualOffsets[1],
        pitch,
        yaw,
        this.center
      );
    }

    if (this.mode === 0) {
      return this.orbitCamera(pitch, yaw, this.radius * this.zoom, this.center);
    }

    throw new Error('Mode not supported');
  }

  setMode(mode: number) {
    this.mode = mode;

    if (this.debugControls) {
      this.debugControls.cameraManualOffsets[0] = 0;
      this.debugControls.cameraManualOffsets[1] = 0;
      this.debugControls.cameraManualOffsets[2] = 0;
    }
  }

  getMode(): number {
    return this.mode;
  }

  setCenter(center: number[]) {
    this.center = center;
    console.log(
      'Camera::setCenter',
      center,
      "@todo -- must recalculate new 'modelRadius' since it changes if you move the center, "
    );
  }

  getCenter() {
    return this.center;
  }

  getCurrentDistanceFromCenter() {
    return this.radius * this.zoom;
  }

  setRadius(radius: number, modelRadius: number) {
    this.radius = radius;
    this.modelRadius = modelRadius;
  }

  setZoom(zoom: number) {
    const minZoomDistance: Centimeter = 200;
    const minZoom = minZoomDistance / this.radius;

    // zoom is 0..1
    // but we shouldn't allow zooming in too much
    // since this.zoom is just multiplier to camera distance from center
    this.zoom = lerp(minZoom, 1, zoom);
  }

  /**
   * @deprecated
   * @param zoom
   */
  setBonusHeight(zoom: number) {
    this.bonusHeight = zoom;
  }

  /**
   * Make sure the clipping planes are the tightest possible
   * @todo -- either double near-far space to account for rotating model on the edge not center
   *          or recalculate clipping planes based on the model rotation
   *
   */
  getClippingPlanes() {
    const cameraDistance = this.radius * this.zoom;
    const nearestPoint = Math.max(cameraDistance - this.modelRadius, 0.1); // Ensure it's not negative or too close to zero
    const farthestPoint = cameraDistance + this.modelRadius;
    let nearPlane = nearestPoint * 0.9;
    let farPlane = farthestPoint * 1.1;

    // @todo -- rem when FPS camera mode is no more
    if (this.mode === 1) {
      nearPlane = 0.1;
      farPlane = 10000;
    }

    this.lastFar = farPlane;
    this.lastNear = nearPlane;

    return { near: nearPlane, far: farPlane };
  }

  private orbitCamera(pitch: Radian, yaw: Radian, radius: number, modelCenter: number[]): number[] {
    const x = modelCenter[0] + radius * Math.sin(yaw) * Math.cos(pitch);
    const y = modelCenter[1] + radius * Math.sin(pitch) + this.bonusHeight;
    const z = modelCenter[2] + radius * Math.cos(yaw) * Math.cos(pitch);

    return [x, y, z];
  }
}

export default Camera;
