import type { Subscription } from '@g360/vt-types';

class ProgramEventEmitter<T extends string> {
  private listeners: { [key in T]?: ((payload: any) => void)[] } = {};

  destroyEventEmitter(): void {
    this.listeners = {};
  }

  subscribe(event: T, fn: (payload: any) => void): Subscription {
    if (event !== undefined) {
      this.listeners[event] = this.listeners[event] || [];

      if (!this.listeners[event]!.filter((c) => c !== fn).length) {
        this.listeners[event]!.push(fn);
      }
    }

    return {
      unsubscribe: () => {
        this.unsubscribe(event, fn);
      },
    };
  }

  subscribeOnce(event: T, fn: (payload: any) => void): Subscription {
    const onceWrapper = (payload: any) => {
      this.unsubscribe(event, onceWrapper);
      fn(payload);
    };

    this.subscribe(event, onceWrapper);

    return {
      unsubscribe: () => {
        this.unsubscribe(event, onceWrapper);
      },
    };
  }

  unsubscribe(event: T, fn: (payload: any) => void): void {
    if (this.listeners[event]) {
      this.listeners[event] = this.listeners[event]!.filter((c) => c !== fn);
    }
  }

  emit(event: T, payload?: any): void {
    if (this.listeners[event]) {
      this.listeners[event]!.forEach((listener) => listener(payload));
    }
  }
}

export default ProgramEventEmitter;
