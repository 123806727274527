import type { Subscription } from '@g360/vt-types';

// TODO(uzars): improve type-checking
type ZoomScopeProgramEvents = 'render' | 'error.set' | 'error.clear' | 'scope_position.set';

class ZoomScopeEventEmitter {
  private listeners: { [key: string]: ((payload) => void)[] } = {};

  /** Destroy emitter by removing all subscribers */
  destroyEventEmitter(): void {
    this.listeners = {};
  }

  subscribe(event: ZoomScopeProgramEvents, fn: (payload) => void): Subscription {
    if (event !== undefined) {
      this.listeners[event] = this.listeners[event] || [];

      if (!this.listeners[event].filter((c) => c !== fn).length) {
        this.listeners[event].push(fn);
      }
    }

    return {
      unsubscribe: () => {
        this.unsubscribe(event, fn);
      },
    };
  }

  unsubscribe(event: ZoomScopeProgramEvents, fn: (payload) => void): void {
    this.listeners[event] = this.listeners[event].filter((c) => c !== fn);
  }

  emit(event: ZoomScopeProgramEvents, payload?): void {
    if (this.listeners[event] && this.listeners[event].length) {
      this.listeners[event].forEach((listener) => listener(payload));
    }
  }
}

export default ZoomScopeEventEmitter;
