import { getVec3Dot, normalizeVec3, vec3CrossProduct } from '../../../common/Utils';

export function createViewMatrix(position: number[], pitch: number, yaw: number): number[] {
  const cosPitch = Math.cos(pitch);
  const sinPitch = Math.sin(pitch);
  const cosYaw = Math.cos(yaw);
  const sinYaw = Math.sin(yaw);
  const lookX = cosPitch * sinYaw;
  const lookY = sinPitch;
  const lookZ = cosPitch * cosYaw;
  const lookDir = normalizeVec3([lookX, lookY, lookZ]);
  const right = normalizeVec3(vec3CrossProduct([0, 1, 0], lookDir));
  const up = vec3CrossProduct(lookDir, right);
  return [
    right[0],
    up[0],
    lookDir[0],
    0,
    right[1],
    up[1],
    lookDir[1],
    0,
    right[2],
    up[2],
    lookDir[2],
    0,
    -getVec3Dot(right, position),
    -getVec3Dot(up, position),
    -getVec3Dot(lookDir, position),
    1,
  ];
}

export function createOrthographicMatrix(
  left: number,
  right: number,
  bottom: number,
  top: number,
  near: number,
  far: number
): number[] {
  return [
    2 / (right - left),
    0,
    0,
    0,
    0,
    2 / (top - bottom),
    0,
    0,
    0,
    0,
    -2 / (far - near),
    0,
    -(right + left) / (right - left),
    -(top + bottom) / (top - bottom),
    -(far + near) / (far - near),
    1,
  ];
}

export function lerpMatrix(a: number[], b: number[], t: number): number[] {
  return a.map((v, i) => v + (b[i] - v) * t);
}

export function calculateDistance(point1: number[], point2: number[]): number {
  const dx = point2[0] - point1[0];
  const dy = point2[1] - point1[1];
  const dz = point2[2] - point1[2];
  return Math.sqrt(dx * dx + dy * dy + dz * dz);
}

export function isRightAngledTriangle(vertices: number[][]): boolean {
  const [v1, v2, v3] = vertices;
  const lengths = [calculateDistance(v1, v2), calculateDistance(v2, v3), calculateDistance(v3, v1)].sort(
    (a, b) => a - b
  );
  return Math.abs(lengths[2] * lengths[2] - (lengths[0] * lengths[0] + lengths[1] * lengths[1])) < Number.EPSILON;
}

export function isRightAngledTriangleDiff(vertices: number[][]) {
  const [v1, v2, v3] = vertices;
  const lengths = [calculateDistance(v1, v2), calculateDistance(v2, v3), calculateDistance(v3, v1)].sort(
    (a, b) => a - b
  );
  return Math.abs(lengths[2] * lengths[2] - (lengths[0] * lengths[0] + lengths[1] * lengths[1]));
}

export function findLegsLength(vertices: number[][]): number[] {
  const [v1, v2, v3] = vertices;
  const lengths = [calculateDistance(v1, v2), calculateDistance(v2, v3), calculateDistance(v3, v1)].sort(
    (a, b) => a - b
  );
  // return lengths.slice(0, 2).map(length => Math.round(length * 10) / 10);
  return lengths.map((length) => Math.round(length * 10) / 10);
}

export function calculateAngles(vertices: number[][]): number[] {
  const [v1, v2, v3] = vertices;
  const lengths = [calculateDistance(v1, v2), calculateDistance(v2, v3), calculateDistance(v3, v1)];
  const [a, b, c] = lengths.sort((aa, bb) => aa - bb); // a and b are the two shortest sides, c is the longest side (hypotenuse)

  const alpha = Math.acos((b * b + c * c - a * a) / (2 * b * c));
  const beta = Math.acos((a * a + c * c - b * b) / (2 * a * c));
  const gamma = Math.acos((a * a + b * b - c * c) / (2 * a * b));

  return [alpha, beta, gamma].map((angle) => Math.round(((angle * 180) / Math.PI) * 10) / 10);
}

export function calculateMidpoint(point1: number[], point2: number[]): number[] {
  return [(point1[0] + point2[0]) / 2, (point1[1] + point2[1]) / 2, (point1[2] + point2[2]) / 2];
}

export function perpdendicularFoorOfTheLongestSide(
  oppositeVertex: number[],
  vertexA: number[],
  vertexB: number[]
): number[] {
  const BA = [vertexB[0] - vertexA[0], vertexB[1] - vertexA[1], vertexB[2] - vertexA[2]];
  const PA = [oppositeVertex[0] - vertexA[0], oppositeVertex[1] - vertexA[1], oppositeVertex[2] - vertexA[2]];

  const dotProduct = (vec1: number[], vec2: number[]) => vec1[0] * vec2[0] + vec1[1] * vec2[1] + vec1[2] * vec2[2];
  const squareDistance = (vec: number[]) => dotProduct(vec, vec);

  const t = dotProduct(PA, BA) / squareDistance(BA);

  return [vertexA[0] + t * BA[0], vertexA[1] + t * BA[1], vertexA[2] + t * BA[2]];
}

export function rotateYMatrix(angleInDegrees: number) {
  const angleInRadians = (angleInDegrees * Math.PI) / 180;
  const cos = Math.cos(angleInRadians);
  const sin = Math.sin(angleInRadians);

  return [
    [cos, 0, sin],
    [0, 1, 0],
    [-sin, 0, cos],
  ];
}

export function rotateXMatrix(angleInDegrees: number) {
  const angleInRadians = (angleInDegrees * Math.PI) / 180;
  const cos = Math.cos(angleInRadians);
  const sin = Math.sin(angleInRadians);

  return [
    [1, 0, 0],
    [0, cos, -sin],
    [0, sin, cos],
  ];
}

export function rotateZMatrix(angleInDegrees: number) {
  const angleInRadians = (angleInDegrees * Math.PI) / 180;
  const cos = Math.cos(angleInRadians);
  const sin = Math.sin(angleInRadians);

  return [
    [cos, -sin, 0],
    [sin, cos, 0],
    [0, 0, 1],
  ];
}

export function multiplyMatrixAndPoint(matrix: number[][], point: number[]) {
  const [x, y, z] = point;

  return [
    x * matrix[0][0] + y * matrix[1][0] + z * matrix[2][0],
    x * matrix[0][1] + y * matrix[1][1] + z * matrix[2][1],
    x * matrix[0][2] + y * matrix[1][2] + z * matrix[2][2],
  ];
}

export function applyMatrix4(vertex3: number[], matrix4: number[]) {
  const x = vertex3[0];
  const y = vertex3[1];
  const z = vertex3[2];
  const w = 1 / (matrix4[3] * x + matrix4[7] * y + matrix4[11] * z + matrix4[15]);

  return [
    (matrix4[0] * x + matrix4[4] * y + matrix4[8] * z + matrix4[12]) * w,
    (matrix4[1] * x + matrix4[5] * y + matrix4[9] * z + matrix4[13]) * w,
    (matrix4[2] * x + matrix4[6] * y + matrix4[10] * z + matrix4[14]) * w,
  ];
}
