import type { AssetConfig, UnitsConfig } from '@g360/vt-types';
import type { DecodedPng } from 'fast-png';
import { decode } from 'fast-png';
import isMobileFn from 'is-mobile';
import { Mixin } from 'ts-mixer';
import urlJoin from 'url-join';

import Matrix from '../../common/Matrix';
import Utils from '../../common/Utils';
import type Renderer from '../../mixins/Renderer';
import type { Image, MeasurePointData, PointData, ProgramName } from '../../types/internal';
import Program from '../mixins/Program';
import MeasureCrosshairProgram from './MeasureCrosshairProgram';
import MeasureDebugProgram from './MeasureDebugProgram';
import MeasureLineProgram from './MeasureLineProgram';
import MeasureMissingDataProgram from './MeasureMissingDataProgram';
import MeasurePointProgram from './MeasurePointProgram';
import MeasureToolEventEmitter from './MeasureToolEventEmitter';
import ZoomScopeProgram from './ZoomScopeProgram';

type SerializableMeasurement = {
  startIdx: number;
  endIdx: number;
};

type Measurement = {
  /** Measurement start point */
  start: MeasurePointData;
  /** Measurement end point, if null, this is an active measurement and ends at cursor */
  end: MeasurePointData | null;
  /** The element that contains the measurement label as well as the remove button */
  labelContainer: HTMLDivElement;
  /** HTML element that displays the measurement length */
  label: HTMLLabelElement;
  /** The actual measurement value in mm */
  distance: number;
};

/** Holding down this key will stop snapping */
const OVERRIDE_SNAP_KEY = 'Control' as const;

// If the touch is held for this amount of time, it's considered a long press
const LONG_PRESS_TIME_MS = 500;
// If the touch moved less than this amount, it's considered a click
const TOUCH_MOVE_ERROR_MARGIN_PX = 5;
// Projection matrix far plane
const MAX_DIST = 65536;
/** Holds business logic and shared state for measure tool shader programs */
class MeasureToolProgram extends Mixin(Program, MeasureToolEventEmitter) {
  // todo: refactor this and use the actual image size
  static readonly MAX_EQUIRECT_WIDTH = 10000;
  static depthCache: Map<string, [Image, DecodedPng]> = new Map(); // path => image
  static normalsCache: Map<string, [Image, DecodedPng]> = new Map(); // path => image
  static edgeCache: Map<string, DecodedPng> = new Map(); // path => image
  // Listed as static to persist between measure mode toggles
  static snapRadius = 15;

  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  renderer: Renderer;
  name: ProgramName;

  orderIndex = 0;
  depthMapTextureObject: WebGLTexture | null = null;
  normalMapTextureObject: WebGLTexture | null = null;
  textureLoaded = false;

  yaw = 0;
  pitch = 0;
  fov = Utils.toRad(120);
  alpha = 1.0;
  yawOffset = 0;

  projectionMatrix: number[] = [];

  isHandheld = isMobileFn({ tablet: true });

  /** Contains all measure points, multiple measurements can share a point */
  measurePoints: MeasurePointData[] = [];
  /** Contains the starting and ending poitns of all measurements */
  measurements: Measurement[] = [];
  /** The measurements which are currently being moved */
  activeMeasurements: Measurement[] = [];
  /** Current cursor position data */
  cursorCoords: PointData | null = null;
  /** The point currently being hovered */
  hoveredPoint: MeasurePointData | null = null;

  zoomScopeProgram: ZoomScopeProgram;

  readonly pointRadius = 14;

  private unitsMode: UnitsConfig;
  private removeButtonUrl: string | null = null;
  private sessionMeasurementKey?: string;
  private ready = false;

  /** Remove label active status on mobile */
  private labelCleanupFns: (() => void)[] = [];

  private debugProgram: MeasureDebugProgram;
  private missingDataProgram: MeasureMissingDataProgram;
  private crosshairProgram: MeasureCrosshairProgram;
  private lineProgram: MeasureLineProgram;
  private pointProgram: MeasurePointProgram;

  private abortController = window.AbortController ? new AbortController() : null;
  private assetConfig: AssetConfig;

  /** User is holding down snap override key, dont snap to edge map */
  private snapDisabled = true;
  /** Used to track whether mouseup or mousemove was first event after mousedown */
  private mouseDownUsed = false;
  /** Used to track error margin for mousemove and mouseclick */
  private mouseDownCoords: [number, number] | null = null;
  /** Used to track that the touch events all belong to the same finger */
  private touchStartId: number | null = null;
  /** Used to track whether user moved their finger less than allowed error margin (still considered a "click") */
  private touchStartCoords: [number, number] | null = null;
  /** Lock camera when dragging a point */
  private _draggingPoint = false;
  /** Where the point was when the user started dragging it */
  private dragStartPoint: MeasurePointData | null = null;
  /** Timer that gets triggered on touch hold */
  private longPressTimer: NodeJS.Timeout | null = null;

  private depthMapData: DecodedPng | null = null;
  private normalMapData: DecodedPng | null = null;
  private edgeMapData: DecodedPng | null = null;
  private positionCache: Map<string, PointData> = new Map();

  private lastRenderWasMoved = false;

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    renderer: Renderer,
    initialUnits: UnitsConfig,
    assetConfig: AssetConfig,
    name?: ProgramName
  ) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.unitsMode = initialUnits;
    this.assetConfig = assetConfig;
    this.name = name ?? 'MeasureToolProgram';

    this.debugProgram = new MeasureDebugProgram(webGLContext, canvas, this);
    this.missingDataProgram = new MeasureMissingDataProgram(webGLContext, canvas, this);
    this.lineProgram = new MeasureLineProgram(webGLContext, canvas, this);
    this.crosshairProgram = new MeasureCrosshairProgram(webGLContext, canvas, assetConfig, this);
    this.pointProgram = new MeasurePointProgram(webGLContext, canvas, assetConfig, this);
    this.zoomScopeProgram = new ZoomScopeProgram(webGLContext, canvas, renderer, this);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleTouchMove = this.handleTouchMove.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);

    this.commitMeasurement = this.commitMeasurement.bind(this);
    this.drawToFramebufferTexture = this.drawToFramebufferTexture.bind(this);

    this.initializeRemoveButtonUrl();
  }

  private get draggingPoint(): boolean {
    return this._draggingPoint;
  }

  private set draggingPoint(dragging: boolean) {
    if (dragging) {
      this.emit('camera.lock');
    } else if (this._draggingPoint) {
      this.emit('camera.unlock');
    }

    this._draggingPoint = dragging;
  }

  async init(): Promise<void> {
    window.addEventListener('keydown', this.handleKeyDown);
    window.addEventListener('keyup', this.handleKeyUp);

    window.addEventListener('mousemove', this.handleMouseMove);
    this.canvas.addEventListener('mousedown', this.handleMouseDown);
    this.canvas.addEventListener('mouseup', this.handleMouseUp);

    window.addEventListener('touchmove', this.handleTouchMove);
    this.canvas.addEventListener('touchstart', this.handleTouchStart);
    this.canvas.addEventListener('touchend', this.handleTouchEnd);
    this.canvas.addEventListener('touchcancel', this.handleTouchEnd);

    this.zoomScopeProgram.subscribe('scope_position.set', (payload) => {
      if (this.measurements.length === 0) return;

      const { x: screenX, y: screenY, radius } = payload;

      const x = (screenX * 0.5 + 0.5) * this.canvas.clientWidth;
      const y = (1 - (screenY * 0.5 + 0.5)) * this.canvas.clientHeight;

      for (let i = 0; i < this.measurements.length; i += 1) {
        const labelContainer = this.measurements[i].labelContainer;

        const distanceToLabelCenter = Utils.euclidianDistanceVec2(
          labelContainer.offsetLeft,
          labelContainer.offsetTop,
          x,
          y
        );

        if (distanceToLabelCenter <= radius && this.cursorCoords) {
          labelContainer.style.visibility = 'hidden';
        } else {
          labelContainer.style.visibility = 'visible';
        }
      }
    });

    this.updateProjectionMatrix();

    this.debugProgram.init();
    this.missingDataProgram.init();
    this.lineProgram.init();
    await this.pointProgram.init();
    await this.crosshairProgram.init();
    this.zoomScopeProgram.init();

    this.ready = true;
  }

  setUnits(mode: UnitsConfig): void {
    this.unitsMode = mode;
    this.measurements.forEach((m) => this.commitMeasurement(m));
  }

  setZoomScopeProgram(zoomScopeProgram: ZoomScopeProgram): void {
    this.zoomScopeProgram = zoomScopeProgram;
  }

  /** Camera has changed, therefor update the projection matrix */
  updateProjectionMatrix(): void {
    const size = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };
    const perspectiveMatrix = Matrix.perspective(this.fov, size, 0.1, MAX_DIST);

    let localRotationMx = Matrix.identityM3();
    localRotationMx = Matrix.rotateX(localRotationMx, -this.pitch);
    localRotationMx = Matrix.rotateY(localRotationMx, this.yaw + this.yawOffset + Utils.toRad(90));
    localRotationMx = Matrix.m3toM4(localRotationMx);

    this.projectionMatrix = Matrix.transposeM4(Matrix.rotatePerspective(perspectiveMatrix, localRotationMx));
  }

  async setDepthMap(depthMapFile: File): Promise<void> {
    const depthTexture = this.createTexture();
    let depthImg: Image;

    // @ts-expect-error this can be undefined
    if (window.createImageBitmap) {
      try {
        depthImg = await createImageBitmap(depthMapFile);
      } catch (e) {
        console.error(e);
        depthImg = await Utils.createImageElement(depthMapFile);
      }
    } else {
      depthImg = await Utils.createImageElement(depthMapFile);
    }

    if (!depthImg) throw new Error(`Failed to set image depth map: ${depthMapFile.name}`);

    this.gl.bindTexture(this.gl.TEXTURE_2D, depthTexture);
    // Don't interpolate this texture, use the nearest pixel instead
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGB, this.gl.RGB, this.gl.UNSIGNED_BYTE, depthImg);

    this.gl.deleteTexture(this.depthMapTextureObject);
    this.depthMapTextureObject = depthTexture;

    const buffer = await depthMapFile.arrayBuffer();
    const decoded = decode(buffer);
    this.depthMapData = decoded;
    this.getUnusableDepthMapPerc(decoded);
    // Clear the position cache, since the depth map has changed
    this.positionCache.clear();

    this.emit('render');
  }

  async setNormalMap(normalMapFile: File): Promise<void> {
    const normalTexture = this.createTexture();
    let normalImg: Image;

    // @ts-expect-error this can be undefined
    if (window.createImageBitmap) {
      try {
        normalImg = await createImageBitmap(normalMapFile);
      } catch (e) {
        console.error(e);
        normalImg = await Utils.createImageElement(normalMapFile);
      }
    } else {
      normalImg = await Utils.createImageElement(normalMapFile);
    }

    if (!normalImg) throw new Error(`Failed to set image normal map: ${normalMapFile.name}`);

    this.gl.bindTexture(this.gl.TEXTURE_2D, normalTexture);
    // Don't interpolate this texture, use the nearest pixel instead
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGB, this.gl.RGB, this.gl.UNSIGNED_BYTE, normalImg);

    this.gl.deleteTexture(this.normalMapTextureObject);
    this.normalMapTextureObject = normalTexture;

    const buffer = await normalMapFile.arrayBuffer();
    const decoded = decode(buffer);
    this.normalMapData = decoded;

    this.emit('render');
  }

  async setEdgeMap(edgeMap: File): Promise<void> {
    const buffer = await edgeMap.arrayBuffer();
    const decoded = decode(buffer);
    this.edgeMapData = decoded;
  }

  /** Get the depth value of the depth map at certain texture coordinates */
  getDepthmapData(textureX: number, textureY: number): number | null {
    if (!this.depthMapData) return null;
    const x = Math.round(textureX * this.depthMapData.width);
    const y = Math.round(textureY * this.depthMapData.height);
    const index = (y * this.depthMapData.width + x) * 3;

    const r = this.depthMapData.data[index];
    const g = this.depthMapData.data[index + 1];
    const b = this.depthMapData.data[index + 2];

    // Decode the depth value from 24bit RGB
    // eslint-disable-next-line no-bitwise
    return (r << 16) + (g << 8) + b;
  }

  getNormalMapData(textureX: number, textureY: number): number[] | null {
    if (!this.normalMapData) return null;
    const x = Math.round(textureX * this.normalMapData.width);
    const y = Math.round(textureY * this.normalMapData.height);
    const index = (y * this.normalMapData.width + x) * 3;

    const normX = this.normalMapData.data[index] / 255;
    const normY = this.normalMapData.data[index + 1] / 255;
    const normZ = this.normalMapData.data[index + 2] / 255;

    const normalized = Utils.normalizeVec3([normX - 0.5, normY - 0.5, normZ - 0.5]);

    let rot = Matrix.identityM3();
    rot = Matrix.rotateZ(rot, -this.yawOffset - Utils.toRad(90));

    const normA = Matrix.multiplyMatrix3AndVec3(rot, [normalized[0], normalized[1], -normalized[2]]);

    return [normA[0], -normA[2], normA[1]];
  }

  /** Get the luma of the edge map at certain texture coordinates */
  getEdgeMapData(textureX: number, textureY: number): number | null {
    if (!this.edgeMapData) return null;
    const x = Math.round(textureX * this.edgeMapData.width);
    const y = Math.round(textureY * this.edgeMapData.height);
    return this.edgeMapData.data[y * this.edgeMapData.width + x];
  }

  async loadScene(sceneKey: string): Promise<void> {
    const maxTextureSize = this.gl.getParameter(this.gl.MAX_TEXTURE_SIZE);
    let options: ImageBitmapOptions = {};
    if (maxTextureSize < MeasureToolProgram.MAX_EQUIRECT_WIDTH) {
      options = {
        resizeWidth: maxTextureSize,
        resizeHeight: maxTextureSize / 2,
        resizeQuality: 'high',
      };
    }

    this.textureLoaded = false;

    this.gl.deleteTexture(this.depthMapTextureObject);
    this.gl.deleteTexture(this.normalMapTextureObject);

    this.sessionMeasurementKey = `measurements-${sceneKey}`;

    const depthMapPath = `3d/${sceneKey}/${sceneKey}-depthmap.png`;
    const normalMapPath = `3d/${sceneKey}/${sceneKey}-normalmap.png`;
    const edgeMapPath = `3d/${sceneKey}/${sceneKey}-edgemap.png`;

    await this.updateTextures(depthMapPath, normalMapPath, edgeMapPath, options);

    // Load new values for measurements
    const setDefaultMeasurements = () => {
      this.measurePoints = [];
      this.measurements = [];
    };
    try {
      const storedMeasurements = sessionStorage.getItem(this.sessionMeasurementKey);
      if (storedMeasurements) {
        const {
          measurements,
          measurePoints,
        }: { measurements: SerializableMeasurement[]; measurePoints: MeasurePointData[] } =
          JSON.parse(storedMeasurements);
        if (measurements && measurePoints) {
          this.measurePoints = measurePoints;
          measurements.forEach((m) => {
            this.addNewMeasurement(measurePoints[m.startIdx]);
            this.finishActiveMeasurements(measurePoints[m.endIdx]);
          });
        } else setDefaultMeasurements();
      } else setDefaultMeasurements();
    } catch (e) {
      console.error('Failed to load measurements from session storage', e);
      try {
        sessionStorage.removeItem(this.sessionMeasurementKey);
      } catch (err) {
        console.error('Failed to remove measurements from session storage', err);
      }
      setDefaultMeasurements();
    }

    this.textureLoaded = true;
    this.positionCache.clear();
    this.emit('render');
  }

  destroy(): void {
    this.gl.deleteTexture(this.depthMapTextureObject);
    this.gl.deleteTexture(this.normalMapTextureObject);

    window.removeEventListener('keydown', this.handleKeyDown);
    window.removeEventListener('keyup', this.handleKeyUp);

    window.removeEventListener('mousemove', this.handleMouseMove);
    this.canvas.removeEventListener('mousedown', this.handleMouseDown);
    this.canvas.removeEventListener('mouseup', this.handleMouseUp);

    window.removeEventListener('touchmove', this.handleTouchMove);
    this.canvas.removeEventListener('touchstart', this.handleTouchStart);
    this.canvas.removeEventListener('touchend', this.handleTouchEnd);
    this.canvas.removeEventListener('touchcancel', this.handleTouchEnd);

    this.missingDataProgram.destroy();
    this.debugProgram.destroy();
    this.lineProgram.destroy();
    this.crosshairProgram.destroy();
    this.pointProgram.destroy();
    this.zoomScopeProgram.destroy();

    this.abortPending();
    this.destroyEventEmitter();
    this.destroyProgram();

    if (this.sessionMeasurementKey) {
      // Map all measurements to their indices in the measurePoints array
      const serializableMeasurements: SerializableMeasurement[] = this.measurements
        .filter((m) => m.end !== null)
        .map((m) => {
          const startIdx = this.measurePoints.indexOf(m.start);
          const endIdx = this.measurePoints.indexOf(m.end!);
          return { startIdx, endIdx };
        })
        .filter((m) => m.startIdx !== -1 && m.endIdx !== -1);

      const serializableMeasurePoints = this.measurePoints
        .filter((_, idx) => serializableMeasurements.some((m) => m.startIdx === idx || m.endIdx === idx))
        .map((p) => ({ ...p, snapPosition: null }));

      try {
        sessionStorage.setItem(
          this.sessionMeasurementKey,
          JSON.stringify({
            measurements: serializableMeasurements,
            measurePoints: serializableMeasurePoints,
          })
        );
      } catch (e) {
        console.error('Failed to save measurements to session storage', e);
      }
    }

    this.measurements.forEach((m) => m.labelContainer.remove());

    if (this.removeButtonUrl) URL.revokeObjectURL(this.removeButtonUrl);
  }

  abortPending(): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = new AbortController();
    }

    this.zoomScopeProgram.abortPending();
  }

  render(): void {
    if (!this.gl || !this.ready || !this.textureLoaded || !this.zoomScopeProgram) return;

    const { renderer } = this;

    if (renderer.isInTransition) return;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    renderer.drawToFramebufferTexture(renderer.fbTexture2!, this.drawToFramebufferTexture);

    this.zoomScopeProgram.render();
  }

  private draw(moved: boolean): void {
    this.sortMeasurements();

    if (moved) {
      this.repositionMeasurePoints();
      this.positionCache.clear();
      // The scene moved, so the rendered crosshair is no longer where it should be, if the cursor hasn't moved
      if (this.cursorCoords) this.updateCrosshairPosition(this.cursorCoords.clientX, this.cursorCoords.clientY);
    }
    this.lastRenderWasMoved = moved;

    // Clear the canvas before drawing
    this.gl.clearColor(0, 0, 0, 0);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    this.missingDataProgram.draw();
    this.debugProgram.draw();
    this.crosshairProgram.draw();
    this.lineProgram.draw();
    this.pointProgram.draw();
  }

  private drawToFramebufferTexture(): void {
    this.draw(this.renderer.nextDrawMoved);
  }

  /**
   * Sorts the measurements array by the average distance of the start and end points to
   * the camera. If the end point is null, it's sorted by the distance of the start point.
   * This is done to make sure that the closest measurements are drawn on top of the others.
   * Workaround for depth testing not working correctly here for some reason.
   */
  private sortMeasurements(): void {
    this.measurements.sort((a, b) => {
      const aEndDst =
        a.end?.snapPosition?.distance ??
        a.end?.distance ??
        this.cursorCoords?.snapPosition?.distance ??
        this.cursorCoords?.distance ??
        0;

      const bEndDst =
        b.end?.snapPosition?.distance ??
        b.end?.distance ??
        this.cursorCoords?.snapPosition?.distance ??
        this.cursorCoords?.distance ??
        0;

      return b.start.distance + bEndDst - (a.start.distance + aEndDst);
    });
  }

  /** Loads depth, normals and edgemap data into appropriate locations, given the URLs */
  private async updateTextures(
    depthMapPath: string,
    normalMapPath: string,
    edgeMapPath: string,
    options?: ImageBitmapOptions
  ): Promise<void> {
    let depthImg: Image | null = null;
    let normalImg: Image | null = null;
    const cacheKey = `${depthMapPath}-${normalMapPath}-${edgeMapPath}`;

    async function imgElFallback(imgData: ImageData | Blob) {
      console.warn('ImageBitmap not supported, falling back to Image element');
      if (imgData instanceof Blob) return Utils.createImageElement(imgData);

      const canv = document.createElement('canvas');
      canv.width = imgData.width;
      canv.height = imgData.height;
      const ctx = canv.getContext('2d');
      if (!ctx) throw new Error('Failed to get context');
      ctx.putImageData(imgData, 0, 0);
      const blob = await new Promise<Blob | null>((resolve) => canv.toBlob(resolve));
      canv.remove();
      if (!blob) throw new Error('Failed to get blob');
      return Utils.createImageElement(blob);
    }

    if (
      !MeasureToolProgram.depthCache.has(cacheKey) ||
      !MeasureToolProgram.normalsCache.has(cacheKey) ||
      !MeasureToolProgram.edgeCache.has(cacheKey)
    ) {
      const res = await Utils.fetchSignedMeasureImgs(
        depthMapPath,
        normalMapPath,
        edgeMapPath,
        this.assetConfig,
        this.abortController
      );
      this.depthMapData = res?.depthDecoded ?? null;
      if (res?.depthDecoded) this.getUnusableDepthMapPerc(res.depthDecoded);
      this.normalMapData = res?.normalsDecoded ?? null;
      this.edgeMapData = res?.edgeDecoded ?? null;

      if (res) {
        // @ts-expect-error this can be undefined
        if (window.createImageBitmap) {
          try {
            depthImg = await createImageBitmap(res.depthBlob, options);
            normalImg = await createImageBitmap(res.normalsBlob, options);
          } catch (error) {
            console.error(error);
            depthImg = await imgElFallback(res.depthBlob);
            normalImg = await imgElFallback(res.normalsBlob);
          }
        } else {
          depthImg = await imgElFallback(res.depthBlob);
          normalImg = await imgElFallback(res.normalsBlob);
        }

        if (this.depthMapData && this.normalMapData && this.edgeMapData) {
          MeasureToolProgram.depthCache.set(cacheKey, [depthImg, this.depthMapData]);
          MeasureToolProgram.normalsCache.set(cacheKey, [normalImg, this.normalMapData]);
          MeasureToolProgram.edgeCache.set(cacheKey, this.edgeMapData);
        }
      }
    } else {
      const cachedDepth = MeasureToolProgram.depthCache.get(cacheKey)!;
      const cachedNormals = MeasureToolProgram.normalsCache.get(cacheKey)!;
      const cachedEdge = MeasureToolProgram.edgeCache.get(cacheKey)!;

      depthImg = cachedDepth[0];
      this.depthMapData = cachedDepth[1];

      normalImg = cachedNormals[0];
      this.normalMapData = cachedNormals[1];

      this.edgeMapData = cachedEdge;
    }

    if (!depthImg || !normalImg) {
      throw new Error(`Failed to set image texture: ${depthMapPath} ${normalMapPath}`);
    }

    const depthTexture = this.createTexture();

    this.gl.bindTexture(this.gl.TEXTURE_2D, depthTexture);
    // Don't interpolate this texture, use the nearest pixel instead
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGB, this.gl.RGB, this.gl.UNSIGNED_BYTE, depthImg);

    this.gl.deleteTexture(this.depthMapTextureObject);
    this.depthMapTextureObject = depthTexture;

    const normalMapTexture = this.createTexture();
    this.gl.bindTexture(this.gl.TEXTURE_2D, normalMapTexture);
    // Don't interpolate this texture, use the nearest pixel instead
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.NEAREST);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.NEAREST);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGB, this.gl.RGB, this.gl.UNSIGNED_BYTE, normalImg);

    this.gl.deleteTexture(this.normalMapTextureObject);
    this.normalMapTextureObject = normalMapTexture;
  }

  /** Fetches the remove button svg and stores it as ObjectURL */
  private async initializeRemoveButtonUrl(): Promise<void> {
    const svgUrl = urlJoin(this.assetConfig.assetPath, 'measure/remove.svg');
    const res = await fetch(svgUrl);
    const text = await res.blob();
    const url = URL.createObjectURL(text);
    this.removeButtonUrl = url;
  }

  /** While points are being moved, disable label cursor events to not interfere */
  private changeLabelStatus(status: 'active' | 'inactive'): void {
    this.measurements.forEach((m) => {
      const c = m.labelContainer;
      if (status === 'active') {
        c.style.pointerEvents = 'auto';
      } else {
        c.style.pointerEvents = 'none';
      }
    });
  }

  private addNewMeasurement(startPoint: MeasurePointData): void {
    // A point has been placed so we can dismiss the tutorial GIF
    this.emit('tutorial.dismiss');

    const label = document.createElement('label');
    label.style.color = 'black';
    label.style.fontWeight = 'bold';
    label.style.fontSize = '12px';
    label.style.fontFamily = '"Gilroy", sans-serif';
    label.style.borderRadius = '15px';
    label.style.backgroundColor = 'white';
    label.style.padding = '2px 8px';
    label.style.boxShadow = '0 0 5px 0 rgba(0, 0, 0, 0.5)';
    label.style.border = '1px solid white';
    label.style.whiteSpace = 'nowrap';

    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.top = '0';
    container.style.left = '0';
    container.style.userSelect = 'none';
    container.style.display = 'none';
    container.style.justifyContent = 'center';

    const measurement: Measurement = { start: startPoint, end: null, label, labelContainer: container, distance: 0 };
    this.measurements.push(measurement);
    this.activeMeasurements.push(measurement);
    this.changeLabelStatus('inactive');
    this.commitMeasurement(measurement);

    let removeButton: HTMLSpanElement | null = null;

    const showRemoveButton = () => {
      this.cursorCoords = null;
      measurement.start.active = true;
      if (measurement.end) measurement.end.active = true;
      // Render to update measure point colors
      this.emit('render');

      label.style.backgroundColor = '#1A222E';
      label.style.color = 'white';

      if (removeButton !== null) return;

      const button = document.createElement('span');
      const labelRect = label.getBoundingClientRect();
      button.style.marginLeft = '2px';
      button.style.borderRadius = '50%';
      button.style.backgroundColor = '#1A222E';
      button.style.height = `${labelRect.height}px`;
      button.style.width = `${labelRect.height}px`;
      button.style.boxShadow = '0 0 5px 0 rgba(0, 0, 0, 0.5)';
      button.style.border = '1px solid white';
      button.style.cursor = 'pointer';
      button.style.display = 'flex';
      button.style.alignItems = 'center';
      button.style.justifyContent = 'center';

      const svg = document.createElement('img');
      if (this.removeButtonUrl === null) {
        console.warn('Remove button url not initialized');
        return;
      }

      svg.src = this.removeButtonUrl;
      svg.style.width = '8px';
      svg.style.height = '8px';

      // Set z-index to 1 to make sure this is above other labels
      container.style.zIndex = '1';

      button.appendChild(svg);

      if (!this.isHandheld) {
        button.addEventListener('mouseenter', () => {
          button.style.backgroundColor = '#485363';
        });
        button.addEventListener('mouseleave', () => {
          button.style.backgroundColor = '#1A222E';
        });
      }

      button.addEventListener('click', () => {
        this.removeMeasurement(measurement);
        this.emit('render');
      });

      removeButton = button;
      container.appendChild(button);
    };

    const hideRemoveButton = () => {
      measurement.start.active = false;
      if (measurement.end) measurement.end.active = false;
      // Render to update measure point colors
      this.emit('render');

      label.style.backgroundColor = 'white';
      label.style.color = 'black';

      container.style.zIndex = 'auto';

      if (removeButton !== null) {
        removeButton.remove();
        removeButton = null;
      }
    };

    if (this.isHandheld) {
      label.addEventListener('touchstart', () => {
        if (this.touchStartId !== null) return;
        if (removeButton === null) {
          // Remove all other remove buttons
          this.labelCleanupFns.forEach((fn) => fn());
          this.labelCleanupFns = [];
          showRemoveButton();
          // Add the hide function to the list of cleanup functions
          // to hide this remove button when another label is clicked
          this.labelCleanupFns.push(hideRemoveButton);
        } else {
          hideRemoveButton();
          this.labelCleanupFns = this.labelCleanupFns.filter((fn) => fn !== hideRemoveButton);
        }
      });
    } else {
      label.addEventListener('mouseenter', showRemoveButton);
      container.addEventListener('mouseleave', hideRemoveButton);
    }

    container.appendChild(label);
    this.canvas.parentNode?.insertBefore(container, this.canvas.nextSibling);
  }

  private removeMeasurement(measurement: Measurement): void {
    const index = this.measurements.indexOf(measurement);
    if (index === -1) {
      console.warn('Tried to remove non-existent measurement');
      return;
    }

    const activeIdx = this.activeMeasurements.indexOf(measurement);
    if (activeIdx !== -1) this.activeMeasurements.splice(activeIdx, 1);

    measurement.labelContainer.remove();
    const start = measurement.start;
    start.active = false;
    const end = measurement.end;
    if (end) end.active = false;
    // Remove it from the measurement array
    this.measurements.splice(index, 1);

    // If point doesn't appear in any other measurements, remove it from the set of measure points
    if (this.measurements.every((m) => m.start !== measurement.start && m.end !== measurement.start)) {
      const idx = this.measurePoints.indexOf(measurement.start);
      if (idx !== -1) this.measurePoints.splice(idx, 1);
    }

    if (measurement.end && this.measurements.every((m) => m.start !== measurement.end && m.end !== measurement.end)) {
      const idx = this.measurePoints.indexOf(measurement.end);
      if (idx !== -1) this.measurePoints.splice(idx, 1);
    }
  }

  private startDraggingPoint(point: MeasurePointData): void {
    const measurementsThatHavePoint = this.measurements.filter((m) => m.start === point || m.end === point);
    for (let i = 0; i < measurementsThatHavePoint.length; i += 1) {
      const m = measurementsThatHavePoint[i];
      if (this.activeMeasurements.includes(m)) {
        // If measurement was already active (dragging single point)
        m.end = null;
      } else {
        // If measurement wasn't active, add it to the list
        this.activeMeasurements.push(m);
        this.changeLabelStatus('inactive');
      }

      if (m.start === point && m.end) {
        m.start = m.end;
        m.end = null;
      } else if (m.end === point) {
        m.end = null;
      } else {
        // Only one point in the measurement to begin with, so remove it
        this.removeMeasurement(m);
      }
    }
    // Remove from measure points, since it's being changed
    const idx = this.measurePoints.indexOf(point);
    if (idx !== -1) this.measurePoints.splice(idx, 1);

    this.draggingPoint = true;

    // Save where the drag started in case we have to restore it later
    this.dragStartPoint = point;
    this.dragStartPoint.active = false;
  }

  private cancelActiveMeasurements(): void {
    if (this.activeMeasurements.length === 0) return;
    // Cancel the active measurements
    // Since removing measurements also removes them from the active array, we make a copy
    [...this.activeMeasurements].forEach((m) => this.removeMeasurement(m));
    this.activeMeasurements = [];
    this.draggingPoint = false;
    this.dragStartPoint = null;
    this.emit('render');
  }

  private finishActiveMeasurements(endPoint: MeasurePointData): void {
    this.changeLabelStatus('active');
    // Make a copy since we might remove measurements from the active array along the way
    const activeCopy = [...this.activeMeasurements];
    for (let i = 0; i < activeCopy.length; i += 1) {
      if (
        // Get all measurements that already exist between these points and remove them
        this.measurements.some((_, j) => {
          // Don't include active measurements in this query
          if (activeCopy.includes(this.measurements[j])) return false;

          const sameStart = this.measurements[j].start === activeCopy[i].start;
          const sameEnd = this.measurements[j].end === activeCopy[i].start;
          const startsAtHovered = this.measurements[j].start === endPoint;
          const endsAtHovered = this.measurements[j].end === endPoint;

          return (sameStart && endsAtHovered) || (sameEnd && startsAtHovered);
        })
      ) {
        // This would be adding a duplicate measurement, so we remove it
        this.removeMeasurement(activeCopy[i]);
        // eslint-disable-next-line no-continue
        continue;
      }
      if (this.activeMeasurements[i].start === endPoint) {
        // Trying to end a measurement at the same point it started
        // so we remove it
        this.removeMeasurement(activeCopy[i]);
        // eslint-disable-next-line no-continue
        continue;
      }
      activeCopy[i].end = endPoint;
      this.commitMeasurement(activeCopy[i]);
    }
    this.activeMeasurements = [];
    this.repositionMeasurePoints();
    this.emit('render');
  }

  private handleKeyDown(e: KeyboardEvent): void {
    // Only handle escape key
    if (e.key === 'Escape') {
      // If there's no measurement, don't do anything
      this.cancelActiveMeasurements();
    } else if (e.key === OVERRIDE_SNAP_KEY) {
      this.snapDisabled = false;
    }
  }

  private handleKeyUp(e: KeyboardEvent): void {
    if (e.key === OVERRIDE_SNAP_KEY) {
      this.snapDisabled = true;
    }
  }

  private handleMouseDown(e: MouseEvent): void {
    if (this.isHandheld) return;
    if (e.target !== this.canvas) return;
    this.mouseDownUsed = false;
    this.mouseDownCoords = [e.clientX, e.clientY];
  }

  private handleMouseUp(): void {
    if (this.isHandheld) return;

    const mouseDownWasUsed = this.mouseDownUsed;
    this.mouseDownUsed = true;
    this.mouseDownCoords = null;

    const wasDraggingPoint = this.draggingPoint;
    this.draggingPoint = false;

    if (!wasDraggingPoint && mouseDownWasUsed) return;
    const releasePoint = this.cursorCoords?.snapPosition ?? this.cursorCoords ?? this.hoveredPoint;
    if (!releasePoint) return;
    if (this.hoveredPoint && !mouseDownWasUsed) {
      if (this.activeMeasurements.length > 0) this.finishActiveMeasurements(this.hoveredPoint);
      // If mouse up gets triggered before mousemove we "continue the measurement" by starting a new one from the hovered point
      else this.addNewMeasurement(this.hoveredPoint);

      return;
    }

    // If the click wasn't moved, we continue measurement from this point
    this.onPressRelease(releasePoint);
  }

  private handleMouseMove(e: MouseEvent): void {
    if (this.isHandheld) return;

    if (e.target !== this.canvas) {
      if (this.cursorCoords !== null) {
        // if mouse was inside the canvas but moved out, we hide the labels
        this.activeMeasurements.forEach((m) => {
          const container = m.labelContainer;
          container.style.display = 'none';
        });
        this.cursorCoords = null;
        this.emit('render');
      }
      return;
    }
    if (this.cursorCoords === null) {
      // If mouse was outside the canvas but moved back in, we un-hide the labels
      this.activeMeasurements.forEach((m) => {
        const container = m.labelContainer;
        container.style.display = 'flex';
      });
    }

    if (
      !this.mouseDownUsed &&
      this.mouseDownCoords &&
      Utils.euclidianDistanceVec2(this.mouseDownCoords[0], this.mouseDownCoords[1], e.clientX, e.clientY) >
        TOUCH_MOVE_ERROR_MARGIN_PX
    ) {
      // If this is the first move event since the last click and we are hovering a point,
      // We start dragging the point
      for (let i = 0; i < this.measurements.length; i += 1) {
        const measurement = this.measurements[i];

        const hoveredStart = measurement.start === this.hoveredPoint;
        const hoveredEnd = measurement.end === this.hoveredPoint && this.hoveredPoint !== null;
        // eslint-disable-next-line no-continue
        if (!hoveredStart && !hoveredEnd) continue;

        // From here we can assume user is dragging a point
        if (hoveredStart) {
          this.startDraggingPoint(measurement.start);
        } else if (hoveredEnd && measurement.end) {
          this.startDraggingPoint(measurement.end);
        }

        break;
      }
      this.mouseDownUsed = true;
    }

    // adjust for canvas offsdets from window
    const canvasRect = this.canvas.getBoundingClientRect();
    this.updateCrosshairPosition(e.clientX - canvasRect.left, e.clientY - canvasRect.top);
  }

  private handleTouchStart(e: TouchEvent): void {
    const hadLabelsToDeselect = this.labelCleanupFns.length > 0;
    if (hadLabelsToDeselect) {
      // User touched canvas, so we hide any label remove buttons
      this.labelCleanupFns.forEach((fn) => fn());
      this.labelCleanupFns = [];
    }

    if (this.touchStartId === null) this.touchStartId = e.touches[0].identifier;
    else return;
    e.preventDefault();
    if (this.longPressTimer !== null) clearTimeout(this.longPressTimer);

    const clientX = e.touches[0].clientX;
    const clientY = e.touches[0].clientY;

    const touchedPoint = this.measurePoints.find(
      (point) =>
        Utils.euclidianDistanceVec2(
          (point.screenX * 0.5 + 0.5) * this.canvas.clientWidth,
          (-point.screenY * 0.5 + 0.5) * this.canvas.clientHeight,
          clientX,
          clientY
        ) < this.pointRadius
    );

    if (hadLabelsToDeselect && !touchedPoint) {
      // If user deselected the label, we dont want to start a new measurement
      // but if user tried to select another point, dont interrupt
      return;
    }

    this.touchStartCoords = [clientX, clientY];

    for (let i = 0; i < this.measurements.length; i += 1) {
      const measurement = this.measurements[i];
      // Point has been selected, and user starts dragging it
      const moveP1 = measurement.start.active && touchedPoint === measurement.start;
      // Point has been selected, and user starts dragging it
      const moveP2 = measurement.end?.active && touchedPoint === measurement.end;

      if (moveP1) {
        this.startDraggingPoint(measurement.start);
        this.updateCrosshairPosition(clientX, clientY);
        break;
      }
      if (moveP2 && measurement.end) {
        this.startDraggingPoint(measurement.end);
        this.updateCrosshairPosition(clientX, clientY);
        break;
      }
    }

    this.longPressTimer = setTimeout(() => {
      for (let i = 0; i < this.measurements.length; i += 1) {
        const measurement = this.measurements[i];

        // eslint-disable-next-line no-continue
        if (touchedPoint !== measurement.start && touchedPoint !== measurement.end) continue;

        if (touchedPoint === measurement.start) {
          // Long press on p1 lets user move p1
          this.startDraggingPoint(measurement.start);
          break;
        }
        if (touchedPoint === measurement.end) {
          // logn press on p2 lets user move p2
          this.startDraggingPoint(measurement.end);
          break;
        }
      }
      if (!touchedPoint) {
        // The long press wasn't on a point
        const activePoint = this.measurePoints.find((p) => p.active);
        if (activePoint) {
          // There was an active point, so we "continue the measurement" by starting a new one from the active point
          activePoint.active = false;
          this.addNewMeasurement(activePoint);
        }
      }

      this.draggingPoint = true;
      // Clear timer after it executes
      this.longPressTimer = null;

      this.updateCrosshairPosition(clientX, clientY);
    }, LONG_PRESS_TIME_MS);
  }

  private handleTouchMove(e: TouchEvent): void {
    if (e.touches[0].identifier !== this.touchStartId) return;
    if (!this.touchStartCoords) return;

    const clientX = e.touches[0].clientX;
    const clientY = e.touches[0].clientY;
    if (
      this.longPressTimer &&
      (Math.abs(this.touchStartCoords[0] - clientX) > TOUCH_MOVE_ERROR_MARGIN_PX ||
        Math.abs(this.touchStartCoords[1] - clientY) > TOUCH_MOVE_ERROR_MARGIN_PX)
    ) {
      // if user moves their finger more than allowed distance, cancel long press
      clearTimeout(this.longPressTimer);
    }

    if (!this.draggingPoint) return;
    this.updateCrosshairPosition(clientX, clientY);
  }

  private handleTouchEnd(e: TouchEvent): void {
    if (e.changedTouches[0].identifier !== this.touchStartId) return;
    this.touchStartId = null;

    if (this.longPressTimer) clearTimeout(this.longPressTimer);

    const wasDraggingPoint = this.draggingPoint;
    if (wasDraggingPoint) {
      this.draggingPoint = false;
    }

    if (!this.touchStartCoords) return;

    const clientX = e.changedTouches[0].clientX;
    const clientY = e.changedTouches[0].clientY;

    if (wasDraggingPoint) {
      // Point was being dragged and is now released
      const releasePoint = this.cursorCoords?.snapPosition ?? this.cursorCoords ?? this.hoveredPoint;
      if (!releasePoint) return;
      this.onPressRelease(releasePoint);
      return;
    }

    const activePoint = this.measurePoints.find((p) => p.active);

    if (activePoint) {
      // If point is active, we "continue the measurent" by starting a new one from the active point
      activePoint.active = false;
      this.addNewMeasurement(activePoint);
    }

    if (
      Math.abs(this.touchStartCoords[0] - clientX) > TOUCH_MOVE_ERROR_MARGIN_PX ||
      Math.abs(this.touchStartCoords[1] - clientY) > TOUCH_MOVE_ERROR_MARGIN_PX
    ) {
      // Point wasn't being dragged but the user moved their finger
      return;
    }

    // Point wasnt being dragged but the user didn't move their finger, essentially a click
    const touchedPoint = this.measurePoints.find(
      (point) =>
        Utils.euclidianDistanceVec2(
          (point.screenX * 0.5 + 0.5) * this.canvas.clientWidth,
          (-point.screenY * 0.5 + 0.5) * this.canvas.clientHeight,
          clientX,
          clientY
        ) < this.pointRadius
    );

    const touchedPointStartsSomeActive = this.activeMeasurements.some((m) => m.start === touchedPoint);
    if (touchedPoint && !touchedPointStartsSomeActive && this.activeMeasurements.length > 0) {
      this.finishActiveMeasurements(touchedPoint);
      return;
    }

    for (let i = 0; i < this.measurePoints.length; i += 1) {
      const point = this.measurePoints[i];
      if (touchedPoint === point) {
        point.active = true;
        this.emit('render');
        return;
      }
    }
    // User didn't try to select an existing point, so we add a new point
    const releasePoint = this.mouseToPointData(clientX, clientY, this.canvas.clientWidth, this.canvas.clientHeight);
    if (!releasePoint) return;
    this.onPressRelease(releasePoint);
  }

  private onPressRelease(releasePoint: PointData): void {
    this.changeLabelStatus('active');

    this.cursorCoords = null;
    const prevDragStartPoint = this.dragStartPoint;
    this.dragStartPoint = null;

    if (releasePoint.distance === 0) {
      // Tried to end measurement on a missing data point

      if (prevDragStartPoint) {
        // If we have a point to snap back to, we do that
        this.measurePoints.push(prevDragStartPoint);
        this.finishActiveMeasurements(prevDragStartPoint);
      } else if (this.isHandheld) {
        // on mobile when clicking a missing data point, we want
        // to not show the measure line until user performs more actions
        const startPoint = this.activeMeasurements.find((m) => m.end === null)?.start;
        this.cancelActiveMeasurements();
        if (startPoint) {
          this.measurePoints.push(startPoint);
          this.addNewMeasurement(startPoint);
          this.repositionMeasurePoints();
        }
        this.emit('render');
      } else {
        this.changeLabelStatus('inactive');
      }

      this.emit('toast.missing_data');
      return;
    }

    let newMeasurePoint: MeasurePointData;
    if (this.hoveredPoint === releasePoint) {
      if (this.activeMeasurements.length === 0) {
        // If no active measurements, user just selected a point to become active
        this.hoveredPoint.active = true;
        return;
      }

      const samePointMeasurements = this.activeMeasurements.filter((m) => m.start === this.hoveredPoint);
      if (samePointMeasurements.length > 0) {
        for (let i = 0; i < samePointMeasurements.length; i += 1) {
          this.removeMeasurement(samePointMeasurements[i]);
        }
      }

      // Check if some of the point belongs to one of the active measurements
      const measurementsThatHavePoint = this.measurements.filter(
        (m) => !this.activeMeasurements.includes(m) && (m.start === this.hoveredPoint || m.end === this.hoveredPoint)
      );
      // If it does, that means user is essentially duplicating a measurement, so we don't want that
      const duplicates = this.activeMeasurements.filter((activeM) =>
        measurementsThatHavePoint.some((m) => activeM.start === m.start || activeM.start === m.end)
      );

      if (duplicates.length > 0) {
        for (let i = 0; i < duplicates.length; i += 1) {
          this.removeMeasurement(duplicates[i]);
        }
      }

      newMeasurePoint = this.hoveredPoint;
    } else {
      newMeasurePoint = { ...releasePoint, active: false };
      this.measurePoints.push(newMeasurePoint);
    }

    if (this.activeMeasurements.length === 0 && !this.hoveredPoint) {
      this.addNewMeasurement(newMeasurePoint);
    } else {
      for (let i = 0; i < this.activeMeasurements.length; i += 1) {
        const measurement = this.activeMeasurements[i];
        measurement.end = newMeasurePoint;

        this.commitMeasurement(measurement);
      }
      this.activeMeasurements = [];
      if (this.isHandheld && this.hoveredPoint) {
        this.hoveredPoint.active = false;
        this.hoveredPoint = null;
      }
    }

    this.repositionMeasurePoints();
    this.emit('render');
  }

  private updateCrosshairPosition(clientX: number, clientY: number): void {
    const width = this.canvas.clientWidth;
    const height = this.canvas.clientHeight;

    const hoveredPoint = this.measurePoints.find(
      (point) =>
        Utils.euclidianDistanceVec2(
          (point.screenX * 0.5 + 0.5) * width,
          (-point.screenY * 0.5 + 0.5) * height,
          clientX,
          clientY
        ) < this.pointRadius
    );

    if (!hoveredPoint) {
      // User stopped dragging point, so we can re-enable normal panning
      if (this.hoveredPoint !== null && !this.draggingPoint) this.emit('camera.unlock');
      this.hoveredPoint = null;
      // If no point is hovered the measurement should not snap to any points for combining measurements
      for (let i = 0; i < this.activeMeasurements.length; i += 1) {
        this.activeMeasurements[i].end = null;
      }
    } else {
      // User could start dragging point, so we disable normal panning to not interfere
      if (this.hoveredPoint === null) this.emit('camera.lock');
      this.hoveredPoint = hoveredPoint;
    }

    for (let i = 0; i < this.measurePoints.length; i += 1) {
      const point = this.measurePoints[i];
      if (hoveredPoint === point) {
        point.active = true;

        for (let j = 0; j < this.activeMeasurements.length; j += 1) {
          this.activeMeasurements[j].end = point;
        }
        break;
      }
      point.active = false;
    }

    if (hoveredPoint) {
      this.repositionMeasurePoints();
      this.activeMeasurements.forEach(this.commitMeasurement);

      this.cursorCoords = null;
      this.canvas.style.cursor = 'pointer';

      this.emit('render');

      return;
    }

    this.canvas.style.cursor = 'default';

    const cursorData = this.mouseToPointData(clientX, clientY, width, height);
    if (cursorData === null) {
      if (this.cursorCoords !== null) {
        this.cursorCoords = null;
        this.emit('render');
      }
      return;
    }

    const snapPoint = this.getSnapPoint(clientX, clientY, width, height);
    if (snapPoint) {
      cursorData.snapPosition = snapPoint;
    }
    this.cursorCoords = cursorData;

    if (this.activeMeasurements.length > 0 && this.cursorCoords) {
      this.repositionMeasurePoints();
      this.activeMeasurements.forEach(this.commitMeasurement);
    }

    this.emit('render');
  }

  /** Returns the point to snap to from the edgemap, if one is found */
  private getSnapPoint(clientX: number, clientY: number, canvasWidth: number, canvasHeight: number): PointData | null {
    if (this.snapDisabled) return null;
    if (!this.edgeMapData) return null;
    let newCoords: PointData | null = null;

    let currentMinX = clientX - 1;
    let currentMaxX = clientX + 1;
    let currentMinY = clientY - 1;
    let currentMaxY = clientY + 1;
    let direction: 'up' | 'down' | 'left' | 'right' | 'center' = 'center';

    let currX = clientX;
    let currY = clientY;

    while (
      !this.lastRenderWasMoved &&
      MeasureToolProgram.snapRadius > 1 &&
      (currentMaxX < clientX + MeasureToolProgram.snapRadius || currentMaxY < clientY + MeasureToolProgram.snapRadius)
    ) {
      if (direction === 'down') currY += 1;
      else if (direction === 'left') currX -= 1;
      else if (direction === 'up') currY -= 1;
      else if (direction === 'right') currX += 1;

      if (direction === 'down' && currY === currentMaxY) direction = 'left';
      else if (direction === 'left' && currX === currentMinX) direction = 'up';
      else if (direction === 'up' && currY === currentMinY) {
        direction = 'right';
        currentMaxY += 1;
        currentMinY -= 1;
      } else if (direction === 'right' && currX === currentMaxX) {
        direction = 'down';
        currentMaxX += 1;
        currentMinX -= 1;
      } else if (direction === 'center') direction = 'down';

      // If out of bounds, skip
      // eslint-disable-next-line no-continue
      if (currX < 0 || currX > canvasWidth || currY < 0 || currY > canvasHeight) continue;

      // If not in circle, skip
      // eslint-disable-next-line no-continue
      if (Utils.euclidianDistanceVec2(currX, currY, clientX, clientY) > MeasureToolProgram.snapRadius) continue;

      const currentPointData = this.mouseToPointData(currX, currY, canvasWidth, canvasHeight);
      // eslint-disable-next-line no-continue
      if (!currentPointData) continue;

      const edgeMapX = currentPointData.textureX;
      const edgeMapY = currentPointData.textureY;

      const edgeMapPixel = this.getEdgeMapData(edgeMapX, edgeMapY);
      if (edgeMapPixel !== 255) {
        // eslint-disable-next-line no-continue
        continue;
      }
      newCoords = currentPointData;
      break;
    }

    return newCoords;
  }

  /** Updates measure point and label positions */
  private repositionMeasurePoints(): void {
    const { clientWidth, clientHeight } = this.canvas;

    for (let i = 0; i < this.measurePoints.length; i += 1) {
      const point = this.measurePoints[i];
      const cart = Utils.projectCartesianToScreen(
        point.cartX,
        point.cartY,
        point.cartZ,
        this.yaw + this.yawOffset,
        this.pitch,
        this.projectionMatrix
      );

      if (cart) {
        point.screenX = cart.x;
        point.screenY = cart.y;
      } else {
        // Element is not visible on screen, set it off screen
        point.screenX = -2;
        point.screenY = -2;
      }
    }

    // Reposition each measurement label
    for (let i = 0; i < this.measurements.length; i += 1) {
      const m = this.measurements[i];

      const point1 = this.measurements[i].start;
      const point2 = this.measurements[i].end ?? this.cursorCoords?.snapPosition ?? this.cursorCoords;

      // eslint-disable-next-line no-continue
      if (!point1 || !point2) continue;

      const middle = Utils.getCappedLineProjection(
        point1,
        point2,
        this.yaw + this.yawOffset,
        this.pitch,
        this.projectionMatrix
      );
      if (!middle) {
        m.labelContainer.style.display = 'none';
        // eslint-disable-next-line no-continue
        continue;
      }
      m.labelContainer.style.display = 'flex';

      const point1Left = (middle.screen1X / 2 + 0.5) * clientWidth;
      const point1Top = (-middle.screen1Y / 2 + 0.5) * clientHeight;
      const point2Left = (middle.screen2X / 2 + 0.5) * clientWidth;
      const point2Top = (-middle.screen2Y / 2 + 0.5) * clientHeight;

      const spaceBetweenPointsX = Math.abs(point1Left - point2Left);
      const spaceBetweenPointsY = Math.abs(point1Top - point2Top);

      let topOffset = 0;
      const labelSize = m.label.getBoundingClientRect();
      if (spaceBetweenPointsX < labelSize.width && spaceBetweenPointsY < labelSize.height) {
        topOffset -= labelSize.height;
      }

      const left = (point1Left + point2Left) / 2;
      const top = (point1Top + point2Top) / 2 + topOffset;

      m.labelContainer.style.transform = `translate(-${labelSize.width / 2}px, -${labelSize.height / 2}px)`;

      m.labelContainer.style.left = `${left}px`;
      m.labelContainer.style.top = `${top}px`;
    }
  }

  private getUnusableDepthMapPerc(decoded: DecodedPng): void {
    // TODO: remove, this is for depth map evaluation
    let unusablePx = 0;
    for (let i = 0; i < decoded.data.length; i += 3) {
      if (
        this.depthMapData?.data[i] === 0 &&
        this.depthMapData?.data[i + 1] === 0 &&
        this.depthMapData?.data[i + 2] === 0
      ) {
        unusablePx += 1;
      }
    }
    const unusableAmount = unusablePx / (decoded.data.length / 3);
    console.log(`Depth map contains ${(unusableAmount * 100).toFixed(2)}% unusable pixels`);
  }

  /** Update the distance shown on the measurement label */
  private commitMeasurement(measurement: Measurement): void {
    const m = measurement;
    const mmDistance = Utils.euclidianDistanceVec3(
      m.start.cartX,
      m.start.cartY,
      m.start.cartZ,
      m.end?.cartX ?? this.cursorCoords?.snapPosition?.cartX ?? this.cursorCoords?.cartX ?? 0,
      m.end?.cartY ?? this.cursorCoords?.snapPosition?.cartY ?? this.cursorCoords?.cartY ?? 0,
      m.end?.cartZ ?? this.cursorCoords?.snapPosition?.cartZ ?? this.cursorCoords?.cartZ ?? 0
    );
    m.distance = mmDistance;

    if (this.unitsMode === 'imperial') {
      m.label.innerHTML = Utils.mmToImperialString(mmDistance);
    } else if (this.unitsMode === 'metric') {
      m.label.innerHTML = Utils.mmToMetricString(mmDistance);
    } else {
      console.warn('Invalid units mode');
    }
  }

  /** Converts a clicked position on the canvas to detailed data about the point */
  private mouseToPointData(
    clientX: number,
    clientY: number,
    canvasWidth: number,
    canvasHeight: number
  ): PointData | null {
    const cacheKey = `${clientX};${clientY}`;

    const cachedVal = this.positionCache.get(cacheKey);
    if (cachedVal !== undefined) {
      return cachedVal;
    }
    const aspectRatio = canvasWidth / canvasHeight;

    const vfov = 2 * Math.atan(Math.tan(this.fov * 0.5) / aspectRatio);
    const focal = 1 / Math.tan(vfov * 0.5);

    // get pixel coordinate of the image the cursor is hoveringin 0-1 space
    const textCoordX = clientX / canvasWidth;
    const textCoordY = 1 - clientY / canvasHeight;
    const stretchedX = (textCoordX - 0.5) * 2;
    const stretchedY = (textCoordY - 0.5) * 2;
    const x = stretchedX * aspectRatio;
    const y = stretchedY;
    const sinpitch = Math.sin(this.pitch);
    const cospitch = Math.cos(this.pitch);

    const a = focal * cospitch - y * sinpitch;
    const root = Math.sqrt(x ** 2 + a ** 2);
    // Horizontal angle
    const yawVal = (this.yaw % (Math.PI * 2)) + this.yawOffset;

    let theta = Math.atan(x / a) + yawVal;
    theta = ((theta + Math.PI) % (Math.PI * 2)) - Math.PI;
    // if theta greater than PI in either direction, add full rotation in opposite direction
    if (Math.abs(theta) > Math.PI) theta += Math.PI * 2 * (theta / -Math.abs(theta));

    // Vertical angle
    const phi = Math.atan((y * cospitch + focal * sinpitch) / root);
    const coordX = theta / Math.PI;
    const coordY = phi / (Math.PI / 2);

    const textureX = (coordX + 1) / 2;
    const textureY = (-coordY + 1) / 2;

    const depth = this.getDepthmapData(textureX, textureY);
    if (depth === null) return null;

    let usedDepth = depth;

    if (usedDepth === 0) {
      // If depth is 0, the pixel in the depth map does not have usable data
      // In this case we need to set some sort of depth to somewhat accurately display the crosshair
      if (this.activeMeasurements.length > 0) {
        // If we have active measurements, take the average distance of each active measurement starting point
        const activeStartingPoints = this.activeMeasurements.map((m) => m.start);
        usedDepth = activeStartingPoints.reduce((acc, p) => {
          const d = Utils.euclidianDistanceVec3(p.cartX, p.cartY, p.cartZ, 0, 0, 0);
          return acc + d / activeStartingPoints.length;
        }, 0);
      } else {
        // Otherwise, set a default far away distance
        usedDepth = 65336;
      }
    }

    const cartesian = Utils.sphericalToCartesian(theta, phi, usedDepth);

    const screenCoords = Utils.projectCartesianToScreen(
      cartesian[0],
      cartesian[1],
      cartesian[2],
      this.yaw + this.yawOffset,
      this.pitch,
      this.projectionMatrix
    );
    if (!screenCoords) return null;

    const position: PointData = {
      clientX,
      clientY,
      textureX,
      textureY,
      screenX: screenCoords.x,
      screenY: screenCoords.y,
      snapPosition: null,
      cartX: cartesian[0],
      cartY: cartesian[1],
      cartZ: cartesian[2],
      distance: depth,
    };

    this.positionCache.set(cacheKey, position);

    return position;
  }
}

export default MeasureToolProgram;
