import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { linearScale } from '@g360/vt-utils';
import { Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from '../../contexts/useLocalizationContext';
import useServices from '../../contexts/useServices';
import useReactive from '../../hooks/useReactive';
import WelcomeButton from './components/WelcomeButton';
import WelcomeImage from './components/WelcomeImage';
import useRect from './hooks/useRect';
const RiseTextAnim = ({ children }) => (_jsx("div", Object.assign({ className: "miw-h-fit min-w-fit overflow-hidden whitespace-nowrap" }, { children: _jsx(Transition.Child, Object.assign({ enter: "transition duration-1000 ease-out", enterFrom: "translate-y-full", enterTo: "translate-y-0", leave: "transition duration-1000 ease-out", leaveFrom: "translate-y-0", leaveTo: "translate-y-full" }, { children: _jsx(_Fragment, { children: children }) })) })));
const FadeUpAnim = ({ children }) => (_jsx(Transition.Child, Object.assign({ as: Fragment, enter: "transition-all duration-1000 ease-out", enterFrom: "opacity-0 translate-y-full", enterTo: "opacity-100 translate-y-0", leave: "transition-all duration-1000 ease-out", leaveFrom: "opacity-100 translate-y-0", leaveTo: "opacity-0 translate-y-full" }, { children: children })));
/**
 * @note There is a 'Can't perform a React state update on an unmounted component.' error if you close the
 * welcome screen before initial animation is finished. Seems like a bug in the Transition component when
 * using Transition.Child. It shouldn't affect anything, so let's just ignore it for now.
 * */
const WelcomeScreen = () => {
    const { layoutService, tourConfigService } = useServices();
    const { isWelcomeScreenOpen } = useReactive(layoutService, ['isWelcomeScreenOpen']);
    const { tourWelcomeScreen, defaultWelcomeImage, welcomeImage, welcomeImageEnabled, features } = useReactive(tourConfigService, ['tourWelcomeScreen', 'defaultWelcomeImage', 'welcomeImage', 'welcomeImageEnabled', 'features']);
    const t = useTranslation();
    const [isOpen, setIsOpen] = useState(isWelcomeScreenOpen);
    const timerRef = useRef(undefined);
    const opacityTimeoutRef = useRef(undefined);
    const wasTouch = useRef(false);
    const [contentRect, contentRef] = useRect();
    const [textRect, textRef] = useRect();
    const [logoRect, logoRef, setLogoRect] = useRect();
    const [isHovering, setIsHovering] = useState(false);
    const [scale, setScale] = useState({
        opacity: 0,
        text: { font: 0, leading: 0, width: 0 },
        logo: { width: 0, height: 0, justify: 'justify-end', position: 'object-right', margin: 0 },
        button: { width: 0, height: 0, margin: 0 },
        icon: { size: 0 },
        content: { padding: 0 },
        flexLayout: 'flex-col-reverse',
    });
    const handleClose = () => {
        layoutService.isWelcomeScreenClosing = true;
        setIsOpen(false);
    };
    const handleMouseOver = () => {
        // Check if event is touch event
        if (wasTouch.current) {
            wasTouch.current = false;
            return;
        }
        setIsHovering(true);
    };
    const handleMouseLeave = () => {
        setIsHovering(false);
    };
    const handleTouchStart = () => {
        wasTouch.current = true;
    };
    const calcScale = () => {
        var _a;
        if (!contentRect || !textRect)
            return;
        if (opacityTimeoutRef.current)
            clearTimeout(opacityTimeoutRef.current);
        const contMinWidth = 320;
        const contMaxWidth = 1216;
        const contCurrentWidth = contentRect.width;
        const baseFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize || '16');
        const logoWidth = linearScale(contCurrentWidth, [contMinWidth, contMaxWidth], [8.438, 18.75]);
        const gapMargin = linearScale(contentRect.width, [320, 1216], [1, 2]);
        // A bit hacky but this makes sure the logo snaps to correct side when flex-wrap happens
        const isLogoOnTop = logoRect && textRect.top > logoRect.top;
        const isOverflowing = contentRect.width > textRect.width + ((_a = logoRect === null || logoRect === void 0 ? void 0 : logoRect.width) !== null && _a !== void 0 ? _a : 0);
        const buttonSize = linearScale(contentRect.width, [320, 1216], [3, 4]);
        const fontAndLeadingScreenRange = contentRect.width >= 320 ? [320, 1216] : [280, 320];
        const fontRemRange = contentRect.width >= 320 ? [2.5, 5] : [2, 2.5];
        const leadingRemRange = contentRect.width >= 320 ? [3.5, 6.5] : [3, 3.5];
        setScale((prevState) => (Object.assign(Object.assign({}, prevState), { text: {
                font: linearScale(contentRect.width, fontAndLeadingScreenRange, fontRemRange),
                leading: linearScale(contentRect.width, fontAndLeadingScreenRange, leadingRemRange),
                width: textRect.width / baseFontSize,
            }, logo: {
                width: logoWidth,
                height: linearScale(contentRect.width, [320, 1216], [3, 10]),
                justify: isLogoOnTop ? 'justify-start' : 'justify-end',
                position: isLogoOnTop ? 'object-left' : 'object-right',
                margin: isLogoOnTop ? gapMargin : 0,
            }, button: {
                width: buttonSize,
                height: buttonSize,
                margin: gapMargin,
            }, icon: {
                size: linearScale(contentRect.width, [320, 1216], [1.25, 1.75]),
            }, content: {
                padding: linearScale(contentRect.width, [320, 1216], [2, 10]),
            }, flexLayout: isOverflowing ? 'flex-row' : 'flex-col-reverse' })));
        // Make the content visible after a small timeout after the last useEffect trigger to avoid flickering
        opacityTimeoutRef.current = setTimeout(() => {
            setScale((prev) => (Object.assign(Object.assign({}, prev), { opacity: 1 })));
            // Not sure if 200ms is enough, maybe we need to increase this for slower devices
        }, 200);
    };
    useEffect(() => {
        if (!tourWelcomeScreen || !features.welcomeScreen)
            layoutService.isWelcomeScreenOpen = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [features]);
    useEffect(() => {
        if (!tourWelcomeScreen || !features.welcomeScreen) {
            layoutService.isWelcomeScreenOpen = false;
            return;
        }
        layoutService.isWelcomeScreenOpen = true;
        layoutService.startCanvasAnimation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourWelcomeScreen]);
    // Need this side effect in case welcome screen state is handled by a host app or dev panel
    useEffect(() => {
        if (!isWelcomeScreenOpen) {
            handleClose();
            return;
        }
        if (isOpen !== isWelcomeScreenOpen) {
            layoutService.isNavigationOpen = false;
            layoutService.isWelcomeScreenClosing = false;
            setIsOpen(isWelcomeScreenOpen);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWelcomeScreenOpen]);
    useEffect(() => {
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
            layoutService.isWelcomeScreenOpen = isOpen;
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);
    useEffect(() => {
        calcScale();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentRect, textRect, logoRect]);
    useEffect(() => {
        if (!welcomeImageEnabled)
            return;
        if (welcomeImage)
            setLogoRect();
        calcScale();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [welcomeImage, welcomeImageEnabled]);
    const welcomeImageSrc = welcomeImage !== null && welcomeImage !== void 0 ? welcomeImage : defaultWelcomeImage;
    const showWelcomeImage = welcomeImageSrc === defaultWelcomeImage ? features.giraffe360Branding : features.clientBranding;
    return (_jsx(Transition
    // This key change triggers the appear animation by remounting the component, but at this point the size
    // calculations should be done so we don't get a flicker
    , Object.assign({ appear: true, show: isOpen && features.welcomeScreen, onClick: handleClose, className: "pointer-events-auto absolute left-0 top-0 flex h-full w-full items-center justify-center bg-theme-primary bg-opacity-60", style: { padding: `0 ${scale.content.padding}rem` }, leave: "transition-opacity duration-1000", leaveFrom: "opacity-100", leaveTo: "opacity-0" }, { children: _jsxs("div", Object.assign({ ref: contentRef, style: { opacity: scale.opacity }, className: "box-border flex w-full max-w-[76rem] select-none flex-col" }, { children: [_jsxs("div", Object.assign({ className: "box-border flex flex-row flex-wrap-reverse" }, { children: [_jsxs("div", Object.assign({ className: "box-border font-primary font-bold text-theme-contrast", ref: textRef, onMouseEnter: handleMouseOver, onMouseLeave: handleMouseLeave, onTouchStart: handleTouchStart, style: {
                                fontSize: `${scale.text.font}rem`,
                                lineHeight: `${scale.text.leading}rem`,
                                paddingRight: `${scale.button.margin}rem`,
                            } }, { children: [_jsx(RiseTextAnim, { children: t('branding.welcome-title-line1') }), _jsx(RiseTextAnim, { children: t('branding.welcome-title-line2') })] })), welcomeImageEnabled && showWelcomeImage && (_jsx("div", Object.assign({ className: `box-border flex grow items-center ${scale.logo.justify}` }, { children: _jsx("div", Object.assign({ ref: logoRef, className: "relative box-border flex items-center", style: {
                                    width: `${scale.logo.width}rem`,
                                    height: `${scale.logo.height}rem`,
                                    marginBottom: `${scale.logo.margin}rem`,
                                } }, { children: _jsx(FadeUpAnim, { children: _jsx("div", Object.assign({ className: "absolute bottom-0 left-0 right-0 top-0" }, { children: _jsx(WelcomeImage, { className: scale.logo.position, src: welcomeImageSrc }) })) }) })) })))] })), _jsx(FadeUpAnim, { children: _jsx("div", Object.assign({ style: {
                            width: `${scale.text.width}rem`,
                        }, onMouseEnter: handleMouseOver, onMouseLeave: handleMouseLeave, onTouchStart: handleTouchStart }, { children: _jsx("div", Object.assign({ style: {
                                width: `${scale.button.width}rem`,
                                height: `${scale.button.height}rem`,
                                marginTop: `${scale.button.margin}rem`,
                            } }, { children: _jsx(WelcomeButton, { className: `transition-size ${isHovering ? 'w-[200%]' : 'w-full'}`, iconStyle: { width: `${scale.icon.size}rem`, height: `${scale.icon.size}rem` }, onClick: handleClose }) })) })) })] })) }), scale.opacity ? 'open' : 'closed'));
};
export default WelcomeScreen;
