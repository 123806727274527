import type { AutoPlaySceneFrames, TourConfig } from '@g360/vt-types';
import cloneDeep from 'lodash/cloneDeep';

/**
 * If the tour contains subScenes, make sure all sceneFrames point to the main scenes of the group.
 * Used in dashboard when main scene of sceneGroup has been changed,
 * but video.json contains the old sceneKeys (now subScenes).
 * @returns rerouted sceneFrames
 */
export function rerouteSubSceneFrames(sceneFrames: AutoPlaySceneFrames[], tourConfig: TourConfig) {
  if (!tourConfig.sceneGroups) return sceneFrames;

  const copiedFrames = cloneDeep(sceneFrames);

  for (let i = 0; i < copiedFrames.length; i += 1) {
    const sceneFrame = copiedFrames[i];

    const subGroupIdx = tourConfig.sceneGroups.findIndex((group) => {
      const idxInGroup = group.indexOf(sceneFrame.sceneKey);
      return idxInGroup !== -1 && idxInGroup !== 0;
    });
    // This sceneFrame targets the mainScene of a group or a scene
    // which isn't in a group so we skip it
    // eslint-disable-next-line no-continue
    if (subGroupIdx === -1) continue;

    // Replace the target of the sceneFrame with the mainScene of the group
    copiedFrames[i].sceneKey = tourConfig.sceneGroups[subGroupIdx][0];
  }

  return copiedFrames;
}
