import type { AssetConfig } from '@g360/vt-types';

import Matrix from '../../common/Matrix';
import { Utils } from '../../index';
import type { Image } from '../../types/internal';
import type Sphere from './Sphere';

// 1/6th of a complete sphere
class SphereFace {
  static readonly letters = ['f', 'r', 'b', 'l', 'u', 'd'];

  public readonly sphere: Sphere;
  public readonly panoKey: string;
  public readonly faceLetter: string; // sphere face id

  public depthTexture: WebGLTexture;
  public depthTextureSet = false; // texture always exists; the snapshot renderer sets this to true when the texture is populated
  public localCubemap?: HTMLImageElement; // only for equirect mode, visual texture, locally generated

  private readonly rotation = [0, 0, 0];
  private readonly position = [0, 0, 0];
  private readonly mainTexLocation: WebGLUniformLocation | null = null;
  private readonly depthTexLocation: WebGLUniformLocation | null = null;
  private readonly usingLocalCubemaps: boolean;
  private readonly assetConfig: AssetConfig;

  private picLoadStageMain = 0;
  private picLoadStageDepth = 0;
  private gl: WebGLRenderingContext;
  private visualTexture: WebGLTexture | null = null;
  private tileFullPath: string;
  private mainImage: Image | null = null;

  constructor(
    id: number,
    sphere: Sphere,
    panoKey: string,
    yawOffset: number,
    position: number[],
    tileFullPath: string,
    usingLocalCubemaps: boolean,
    assetConfig: AssetConfig
  ) {
    this.faceLetter = SphereFace.letters[id];
    this.sphere = sphere;

    this.gl = sphere.sphereProgram.gl;
    this.mainTexLocation = sphere.sphereProgram.mainTexLocation;
    this.depthTexLocation = sphere.sphereProgram.depthTexLocation;
    this.depthTexture = sphere.sphereProgram.createTexture() as WebGLTexture;

    this.panoKey = panoKey;
    this.position = position;

    this.tileFullPath = tileFullPath;
    this.usingLocalCubemaps = usingLocalCubemaps;
    this.assetConfig = assetConfig;

    if (this.faceLetter === 'f') {
      this.rotation = [Math.PI / 2, yawOffset + 0 / 2, Math.PI];
    }
    if (this.faceLetter === 'r') {
      this.rotation = [Math.PI / 2, yawOffset + Math.PI / 2, Math.PI];
    }
    if (this.faceLetter === 'b') {
      this.rotation = [Math.PI / 2, yawOffset + (2 * Math.PI) / 2, Math.PI];
    }
    if (this.faceLetter === 'l') {
      this.rotation = [Math.PI / 2, yawOffset + (3 * Math.PI) / 2, Math.PI];
    }
    if (this.faceLetter === 'u') {
      this.rotation = [Math.PI, 0, Math.PI - yawOffset];
    }
    if (this.faceLetter === 'd') {
      this.rotation = [0, 0, Math.PI + yawOffset];
    }
    // console.log(`Sphere face ${panoKey}/${this.faceLetter} created`);
  }

  public get canItBeDrawn(): boolean {
    return !!this.mainImage && this.depthTextureSet;
  }

  public get depthmapsLoaded(): boolean {
    return this.depthTextureSet;
  }

  // mild version gets called after every pano switch on all panos except for current one (to free up memory)
  // in mild version, we keep the depth texture, because it is not saved anywhere else
  destroy(mild = false): void {
    this.gl.deleteTexture(this.visualTexture);
    if (!mild) {
      this.gl.deleteTexture(this.depthTexture);
      this.depthTextureSet = false;
    }
    this.picLoadStageMain = 0;
    Utils.closeImage(this.mainImage);
    this.mainImage = null;
  }

  /**
   * Download images and upload to GPU
   */
  async loadImages() {
    // Download pano cubemap ..
    if (!this.usingLocalCubemaps) {
      try {
        const image = await Utils.fetchSignedImage(
          this.tileFullPath,
          this.assetConfig,
          this.sphere.sphereProgram.abortController
        );
        if (image) {
          this.mainImage = image;
        }
      } catch (error) {
        // ErrorLogger.captureMessage(`Sphere image download failed: ${error}`); // don't report error (may become spammy)
        // throw new Error('Sphere image fetch failed ');
      }
    } else if (this.localCubemap) {
      // .. or use locally generated cube map as visual texture. (in equirect mode)
      this.mainImage = this.localCubemap;
    }

    if (!this.mainImage) {
      // should not happen normally
      // don't report error (may become spammy)
      // fill missing image with grey and move on
      const imageData = new ImageData(new Uint8ClampedArray([194, 194, 194, 255]), 1, 1);
      this.mainImage = await createImageBitmap(imageData);
    }

    // upload to gpu
    this.gl.uniform1i(this.mainTexLocation, 1);
    this.visualTexture = this.sphere.sphereProgram.createTexture() as WebGLTexture;
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.visualTexture);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGB, this.gl.RGB, this.gl.UNSIGNED_BYTE, this.mainImage);

    if (this.depthTexture) {
      this.gl.uniform1i(this.depthTexLocation, 2);
      this.gl.bindTexture(this.gl.TEXTURE_2D, this.depthTexture);
    } // else {  silently move on without correct depth texture, should not happen normally, not reporting it }
  }

  draw() {
    this.gl.activeTexture(this.gl.TEXTURE1);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.visualTexture);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.depthTexture);

    // make the top and bottom sphere face a bit larger to reduce seams
    const s = this.faceLetter === 'u' || this.faceLetter === 'd' ? 1.005 : 1;
    const scaleMatrix = Matrix.scalingM4(
      this.sphere.sphereProgram.sphereScale * s,
      this.sphere.sphereProgram.sphereScale * s,
      -this.sphere.sphereProgram.sphereScale * s
    );
    this.gl.uniformMatrix4fv(this.sphere.sphereProgram.matrixScaleLocation, false, new Float32Array(scaleMatrix));

    let localRotationMatrix = Matrix.identityM3();
    localRotationMatrix = Matrix.rotateX(localRotationMatrix, this.rotation[0]);
    localRotationMatrix = Matrix.rotateY(localRotationMatrix, this.rotation[1]);
    localRotationMatrix = Matrix.rotateZ(localRotationMatrix, this.rotation[2]);
    localRotationMatrix = Matrix.m3toM4(localRotationMatrix);

    this.gl.uniformMatrix4fv(this.sphere.sphereProgram.matrixLocalRotationLocation, false, new Float32Array(localRotationMatrix)); // prettier-ignore
    const cameraPosMatrix = Matrix.translationM4(
      this.sphere.sphereProgram.cameraPosition[0] + this.position[0] - this.sphere.positionOffsetForTransition[0],
      this.sphere.sphereProgram.cameraPosition[1] + this.position[1] - this.sphere.positionOffsetForTransition[1],
      this.sphere.sphereProgram.cameraPosition[2]
    );
    this.gl.uniformMatrix4fv(this.sphere.sphereProgram.matrixCameraPosLocation, false, new Float32Array(cameraPosMatrix)); // prettier-ignore

    this.gl.drawElements(this.gl.TRIANGLES, this.sphere.sphereProgram.geometryIndices.length, this.gl.UNSIGNED_SHORT, 0); // prettier-ignore
  }
}

export default SphereFace;
