import { Mixin } from 'ts-mixer';

import Utils from '../../common/Utils';
import type Renderer from '../../mixins/Renderer';
import type { Image, ProgramName } from '../../types/internal';
import type MeasureToolProgram from '../MeasureToolProgram';
import WebGLProgram from '../mixins/Program';
import fragmentShader from './equirect.fs.glsl';
import vertexShader from './equirect.vs.glsl';
import EquirectEventEmitter from './EquirectEventEmitter';

interface EquirectProgram {
  setOptimalLevel?(): void;
  loadTiles?(): void;
}

class EquirectProgram extends Mixin(WebGLProgram, EquirectEventEmitter) {
  // todo: refactor this and use the actual image size
  static readonly MAX_EQUIRECT_WIDTH = 10000;
  static readonly cache: Map<string, Image> = new Map(); // path => image

  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  renderer: Renderer;
  name: ProgramName;

  orderIndex = 0;
  vertexBuffer: WebGLBuffer | null;
  vertexAttribute = 0;
  aspectRatioUniform: WebGLUniformLocation | null = null;
  alphaUniform: WebGLUniformLocation | null = null;
  yawUniform: WebGLUniformLocation | null = null;
  pitchUniform: WebGLUniformLocation | null = null;
  fovUniform: WebGLUniformLocation | null = null;

  primaryTextureObject: WebGLTexture | null = null;
  secondaryTextureObject: WebGLTexture | null = null;
  textureLoaded = false;
  primaryLayoutPath = '';
  secondaryLayoutPath = '';

  ready = false;
  _yaw = 0;
  _pitch = 0;
  _fov = Utils.toRad(120);
  alpha = 1.0;
  _yawOffset = 0;

  abortController = window.AbortController ? new AbortController() : null;
  forcePreview = false;
  isPreloadPano = false;

  vertices = new Float32Array([-1, 1, 1, 1, 1, -1, -1, 1, 1, -1, -1, -1]);

  measureToolProgram?: MeasureToolProgram;

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, renderer: Renderer, name?: ProgramName) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.name = name ?? 'PanoProgram';

    this.vertexBuffer = this.gl.createBuffer();
    this.primaryTextureObject = this.createTexture();

    this.drawToFramebufferTexture = this.drawToFramebufferTexture.bind(this);
  }

  get yaw(): number {
    return this._yaw;
  }

  set yaw(yaw: number) {
    this._yaw = yaw;
  }

  get fov(): number {
    return this._fov;
  }

  set fov(fov: number) {
    this._fov = fov;
  }

  get pitch(): number {
    return this._pitch;
  }

  set pitch(pitch: number) {
    this._pitch = pitch;
  }

  get yawOffset(): number {
    return this._yawOffset;
  }

  set yawOffset(yawOffset: number) {
    this._yawOffset = yawOffset;
  }

  /** Used for vt-video rendering (cleared before each transition) - for larger projects RAM usage is to high otherwise
   *  But wee need to keep the cache because the image is reused between equirect and transition rendering
   */
  static clearCache(): void {
    EquirectProgram.cache.clear();
  }

  init(): void {
    if (__USE_EQUIRECT__) {
      this.initShaders(vertexShader, fragmentShader);

      if (this.program) {
        this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');
        this.alphaUniform = this.gl.getUniformLocation(this.program, 'u_alpha');
        this.aspectRatioUniform = this.gl.getUniformLocation(this.program, 'u_aspectRatio');
        this.yawUniform = this.gl.getUniformLocation(this.program, 'u_yaw');
        this.pitchUniform = this.gl.getUniformLocation(this.program, 'u_pitch');
        this.fovUniform = this.gl.getUniformLocation(this.program, 'u_fov');

        this.vertexAttributes = [this.vertexAttribute];
      }

      this.ready = true;
    }
  }

  async reloadWithOptions(primaryOptions?: ImageBitmapOptions, secondaryOptions?: ImageBitmapOptions): Promise<void> {
    if (__USE_EQUIRECT__) {
      EquirectProgram.cache.clear();

      await this.preload(this.primaryLayoutPath, primaryOptions);
      this.loadSecondaryTexture(this.secondaryLayoutPath, secondaryOptions);
    }
  }

  async setTexture(imagePath: string, options?: ImageBitmapOptions): Promise<WebGLTexture | null> {
    let image;

    if (!EquirectProgram.cache.has(imagePath)) {
      image = await Utils.fetchImage(imagePath, this.abortController, options);

      if (image) {
        EquirectProgram.cache.set(imagePath, image);
      }
    } else {
      image = EquirectProgram.cache.get(imagePath);
    }

    if (!image) {
      console.error(`Failed to set image texture: ${imagePath}`);
      return this.createTexture();
    }

    const textureObject = this.createTexture();

    this.gl.activeTexture(this.gl.TEXTURE0);
    this.gl.bindTexture(this.gl.TEXTURE_2D, textureObject);
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGB, this.gl.RGB, this.gl.UNSIGNED_BYTE, image);

    return textureObject;
  }

  async loadSecondaryTexture(secondaryLayoutPath: string, options?: ImageBitmapOptions): Promise<void> {
    if (__USE_EQUIRECT__) {
      if (secondaryLayoutPath && this.secondaryLayoutPath !== secondaryLayoutPath) {
        this.secondaryTextureObject = await this.setTexture(secondaryLayoutPath, options);
        this.secondaryLayoutPath = secondaryLayoutPath;
        this.emit('render');
      }
    }
  }

  async preload(primaryLayoutPath: string, primaryOptions?: ImageBitmapOptions): Promise<void> {
    if (__USE_EQUIRECT__) {
      // NOTE: only if createImageBitmap is supported and used, options are passed to the Engine in
      // assetConfig.equirectAssets.bitMapOptions.(primary | secondary)
      // This allows to reduce image size to make texture loading to the GPU faster with the cost of lower quality
      const bitMapOptions = {
        primary: primaryOptions || {},
      };

      const maxTextureSize = this.gl.getParameter(this.gl.MAX_TEXTURE_SIZE);

      if (maxTextureSize < EquirectProgram.MAX_EQUIRECT_WIDTH) {
        const resizeOptions: ImageBitmapOptions = {
          resizeWidth: maxTextureSize,
          resizeHeight: maxTextureSize / 2,
          resizeQuality: 'high',
        };
        bitMapOptions.primary = { ...bitMapOptions.primary, ...resizeOptions };
      }

      this.primaryLayoutPath = primaryLayoutPath;
      this.textureLoaded = false;
      this.primaryTextureObject = await this.setTexture(primaryLayoutPath, bitMapOptions.primary);
      this.textureLoaded = true;
      this.emit('render');
    }
  }

  destroy(): void {
    if (__USE_EQUIRECT__) {
      this.gl.deleteTexture(this.primaryTextureObject);
      this.gl.deleteTexture(this.secondaryTextureObject);
      this.abortPending();
      this.destroyEventEmitter();
      this.destroyProgram();
    }
  }

  abortPending(): void {
    if (__USE_EQUIRECT__) {
      if (this.abortController) {
        this.abortController.abort();
      }
    }
  }

  render(): void {
    if (!__USE_EQUIRECT__) return;
    if (!this.gl || !this.ready) return;

    const { renderer } = this;
    if (renderer.renderMode !== 'pano') return;

    // Transition rendering
    if (renderer.isInTransition) {
      const isDoublePanoBlend = !renderer.transitionConnected || renderer.watermarkInterrupted;

      if (isDoublePanoBlend) {
        if (this.isPreloadPano) this.alpha = renderer.transitionProgress;
        this.draw(true);
        if (this.isPreloadPano) renderer.render();
      }

      return;
    }

    const { cameraMoved } = renderer;

    // Normal rendering with measure tool
    if (this.measureToolProgram) {
      renderer.nextDrawMoved = cameraMoved;

      if (cameraMoved) {
        renderer.shouldDrawAfterMove = true;

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.needsToDrawFb = false;
      } else if (renderer.needsToDrawFb) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.needsToDrawFb = false;
      } else if (renderer.shouldDrawAfterMove) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        renderer.drawToFramebufferTexture(renderer.fbTexture1!, this.drawToFramebufferTexture);
        renderer.shouldDrawAfterMove = false;
      }

      return;
    }

    // Normal rendering
    this.draw(cameraMoved);
  }

  draw(moved = false): void {
    if (__USE_EQUIRECT__) {
      if (!this.gl || !this.ready) return;

      this.loadShaders();
      this.enableVertexAttributes();

      if (!this.primaryTextureObject || !this.textureLoaded) return;

      this.gl.enable(this.gl.CULL_FACE);
      this.gl.cullFace(this.gl.FRONT);
      this.gl.activeTexture(this.gl.TEXTURE0);

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
      this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
      this.gl.enableVertexAttribArray(this.vertexAttribute);
      this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

      this.gl.uniform1f(this.aspectRatioUniform, this.gl.drawingBufferWidth / this.gl.drawingBufferHeight);
      this.gl.uniform1f(this.yawUniform, this.yaw + this.yawOffset);
      this.gl.uniform1f(this.pitchUniform, this.pitch);
      this.gl.uniform1f(this.fovUniform, this.fov);

      if (!moved && this.secondaryTextureObject) {
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.secondaryTextureObject);
      } else {
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.primaryTextureObject);
      }

      this.gl.uniform1f(this.alphaUniform, this.alpha);

      this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);

      this.gl.disable(this.gl.CULL_FACE);
      this.disableVertexAttributes();
    }
  }

  private drawToFramebufferTexture(): void {
    this.draw(this.renderer.nextDrawMoved);
  }
}

export default EquirectProgram;
