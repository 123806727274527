import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Listbox, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import useGlobalContext from '../../../contexts/useGlobalContext';
import { useTranslation } from '../../../contexts/useLocalizationContext';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import Scrollbars from './Scrollbars';
const OutsideSwitch = ({ listMode }) => {
    const { engine } = useGlobalContext();
    const { navigationService, layoutService } = useServices();
    const { structure } = navigationService;
    const { activeScene, transitionInProgress } = useReactive(navigationService, ['activeScene', 'transitionInProgress']);
    const t = useTranslation();
    const { isRemoteControlled } = useReactive(layoutService, ['isRemoteControlled']);
    const { analytics } = useGlobalContext();
    const scenesList = listMode ? structure.allScenes : structure.outsideScenes;
    if (!(scenesList === null || scenesList === void 0 ? void 0 : scenesList.length) || (scenesList === null || scenesList === void 0 ? void 0 : scenesList.length) === 1)
        return null;
    const activeItem = activeScene.outside ? activeScene : scenesList[0];
    const activeItemIndex = scenesList.findIndex((item) => item.sceneKey === activeItem.sceneKey);
    return (_jsxs(Listbox, Object.assign({ as: "div", disabled: isRemoteControlled || transitionInProgress, className: "relative h-10 w-full min-w-[9.5rem] max-w-[18.5rem]", value: activeItem.sceneKey, onChange: (nextSceneKey) => {
            engine.loadSceneKey(nextSceneKey);
            analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'OMENU', 'Outdoor', { scene_id: nextSceneKey });
        } }, { children: [_jsx(Listbox.Button, Object.assign({ as: "button", className: "btn-theme-primary h-10 w-full disabled:cursor-not-allowed disabled:hover:bg-opacity-theme-low", "data-testid": "outside-switch-btn" }, { children: _jsx("span", Object.assign({ className: "min-w-0 truncate px-1" }, { children: activeItem.name || t('layout.outdoor-n', { index: activeItemIndex + 1 }) })) })), _jsx("div", Object.assign({ className: "absolute bottom-full h-[20rem] w-full overflow-hidden pb-3" }, { children: _jsx(Transition, Object.assign({ as: Fragment, enter: "transition duration-400 ease-out", enterFrom: "translate-y-3 opacity-0", enterTo: "translate-y-0 opacity-100", leave: "transition duration-400 ease-out", leaveFrom: "translate-y-0 opacity-100", leaveTo: "translate-y-3 opacity-0" }, { children: _jsx(Listbox.Options, Object.assign({ as: "div", className: "absolute bottom-0 w-full pb-2 outline-none" }, { children: _jsx(Scrollbars, Object.assign({ className: "max-h-[17.75rem]" }, { children: scenesList === null || scenesList === void 0 ? void 0 : scenesList.map((sceneConfig, index) => (_jsx(Listbox.Option, Object.assign({ as: Fragment, value: sceneConfig.sceneKey }, { children: _jsx("button", Object.assign({ type: "button", className: "btn-theme-secondary h-auto min-h-[2.5rem] w-full px-1 py-0", "data-testid": "outside-scene-btn" }, { children: sceneConfig.name || t('layout.outdoor-n', { index: index + 1 }) })) }), `outside-${sceneConfig.sceneKey}`))) })) })) })) }))] })));
};
export default OutsideSwitch;
