import { init, ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import "./index.css";
import "./polyfills.js";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { InfoPageLayout } from "components/layout";
import { ErrorIcon } from "components/icons";
import ErrorBoundary from "components/wrappers/ErrorBoundary/ErrorBoundary";
import { createRoot } from 'react-dom/client';
import React from 'react';

const IS_SENTRY_ENABLED = Boolean(process.env.REACT_APP_SENTRY_PROJECT);

if (IS_SENTRY_ENABLED) {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    release: process.env.REACT_APP_RELEASE,
  });
}
const ErrorBoundaryComponent = IS_SENTRY_ENABLED ? SentryErrorBoundary : ErrorBoundary;

const container = document.getElementById("root");

if (!container) {
  throw new Error("No root container found");
}
const root = createRoot(container);

root.render(
  <ErrorBoundaryComponent
    fallback={
      <InfoPageLayout
        icon={<ErrorIcon className="w-[4.6rem] h-[4.6rem]"/>}
        headLine="Something went wrong"
      />
    }
  >
    <App/>
  </ErrorBoundaryComponent>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
