import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
import useGlobalContext from '../../contexts/useGlobalContext';
import useServices from '../../contexts/useServices';
import useReactive from '../../hooks/useReactive';
import useResizeObserver from '../../hooks/useResizeObserver';
import PanoSwitcher from '../altPano/PanoSwitcher';
import InfoModal from '../infoModal';
import MiniMapCircle from './components/MiniMapCircle';
import MiniMapMenuButton from './components/MiniMapMenu';
import TimeStampLogo from './components/TimeStampLogo';
import UtilsMenu from './components/UtilsMenu';
import LayoutPortal from './containers/LayoutPortal';
import LayoutSlot from './containers/LayoutSlot';
import isToolbarVisible from './utils/isToolbarVisible';
const StandardLayout = ({ enabledNavigation, enabledUtils }) => {
    const { engine } = useGlobalContext();
    const { tourConfigService, layoutService } = useServices();
    const { navigationMode, projectDataConfig, showRoomArea, disabledControlsTop, showTotalAreas } = useReactive(tourConfigService, ['projectDataConfig', 'navigationMode', 'showRoomArea', 'disabledControlsTop', 'showTotalAreas']);
    const disableDisclaimerAndUnitSwitch = (!enabledNavigation.miniMap || !showRoomArea) && (!showTotalAreas || !(projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.totalArea));
    const [hideNav, setHideNav] = useState(false);
    const canvasRef = useRef(engine.getCanvasElement());
    /** Handle navigation visibility based on viewport size */
    const handleLayoutChange = () => {
        const { width, height } = canvasRef.current.getBoundingClientRect();
        const shouldHideNavigation = width < layoutService.minSafeSize.width || height < layoutService.minSafeSize.height;
        // When there is not enough space for the navigation menu, hide it
        setHideNav(shouldHideNavigation);
    };
    // React to canvas size changes
    useResizeObserver(handleLayoutChange, canvasRef, true, 3);
    const showTopRightControls = !disabledControlsTop && layoutService.appContext !== 'guided-viewing';
    const showMiniMapMenu = enabledNavigation.miniMap || isToolbarVisible(enabledNavigation);
    return (_jsxs(_Fragment, { children: [showTopRightControls && (_jsx(LayoutSlot, Object.assign({ position: "top-right" }, { children: _jsx(UtilsMenu, { enabledUtils: enabledUtils, showPanSwitcher: true }) }))), _jsx(LayoutSlot, Object.assign({ position: "top-middle" }, { children: _jsx("div", Object.assign({ className: "hidden justify-center sm:flex" }, { children: _jsx(PanoSwitcher, {}) })) })), enabledUtils.info && (_jsx(LayoutPortal, { children: _jsx(InfoModal, { disabledDisclaimerAndUnitSwitch: disableDisclaimerAndUnitSwitch }) })), _jsx(LayoutSlot, Object.assign({ className: "space-y-4 smvh:space-y-6", position: "bottom-left" }, { children: !hideNav && (_jsxs(_Fragment, { children: [_jsx(MiniMapCircle, { enabledNavigation: enabledNavigation }), showMiniMapMenu && (_jsx(MiniMapMenuButton, { enabledNavigation: enabledNavigation, listMode: navigationMode === 'list' }))] })) })), _jsx(LayoutSlot, Object.assign({ position: "bottom-right" }, { children: _jsx(TimeStampLogo, {}) }))] }));
};
export default StandardLayout;
