/* eslint-disable no-restricted-syntax,no-continue,no-lonely-if */
import type { SceneConfig } from '@g360/vt-types';

import type FloorPlan3DProgram from '../FloorPlan3DProgram';
import FPWebGLProgram from '../FPWebGLProgram';
import type ModelNavigation from '../ModelNavigation';
import type { FPMesh } from '../types';
import { hexToRGB01 } from '../utils/color';
import fragmentShader from './transparent.fs.glsl';
import vertexShader from './transparent.vs.glsl';

class TransparentProgram extends FPWebGLProgram {
  sceneConfig?: SceneConfig;

  private readonly alpha = 0.4;
  private readonly alphaWallCap = 0.5;
  private readonly color = hexToRGB01('#232323');
  private readonly colorWallCap = hexToRGB01('#000000');
  private navigation: ModelNavigation;

  constructor(
    gl: WebGL2RenderingContext,
    canvas: HTMLCanvasElement,
    parentProgram: FloorPlan3DProgram,
    navigation: ModelNavigation
  ) {
    super(gl, canvas, parentProgram);
    this.navigation = navigation;
  }

  init(): void {
    super.init(vertexShader, fragmentShader);

    this.vertexAttributes = [this.positionLocation];
    this.gl.vertexAttribPointer(this.positionLocation, 3, this.gl.FLOAT, false, 0, 0);
  }

  loadGeometry(meshes: FPMesh[]): void {
    this.meshes = meshes;

    for (let m = 0; m < meshes.length; m += 1) {
      const mesh = meshes[m];

      if (mesh.dataNum === 0) continue; // skip bad meshes
      if (mesh.isOutline || mesh.isCeiling) continue; // not for this shader
      if (mesh.isWallCap && mesh.isBottomWall) continue; // skip wall caps that are in hidden in middle of the wall

      // if (mesh.isWallCap) continue; // skipppppppp all caps @todo -- rem all cap logic

      this.goodMeshIds.push(m);
    }
  }

  draw(): void {
    if (!this.gl) return;

    if (!this.navigation.getCenteringOnRoom() === null) return; // not centering on room - nothing needs to be drawn with transparent shader

    this.loadShaders();
    this.enableVertexAttributes();
    this.gl.enable(this.gl.POLYGON_OFFSET_FILL); // @todo -- is this needed for transparent shader?

    // make sure that LINES don't z-fight with solid geometry, this pushes lines towards camera
    // (actually anything that is not drawn by this shader)
    this.gl.polygonOffset(1.0, 1.0);

    this.gl.depthMask(false);
    this.gl.blendFunc(this.gl.SRC_ALPHA, this.gl.ONE_MINUS_SRC_ALPHA);

    this.gl.uniformMatrix4fv(this.matrixWorldPosLocation, false, this.parentProgram.matrixWorldFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixViewPosLocation, false, this.parentProgram.matrixViewFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixProjectionPosLocation, false, this.parentProgram.matrixProjectionFloat32Array);

    // Draw each mesh separately
    for (const meshId of this.goodMeshIds) {
      const mesh = this.meshes[meshId];

      if (!mesh.unfocusedRoom) continue; // not unfocused - no need to draw with transparent shader

      this.gl.uniform3fv(this.lightColorLocation, mesh.isWallCap ? this.colorWallCap : this.color);
      this.gl.uniform1f(this.alphaLocation, mesh.isWallCap ? this.alphaWallCap : this.alpha);

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.parentProgram.positionBuffer);
      this.gl.vertexAttribPointer(this.positionLocation, 3, this.gl.FLOAT, false, 0, mesh.dataOffsetPositions);

      this.gl.drawArrays(this.gl.TRIANGLES, 0, mesh.dataNum);
    }

    this.gl.disable(this.gl.POLYGON_OFFSET_FILL);
    this.gl.depthMask(true);
    this.disableVertexAttributes();
  }
}

export default TransparentProgram;
