import type { SceneConfig } from '@g360/vt-types';
import { Mixin } from 'ts-mixer';

import Matrix from '../../common/Matrix';
import RendererDebug from '../../common/RendererDebug';
import type DebugControls from '../../DebugControls';
import { Utils } from '../../index';
import type { ProgramName } from '../../types/internal';
import WebGLProgram from '../mixins/Program';
import fragmentShader from './depth.fs.glsl';
import vertexShader from './depth.vs.glsl';

/**
 * a) Renders depthmaps for transition (just before the transition starts)
 *    There was talk for downloading instead of rendering, but AFAIK no project has the assets,
 *    and the functionality to download the depthmaps (if tour.json has the paths) has been removed
 *
 * b) Renders colorful debug view of the geometry (when debug controls are enabled)
 */
class DepthProgram extends Mixin(WebGLProgram) {
  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  oldGeometry = false;
  name: ProgramName;
  debugControls?: DebugControls;

  pitch = 0;
  yaw = 0;
  yawOffset = 0;
  fov = 0;
  alpha = 1.0;
  cameraPosition: number[] = [];

  orderIndex = 0;
  vertCoordLocation = 0;
  matrixPerspectiveLocation: WebGLUniformLocation | null = null;
  matrixRotationLocation: WebGLUniformLocation | null = null;
  matrixScaleLocation: WebGLUniformLocation | null = null;
  matrixCameraPosLocation: WebGLUniformLocation | null = null;
  nearLocation: WebGLUniformLocation | null = null;
  farLocation: WebGLUniformLocation | null = null;
  resolutionLocation: WebGLUniformLocation | null = null;
  positionBuffer: WebGLBuffer | null = null;

  floor = '-';
  building = '-';
  geometry: number[] = [];

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    oldGeometry: boolean,
    name?: ProgramName,
    debugControls?: DebugControls
  ) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.oldGeometry = oldGeometry;
    this.name = name ?? 'DepthProgram';
    this.debugControls = debugControls;
  }

  init(): void {
    this.initShaders(vertexShader, fragmentShader);
    if (this.program) {
      this.vertCoordLocation = this.gl.getAttribLocation(this.program, 'a_vertCoord');
      this.matrixPerspectiveLocation = this.gl.getUniformLocation(this.program, 'u_perspective');
      this.matrixRotationLocation = this.gl.getUniformLocation(this.program, 'u_rotate');
      this.matrixScaleLocation = this.gl.getUniformLocation(this.program, 'u_scale');
      this.matrixCameraPosLocation = this.gl.getUniformLocation(this.program, 'u_cameraPos');
      this.nearLocation = this.gl.getUniformLocation(this.program, 'u_near');
      this.farLocation = this.gl.getUniformLocation(this.program, 'u_far');
      this.resolutionLocation = this.gl.getUniformLocation(this.program, 'u_resolution');
      this.positionBuffer = this.gl.createBuffer();
      this.vertexAttributes = [this.vertCoordLocation];
    }
  }

  loadGeometry(sceneConfig: SceneConfig): void {
    this.loadShaders(); // even no new geometry is loaded

    if (sceneConfig.geometry) {
      const floor = sceneConfig.floor;
      const building = sceneConfig.building;

      if (!this.geometry || this.floor !== floor || this.building !== building) {
        this.geometry = sceneConfig.geometry.flat(2);
        this.floor = floor || '--';
        this.building = building;
        this.prepStage();
      }
    }
  }

  prepStage(): void {
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.geometry), this.gl.STATIC_DRAW);
    this.gl.vertexAttribPointer(this.vertCoordLocation, 3, this.gl.FLOAT, false, 0, 0);
  }

  // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
  destroy(): void {}

  render(): void {
    if (!__DEV_PANEL__) return;
    if (!RendererDebug.runDebugRender.depth) return;

    this.draw();
  }

  draw(snapshotMode = false): void {
    if (!this.gl) return;

    if (!snapshotMode) {
      this.loadShaders(); // shaders are already loaded in this mode
    }

    this.enableVertexAttributes();
    this.gl.enable(this.gl.DEPTH_TEST);

    if (!this.oldGeometry) {
      // 1 sided door fix
      // this can be done only to modern projects, as old ones have their triangles ordered the wrong way
      this.gl.enable(this.gl.CULL_FACE);
      this.gl.cullFace(this.gl.FRONT);
    }

    // need to mask WHITE anything not rendered (holes in geometry or hallways too long from camera to see the other end)
    this.gl.clearColor(1.0, 1.0, 1.0, 1.0);
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    const drawNear = 20; // needs to as BIG as the geometry allows it
    const drawFar = 1270; // needs to be as SMALL as geometry allows it
    const size = { width: this.gl.drawingBufferWidth, height: this.gl.drawingBufferHeight };

    const geometryPerspectiveMatrix = Matrix.transposeM4(Matrix.perspective(this.fov, size, drawNear, drawFar));
    this.gl.uniformMatrix4fv(this.matrixPerspectiveLocation, false, new Float32Array(geometryPerspectiveMatrix));

    this.gl.uniform1f(this.nearLocation, drawNear);
    this.gl.uniform1f(this.farLocation, drawFar);
    this.gl.uniform1f(this.resolutionLocation, Math.max(size.width, size.height));

    const pos = this.debugControls && !snapshotMode ? this.debugControls.cameraManualOffsetsCalculated : [0, 0, 0];
    const cameraPosMatrix = Matrix.translationM4(
      pos[0] - this.cameraPosition[0],
      pos[1] - this.cameraPosition[1],
      pos[2]
    );

    this.gl.uniformMatrix4fv(this.matrixCameraPosLocation, false, new Float32Array(cameraPosMatrix));

    let modelRotationMatrix = Matrix.identityM3();
    modelRotationMatrix = Matrix.rotateZ(modelRotationMatrix, this.yaw);
    modelRotationMatrix = Matrix.rotateX(modelRotationMatrix, Utils.toRad(-90) + this.pitch);
    modelRotationMatrix = Matrix.m3toM4(modelRotationMatrix);
    this.gl.uniformMatrix4fv(this.matrixRotationLocation, false, new Float32Array(modelRotationMatrix));

    const scaleMatrix = Matrix.scalingM4(1, 1, -1);
    this.gl.uniformMatrix4fv(this.matrixScaleLocation, false, new Float32Array(scaleMatrix));

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
    this.gl.vertexAttribPointer(this.vertCoordLocation, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, this.geometry.length / 3);

    this.gl.enable(this.gl.BLEND);
    this.gl.disable(this.gl.DEPTH_TEST);
    this.gl.disable(this.gl.CULL_FACE);
    this.disableVertexAttributes();
  }
}

export default DepthProgram;
