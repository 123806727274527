import type { CaptionLightMode } from '@g360/vt-types';
import { getTextScaleForVideoEditor } from '@g360/vt-utils';
import noop from 'lodash/noop';
import { Mixin } from 'ts-mixer';

import ProgramEventEmitter from '../../../common/ProgramEventEmitter';
import type Renderer from '../../../mixins/Renderer';
import WebGLProgram from '../../mixins/Program';
import fragmentShader from './background.fs.glsl';
import vertexShader from './background.vs.glsl';
import { CaptionBackgroundProgramEvents } from './CaptionBackgroundProgramEvents';
import { getTextBackgroundColor, increaseBoundingBox } from './utils';

type BackgroundParams = {
  position: { x1: number; x2: number; y1: number; y2: number } | null;
  lightMode: CaptionLightMode;
  animationDuration: number;
};

class CaptionBackgroundProgram extends Mixin(WebGLProgram, ProgramEventEmitter<CaptionBackgroundProgramEvents>) {
  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  vertexBuffer: WebGLBuffer | null;
  textureBuffer: WebGLBuffer | null;
  vertexData: Float32Array = new Float32Array(0);
  vertexAttribute = 0;
  resolutionUniform: WebGLUniformLocation | null = null;
  colorUniform: WebGLUniformLocation | null = null;
  rectUniform: WebGLUniformLocation | null = null;

  paddingSize: [number, number, number, number] = [20, 32, 32, 32];
  color: [number, number, number, number] = [0, 0, 0, 1];
  boundingBox: { x1: number; x2: number; y1: number; y2: number } = { x1: 0, x2: 0, y1: 0, y2: 0 };
  params: BackgroundParams = {
    position: null,
    lightMode: 'light',
    animationDuration: 0,
  };
  private renderer: Renderer;

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, renderer: Renderer) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;

    this.vertexBuffer = this.gl.createBuffer();
    this.textureBuffer = this.gl.createBuffer();
    this.renderer = renderer;

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  init() {
    this.initShaders(vertexShader, fragmentShader);

    if (!this.program) {
      throw new Error('PROGRAM_NOT_INITIALIZED');
    }

    this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_position');
    this.resolutionUniform = this.gl.getUniformLocation(this.program, 'u_resolution');
    this.rectUniform = this.gl.getUniformLocation(this.program, 'u_rect');
    this.colorUniform = this.gl.getUniformLocation(this.program, 'u_color');

    this.vertexAttributes = [this.vertexAttribute];
  }

  updateParams(params: BackgroundParams) {
    this.params = params;

    if (params.lightMode) {
      this.color = getTextBackgroundColor(params.lightMode);
    }

    const textScale = getTextScaleForVideoEditor(this.canvas.getBoundingClientRect());
    const paddingSizeScaled = this.paddingSize.map((size) => size * textScale) as [number, number, number, number];
    if (this.params.position) {
      const { x1, x2, y1, y2 } = increaseBoundingBox(this.params.position, paddingSizeScaled, [
        this.canvas.clientWidth,
        this.canvas.clientHeight,
      ]);

      this.boundingBox = { x1, x2, y1, y2 };

      this.vertexData = new Float32Array([x1, y1, x2, y1, x1, y2, x1, y2, x2, y1, x2, y2]);

      if (params.animationDuration > 0) {
        this.fadeAnimation('fadeIn', params.animationDuration);
      }
    }
  }

  getBoundingBox() {
    return this.boundingBox;
  }

  fadeAnimation(type: 'fadeIn' | 'fadeOut', duration: number, finishedCallback?: () => void) {
    const maxAlpha = this.color[3];
    this.color[3] = type === 'fadeIn' ? 0 : maxAlpha;

    this.renderer.startAnimation(
      'backgroundAnimation',
      (progress) => {
        this.color[3] = maxAlpha * (type === 'fadeOut' ? maxAlpha - progress : progress);
      },
      finishedCallback ?? noop,
      'linear',
      duration
    );
  }

  hide(noAnimation = false) {
    if (noAnimation) {
      this.color[3] = 0;
      return;
    }

    this.fadeAnimation('fadeOut', this.params.animationDuration);
  }

  draw() {
    if (!this.program || this.vertexData.length === 0) {
      return;
    }

    this.loadShaders();

    this.gl.uniform2fv(this.resolutionUniform, [this.gl.drawingBufferWidth, this.gl.drawingBufferHeight]);
    this.gl.uniform4fv(this.rectUniform, [
      this.boundingBox.x1,
      this.boundingBox.y1,
      this.boundingBox.x2,
      this.boundingBox.y2,
    ]);
    this.gl.uniform4fv(this.colorUniform, this.color ? this.color : [0, 0, 0, 0]);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertexData, this.gl.STATIC_DRAW);

    this.enableVertexAttributes();

    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);
    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);

    this.disableVertexAttributes();
  }

  destroy() {
    this.destroyProgram();
  }

  private handleWindowResize(): void {
    this.updateParams(this.params);
  }
}

export default CaptionBackgroundProgram;
