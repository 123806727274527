/* eslint-disable @typescript-eslint/no-explicit-any */

import type { EngineEventMap, Subscription } from '@g360/vt-types';

import type { CallBack, PayLoad } from '../types/internal';

class EventEmitter {
  private listeners: Record<string, any[]> = {};

  /** Destroy emitter by removing all subscribers */
  destroyEventEmitter(): void {
    this.listeners = {};
  }

  /**
   * Subscribe function to Engine events: {@link EngineEventMap}
   */
  subscribe<K extends keyof EngineEventMap>(type: K, fn: (...payload: PayLoad<K>) => void): Subscription {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }

    if (this.listeners[type].indexOf(fn) === -1) {
      this.listeners[type].push(fn);
    }

    return {
      unsubscribe: () => {
        this.unsubscribe(type, fn);
      },
    };
  }

  /**
   * Unsubscribe function from Engine events
   */
  unsubscribe<K extends keyof EngineEventMap>(event: K, fn: CallBack<K>): void {
    this.listeners[event] = this.listeners[event]?.filter((c) => c !== fn);
  }

  /**
   * Emits Engine event to all subscribers
   */
  protected emit<K extends keyof EngineEventMap>(event: K, ...payload: PayLoad<K>): void {
    if (this.listeners[event] && this.listeners[event].length) {
      this.listeners[event].forEach((listener) => listener(...payload));
    }
  }
}

export default EventEmitter;
