import fscreen from 'fscreen';
import { useEffect, useState } from 'react';
const useFullScreen = (analytics) => {
    const [isFullscreen, setFullscreen] = useState(false);
    const [enabledFullscreen, setEnabledFullscreen] = useState(false);
    const toggleFullscreen = () => {
        if (fscreen.fullscreenElement === null) {
            fscreen.requestFullscreen(document.documentElement);
        }
        else {
            fscreen.exitFullscreen();
        }
    };
    const handler = () => {
        if (fscreen.fullscreenElement === null) {
            setFullscreen(false);
            analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'FS', 'Close');
        }
        else {
            setFullscreen(true);
            analytics === null || analytics === void 0 ? void 0 : analytics.push('click', 'FS', 'Open');
        }
    };
    useEffect(() => {
        setEnabledFullscreen(fscreen.fullscreenEnabled);
        setFullscreen(fscreen.fullscreenElement !== null);
        fscreen.addEventListener('fullscreenchange', handler, false);
        return () => {
            fscreen.removeEventListener('fullscreenchange', handler, false);
        };
    }, []);
    return {
        isFullscreen,
        enabledFullscreen,
        toggleFullscreen,
    };
};
export default useFullScreen;
