/* eslint-disable no-restricted-syntax,no-continue */
import type { SceneConfig } from '@g360/vt-types';

import type FloorPlan3DProgram from '../FloorPlan3DProgram';
import FPWebGLProgram from '../FPWebGLProgram';
import type { FPMesh } from '../types';
import fragmentShader from './sunDepthSnapshot.fs.glsl';
import vertexShader from './sunDepthSnapshot.vs.glsl';

/**
 * Same as FloorPlan3DSolidProgram, but with simpler shader and this draws ceiling too
 * used for sun depth rendering
 */
class SunDepthProgram extends FPWebGLProgram {
  sceneConfig?: SceneConfig;

  constructor(gl: WebGL2RenderingContext, canvas: HTMLCanvasElement, parentProgram: FloorPlan3DProgram) {
    super(gl, canvas, parentProgram);
    this.parentProgram = parentProgram;
  }

  init(): void {
    super.init(vertexShader, fragmentShader);

    this.vertexAttributes = [this.positionLocation];
    this.gl.vertexAttribPointer(this.positionLocation, 3, this.gl.FLOAT, false, 0, 0);
  }

  loadGeometry(meshes: FPMesh[]): void {
    this.meshes = meshes;

    for (let m = 0; m < meshes.length; m += 1) {
      const mesh = meshes[m];

      if (mesh.dataNum === 0) continue; // skip bad meshes
      if (mesh.isOutline) continue; // outlines never contribute to shadows
      if (mesh.isWallCap && mesh.isBottomWall) continue; // bottom wall caps are hidden inside the walls
      // @todo -- review the these rules later to minimize the number of meshes that are drawn

      this.goodMeshIds.push(m);
    }
  }

  draw(): void {
    if (!this.gl) return;

    this.loadShaders();
    this.enableVertexAttributes();
    this.gl.disable(this.gl.CULL_FACE); // @todo -- make better ceiling geometry and enable culling

    this.gl.uniformMatrix4fv(this.matrixWorldPosLocation, false, this.parentProgram.sunMatrixWorldFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixViewPosLocation, false, this.parentProgram.sunMatrixViewFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixProjectionPosLocation, false, this.parentProgram.sunMatrixProjectionFloat32Array); // prettier-ignore

    this.gl.uniform1f(this.farLocation, this.parentProgram.sunFar);
    this.gl.uniform1f(this.nearLocation, this.parentProgram.sunNear);

    for (const meshId of this.goodMeshIds) {
      const mesh = this.meshes[meshId];

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.parentProgram.positionBuffer);
      this.gl.vertexAttribPointer(this.positionLocation, 3, this.gl.FLOAT, false, 0, mesh.dataOffsetPositions);

      this.gl.drawArrays(this.gl.TRIANGLES, 0, mesh.dataNum);
    }

    this.gl.enable(this.gl.CULL_FACE);

    this.disableVertexAttributes();
  }
}

export default SunDepthProgram;
