import { useState } from 'react';
const useNavigationSize = () => {
    const [state, setState] = useState({
        squareSize: { width: 0, height: 0 },
        navRadius: 0,
    });
    function setSquareSize(size) {
        setState(Object.assign(Object.assign({}, state), { squareSize: { width: size, height: size }, navRadius: size / 2 }));
    }
    return Object.assign(Object.assign({}, state), { setSquareSize });
};
export default useNavigationSize;
