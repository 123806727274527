import type { AssetConfig, BlurMaskData, SceneConfig } from '@g360/vt-types';
import { Mixin } from 'ts-mixer';

import { STARTING_EDITOR_BLUR } from '../../common/Globals';
import Utils from '../../common/Utils';
import type Renderer from '../../mixins/Renderer';
import type { ProgramName } from '../../types/internal';
import ColorCorrectionProgramPartial from '../mixins/ColorCorrectionProgramPartial';
import WebGLProgram from '../mixins/Program';
import fragmentShader from './blur.fs.glsl';
import vertexShader from './blur.vs.glsl';
import Blurrer from './Blurrer';

class BlurProgram extends Mixin(WebGLProgram, ColorCorrectionProgramPartial) {
  equirectImageCache: Record<string, HTMLImageElement> = {}; // path => image
  blurrers = new Map<string, Blurrer>(); // one for each scene
  lastBlurIntensity = STARTING_EDITOR_BLUR; // gets stolen from last updated blur mask (blur intensities may differ per scene)
  blurPatchImageData?: ImageData;

  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  renderer: Renderer;
  name: ProgramName;

  orderIndex = 0;
  vertexBuffer: WebGLBuffer | null;
  vertexAttribute = 0;
  aspectRatioUniform: WebGLUniformLocation | null = null;
  alphaUniform: WebGLUniformLocation | null = null;
  yawUniform: WebGLUniformLocation | null = null;
  pitchUniform: WebGLUniformLocation | null = null;
  fovUniform: WebGLUniformLocation | null = null;
  textureObject: WebGLTexture | null = null;

  textureLoaded = false;
  sceneConfig?: SceneConfig;
  ready = false;
  yaw = 0;
  pitch = 0;
  fov = Utils.toRad(120);
  alpha = 1.0;
  yawOffset = 0;
  readonly vertices = new Float32Array([-1, 1, 1, 1, 1, -1, -1, 1, 1, -1, -1, -1]);

  visibleA = true; // should the blurs be rendered | toggled by transition
  visibleB = true; // | toggled by editor
  doBlursWhenReady: (() => void) | null = null;

  constructor(webGLContext: WebGLRenderingContext, canvas: HTMLCanvasElement, renderer: Renderer, name?: ProgramName) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.name = name ?? 'BlurProgram';

    this.vertexBuffer = this.gl.createBuffer();
    this.textureObject = this.createTexture();
  }

  private get visible(): boolean {
    return this.visibleA && this.visibleB;
  }

  init(): void {
    this.initShaders(vertexShader, fragmentShader);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');
      this.alphaUniform = this.gl.getUniformLocation(this.program, 'u_alpha');
      this.aspectRatioUniform = this.gl.getUniformLocation(this.program, 'u_aspectRatio');
      this.yawUniform = this.gl.getUniformLocation(this.program, 'u_yaw');
      this.pitchUniform = this.gl.getUniformLocation(this.program, 'u_pitch');
      this.fovUniform = this.gl.getUniformLocation(this.program, 'u_fov');
      this.gl.enableVertexAttribArray(this.vertexAttribute);
      this.initColorCorrection(this.program, this.gl);
    }

    this.ready = true;
  }

  async setSceneConfig(sceneConfig: SceneConfig, assetPath: string, assetConfig: AssetConfig): Promise<void> {
    return new Promise((resolve) => {
      this.sceneConfig = sceneConfig;
      this.yawOffset = Utils.toRad(sceneConfig.camera[3]);

      if (!this.equirectImageCache[sceneConfig.sceneKey]) {
        this.renderer.createEquirectForPano(assetPath, assetConfig).then((equirectImage) => {
          this.equirectImageCache[sceneConfig.sceneKey] = equirectImage;
          this.changeBlur(true);
          if (this.doBlursWhenReady) {
            this.doBlursWhenReady();
            this.doBlursWhenReady = null;
          }
          resolve();
        });
      } else {
        this.changeBlur(true);
        if (this.doBlursWhenReady) {
          this.doBlursWhenReady();
          this.doBlursWhenReady = null;
        }
        resolve();
      }
    });
  }

  setBlurMasksAndRegenerateBlurs(
    sceneKey: string,
    intensity: number,
    masks: BlurMaskData[],
    highQuality: boolean
  ): void {
    this.lastBlurIntensity = intensity;
    if (!this.blurrers[sceneKey]) {
      // Blurs are not yet ready. Do this when they are:
      // console.log("setBlurMasksAndRegenerateBlurs::later");
      this.doBlursWhenReady = () => {
        setTimeout(() => {
          // console.log("setBlurMasksAndRegenerateBlurs::later is now");
          this.setBlurMasksAndRegenerateBlurs(sceneKey, intensity, masks, highQuality);
        }, 100);
      };
      return;
    }

    // console.log("setBlurMasksAndRegenerateBlurs::yes");
    (this.blurrers[sceneKey] as Blurrer).setBlurMasks(intensity, masks);
    this.changeBlur(highQuality);
  }

  getBlurMaskPngDataUrl(sceneKey: string, width: number): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.blurrers[sceneKey]) {
        reject(new Error('Mask unavailable, scene was not open in editor'));
        return;
      }

      (this.blurrers[sceneKey] as Blurrer).makeComboMask(width).then((pngUrl: string) => {
        resolve(pngUrl);
      });
    });
  }

  getBlurMaskPngBlob(sceneKey: string, width: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      if (!this.blurrers[sceneKey]) {
        reject(new Error('Mask unavailable, scene was not open in editor'));
        return;
      }

      (this.blurrers[sceneKey] as Blurrer).makeComboMaskBlob(width).then((pngBlob: Blob) => {
        resolve(pngBlob);
      });
    });
  }

  // change to the current pano & corresponding blurmask
  changeBlur(highQuality: boolean): void {
    this.textureLoaded = false;

    const currentSceneKey = this.sceneConfig?.sceneKey || '';
    const equirectImg = this.equirectImageCache[currentSceneKey];
    if (!equirectImg) return;

    // if first time make blurs for current pano
    if (!this.blurrers[currentSceneKey]) {
      this.blurrers[currentSceneKey] = new Blurrer(equirectImg);
      this.blurrers[currentSceneKey].makeComboBlur(highQuality).then((imgData) => {
        this.onBlurDone(imgData);
      });
    } else {
      // pano already have blurs, just use latest blur mask
      this.blurrers[currentSceneKey]
        .makeComboBlur(highQuality)
        .then((imgData) => {
          this.onBlurDone(imgData);
        })
        .catch((err) => {
          console.log(`B)blurProgram::changeBlur:${err}`);
        });
    }
  }

  onBlurDone(blurImageData: ImageData): void {
    this.blurPatchImageData = blurImageData;
    this.renderer.render();

    setTimeout(() => {
      this.renderer.render();
    }, 32); // safari is sometimes slow to render the first time
  }

  destroy(): void {
    this.gl.deleteTexture(this.textureObject);
    this.destroyProgram();
  }

  render(): void {
    if (!this.visible || !this.gl || !this.ready || this.renderer.isInTransition) return;
    this.draw();
  }

  private draw(): void {
    this.loadShaders();

    if (!this.textureLoaded) {
      const imageData = this.blurPatchImageData;
      if (imageData && imageData.width > 0 && imageData.height > 0) {
        this.gl.activeTexture(this.gl.TEXTURE0);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureObject);
        this.gl.texImage2D(this.gl.TEXTURE_2D, 0, this.gl.RGBA, this.gl.RGBA, this.gl.UNSIGNED_BYTE, imageData);
        this.textureLoaded = true;
      }
    }

    if (!this.textureObject || !this.textureLoaded) return;

    this.setColorCorrectionUniforms(this.gl);

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
    this.gl.enableVertexAttribArray(this.vertexAttribute);
    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.uniform1f(this.aspectRatioUniform, this.gl.drawingBufferWidth / this.gl.drawingBufferHeight);
    this.gl.uniform1f(this.yawUniform, this.yaw + this.yawOffset);
    this.gl.uniform1f(this.pitchUniform, this.pitch);
    this.gl.uniform1f(this.fovUniform, this.fov);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.textureObject);
    this.gl.uniform1f(this.alphaUniform, this.alpha);

    this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);

    this.gl.disable(this.gl.CULL_FACE);
  }
}

export default BlurProgram;
