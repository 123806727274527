import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BrowserInfo } from '@g360/vt-utils/';
import { Transition } from '@headlessui/react';
import { useMemo, useState } from 'react';
import { useCookieConsentContext } from '../../contexts/useCookieConsentContext';
import useServices from '../../contexts/useServices';
import useBranding from '../../hooks/useBranding';
import useFullScreen from '../../hooks/useFullscreen';
import useReactive from '../../hooks/useReactive';
import AutoplayIndex from '../autoPlay';
import WelcomeScreen from '../branding/WelcomeScreen';
import CookieModal from '../cookieConsent/CookieModal';
import CookiePopup from '../cookieConsent/CookiePopup';
import HotSpot from '../hotSpot/components/HotSpot';
import HotSpotImagePreviewOverlay from '../hotSpot/components/HotSpotImagePreviewOverlay';
import MeasureTutorial from '../measureTool/components/MeasureTutorial';
import PreloadScreen from '../preload/components/PreloadScreen';
import GradientBottom from './components/GradientBottom';
import SceneTutorial from './components/SceneTutorial';
import ToastMessage from './components/ToastMessage';
import ScreenPadding from './containers/LayoutPadding';
import DynamicLayout from './DynamicLayout';
import isMeasureToolAvailable from './utils/isMeasureToolAvailable';
import isThereAnyInfoSlot from './utils/isThereAnyInfoSlot';
const RootLayout = ({ noHotSpot, noFullScreenOption, onHotSpotEditClick, onSelectHotSpot }) => {
    const { layoutService, tourConfigService, navigationService } = useServices();
    const { isWelcomeScreenOpen, measureMode } = useReactive(layoutService, [
        'isWelcomeScreenOpen',
        'measureMode',
        'isNavigationOpen',
        'isUtilityMenuOpen',
    ]);
    const { navigationMode, lsfRenderingMode, tourConfig, projectDataConfig, showRoomArea, features } = useReactive(tourConfigService, ['navigationMode', 'lsfRenderingMode', 'tourConfig', 'projectDataConfig', 'showRoomArea', 'features']);
    const { showOutsideSwitch, buildingKey } = useReactive(navigationService, ['showOutsideSwitch', 'buildingKey']);
    const { hasOutsideScenes, hasBuildings, hasMultipleBuildings } = navigationService;
    const { themeClassName } = useBranding();
    const [hotSpotImagePreviewSrc, setHotSpotPreviewImageSrc] = useState(null);
    const enabledNavigation = {
        buildings: !['list', 'none'].includes(navigationMode) && (hasMultipleBuildings || (hasBuildings && hasOutsideScenes)),
        floors: !['list', 'none'].includes(navigationMode) && navigationService.getFloorCount() > 1,
        // NOTE: outside has priority, if outside is enabled, floors are disabled
        outside: navigationMode !== 'none' && (navigationMode === 'list' || showOutsideSwitch),
        miniMap: !['list', 'none'].includes(navigationMode) && hasBuildings && buildingKey !== 'outside',
    };
    const { enabledFullscreen } = useFullScreen();
    const { consentNecessary } = useCookieConsentContext();
    // If no scenes in the project have the measure flag enabled, we don't show the button to enter measure mode at all
    const measureToolAvailable = useMemo(() => isMeasureToolAvailable(tourConfig.scenes), [tourConfig.scenes]);
    const disableDisclaimerAndUnitSwitch = (!enabledNavigation.miniMap || !showRoomArea) && !(projectDataConfig === null || projectDataConfig === void 0 ? void 0 : projectDataConfig.totalArea);
    // Info button is disabled if there is nothing to show in the info modal
    const disabledInfoModal = lsfRenderingMode !== 'off' &&
        !consentNecessary &&
        disableDisclaimerAndUnitSwitch &&
        !isThereAnyInfoSlot(projectDataConfig);
    const enabledUtils = {
        fullscreen: !noFullScreenOption && enabledFullscreen,
        measure: measureToolAvailable,
        info: !disabledInfoModal,
    };
    const handleAnimationEnd = () => {
        // Open the navigation after some delay if not on a mobile device and if default state is not closed (ex: idealista)
        if (!BrowserInfo.isMobile() && features.isMinimapOpenByDefault) {
            setTimeout(() => {
                layoutService.isNavigationOpen = true;
            }, 100);
        }
    };
    const autoplayOn = false; //  !!__AUTOPLAY_SKIN__; // comes from .env through craco.config.js; used to treeshake off the autoplay in production (or in other branches)
    return (_jsxs("div", Object.assign({ id: "layout-root", className: `vt-player-lib pointer-events-none relative h-full w-full ${themeClassName} ${measureMode ? '' : '@container'}` }, { children: [_jsx(WelcomeScreen, {}), _jsxs(Transition, Object.assign({ appear: true, show: !isWelcomeScreenOpen, className: "opacity-0", enter: "transition duration-400 ease-out delay-0", enterFrom: "opacity-0", enterTo: "opacity-100", leave: "transition duration-400 ease-out", leaveFrom: "opacity-100", leaveTo: "opacity-0", afterEnter: handleAnimationEnd }, { children: [_jsxs(ScreenPadding, { children: [_jsx(DynamicLayout, { isMeasureMode: measureMode, lsfRenderingMode: lsfRenderingMode, enabledNavigation: enabledNavigation, enabledUtils: enabledUtils }), _jsx(ToastMessage, {})] }), autoplayOn && _jsx(AutoplayIndex, {}), _jsx(PreloadScreen, {}), _jsx(CookiePopup, {}), _jsx(CookieModal, {}), _jsx(SceneTutorial, {}), _jsx(MeasureTutorial, {}), !noHotSpot && (_jsx(HotSpot, { onExpandImage: (src) => setHotSpotPreviewImageSrc(src), lockAutoClose: hotSpotImagePreviewSrc !== null, onHotSpotEditClick: onHotSpotEditClick, onSelectHotSpot: onSelectHotSpot })), _jsx(HotSpotImagePreviewOverlay, { src: hotSpotImagePreviewSrc, onClose: () => setHotSpotPreviewImageSrc(null) }), _jsx(GradientBottom, {})] }))] })));
};
export default RootLayout;
