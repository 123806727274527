import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import useServices from '../../../contexts/useServices';
import useReactive from '../../../hooks/useReactive';
import CloseIcon from '../../infoModal/icons/CloseIcon';
const ToastMessage = () => {
    const { layoutService } = useServices();
    const { activeToast, showToast } = useReactive(layoutService, ['showToast', 'activeToast']);
    const twTextAlign = !(activeToast === null || activeToast === void 0 ? void 0 : activeToast.persistent) ? 'text-left' : 'text-center';
    const handleDismiss = () => {
        layoutService.dismissToastMessage();
    };
    return (_jsxs(Transition, Object.assign({ as: "div", className: "absolute bottom-0 left-1/2 z-[1000] flex w-full max-w-[16.875rem] -translate-x-1/2 items-center rounded-md bg-modal-dark px-[1rem] pb-[0.813rem] pt-[0.688rem] font-primary text-sm font-bold text-white", show: showToast, enter: "transition-all duration-[400ms] ease-out", enterFrom: "opacity-0 translate-y-1/4", enterTo: "opacity-100 translate-y-0", leave: "transition-all duration-[400ms] ease-out", leaveFrom: "opacity-100 translate-y-0", leaveTo: " opacity-0 translate-y-1/4" }, { children: [_jsx("div", Object.assign({ className: `grow ${twTextAlign}` }, { children: activeToast === null || activeToast === void 0 ? void 0 : activeToast.message })), !(activeToast === null || activeToast === void 0 ? void 0 : activeToast.persistent) && (_jsx("button", Object.assign({ className: "center group pointer-events-auto ml-[1rem] flex h-6 w-6 items-center justify-center rounded-md", type: "button", onClick: handleDismiss }, { children: _jsx(CloseIcon, { className: "h-6 w-6 stroke-white group-hover:stroke-modal-gray-hover" }) })))] })));
};
export default ToastMessage;
