import DeclinedPage from "components/page/DeclinedPage";
import LeaveVideoChatPage from "components/page/LeaveVideoChatPage";
import VideoChatPage from "components/page/VideoChatPage";
import { FC } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ErrorPage from "./components/page/ErrorPage";
import GuidedViewingNotFound from "./components/page/GuidedViewingNotFound";
import config from "./config";

const App: FC = () => {
  return (
    <Router basename={config.baseName ?? undefined}>
      <Routes>
        <Route path="/leave" element={<LeaveVideoChatPage />} />
        <Route
          path="/guided-viewing-not-found"
          element={<GuidedViewingNotFound />}
        />
        <Route path="/:id" element={<VideoChatPage />} />
        <Route path="/:id/declined" element={<DeclinedPage />} />
        <Route path="/:id/error" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
};

export default App;
