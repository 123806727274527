// eslint-disable-next-line import/no-extraneous-dependencies
import type { Features } from '@g360/vt-types';

const DEFAULT_FEATURES: Features = {
  cookieConsent: true,
  cookiePopup: true,
  giraffe360Branding: true,
  clientBranding: true,
  welcomeScreen: true,
  hotspotClickableLinks: true,
  infoSection: true,
  fullscreen: true,
  contactSection: true,
  propertyInfo: true,
  linkSharing: true,
  defaultUnits: 'floorplan-based',
  isMinimapOpenByDefault: true,
  timestamp: true,
};

export default DEFAULT_FEATURES;
