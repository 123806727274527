import { linearScale } from '@g360/vt-utils';
import { Mixin } from 'ts-mixer';

import { Utils } from '../../..';
import type Renderer from '../../../mixins/Renderer';
import WebGLProgram from '../../mixins/Program';
import type SphereProgram from '..';
import fragmentShader from './transitionBlend.fs.glsl';
import vertexShader from './transitionBlend.vs.glsl';

class TransitionBlendProgram extends Mixin(WebGLProgram) {
  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;
  renderer: Renderer;

  fov = 0;
  pitch = 0;
  yaw = 0;
  alpha = 0; // here for legacy reasons, not used in rendering

  orderIndex = 0;
  vertCoordLocation = 0;
  uvLocation = 0;
  texLocation: WebGLUniformLocation | null = null;
  alphaLocation: WebGLUniformLocation | null = null;
  vertexBuffer: WebGLBuffer | null = null;
  vertIndexBuffer: WebGLBuffer | null = null;
  uvBuffer: WebGLBuffer | null = null;
  tex: WebGLTexture;
  geometry: number[] = [];
  geometryIndices: number[] = [];
  uvMap: number[] = [];

  private mainProgram: SphereProgram;

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    renderer: Renderer,
    mainProgram: SphereProgram
  ) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.renderer = renderer;
    this.mainProgram = mainProgram;

    // eslint-disable-next-line no-param-reassign
    if (mainProgram) mainProgram.transitionBlendProgram = this;

    this.tex = this.createTexture() as WebGLTexture;
    this.createGeometry();
  }

  createGeometry(): void {
    this.geometry = [-1, 1, 0, -1, -1, 0, 1, -1, 0, 1, 1, 0];
    this.geometryIndices = [0, 1, 2, 2, 3, 0];
    this.uvMap = [0, 1, 0, 0, 1, 0, 1, 1];
  }

  init(): void {
    this.initShaders(vertexShader, fragmentShader);
    if (this.program) {
      this.vertCoordLocation = this.gl.getAttribLocation(this.program, 'a_vertCoord');
      this.uvLocation = this.gl.getAttribLocation(this.program, 'a_uv');
      this.alphaLocation = this.gl.getUniformLocation(this.program, 'u_alpha');
      this.texLocation = this.gl.getUniformLocation(this.program, 'u_tex');

      this.vertexBuffer = this.gl.createBuffer();
      this.vertIndexBuffer = this.gl.createBuffer();
      this.uvBuffer = this.gl.createBuffer();

      this.vertexAttributes = [this.vertCoordLocation, this.uvLocation];
      this.prepStage();
    }
  }

  prepStage(): void {
    this.loadShaders(false);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.geometry), this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(this.uvMap), this.gl.STATIC_DRAW);

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.vertIndexBuffer);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(this.geometryIndices), this.gl.STATIC_DRAW);

    this.gl.uniform1i(this.texLocation, 2);
    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.tex);
  }

  destroy(): void {
    // there is no real reason to explicitly delete textures, there is no mem leaks in js (don't quote me on this)
    // since next 2 textures will be rendered here on next transition, this allows us to save 2 texture-worth of memory while not in transition
    this.gl.deleteTexture(this.tex);
  }

  render(): void {
    if (!this.gl) return;

    const { renderer } = this;

    if (!renderer.isInTransition) return;

    if (!renderer.transitionConnected || renderer.watermarkInterrupted) {
      renderer.render();
      return;
    }

    const { transitionDirection } = renderer;
    const { PI } = Math;

    const sideways = transitionDirection > PI * 0.25 && transitionDirection < PI * 0.75;
    const backwards = transitionDirection > PI * 0.5;
    let endBlend = renderer.transitionWallEncounterPercentB;

    // usually leave first few % of transition without any blending
    let startBlend = endBlend < 0.2 ? 0 : 0.1;

    if (backwards || sideways) {
      endBlend += 0.3; // moving backwards, end blend is pushed to when moved through the last wall + a bit
      startBlend = endBlend / 3 + 0.1;
    }

    endBlend = Utils.clamp(endBlend, 0.05, 0.95);
    const blendLen = endBlend - startBlend;
    let percentage = linearScale(renderer.transitionProgress - startBlend, [0, 1], [0, 1 / blendLen]);
    percentage = Utils.clamp(percentage, 0.001, 0.999);

    this.draw(percentage);
    renderer.render();
  }

  private draw(alpha: number): void {
    this.loadShaders(false);
    this.enableVertexAttributes();

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.BACK);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.vertexAttribPointer(this.vertCoordLocation, 3, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.vertIndexBuffer);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvBuffer);
    this.gl.vertexAttribPointer(this.uvLocation, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.tex);

    this.gl.uniform1f(this.alphaLocation, alpha);

    this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);

    this.gl.disable(this.gl.CULL_FACE);
    this.disableVertexAttributes();
  }
}

export default TransitionBlendProgram;
