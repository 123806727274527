import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCookieConsentContext } from '../../../../contexts/useCookieConsentContext';
import useServices from '../../../../contexts/useServices';
import useReactive from '../../../../hooks/useReactive';
import AgentInfo from '../../components/AgentInfo';
import Footer from '../../components/Footer';
import PropertyInfo from '../../components/PropertyInfo';
import ShareTour from '../../components/ShareTour';
import { isValidContactData, isValidProjectInfo } from './utils';
function Information({ hasOverflowY, disabledDisclaimerAndUnitSwitch, onFpLearnMoreClick }) {
    const { tourConfigService, layoutService } = useServices();
    const { projectDataConfig, lsfRenderingMode, projectUrl, features } = useReactive(tourConfigService, [
        'projectUrl',
        'projectDataConfig',
        'tourConfig',
        'lsfRenderingMode',
        'features',
    ]);
    const { consentNecessary } = useCookieConsentContext();
    const shouldRenderAgentInfo = isValidContactData(projectDataConfig, lsfRenderingMode);
    const shouldRenderPropertyInfo = isValidProjectInfo(projectDataConfig, lsfRenderingMode);
    const shouldRenderShareTour = lsfRenderingMode === 'off' && layoutService.appContext !== 'guided-viewing' && projectUrl;
    const twFooterBorder = shouldRenderAgentInfo || shouldRenderPropertyInfo || shouldRenderShareTour || hasOverflowY
        ? 'border-t border-modal-gray-light'
        : '';
    const twContentTopPadding = hasOverflowY ? 'pt-6' : 'pt-2';
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: `px-8 ${twContentTopPadding}` }, { children: [shouldRenderAgentInfo && features.contactSection && _jsx(AgentInfo, {}), shouldRenderPropertyInfo && features.propertyInfo && _jsx(PropertyInfo, {}), shouldRenderShareTour && features.linkSharing && (_jsx(ShareTour, { addTopMargin: shouldRenderAgentInfo || shouldRenderPropertyInfo }))] })), _jsx(Footer, { className: twFooterBorder, noCookieConsent: !consentNecessary, disabledDisclaimerAndUnitSwitch: disabledDisclaimerAndUnitSwitch, onFpLearnMoreClick: onFpLearnMoreClick })] }));
}
export default Information;
