import type { Destroyable } from '@g360/vt-types';

import WebGLProgram from '../mixins/Program';
import type FloorPlan3DProgram from './FloorPlan3DProgram';
import type { FPMesh } from './types';

/**
 * Base for all programs in FloorPlan3D
 * All programs try to get locations of all the mentioned uniforms and attributes
 * No biggie if a shader doesn't have all of them
 */
class FPWebGLProgram extends WebGLProgram implements Destroyable {
  gl: WebGL2RenderingContext;
  canvas: HTMLCanvasElement;
  parentProgram: FloorPlan3DProgram;

  goodMeshIds: number[] = [];
  meshes: FPMesh[] = [];

  isInitialized = false;

  positionLocation = 0;
  texCoordLocation = 0;
  normaLocation = 0;
  expandCoordLocation = 0;
  mainTexLocation: WebGLUniformLocation | null = null;
  shadowMapLocation: WebGLUniformLocation | null = null;
  farLocation: WebGLUniformLocation | null = null;
  nearLocation: WebGLUniformLocation | null = null;
  actualCameraPosLocation: WebGLUniformLocation | null = null;
  matrixWorldPosLocation: WebGLUniformLocation | null = null;
  matrixViewPosLocation: WebGLUniformLocation | null = null;
  matrixProjectionPosLocation: WebGLUniformLocation | null = null;
  matrixSunWorldPosLocation: WebGLUniformLocation | null = null;
  matrixSunViewPosLocation: WebGLUniformLocation | null = null;
  matrixSunProjectionPosLocation: WebGLUniformLocation | null = null;
  sunFarLocation: WebGLUniformLocation | null = null;
  sunDepthMapSizeLocation: WebGLUniformLocation | null = null;
  sunDirectionLocation: WebGLUniformLocation | null = null;
  lightColorLocation: WebGLUniformLocation | null = null;
  alphaLocation: WebGLUniformLocation | null = null;

  timeLocation: WebGLUniformLocation | null = null; // only for debug

  constructor(gl: WebGL2RenderingContext, canvas: HTMLCanvasElement, parentProgram: FloorPlan3DProgram) {
    super();
    this.gl = gl;
    this.canvas = canvas;
    this.parentProgram = parentProgram;
  }

  init(vertexShader: string, fragmentShader: string): void {
    this.initShaders(vertexShader, fragmentShader);
    this.isInitialized = true;
    if (!this.program) return;
    this.positionLocation = this.gl.getAttribLocation(this.program, 'a_position');
    this.texCoordLocation = this.gl.getAttribLocation(this.program, 'a_texCoord');
    this.normaLocation = this.gl.getAttribLocation(this.program, 'a_normal');
    this.expandCoordLocation = this.gl.getAttribLocation(this.program, 'a_expandCoord');
    this.mainTexLocation = this.gl.getUniformLocation(this.program, 'u_mainTex');
    this.shadowMapLocation = this.gl.getUniformLocation(this.program, 'u_shadowMap');
    this.timeLocation = this.gl.getUniformLocation(this.program, 'u_time');
    this.actualCameraPosLocation = this.gl.getUniformLocation(this.program, 'u_actualCameraPos');
    this.matrixWorldPosLocation = this.gl.getUniformLocation(this.program, 'u_worldMatrix');
    this.matrixViewPosLocation = this.gl.getUniformLocation(this.program, 'u_viewMatrix');
    this.matrixProjectionPosLocation = this.gl.getUniformLocation(this.program, 'u_projectionMatrix');
    this.matrixSunWorldPosLocation = this.gl.getUniformLocation(this.program, 'u_sunWorldMatrix');
    this.matrixSunViewPosLocation = this.gl.getUniformLocation(this.program, 'u_sunViewMatrix');
    this.matrixSunProjectionPosLocation = this.gl.getUniformLocation(this.program, 'u_sunProjectionMatrix');
    this.sunFarLocation = this.gl.getUniformLocation(this.program, 'u_sunFar');
    this.nearLocation = this.gl.getUniformLocation(this.program, 'u_near');
    this.farLocation = this.gl.getUniformLocation(this.program, 'u_far');
    this.sunDepthMapSizeLocation = this.gl.getUniformLocation(this.program, 'u_sunDepthMapSize');
    this.sunDirectionLocation = this.gl.getUniformLocation(this.program, 'u_sunDirection');
    this.lightColorLocation = this.gl.getUniformLocation(this.program, 'u_lightColor');
    this.alphaLocation = this.gl.getUniformLocation(this.program, 'u_alpha');
  }

  destroy(): void {
    this.destroyProgram();
  }
}

export default FPWebGLProgram;
