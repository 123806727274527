abstract class Program {
  static readonly basicIndices = new Uint16Array([0, 1, 2, 0, 2, 3]);

  protected program?: WebGLProgram | null;
  protected basicIndexBuffer?: WebGLBuffer | null;
  protected vertexAttributes: number[] = []; // list vertex attributes to be enabled before and disabled after a draw in order no to mess up state of other programs

  private vertexShader?: WebGLShader | null;
  private fragmentShader?: WebGLShader | null;

  abstract gl: WebGLRenderingContext;
  abstract canvas: HTMLCanvasElement;

  public createTexture(placeholderColor: number[] = [0, 255, 0, 0], useAlphaChannel = false): WebGLTexture | null {
    const texture = this.gl.createTexture();
    this.gl.bindTexture(this.gl.TEXTURE_2D, texture);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_S, this.gl.CLAMP_TO_EDGE);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_WRAP_T, this.gl.CLAMP_TO_EDGE);

    const colorFormat = useAlphaChannel ? this.gl.RGBA : this.gl.RGB;
    // fill with 1px placeholder color
    this.gl.texImage2D(this.gl.TEXTURE_2D, 0, colorFormat, 1, 1, 0, colorFormat, this.gl.UNSIGNED_BYTE, new Uint8Array(placeholderColor)); // prettier-ignore

    return texture;
  }

  protected initShaders(vertexShader: string, fragmentShader: string): void {
    this.vertexShader = this._compileShader('VERTEX_SHADER', vertexShader);
    this.fragmentShader = this._compileShader('FRAGMENT_SHADER', fragmentShader);

    if (this.vertexShader && this.fragmentShader) {
      this.program = this._createProgram(this.vertexShader, this.fragmentShader);

      if (this.program) {
        this._loadProgram(this.program);
      } else {
        console.warn('Failed to create program?');
        throw new Error('SHADER_ERROR');
      }
    } else {
      console.warn('Failed to compile shaders?');
      throw new Error('SHADER_ERROR');
    }
  }

  protected loadShaders(withBasicIndices = true): void {
    if (this.program) {
      this._loadProgram(this.program);
      if (withBasicIndices) {
        this.loadBasicVertexIndices();
      }
    } else {
      console.warn('Failed to load shaders!');
      throw new Error('SHADER_ERROR');
    }
  }

  protected loadBasicVertexIndices(): void {
    if (!this.basicIndexBuffer) {
      this.basicIndexBuffer = this.gl.createBuffer();
    }

    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, this.basicIndexBuffer);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, Program.basicIndices, this.gl.STATIC_DRAW);
  }

  protected destroyProgram(): void {
    if (this.program) this.gl.deleteProgram(this.program);
    if (this.vertexShader) this.gl.deleteShader(this.vertexShader);
    if (this.fragmentShader) this.gl.deleteShader(this.fragmentShader);
  }

  protected enableVertexAttributes() {
    for (let i = 0; i < this.vertexAttributes.length; i += 1) {
      this.gl.enableVertexAttribArray(this.vertexAttributes[i]);
    }
  }

  protected disableVertexAttributes() {
    for (let i = 0; i < this.vertexAttributes.length; i += 1) {
      this.gl.disableVertexAttribArray(this.vertexAttributes[i]);
    }
  }

  private _compileShader(shaderType: 'VERTEX_SHADER' | 'FRAGMENT_SHADER', shaderSource: string): WebGLShader | null {
    const shader = this.gl.createShader(this.gl[shaderType]);

    if (shader) {
      this.gl.shaderSource(shader, shaderSource);
      this.gl.compileShader(shader);

      if (!this.gl.getShaderParameter(shader, this.gl.COMPILE_STATUS)) {
        console.warn('Failed to compile shader:', this.gl.getShaderInfoLog(shader));

        return null;
      }

      return shader;
    }

    console.warn('Failed to create shader!');

    return null;
  }

  private _createProgram(vertexShader: WebGLShader, fragmentShader: WebGLShader): WebGLProgram | null {
    const program = this.gl.createProgram();

    if (program) {
      this.gl.attachShader(program, vertexShader);
      this.gl.attachShader(program, fragmentShader);
      this.gl.linkProgram(program);

      if (!this.gl.getProgramParameter(program, this.gl.LINK_STATUS)) {
        console.warn(this.gl.getProgramInfoLog(program));

        return null;
      }

      return program;
    }

    return null;
  }

  private _loadProgram(program: WebGLProgram): void {
    this.gl.useProgram(program);
  }
}

export default Program;
