import type { AssetConfig } from '@g360/vt-types';
import { Mixin } from 'ts-mixer';
import urlJoin from 'url-join';

import Utils from '../../common/Utils/Utils';
import type { Image } from '../../types/internal';
import WebGLProgram from '../mixins/Program';
import fragmentShader from './measurePoints.fs.glsl';
import vertexShader from './measurePoints.vs.glsl';
import type MeasureToolProgram from './MeasureToolProgram';

const geometry = [1, 1, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0] as const;

/** Draws the flat measure points as well as flat crosshair center point */
class MeasurePointProgram extends Mixin(WebGLProgram) {
  private static inactiveImg: Image | null = null;
  private static activeImg: Image | null = null;
  private static crossImg: Image | null = null;

  gl: WebGLRenderingContext;
  canvas: HTMLCanvasElement;

  vertexBuffer: WebGLBuffer | null;
  uvBuffer: WebGLBuffer | null = null;
  /** Which point texture to use */
  textureBuffer: WebGLBuffer | null = null;

  vertexAttribute = 0;
  uvAttribute = 0;
  texAttribute = 0;

  inactiveTexUniform: WebGLUniformLocation | null = null;
  activeTexUniform: WebGLUniformLocation | null = null;
  crossTexUniform: WebGLUniformLocation | null = null;

  /** Normal measure point */
  private inactivePointTexture: WebGLTexture | null = null;
  /** Active (hovered) measure point */
  private activePointTexture: WebGLTexture | null = null;
  /** Crosshair center over missing data */
  private crossPointTexture: WebGLTexture | null = null;

  private abortController = window.AbortController ? new AbortController() : null;

  private mainProgram: MeasureToolProgram;

  private vertices = new Float32Array();
  /** Which point texture to use */
  private textureMap = new Float32Array();
  private uvMap = new Float32Array();

  private assetConfig: AssetConfig;

  constructor(
    webGLContext: WebGLRenderingContext,
    canvas: HTMLCanvasElement,
    assetConfig: AssetConfig,
    mainProgram: MeasureToolProgram
  ) {
    super();
    this.gl = webGLContext;
    this.canvas = canvas;
    this.mainProgram = mainProgram;
    this.assetConfig = assetConfig;

    this.vertexBuffer = this.gl.createBuffer();
    this.uvBuffer = this.gl.createBuffer();
    this.textureBuffer = this.gl.createBuffer();

    this.inactivePointTexture = this.createTexture();
    this.activePointTexture = this.createTexture();
    this.crossPointTexture = this.createTexture();

    this.loadPointTextures();
  }

  async init(): Promise<void> {
    this.initShaders(vertexShader, fragmentShader);

    if (this.program) {
      this.vertexAttribute = this.gl.getAttribLocation(this.program, 'a_texCoord');
      this.uvAttribute = this.gl.getAttribLocation(this.program, 'a_uv');
      this.texAttribute = this.gl.getAttribLocation(this.program, 'a_tex');

      this.inactiveTexUniform = this.gl.getUniformLocation(this.program, 'u_tex_inactive');
      this.activeTexUniform = this.gl.getUniformLocation(this.program, 'u_tex_active');
      this.crossTexUniform = this.gl.getUniformLocation(this.program, 'u_tex_cross');

      this.vertexAttributes = [this.vertexAttribute, this.uvAttribute, this.texAttribute];
    }
    await this.loadPointTextures();
  }

  async loadPointTextures(): Promise<void> {
    if (!MeasurePointProgram.inactiveImg || !MeasurePointProgram.activeImg || !MeasurePointProgram.crossImg) {
      const urlInactive = urlJoin(this.assetConfig.assetPath, 'measure/point_inactive.png');
      const urlActive = urlJoin(this.assetConfig.assetPath, 'measure/point_active.png');
      const urlCross = urlJoin(this.assetConfig.assetPath, 'measure/point_cross.png');

      const [inactiveImg, activeImg, crossImg] = await Promise.all([
        Utils.fetchImage(urlInactive, this.abortController),
        Utils.fetchImage(urlActive, this.abortController),
        Utils.fetchImage(urlCross, this.abortController),
      ]);

      if (!inactiveImg || !activeImg || !crossImg) {
        throw new Error('Failed to load measure point textures');
      }

      MeasurePointProgram.inactiveImg = inactiveImg;
      MeasurePointProgram.activeImg = activeImg;
      MeasurePointProgram.crossImg = crossImg;
    }

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.inactivePointTexture);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR_MIPMAP_LINEAR);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
    this.gl.texImage2D(
      this.gl.TEXTURE_2D,
      0,
      this.gl.RGBA,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      MeasurePointProgram.inactiveImg
    );
    this.gl.generateMipmap(this.gl.TEXTURE_2D);

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.activePointTexture);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR_MIPMAP_LINEAR);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
    this.gl.texImage2D(
      this.gl.TEXTURE_2D,
      0,
      this.gl.RGBA,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      MeasurePointProgram.activeImg
    );
    this.gl.generateMipmap(this.gl.TEXTURE_2D);

    this.gl.bindTexture(this.gl.TEXTURE_2D, this.crossPointTexture);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR_MIPMAP_LINEAR);
    this.gl.texParameteri(this.gl.TEXTURE_2D, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
    this.gl.texImage2D(
      this.gl.TEXTURE_2D,
      0,
      this.gl.RGBA,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      MeasurePointProgram.crossImg
    );
    this.gl.generateMipmap(this.gl.TEXTURE_2D);
  }

  destroy(): void {
    this.gl.deleteTexture(this.inactivePointTexture);
    this.gl.deleteTexture(this.activePointTexture);
    this.gl.deleteTexture(this.crossPointTexture);
    this.abortPending();
    this.destroyProgram();
  }

  abortPending(): void {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = new AbortController();
    }
  }

  draw(): void {
    this.loadShaders();
    this.enableVertexAttributes();

    this.gl.enable(this.gl.CULL_FACE);
    this.gl.cullFace(this.gl.FRONT);

    const neededVertices = this.mainProgram.measurePoints.length * 12 + 12;
    if (this.vertices.length !== neededVertices) this.vertices = new Float32Array(neededVertices);
    if (this.uvMap.length !== neededVertices) this.uvMap = new Float32Array(neededVertices);
    const neededActive = this.mainProgram.measurePoints.length * 6 + 6;
    if (this.textureMap.length !== neededActive) this.textureMap = new Float32Array(neededActive);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.inactivePointTexture);
    this.gl.uniform1i(this.inactiveTexUniform, 2);

    this.gl.activeTexture(this.gl.TEXTURE3);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.activePointTexture);
    this.gl.uniform1i(this.activeTexUniform, 3);

    this.gl.activeTexture(this.gl.TEXTURE4);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.crossPointTexture);
    this.gl.uniform1i(this.crossTexUniform, 4);

    const radiusX = (this.mainProgram.pointRadius * window.devicePixelRatio) / this.gl.drawingBufferWidth;
    const radiusY = (this.mainProgram.pointRadius * window.devicePixelRatio) / this.gl.drawingBufferHeight;

    const cursorPos = this.mainProgram.cursorCoords?.snapPosition ?? this.mainProgram.cursorCoords;

    if (cursorPos) {
      // Draw crosshair center point
      const cursorX = cursorPos.screenX;
      const cursorY = cursorPos.screenY;

      // On mobile we scale down the crosshair center point so it doesn't fill up as much of the zoom scope
      const cursorRadiusX = this.mainProgram.isHandheld ? radiusX * 0.6 : radiusX;
      const cursorRadiusY = this.mainProgram.isHandheld ? radiusY * 0.6 : radiusY;

      const usedTex = cursorPos.distance === 0 ? 2 : 0;

      this.textureMap[0] = usedTex;
      this.textureMap[1] = usedTex;
      this.textureMap[2] = usedTex;
      this.textureMap[3] = usedTex;
      this.textureMap[4] = usedTex;
      this.textureMap[5] = usedTex;

      this.vertices[0] = cursorX + cursorRadiusX;
      this.vertices[1] = cursorY + cursorRadiusY;
      this.vertices[2] = cursorX - cursorRadiusX;
      this.vertices[3] = cursorY - cursorRadiusY;
      this.vertices[4] = cursorX - cursorRadiusX;
      this.vertices[5] = cursorY + cursorRadiusY;
      this.vertices[6] = cursorX + cursorRadiusX;
      this.vertices[7] = cursorY + cursorRadiusY;
      this.vertices[8] = cursorX + cursorRadiusX;
      this.vertices[9] = cursorY - cursorRadiusY;
      this.vertices[10] = cursorX - cursorRadiusX;
      this.vertices[11] = cursorY - cursorRadiusY;
      this.uvMap[0] = geometry[0];
      this.uvMap[1] = geometry[1];
      this.uvMap[2] = geometry[2];
      this.uvMap[3] = geometry[3];
      this.uvMap[4] = geometry[4];
      this.uvMap[5] = geometry[5];
      this.uvMap[6] = geometry[6];
      this.uvMap[7] = geometry[7];
      this.uvMap[8] = geometry[8];
      this.uvMap[9] = geometry[9];
      this.uvMap[10] = geometry[10];
      this.uvMap[11] = geometry[11];

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
      this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
      this.gl.enableVertexAttribArray(this.vertexAttribute);
      this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvBuffer);
      this.gl.bufferData(this.gl.ARRAY_BUFFER, this.uvMap, this.gl.DYNAMIC_DRAW);
      this.gl.enableVertexAttribArray(this.uvAttribute);
      this.gl.vertexAttribPointer(this.uvAttribute, 2, this.gl.FLOAT, false, 0, 0);

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureBuffer);
      this.gl.bufferData(this.gl.ARRAY_BUFFER, this.textureMap, this.gl.DYNAMIC_DRAW);
      this.gl.enableVertexAttribArray(this.texAttribute);
      this.gl.vertexAttribPointer(this.texAttribute, 1, this.gl.FLOAT, false, 0, 0);

      this.gl.drawArrays(this.gl.TRIANGLES, 0, 6);
    }
    // return;

    for (let i = 0; i < this.mainProgram.measurePoints.length; i += 1) {
      const p = this.mainProgram.measurePoints[i];

      const v1 = [p.screenX + radiusX, p.screenY + radiusY];
      const v2 = [p.screenX - radiusX, p.screenY + radiusY];
      const v3 = [p.screenX - radiusX, p.screenY - radiusY];
      const v4 = [p.screenX + radiusX, p.screenY - radiusY];

      const vertOffset = i * 12 + 12;
      const activeOffset = i * 6 + 6;

      const active = p.active ? 1 : 0;

      this.textureMap[activeOffset + 0] = active;
      this.textureMap[activeOffset + 1] = active;
      this.textureMap[activeOffset + 2] = active;
      this.textureMap[activeOffset + 3] = active;
      this.textureMap[activeOffset + 4] = active;
      this.textureMap[activeOffset + 5] = active;

      // v1
      this.vertices[vertOffset + 0] = v1[0];
      this.vertices[vertOffset + 1] = v1[1];
      this.uvMap[vertOffset + 0] = geometry[0];
      this.uvMap[vertOffset + 1] = geometry[1];
      // v3
      this.vertices[vertOffset + 2] = v3[0];
      this.vertices[vertOffset + 3] = v3[1];
      this.uvMap[vertOffset + 2] = geometry[2];
      this.uvMap[vertOffset + 3] = geometry[3];
      // v2
      this.vertices[vertOffset + 4] = v2[0];
      this.vertices[vertOffset + 5] = v2[1];
      this.uvMap[vertOffset + 4] = geometry[4];
      this.uvMap[vertOffset + 5] = geometry[5];
      // v1
      this.vertices[vertOffset + 6] = v1[0];
      this.vertices[vertOffset + 7] = v1[1];
      this.uvMap[vertOffset + 6] = geometry[6];
      this.uvMap[vertOffset + 7] = geometry[7];
      // v4
      this.vertices[vertOffset + 8] = v4[0];
      this.vertices[vertOffset + 9] = v4[1];
      this.uvMap[vertOffset + 8] = geometry[8];
      this.uvMap[vertOffset + 9] = geometry[9];
      // v3
      this.vertices[vertOffset + 10] = v3[0];
      this.vertices[vertOffset + 11] = v3[1];
      this.uvMap[vertOffset + 10] = geometry[10];
      this.uvMap[vertOffset + 11] = geometry[11];
    }

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.vertexBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.vertices, this.gl.DYNAMIC_DRAW);
    this.gl.enableVertexAttribArray(this.vertexAttribute);
    this.gl.vertexAttribPointer(this.vertexAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.uvBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.uvMap, this.gl.DYNAMIC_DRAW);
    this.gl.enableVertexAttribArray(this.uvAttribute);
    this.gl.vertexAttribPointer(this.uvAttribute, 2, this.gl.FLOAT, false, 0, 0);

    this.gl.activeTexture(this.gl.TEXTURE2);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.inactivePointTexture);
    this.gl.uniform1i(this.inactiveTexUniform, 2);

    this.gl.activeTexture(this.gl.TEXTURE3);
    this.gl.bindTexture(this.gl.TEXTURE_2D, this.activePointTexture);
    this.gl.uniform1i(this.activeTexUniform, 3);

    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.textureBuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, this.textureMap, this.gl.DYNAMIC_DRAW);
    this.gl.enableVertexAttribArray(this.texAttribute);
    this.gl.vertexAttribPointer(this.texAttribute, 1, this.gl.FLOAT, false, 0, 0);

    this.gl.drawArrays(this.gl.TRIANGLES, 6, this.vertices.length / 2 - 6);

    this.gl.disable(this.gl.CULL_FACE);
    this.disableVertexAttributes();
  }
}

export default MeasurePointProgram;
