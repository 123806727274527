import type Engine from "@g360/vt-engine";
import type { Services } from "@g360/vt-player";
import {
  GuidedViewingConfig,
  GuidedViewingGuestObserver,
  GuidedViewingStreamEvent,
  TourConfig,
} from "@g360/vt-types";
import Main from "@g360/vt-player";
import "@g360/vt-player/lib/index.css";
import { Pointer } from "components/elements";
import { use100vh, usePingAnimation, useTourEvents } from "hooks";
import { FC, useEffect, useRef, useState } from "react";
import useAppStateContext from "hooks/useAppStateContext";
import { loadTourJson } from "@g360/vt-utils";

interface VirtualTourWrapperProps {
  isInControlOfTour: boolean;
  infoModalOpen: boolean;
  /** Called when the info modal is toggled, use to sync host app info modal open state */
  onInfoModalToggle?: (open: boolean) => void;
}

const VirtualTourWrapper: FC<VirtualTourWrapperProps> = ({
  isInControlOfTour,
  infoModalOpen,
  onInfoModalToggle,
}) => {
  const [tourConfig, setTourConfig] = useState<TourConfig | null>(null);
  const [appState, setAppState] = useState<{
    engine: Engine;
    services: Services;
  } | null>(null);
  const rootRef = useRef<HTMLElement>(document.body);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const { divRef } = use100vh();
  const [remoteViewingConfig, setRemoteViewingConfig] =
    useState<GuidedViewingConfig | null>(null);

  const { pingPixelCoords, setPingPixelCoords } = usePingAnimation(
    isInControlOfTour,
    appState?.engine || null,
    canvasRef
  );

  const { remoteFrames$, sendTourKeyFrame } = useTourEvents();
  const { assetConfig, jsonPath } = useAppStateContext();

  const handleEngineReady = (engine: Engine, services: Services) => {
    setAppState({ engine, services });
  };

  const handleEngineError = (error: unknown) => {
    // eslint-disable-next-line no-console
    console.error(error);
  };

  useEffect(() => {
    if (!assetConfig || !jsonPath) return;

    const abortController = new AbortController();

    const init = async () => {
      const nextTourConfig = await loadTourJson(
        jsonPath,
        assetConfig.signatureData,
        abortController.signal
      );
      setTourConfig(nextTourConfig);
    };

    init();

    return () => {
      abortController.abort();
    };
  }, [assetConfig, jsonPath]);

  useEffect(() => {
    if (!appState) return;

    setRemoteViewingConfig({
      isInControlOfTour,
      onHostEmit: (data: GuidedViewingStreamEvent) => {
        // Sending keyframes to remote participants
        sendTourKeyFrame(data);
      },
      onGuestReceive: (observer: GuidedViewingGuestObserver) => {
        // Receiving data from remote participant
        remoteFrames$.subscribe({
          next: (data) => {
            observer.next(data);
          },
          error: (error) => console.error(error),
          complete: () => observer.unsubscribe(),
        });
      },
    });
  }, [appState, isInControlOfTour, remoteFrames$, sendTourKeyFrame]);

  useEffect(() => {
    if (!appState || !remoteViewingConfig) return;

    const { engine } = appState;
    engine.initGuidedViewing(remoteViewingConfig);
  }, [appState, remoteViewingConfig]);

  return (
    <>
      <div ref={divRef} className="relative">
        {pingPixelCoords !== null && (
          <Pointer
            x={pingPixelCoords.x}
            y={pingPixelCoords.y}
            clearPercentCoords={() => {
              // Remove ping pointer element after the element animation is complete
              setPingPixelCoords(null);
            }}
          />
        )}
        <canvas
          id="vt-canvas"
          className="absolute left-0 top-0 h-full w-full outline-none"
          ref={canvasRef}
        />
        {tourConfig && canvasRef?.current && assetConfig && (
          <div id="vt-skin">
            <Main
              appContext="guided-viewing"
              tourConfig={tourConfig}
              assetConfig={assetConfig}
              canvasElem={canvasRef.current}
              noWelcomeScreen
              noCookieConsent
              language="en"
              hostRootRef={rootRef}
              infoModalOpen={infoModalOpen}
              onInfoModalToggle={onInfoModalToggle}
              onEngineReady={handleEngineReady}
              onEngineError={handleEngineError}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default VirtualTourWrapper;
