/* eslint-disable no-restricted-syntax,no-continue */
import type { SceneConfig } from '@g360/vt-types';

import Camera from '../Camera';
import FloorPlan3DProgram from '../FloorPlan3DProgram';
import FPWebGLProgram from '../FPWebGLProgram';
import ModelNavigation from '../ModelNavigation';
import { FPMesh } from '../types';
import fragmentShader from './outline.fs.glsl';
import vertexShader from './outline.vs.glsl';

/**
 * Program for rendering the outlines with adaptive thickness
 * Mesh must have expandCoords
 */
class OutlineProgram extends FPWebGLProgram {
  sceneConfig?: SceneConfig;

  private readonly navigation: ModelNavigation; // @todo -- rem
  private readonly camera: Camera;

  constructor(
    gl: WebGL2RenderingContext,
    canvas: HTMLCanvasElement,
    parentProgram: FloorPlan3DProgram,
    navigation: ModelNavigation,
    camera: Camera
  ) {
    super(gl, canvas, parentProgram);
    this.navigation = navigation;
    this.camera = camera;
  }

  init(): void {
    super.init(vertexShader, fragmentShader);
    this.vertexAttributes = [this.positionLocation, this.expandCoordLocation];
    this.gl.vertexAttribPointer(this.positionLocation, 3, this.gl.FLOAT, false, 0, 0);
    this.gl.vertexAttribPointer(this.expandCoordLocation, 3, this.gl.FLOAT, false, 0, 0);
  }

  loadGeometry(meshes: FPMesh[]): void {
    this.meshes = meshes;

    for (let m = 0; m < meshes.length; m += 1) {
      const mesh = meshes[m];

      if (mesh.dataNum === 0) continue; // skip bad meshes
      if (!mesh.isOutline) continue;

      this.goodMeshIds.push(m);
    }
  }

  draw(): void {
    if (!this.gl) return;

    this.loadShaders();
    this.enableVertexAttributes();
    this.gl.disable(this.gl.CULL_FACE); // current 3d pipes look better when rendered from both sides

    this.gl.uniform3fv(this.actualCameraPosLocation, this.parentProgram.simpleCameraPosFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixWorldPosLocation, false, this.parentProgram.matrixWorldFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixViewPosLocation, false, this.parentProgram.matrixViewFloat32Array);
    this.gl.uniformMatrix4fv(this.matrixProjectionPosLocation, false, this.parentProgram.matrixProjectionFloat32Array);

    // @todo -- rem later
    // const time = Math.abs(Math.sin(Date.now() * 0.001)); // oscillating between 0 and 1
    // this.gl.uniform1f(this.timeLocation, time);

    // @todo -- check for all project sizes
    this.gl.uniform1f(this.farLocation, 10000); // farthest distance possible, max expansion fx will be applied

    for (const meshId of this.goodMeshIds) {
      const mesh = this.meshes[meshId];

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.parentProgram.positionBuffer);
      this.gl.vertexAttribPointer(this.positionLocation, 3, this.gl.FLOAT, false, 0, mesh.dataOffsetPositions);

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.parentProgram.expandCoordBuffer);
      this.gl.vertexAttribPointer(this.expandCoordLocation, 3, this.gl.FLOAT, false, 0, mesh.dataOffsetExpandCoords);

      this.gl.drawArrays(this.gl.TRIANGLES, 0, mesh.dataNum);
    }

    this.gl.enable(this.gl.CULL_FACE);
    this.disableVertexAttributes();
  }
}

export default OutlineProgram;
