import { jsx as _jsx } from "react/jsx-runtime";
function getVariant(variant, theme) {
    if (!variant || !theme)
        return '';
    return {
        primary: {
            dark: 'bg-white text-theme-secondary hover:bg-modal-dark hover:text-theme-primary',
            light: 'bg-modal-dark text-white hover:bg-white hover:text-modal-dark',
        },
        secondary: {
            dark: 'text-theme-primary border-2 border-white hover:border-modal-dark',
            light: 'text-modal-dark border-2 border-modal-dark hover:border-white',
        },
    }[variant][theme];
}
const TextButton = ({ className, variant = 'primary', theme = 'dark', children, onClick, fullWidth }) => {
    const twVariant = getVariant(variant, theme);
    return (_jsx("button", Object.assign({ className: `
        pointer-events-auto relative flex h-8 items-center justify-center
        overflow-hidden whitespace-nowrap rounded-lg px-2 text-center font-primary
        text-sm font-bold ${twVariant} ${className} ${fullWidth ? 'w-full' : 'w-[6.5rem]'}`, type: "button", onClick: onClick }, { children: children })));
};
export default TextButton;
