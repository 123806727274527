class Cube2Equirect {
  private efSqrt = false;
  private efPow = false;
  private stopMov = false;
  private sphereMap = false;
  private readonly width = 512 * 4;
  private readonly height = 512 * 2;
  private downR = 180;
  private upR = 180;
  private frontR = 0;
  private backR = 0;
  private rightR = 0;
  private leftR = 0;
  private canvasTemp: HTMLCanvasElement;
  private waitForTexturesToLoad = false;
  private leftImg?: HTMLImageElement;
  private rightImg?: HTMLImageElement;
  private backImg?: HTMLImageElement;
  private frontImg?: HTMLImageElement;
  private downImg?: HTMLImageElement;
  private upImg?: HTMLImageElement;
  private canvas?: HTMLCanvasElement;
  private gl: WebGL2RenderingContext | WebGLRenderingContext | null;
  private shader?: WebGLProgram;
  private cubemapTex: any;
  private afterDrawFrame = false;
  private reqDraw = false;
  private onCreated?: (image: HTMLImageElement) => void;

  constructor() {
    this.canvasTemp = document.createElement('canvas');
    this.gl = null;

    this.initializeGL();
    this.InitializeShader();
    this.launchWebgl();
  }

  public async convert(comboImages: HTMLImageElement[], onCreated: (image: HTMLImageElement) => void): Promise<void> {
    const onDone = () => {
      this.waitForTexturesToLoad = true;
      this.setTextures(comboImages);
      this.onCreated = onCreated;
    };

    let numWaiting = comboImages.length;
    for (let i = 0; i < comboImages.length; i += 1) {
      if (comboImages[i].complete) {
        numWaiting -= 1; // tick off already loaded images
      } else {
        // eslint-disable-next-line no-loop-func
        comboImages[i].addEventListener('load', () => {
          numWaiting -= 1;
          if (numWaiting === 0) {
            // when all images have finished loading
            onDone();
          }
        });
      }
    }

    // all images were already loaded
    if (numWaiting === 0) {
      onDone();
    }
  }

  private initializeGL(): void {
    this.canvas = document.createElement('canvas');
    this.gl = this.canvas.getContext('webgl2');
    if (!this.gl) {
      this.gl = this.canvas.getContext('webgl');
    }
  }

  private InitializeShader(): void {
    if (!this.gl) return;

    let ErrorMessage = '';

    const shaderVs = this.gl.createShader(this.gl.VERTEX_SHADER);
    const shaderFrag = this.gl.createShader(this.gl.FRAGMENT_SHADER);
    if (!shaderVs || !shaderFrag) return;

    this.gl.shaderSource(
      shaderVs,
      `precision mediump float;
attribute vec4 a_Position;
attribute vec4 a_Color;
varying vec4 color;
void main() {
gl_Position = a_Position;
}
`
    );
    this.gl.shaderSource(
      shaderFrag,
      `precision mediump float;
varying vec4 color;
uniform float u_time;
uniform vec2 u_resolution;
uniform vec2 u_mouse;
//uniform sampler2D u_texture1;
uniform samplerCube u_cubemap1;
uniform bool ef_sqrt;
uniform bool ef_pow;
uniform bool stop_mov;
uniform bool sphere_map;

#define iResolution u_resolution
#define iTime u_time
#define iMouse u_mouse
#define iChannel0 u_cubemap1

float pi = 3.1415926536;

void get_panorama( out vec4 fragColor, in vec2 P ) {
float theta = (1.0 - P.y) * pi;
float phi   = P.x * pi * 2.0;
if(!stop_mov)phi += sin(iTime/5.)*pi;
vec3 dir = vec3(sin(theta) * sin(phi), cos(theta), sin(theta) * cos(phi));
fragColor = (textureCube(iChannel0, dir));
if(ef_sqrt)
fragColor=sqrt(fragColor);
if(ef_pow)
fragColor*=fragColor;
fragColor.a=1.;
}

vec3 SetCamera(vec2 uv)
{
    vec3 ro = vec3(0.,2.,0.);
    vec2 m = (iMouse.xy*2.5)/iResolution.xy;
    m.x=-m.x;
    uv.y=-uv.y;
    vec3 rd = normalize(vec3(uv, 1.5));
    mat3 rotX = mat3(1.0, 0.0, 0.0, 0.0, cos(m.y), sin(m.y), 0.0, -sin(m.y), cos(m.y));
    mat3 rotY = mat3(cos(m.x), 0.0, -sin(m.x), 0.0, 1.0, 0.0, sin(m.x), 0.0, cos(m.x));
    rd = (rotY * rotX) * rd;
    return rd;
}

vec2 uv_sphere(vec3 v)
{
return vec2(0.5 + atan(v.z, v.x) / (2.0 * pi), acos(v.y) / pi);
}

void to_sphere( out vec4 fragColor, in vec2 fragCoord )
{
    vec2 P = fragCoord.xy / iResolution.y-(iResolution.xy/iResolution.y)/2.;
vec3 dir=SetCamera(P);
    get_panorama(fragColor, uv_sphere(dir));
}

void mainImage( out vec4 fragColor, in vec2 fragCoord) {
vec2 P = fragCoord.xy / iResolution.xy;
if(!sphere_map)get_panorama(fragColor,P);
else to_sphere(fragColor,fragCoord);
fragColor.a=1.;
}

void main( void ) {
vec4 fragColor=vec4(0.);
mainImage(fragColor,gl_FragCoord.xy);
gl_FragColor = fragColor;
}
`
    );
    this.gl.compileShader(shaderVs);
    this.gl.compileShader(shaderFrag);
    let error = false;
    if (!this.gl.getShaderParameter(shaderVs, this.gl.COMPILE_STATUS)) {
      ErrorMessage += this.gl.getShaderInfoLog(shaderVs);
      error = true;
    }
    if (!this.gl.getShaderParameter(shaderFrag, this.gl.COMPILE_STATUS)) {
      ErrorMessage += this.gl.getShaderInfoLog(shaderFrag);
      error = true;
    }
    const program = this.gl.createProgram();
    if (!program) return;

    const ret = this.gl.getProgramInfoLog(program);
    if (ret !== '') {
      ErrorMessage += ret;
    }
    this.gl.attachShader(program, shaderVs);
    this.gl.attachShader(program, shaderFrag);
    this.gl.linkProgram(program);

    if (error) {
      console.log(`${ErrorMessage} ...failed to initialize shader.`);
    } else {
      this.shader = program;
    }
  }

  private setupCubeMap(): boolean {
    if (!this.gl) return false;

    // are all pics square
    if (
      !(
        this.leftImg?.width === this.rightImg?.width &&
        this.rightImg?.width === this.upImg?.width &&
        this.upImg?.width === this.downImg?.width &&
        this.downImg?.width === this.backImg?.width &&
        this.backImg?.width === this.frontImg?.width
      )
    ) {
      console.error('Cube2Equirect::setupCubeMap:images are supposed to be square');
      return false;
    }

    // are all pics loaded
    if (!(this.leftImg && this.rightImg && this.backImg && this.frontImg && this.downImg && this.upImg)) return false;

    this.cubemapTex = this.gl.createTexture();
    this.gl.bindTexture(this.gl.TEXTURE_CUBE_MAP, this.cubemapTex);

    let dataTypedArray = new Uint8Array(this.rotateImg(this.leftImg, this.leftR));
    this.gl.texImage2D(
      this.gl.TEXTURE_CUBE_MAP_POSITIVE_X,
      0,
      this.gl.RGBA,
      this.leftImg?.width || 0,
      this.leftImg?.height || 0,
      0,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      dataTypedArray
    );

    dataTypedArray = new Uint8Array(this.rotateImg(this.rightImg, this.rightR));
    this.gl.texImage2D(
      this.gl.TEXTURE_CUBE_MAP_NEGATIVE_X,
      0,
      this.gl.RGBA,
      this.rightImg?.width || 0,
      this.rightImg?.height || 0,
      0,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      dataTypedArray
    );

    dataTypedArray = new Uint8Array(this.rotateImg(this.upImg, this.upR));
    this.gl.texImage2D(
      this.gl.TEXTURE_CUBE_MAP_POSITIVE_Y,
      0,
      this.gl.RGBA,
      this.upImg?.width || 0,
      this.upImg?.height || 0,
      0,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      dataTypedArray
    );

    dataTypedArray = new Uint8Array(this.rotateImg(this.downImg, this.downR));
    this.gl.texImage2D(
      this.gl.TEXTURE_CUBE_MAP_NEGATIVE_Y,
      0,
      this.gl.RGBA,
      this.downImg?.width || 0,
      this.downImg?.height || 0,
      0,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      dataTypedArray
    );

    dataTypedArray = new Uint8Array(this.rotateImg(this.backImg, this.backR));
    this.gl.texImage2D(
      this.gl.TEXTURE_CUBE_MAP_POSITIVE_Z,
      0,
      this.gl.RGBA,
      this.backImg?.width || 0,
      this.backImg?.height || 0,
      0,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      dataTypedArray
    );

    dataTypedArray = new Uint8Array(this.rotateImg(this.frontImg, this.frontR));
    this.gl.texImage2D(
      this.gl.TEXTURE_CUBE_MAP_NEGATIVE_Z,
      0,
      this.gl.RGBA,
      this.frontImg?.width || 0,
      this.frontImg?.height || 0,
      0,
      this.gl.RGBA,
      this.gl.UNSIGNED_BYTE,
      dataTypedArray
    );

    this.gl.texParameteri(this.gl.TEXTURE_CUBE_MAP, this.gl.TEXTURE_MAG_FILTER, this.gl.LINEAR);
    this.gl.texParameteri(this.gl.TEXTURE_CUBE_MAP, this.gl.TEXTURE_MIN_FILTER, this.gl.LINEAR);
    return true;
    //  console.log("cubemap rendered");
  }

  private rotateImg(img: HTMLImageElement, angle: number): ArrayBufferLike {
    this.canvasTemp.width = img.width;
    this.canvasTemp.height = img.height;
    const ctx = this.canvasTemp.getContext('2d', { willReadFrequently: true });
    if (ctx) {
      ctx.translate(this.canvasTemp.width / 2, this.canvasTemp.height / 2);
      ctx.rotate((angle * Math.PI) / 180);
      ctx.drawImage(img, -img.width / 2, -img.width / 2);
      ctx.restore();
      const imageData = ctx.getImageData(0, 0, img.width, img.height);
      return imageData.data.buffer;
    }
    return new ArrayBuffer(0);
  }

  private setTextures(comboImages: HTMLImageElement[]): void {
    // ['f', 'b', 'u', 'd', 'l', 'r'];
    //   0    1    2    3    4    5
    this.leftImg = comboImages[4];
    this.rightImg = comboImages[5];
    this.backImg = comboImages[1];
    this.frontImg = comboImages[0];
    this.downImg = comboImages[3];
    this.upImg = comboImages[2];
  }

  private launchWebgl(): void {
    if (!this.gl || !this.shader) return;

    const vertices = new Float32Array([-1.0, 1.0, 0.0, -1.0, -1.0, 0.0, 1.0, -1.0, 0.0, 1.0, 1.0, 0.0]);
    const indices = [0, 1, 2, 2, 3, 0];
    const vertexbuffer = this.gl.createBuffer();
    const indexbuffer = this.gl.createBuffer();
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, vertexbuffer);
    this.gl.bufferData(this.gl.ARRAY_BUFFER, new Float32Array(vertices), this.gl.STATIC_DRAW);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, null);
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, indexbuffer);
    this.gl.bufferData(this.gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indices), this.gl.STATIC_DRAW);
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, null);
    this.gl.bindBuffer(this.gl.ARRAY_BUFFER, vertexbuffer);
    this.gl.bindBuffer(this.gl.ELEMENT_ARRAY_BUFFER, indexbuffer);
    this.gl.viewport(0, 0, this.width, this.height);
    const coords = this.gl.getAttribLocation(this.shader, 'a_Position');
    this.gl.vertexAttribPointer(coords, 3, this.gl.FLOAT, false, 0, 0);
    this.gl.enableVertexAttribArray(coords);

    window.requestAnimationFrame(() => this.frameStep());
  }

  private frameStep(): void {
    if (!this.gl || !this.shader || !this.canvas) return;

    if (this.reqDraw) {
      this.gl.clearColor(0.0, 0.0, 0.0, 1.0);
      this.gl.clear(this.gl.COLOR_BUFFER_BIT);
      this.gl.useProgram(this.shader);
      this.gl.uniform2f(this.gl.getUniformLocation(this.shader, 'u_resolution'), this.width, this.height);
      this.gl.uniform1i(this.gl.getUniformLocation(this.shader, 'ef_sqrt'), this.efSqrt ? 1 : 0);
      this.gl.uniform1i(this.gl.getUniformLocation(this.shader, 'ef_pow'), this.efPow ? 1 : 0);
      this.gl.uniform1i(this.gl.getUniformLocation(this.shader, 'stop_mov'), this.stopMov ? 1 : 0);
      this.gl.uniform1i(this.gl.getUniformLocation(this.shader, 'sphere_map'), this.sphereMap ? 1 : 0);
      // gl.activeTexture(this.gl.TEXTURE0);
      this.gl.bindTexture(this.gl.TEXTURE_CUBE_MAP, this.cubemapTex);
      this.gl.uniform1i(this.gl.getUniformLocation(this.shader, 'u_cubemap1'), 0);

      this.gl.drawElements(this.gl.TRIANGLES, 6, this.gl.UNSIGNED_SHORT, 0);
    }

    if (this.afterDrawFrame) {
      this.saveImage();
      this.afterDrawFrame = false;
      this.reqDraw = false;
      this.gl.viewport(0, 0, this.width, this.height);
      this.sphereMap = false;
      // console.log("RENDER:save image");
    }
    if (this.reqDraw) {
      this.canvas.height = this.height;
      this.canvas.width = this.width;
      this.gl.viewport(0, 0, this.width, this.height);
      this.afterDrawFrame = true;
      this.sphereMap = false;
      // console.log("RENDER:just some draw");
    }

    if (this.waitForTexturesToLoad) {
      if (this.leftImg && this.rightImg && this.backImg && this.frontImg && this.downImg && this.upImg) {
        this.gl.deleteTexture(this.cubemapTex);
        if (this.setupCubeMap()) {
          this.reqDraw = true;
          this.waitForTexturesToLoad = false;
        } else {
          console.log("setupCubeMap failed, let's try again later");
        }
      }
    }

    window.requestAnimationFrame(() => this.frameStep());
  }

  private saveImage(): void {
    if (!this.canvas || !this.onCreated) return;

    const dataurl = this.canvas.toDataURL('image/jpg');
    const img = document.createElement('img');
    img.src = dataurl;
    this.onCreated(img);
  }
}

export default Cube2Equirect;
