import { useEffect, useState } from 'react';
export default function useReactive(reactive, keys) {
    const [state, setState] = useState(() => keys.map((key) => ({ [key]: reactive[key] })).reduce((acc, item) => (Object.assign(Object.assign({}, acc), item)), {}));
    useEffect(() => {
        if (!reactive || !keys.length)
            return () => undefined;
        // If `reactive` changes, we need to update the state to match the new instance
        setState(() => keys.map((key) => ({ [key]: reactive[key] })).reduce((acc, item) => (Object.assign(Object.assign({}, acc), item)), {}));
        const cleaners = keys.map((key) => reactive.onChange(key, (val) => {
            setState((prev) => (Object.assign(Object.assign({}, prev), { [key]: val })));
        }));
        return () => cleaners.forEach((clean) => clean());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...keys, reactive]);
    return state;
}
