import type { Size } from '@g360/vt-types';

// TODO: improve type-checking

function getAngleSinCos(angleInRadians: number): { s: number; c: number } {
  return {
    s: Math.sin(angleInRadians),
    c: Math.cos(angleInRadians),
  };
}

export function rotationM3(angleInRadians: number): number[] {
  const { s, c } = getAngleSinCos(angleInRadians);

  // prettier-ignore
  return [
    c,-s, 0,
    s, c, 0,
    0, 0, 1,
  ];
}

export function scalingM3(sx: number, sy: number): number[] {
  // prettier-ignore
  return [
    sx, 0, 0,
    0, sy, 0,
    0, 0, 1,
  ];
}

export function translationM3(tx: number, ty: number): number[] {
  // prettier-ignore
  return [
    1, 0, 0,
    0, 1, 0,
    tx, ty, 1,
  ];
}

export function translationM4(tx: number, ty: number, tz: number): number[] {
  // prettier-ignore
  return [
    1, 0, 0, 0,
    0, 1, 0, 0,
    0, 0, 1, 0,
    tx, ty, tz, 1,
  ]
}

export function identityM3(): number[] {
  // prettier-ignore
  return [
    1, 0, 0,
    0, 1, 0,
    0, 0, 1,
  ]
}

export function invertM4(m: number[]): number[] {
  const result = new Array(16);

  const inv = new Array(16);
  inv[0] =
    m[5] * m[10] * m[15] -
    m[5] * m[11] * m[14] -
    m[9] * m[6] * m[15] +
    m[9] * m[7] * m[14] +
    m[13] * m[6] * m[11] -
    m[13] * m[7] * m[10];
  inv[4] =
    -m[4] * m[10] * m[15] +
    m[4] * m[11] * m[14] +
    m[8] * m[6] * m[15] -
    m[8] * m[7] * m[14] -
    m[12] * m[6] * m[11] +
    m[12] * m[7] * m[10];
  inv[8] =
    m[4] * m[9] * m[15] -
    m[4] * m[11] * m[13] -
    m[8] * m[5] * m[15] +
    m[8] * m[7] * m[13] +
    m[12] * m[5] * m[11] -
    m[12] * m[7] * m[9];
  inv[12] =
    -m[4] * m[9] * m[14] +
    m[4] * m[10] * m[13] +
    m[8] * m[5] * m[14] -
    m[8] * m[6] * m[13] -
    m[12] * m[5] * m[10] +
    m[12] * m[6] * m[9];
  inv[1] =
    -m[1] * m[10] * m[15] +
    m[1] * m[11] * m[14] +
    m[9] * m[2] * m[15] -
    m[9] * m[3] * m[14] -
    m[13] * m[2] * m[11] +
    m[13] * m[3] * m[10];
  inv[5] =
    m[0] * m[10] * m[15] -
    m[0] * m[11] * m[14] -
    m[8] * m[2] * m[15] +
    m[8] * m[3] * m[14] +
    m[12] * m[2] * m[11] -
    m[12] * m[3] * m[10];
  inv[9] =
    -m[0] * m[9] * m[15] +
    m[0] * m[11] * m[13] +
    m[8] * m[1] * m[15] -
    m[8] * m[3] * m[13] -
    m[12] * m[1] * m[11] +
    m[12] * m[3] * m[9];
  inv[13] =
    m[0] * m[9] * m[14] -
    m[0] * m[10] * m[13] -
    m[8] * m[1] * m[14] +
    m[8] * m[2] * m[13] +
    m[12] * m[1] * m[10] -
    m[12] * m[2] * m[9];
  inv[2] =
    m[1] * m[6] * m[15] -
    m[1] * m[7] * m[14] -
    m[5] * m[2] * m[15] +
    m[5] * m[3] * m[14] +
    m[13] * m[2] * m[7] -
    m[13] * m[3] * m[6];
  inv[6] =
    -m[0] * m[6] * m[15] +
    m[0] * m[7] * m[14] +
    m[4] * m[2] * m[15] -
    m[4] * m[3] * m[14] -
    m[12] * m[2] * m[7] +
    m[12] * m[3] * m[6];
  inv[10] =
    m[0] * m[5] * m[15] -
    m[0] * m[7] * m[13] -
    m[4] * m[1] * m[15] +
    m[4] * m[3] * m[13] +
    m[12] * m[1] * m[7] -
    m[12] * m[3] * m[5];
  inv[14] =
    -m[0] * m[5] * m[14] +
    m[0] * m[6] * m[13] +
    m[4] * m[1] * m[14] -
    m[4] * m[2] * m[13] -
    m[12] * m[1] * m[6] +
    m[12] * m[2] * m[5];
  inv[3] =
    -m[1] * m[6] * m[11] +
    m[1] * m[7] * m[10] +
    m[5] * m[2] * m[11] -
    m[5] * m[3] * m[10] -
    m[9] * m[2] * m[7] +
    m[9] * m[3] * m[6];
  inv[7] =
    m[0] * m[6] * m[11] -
    m[0] * m[7] * m[10] -
    m[4] * m[2] * m[11] +
    m[4] * m[3] * m[10] +
    m[8] * m[2] * m[7] -
    m[8] * m[3] * m[6];
  inv[11] =
    -m[0] * m[5] * m[11] +
    m[0] * m[7] * m[9] +
    m[4] * m[1] * m[11] -
    m[4] * m[3] * m[9] -
    m[8] * m[1] * m[7] +
    m[8] * m[3] * m[5];
  inv[15] =
    m[0] * m[5] * m[10] -
    m[0] * m[6] * m[9] -
    m[4] * m[1] * m[10] +
    m[4] * m[2] * m[9] +
    m[8] * m[1] * m[6] -
    m[8] * m[2] * m[5];

  let det = m[0] * inv[0] + m[1] * inv[4] + m[2] * inv[8] + m[3] * inv[12];

  if (det === 0) {
    throw new Error('Matrix is not invertible');
  }

  det = 1.0 / det;

  for (let i = 0; i < 16; i += 1) {
    result[i] = inv[i] * det;
  }

  return result;
}

export function identityM4(): number[] {
  // prettier-ignore
  return [
    1, 0, 0, 0,
    0, 1, 0, 0,
    0, 0, 1, 0,
    0, 0, 0, 1
  ]
}

export function perspective(hFov: number, size: Size, zNear: number, zFar: number): number[] {
  const aspect = size.width / size.height;
  const fovY = 2 * Math.atan((Math.tan(hFov / 2) * size.height) / size.width);
  const f = 1 / Math.tan(fovY / 2);
  // prettier-ignore
  return [
    f / aspect, 0, 0, 0,
    0, f, 0, 0,
    0, 0, (zFar + zNear) / (zNear - zFar), (2 * zFar * zNear) / (zNear - zFar),
    0, 0, -1, 0,
  ];
}

export function rotateX(m3: number[], angleInRadians: number): number[] {
  if (__DEV__) {
    if (m3.length !== 9) {
      throw new Error('rotateX: m3.length !== 9');
    }
  }
  const { s, c } = getAngleSinCos(angleInRadians);

  // prettier-ignore
  return [
    m3[0], c * m3[1] + s * m3[2], c * m3[2] - s * m3[1],
    m3[3], c * m3[4] + s * m3[5], c * m3[5] - s * m3[4],
    m3[6], c * m3[7] + s * m3[8], c * m3[8] - s * m3[7],
  ];
}

export function rotateY(m3: number[], angleInRadians: number): number[] {
  if (__DEV__) {
    if (m3.length !== 9) {
      throw new Error('rotateY: m3.length !== 9');
    }
  }
  const { s, c } = getAngleSinCos(angleInRadians);
  // prettier-ignore
  return [
    c * m3[0] - s * m3[2], m3[1], c * m3[2] + s * m3[0],
    c * m3[3] - s * m3[5], m3[4], c * m3[5] + s * m3[3],
    c * m3[6] - s * m3[8], m3[7], c * m3[8] + s * m3[6],
  ];
}

export function rotateZ(m3: number[], angleInRadians: number): number[] {
  if (__DEV__) {
    if (m3.length !== 9) {
      throw new Error('rotateZ: m3.length !== 9');
    }
  }
  const { s, c } = getAngleSinCos(angleInRadians);
  // prettier-ignore
  return [
    c * m3[0] + s * m3[1], c * m3[1] - s * m3[0], m3[2],
    c * m3[3] + s * m3[4], c * m3[4] - s * m3[3], m3[5],
    c * m3[6] + s * m3[7], c * m3[7] - s * m3[6], m3[8],
  ];
}

export function m3toM4(m3: number[]): number[] {
  // prettier-ignore
  return [
    m3[0], m3[1], m3[2], 0,
    m3[3], m3[4], m3[5], 0,
    m3[6], m3[7], m3[8], 0,
    0, 0, 0, 1,
  ];
}

export function m3toM4Transpose(m3: number[]): number[] {
  // prettier-ignore
  return [
    m3[0], m3[3], m3[6], 0,
    m3[1], m3[4], m3[7], 0,
    m3[2], m3[5], m3[8], 0,
    0, 0, 0, 1,
  ];
}

export function transposeM4(m4: number[]): number[] {
  // prettier-ignore
  return [
    m4[0], m4[4], m4[8], m4[12],
    m4[1], m4[5], m4[9], m4[13],
    m4[2], m4[6], m4[10], m4[14],
    m4[3], m4[7], m4[11], m4[15],
  ]
}

export function transposeM3(m3: number[]): number[] {
  // prettier-ignore
  return [
    m3[0], m3[3], m3[6],
    m3[1], m3[4], m3[7],
    m3[2], m3[5], m3[8],
  ]
}

export function multiplyMatrix3AndVec3(matrix: number[], point: number[]): number[] {
  const c0r0 = matrix[0];
  const c1r0 = matrix[1];
  const c2r0 = matrix[2];
  const c0r1 = matrix[3];
  const c1r1 = matrix[4];
  const c2r1 = matrix[5];
  const c0r2 = matrix[6];
  const c1r2 = matrix[7];
  const c2r2 = matrix[8];
  const x = point[0];
  const y = point[1];
  const z = point[2];
  const resultX = x * c0r0 + y * c0r1 + z * c0r2;
  const resultY = x * c1r0 + y * c1r1 + z * c1r2;
  const resultZ = x * c2r0 + y * c2r1 + z * c2r2;
  return [resultX, resultY, resultZ];
}

export function multiplyM3(a: number[], b: number[]): number[] {
  const a00 = a[0 * 3 + 0];
  const a01 = a[0 * 3 + 1];
  const a02 = a[0 * 3 + 2];
  const a10 = a[1 * 3 + 0];
  const a11 = a[1 * 3 + 1];
  const a12 = a[1 * 3 + 2];
  const a20 = a[2 * 3 + 0];
  const a21 = a[2 * 3 + 1];
  const a22 = a[2 * 3 + 2];
  const b00 = b[0 * 3 + 0];
  const b01 = b[0 * 3 + 1];
  const b02 = b[0 * 3 + 2];
  const b10 = b[1 * 3 + 0];
  const b11 = b[1 * 3 + 1];
  const b12 = b[1 * 3 + 2];
  const b20 = b[2 * 3 + 0];
  const b21 = b[2 * 3 + 1];
  const b22 = b[2 * 3 + 2];
  // prettier-ignore
  return [
    b00 * a00 + b01 * a10 + b02 * a20,
    b00 * a01 + b01 * a11 + b02 * a21,
    b00 * a02 + b01 * a12 + b02 * a22,
    b10 * a00 + b11 * a10 + b12 * a20,
    b10 * a01 + b11 * a11 + b12 * a21,
    b10 * a02 + b11 * a12 + b12 * a22,
    b20 * a00 + b21 * a10 + b22 * a20,
    b20 * a01 + b21 * a11 + b22 * a21,
    b20 * a02 + b21 * a12 + b22 * a22,
  ]
}

export function translateM3(m: number[], tx: number, ty: number): number[] {
  return multiplyM3(m, translationM3(tx, ty));
}

export function rotateM3(m: number[], angleInRadians: number): number[] {
  return multiplyM3(m, rotationM3(angleInRadians));
}

export function scaleM3(m: number[], sx: number, sy: number): number[] {
  return multiplyM3(m, scalingM3(sx, sy));
}

export function scalingM4(_sx: number, _sy: number, _sz: number): number[] {
  const sx = _sx;
  const sy = _sy || _sx;
  const sz = _sz || _sx;
  // prettier-ignore
  return [
    sx, 0, 0, 0,
    0, sy, 0, 0,
    0, 0, sz, 0,
    0, 0, 0, 1,
  ]
}

// http://www.cse.unsw.edu.au/~cs9018/readings/projective_texture_mapping.pdf
export function eyeLinearTexgenMatrix(): number[] {
  // prettier-ignore
  return [
    0.5, 0.0, 0.0, 0.5,
    0.0, 0.5, 0.0, 0.5,
    0.0, 0.0, 0.5, 0.5,
    0.0, 0.0, 0.0, 1.0,
  ]
}

export function multiplyM4AndPoint(matrix: number[], point: number[]): number[] {
  const c0r0 = matrix[0];
  const c1r0 = matrix[1];
  const c2r0 = matrix[2];
  const c3r0 = matrix[3];
  const c0r1 = matrix[4];
  const c1r1 = matrix[5];
  const c2r1 = matrix[6];
  const c3r1 = matrix[7];
  const c0r2 = matrix[8];
  const c1r2 = matrix[9];
  const c2r2 = matrix[10];
  const c3r2 = matrix[11];
  const c0r3 = matrix[12];
  const c1r3 = matrix[13];
  const c2r3 = matrix[14];
  const c3r3 = matrix[15];
  const x = point[0];
  const y = point[1];
  const z = point[2];
  const w = point[3];
  const resultX = x * c0r0 + y * c0r1 + z * c0r2 + w * c0r3;
  const resultY = x * c1r0 + y * c1r1 + z * c1r2 + w * c1r3;
  const resultZ = x * c2r0 + y * c2r1 + z * c2r2 + w * c2r3;
  const resultW = x * c3r0 + y * c3r1 + z * c3r2 + w * c3r3;
  return [resultX, resultY, resultZ, resultW];
}

export function multiplyM4(matrixA: number[], matrixB: number[]): number[] {
  const column0 = [matrixB[0], matrixB[4], matrixB[8], matrixB[12]];
  const column1 = [matrixB[1], matrixB[5], matrixB[9], matrixB[13]];
  const column2 = [matrixB[2], matrixB[6], matrixB[10], matrixB[14]];
  const column3 = [matrixB[3], matrixB[7], matrixB[11], matrixB[15]];
  const result0 = multiplyM4AndPoint(matrixA, column0);
  const result1 = multiplyM4AndPoint(matrixA, column1);
  const result2 = multiplyM4AndPoint(matrixA, column2);
  const result3 = multiplyM4AndPoint(matrixA, column3);
  // prettier-ignore
  return [
    result0[0], result1[0], result2[0], result3[0],
    result0[1], result1[1], result2[1], result3[1],
    result0[2], result1[2], result2[2], result3[2],
    result0[3], result1[3], result2[3], result3[3],
  ]
}

export function translateM4(matrix: number[], x: number, y: number, z: number): number[] {
  const translationMatrix = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, x, y, z, 1];
  return multiplyM4(matrix, translationMatrix);
}

export function rotatePerspective(p: number[], r: number[]): number[] {
  // prettier-ignore
  return [
    p[0]*r[0], p[0]*r[1], p[0]*r[2], 0,
    p[5]*r[4], p[5]*r[5], p[5]*r[6], 0,
    p[10]*r[8], p[10]*r[9], p[10]*r[10], p[11],
    -r[8], -r[9], -r[10], 0,
  ];
}

export function applyRotatedPerspectiveToVector(m: number[], v: Float32Array): Float32Array {
  return new Float32Array([
    m[0] * v[0] + m[1] * v[1] + m[2] * v[2],
    m[4] * v[0] + m[5] * v[1] + m[6] * v[2],
    m[11] + m[8] * v[0] + m[9] * v[1] + m[10] * v[2],
    1 / (m[12] * v[0] + m[13] * v[1] + m[14] * v[2]),
  ]);
}

export default {
  rotationM3,
  perspective,
  identityM3,
  identityM4,
  rotateX,
  rotateY,
  rotateZ,
  m3toM4,
  m3toM4Transpose,
  transposeM4,
  multiplyMatrix3AndVec3,
  transposeM3,
  multiplyM3,
  translateM3,
  translateM4,
  rotateM3,
  scaleM3,
  translationM3,
  translationM4,
  scalingM3,
  scalingM4,
  eyeLinearTexgenMatrix,
  multiplyM4AndPoint,
  multiplyM4,
  invertM4,
  rotatePerspective,
  applyRotatedPerspectiveToVector,
};
