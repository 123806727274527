/* eslint-disable */
import type { Radian } from '@g360/vt-types';
import { FPMesh } from '../types';

export function debugMoveCamera(
  forward: number,
  right: number,
  up: number,
  pitch: Radian,
  yaw: Radian,
  orbitCenter: number[]
) {
  const forwardX = Math.sin(yaw) * Math.cos(pitch);
  const forwardY = Math.sin(pitch);
  const forwardZ = Math.cos(yaw) * Math.cos(pitch);
  const rightX = Math.cos(yaw);
  const rightZ = -Math.sin(yaw);

  return [
    orbitCenter[0] + (forward * forwardX + right * rightX),
    orbitCenter[1] + (forward * forwardY + up),
    orbitCenter[2] + (forward * forwardZ + right * rightZ),
  ];
}

export function calculateMilitaryTimeFromPercentage(percentage: number): string {
  // Ensure the percentage is between 0 and 1
  const clampedPercentage = Math.max(0, Math.min(1, percentage));
  // Calculate total minutes in a day from the percentage
  const totalMinutes = clampedPercentage * 1440;
  // Calculate hours and minutes
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  // Format hours and minutes to two digits and concatenate with a colon
  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function drawShapesOnCanvas(meshes: FPMesh[], canvasId: string = 'shapeCanvas') {
  let shapes: number[][] = [];
  let debugIds: string[] = [];
  for (let i = 0; i < meshes.length; i += 1) {
    const mesh = meshes[i];
    const debugId = mesh.debugId;

    const flatWallMesh = mesh.shape2d.flat();
    shapes.push(flatWallMesh);
    debugIds.push(debugId + '');

    if (mesh.debugLine) {
      const rays = mesh.debugLine.flat();
      shapes.push(rays);
      debugIds.push('');
    }
  }

  // delete existing canvas
  const existingCanvas = document.getElementById(canvasId);
  if (existingCanvas) existingCanvas.remove();

  // Create canvas element
  const canvas = document.createElement('canvas');
  canvas.id = canvasId;
  canvas.width = 800; // You can adjust these dimensions
  canvas.height = 600;
  canvas.style.position = 'absolute';
  canvas.style.pointerEvents = 'none';
  canvas.style.top = '0';
  canvas.style.left = '0';

  document.body.appendChild(canvas);

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    console.error('Could not get 2D context');
    return;
  }

  // Find data bounds
  const allPoints = shapes.flat();
  const xValues = allPoints.filter((_, i) => i % 2 === 0);
  const yValues = allPoints.filter((_, i) => i % 2 === 1);
  const minX = Math.min(...xValues);
  const maxX = Math.max(...xValues);
  const minY = Math.min(...yValues);
  const maxY = Math.max(...yValues);

  // Function to map data coordinates to canvas coordinates
  // flipping Y axis
  const mapToCanvas = (x: number, y: number) => {
    const padding = 50;
    const scaleX = (canvas.width - 2 * padding) / (maxX - minX);
    const scaleY = (canvas.height - 2 * padding) / (maxY - minY);
    return {
      x: padding + (x - minX) * scaleX,
      y: padding + (y - minY) * scaleY, // Y-axis flipped here
    };
  };

  // Draw shapes
  shapes.forEach((shape, index) => {
    ctx.beginPath();
    let centerX = 0,
      centerY = 0;
    for (let i = 0; i < shape.length; i += 2) {
      const { x, y } = mapToCanvas(shape[i], shape[i + 1]);
      if (i === 0) {
        ctx.moveTo(x, y);
      } else {
        ctx.lineTo(x, y);
      }
      centerX += x;
      centerY += y;
    }
    ctx.closePath();

    // Calculate center of the shape
    centerX /= shape.length / 2;
    centerY /= shape.length / 2;

    // Set a different color for each shape
    ctx.fillStyle = `hsla(${(index * 137) % 360}, 50%, 50%, 0.5)`;
    ctx.fill();

    ctx.strokeStyle = `hsl(${(index * 137) % 360}, 70%, 30%)`;
    ctx.stroke();

    // Draw debug ID
    if (debugIds[index] !== undefined) {
      ctx.fillStyle = 'black';
      ctx.font = '12px Arial';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(debugIds[index].toString(), centerX, centerY);
    }
  });
}

export function isThereANan(v): boolean {
  for (const x of v) {
    if (Number.isNaN(x)) return true;
  }
  return false;
}
